import React, { useCallback, useContext, useEffect, useState } from "react";
import usePageTracking from "../../util/usePageTracking";

import { useNavigate } from "react-router";

import { useQuery } from "@apollo/client";

import Confetti from "react-confetti";
import LiveStreamGradBack from "../../components/creator/LiveStreamGradBack";

import { customerClient, guestClient } from "../../GraphqlApolloClients";

import { CustomerAuthContext } from "../../context/customerAuth";
import { useForm, useWindowSize } from "../../util/hooks";

import { useElements, useStripe } from "@stripe/react-stripe-js";
import BuyCoinsForm from "../../components/creator/BuyCoinsForm";
import BuySubscriptionForm from "../../components/creator/BuySubscriptionForm";
import CodeForm from "../../components/creator/CodeForm";

import BattleNotifButton from "../../components/creator/BattleNotifButton";
import FriendNotifButton from "../../components/creator/FriendNotifButton";
import GenericPaymentSuccessfulForm from "../../components/creator/GenericPaymentSuccessfulForm";
import MiniNavBar from "../../components/creator/MiniNavBar";
import PowerUpPaymentSuccessfulForm from "../../components/creator/PowerUpPaymentSuccessfulForm";
import TNReviewForm from "../../components/creator/TNReviewForm";
import TopBar from "../../components/creator/TopBar";
import {
  ACTIVE_NOTIFICATION_QUERY,
  FETCH_CUSTOMER,
  FETCH_GUEST_CUSTOMER,
} from "../../util/gqlFunctions";

function Notifications(props) {
  usePageTracking();

  let navigate = useNavigate();
  const size = useWindowSize();

  const { customer, logoutCustomer: logout } = useContext(CustomerAuthContext);

  useEffect(() => {
    document.title = "Notifications | TKnight";
  }, []);

  const [shouldPlaySoundEffects, setShouldPlaySoundEffects] = useState(false);

  const {
    data: { getCustomer: targetCustomer } = {},
    loading: loadingCustomerInfo,
  } = useQuery(FETCH_CUSTOMER, {
    client: customerClient,
  });

  const {
    data: { getGuestCustomer: targetGuestCustomer } = {},
    // loading: loadingGuestCustomer,
  } = useQuery(FETCH_GUEST_CUSTOMER, { client: guestClient });

  const [checkedIsCustomerLoggedIn, setCheckedIsCustomerLoggedIn] =
    useState(false);

  useEffect(() => {
    if (
      (customer &&
        !loadingCustomerInfo &&
        !targetCustomer &&
        !checkedIsCustomerLoggedIn) ||
      (targetCustomer && targetCustomer.isBlocked)
    ) {
      logout();
      setCheckedIsCustomerLoggedIn(true);
    }
  }, [
    customer,
    loadingCustomerInfo,
    targetCustomer,
    logout,
    checkedIsCustomerLoggedIn,
  ]);

  useEffect(() => {
    if (!customer) {
      navigate("/login");
    }
  }, [customer, navigate]);

  const resetInterval = 1000;

  const [isInviteCopied, setIsInviteCopied] = useState(null);
  const [isSharingInviteToApps, setIsSharingInviteToApps] = useState(false);

  const [isCaptionCopied, setIsCaptionCopied] = useState(null);
  const [isSharingToApps, setIsSharingToApps] = useState(false);

  const [isFirstTime, setIsFirstTime] = useState(true);

  const { values, setValues } = useForm(toggleFriendCallback, {});

  useEffect(() => {
    let timeout;
    if (isCaptionCopied && resetInterval) {
      timeout = setTimeout(() => setIsCaptionCopied(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isCaptionCopied, resetInterval]);

  const [subscriptionType, setSubscriptionType] = useState(null);

  useEffect(() => {
    let timeout;
    if (isInviteCopied && resetInterval) {
      timeout = setTimeout(() => setIsInviteCopied(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isInviteCopied, resetInterval]);
  const stripe = useStripe();
  const elements = useElements();

  const [shouldShowMiniNavBar, setShouldShowMiniNavBar] = useState(false);

  const inviteFriends = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    var textToShare = "Check out tknight.live\n\n";
    textToShare +=
      "If you survive 7 trivia questions, you get to split the prize with other winners. ";
    // if (targetCustomer.totalEarnings) {
    //   textToShare +=
    //     "I've won $" +
    //     (targetCustomer.totalEarnings / 100).toFixed(2) +
    //     " so far! ";
    // }
    textToShare += "It's really fun!!\n\n";
    if (targetCustomer) {
      textToShare +=
        'Sign up with my extra ❤️ code: "' + targetCustomer.username + '"\n\n';
    }
    textToShare += "#TKnight";

    // textToShare +=
    //   " ".repeat(registrationAddress.length + 11) +
    //   "``" +
    //   "`".repeat(Math.ceil(targetCustomer.username.length * 1.7)) +
    //   "``\n\n";

    // await handleInviteDownload();

    if (navigator.share) {
      navigator
        .share({
          title: "Battle on TKnight!",
          text: textToShare,
          // url: "http://worldphraze.com/",
          // url:
          //   playersPhraze && playersPhraze.isEligibleForPrize
          //     ? "W/" + playersPhraze.id
          //     : "phraze",
        })
        .then(() => {
          setIsInviteCopied(true);

          setIsSharingInviteToApps(true);
        })
        .catch((error) => {
          setIsSharingInviteToApps(false);
          navigator.clipboard.writeText(textToShare);
          setIsInviteCopied(true);
        });
    } else {
      navigator.clipboard.writeText(textToShare);
      setIsInviteCopied(true);
    }
  };

  const [shouldOpenReviewForm, setShouldOpenReviewForm] = useState(false);

  const {
    data: { getActiveNotifications: ongoingBattleNotifs } = [],
    loading: loadingOngoingBattleNotifs,
  } = useQuery(ACTIVE_NOTIFICATION_QUERY, {
    client: customerClient,
    variables: {
      type: 0,
    },
    // fetchPolicy: 'cache-and-network',
  });

  const {
    data: { getActiveNotifications: battleRequestNotifs } = [],
    loading: loadingBattleRequestNotifs,
  } = useQuery(ACTIVE_NOTIFICATION_QUERY, {
    client: customerClient,
    variables: {
      type: 1,
    },
    // fetchPolicy: 'cache-and-network',
  });
  const {
    data: { getActiveNotifications: battleResultNotifs } = [],
    loading: loadingBattleResultNotifs,
  } = useQuery(ACTIVE_NOTIFICATION_QUERY, {
    client: customerClient,
    variables: {
      type: 2,
    },
    // fetchPolicy: 'cache-and-network',
  });
  const {
    data: { getActiveNotifications: friendRequestNotifs } = [],
    loading: loadingFriendRequestNotifs,
  } = useQuery(ACTIVE_NOTIFICATION_QUERY, {
    client: customerClient,
    variables: {
      type: 5,
    },
    // fetchPolicy: 'cache-and-network',
  });

  const [shouldOpenProInviteCodeForm, setShouldOpenProInviteCodeForm] =
    useState(false);

  function toggleFriendCallback() {}

  const [shouldOpenBuyCoinsForm, setShouldOpenBuyCoinsForm] = useState(false);
  const [
    shouldOpenCoinPurchaseSuccessfulForm,
    setShouldOpenCoinPurchaseSuccessfulForm,
  ] = useState(false);
  const [shouldOpenPaymentSuccessfulForm, setShouldOpenPaymentSuccessfulForm] =
    useState(false);
  const [
    shouldOpenSubPaymentSuccessfulForm,
    setShouldOpenSubPaymentSuccessfulForm,
  ] = useState(false);

  const [shouldOpenJoinedProPlanPopup, setShouldOpenJoinedProPlanPopup] =
    useState(false);
  const [shouldOpenReviewSentForm, setShouldOpenReviewSentForm] =
    useState(false);
  const toggleBuySubscriptionForm = (subscriptionType) => {
    setShouldOpenSubPaymentSuccessfulForm(false);

    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(false);

    setSubscriptionType(subscriptionType);
  };

  const toggleReviewForm = useCallback(() => {
    setShouldOpenSubPaymentSuccessfulForm(false);

    setShouldOpenPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(false);

    setShouldOpenReviewForm(!shouldOpenReviewForm);
  }, [shouldOpenReviewForm]);
  const toggleReviewSentForm = () => {
    setShouldOpenSubPaymentSuccessfulForm(false);

    setShouldOpenPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(false);

    setShouldOpenReviewSentForm(!shouldOpenReviewSentForm);
  };

  const toggleCoinPurchaseSuccessfulForm = () => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);

    setShouldOpenBuyCoinsForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(false);

    setShouldOpenCoinPurchaseSuccessfulForm(
      !shouldOpenCoinPurchaseSuccessfulForm
    );
  };
  const toggleBuyCoinsForm = () => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);

    setShouldOpenCoinPurchaseSuccessfulForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(false);

    setShouldOpenBuyCoinsForm(!shouldOpenBuyCoinsForm);
  };
  const toggleSubPaymentSuccessfulForm = () => {
    setSubscriptionType(null);

    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(false);

    setShouldOpenSubPaymentSuccessfulForm(!shouldOpenSubPaymentSuccessfulForm);
  };
  const toggleJoinedProPlanPopup = () => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(false);

    setShouldOpenJoinedProPlanPopup(!shouldOpenJoinedProPlanPopup);
  };
  const toggleProInviteCodeForm = () => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(!shouldOpenProInviteCodeForm);
  };
  const togglePaymentSuccessfulForm = () => {
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldShowMiniNavBar(false);
    setShouldOpenProInviteCodeForm(false);

    setShouldOpenPaymentSuccessfulForm(!shouldOpenPaymentSuccessfulForm);
  };

  return (
    <div className="w-screen overflow-hidden relative h-full min-h-screen">
      <LiveStreamGradBack gradIndex={1} />

      {shouldShowMiniNavBar && targetCustomer && (
        <MiniNavBar
          targetCustomer={targetCustomer}
          isSharingInviteToApps={isSharingInviteToApps}
          isSharingToApps={isSharingToApps || isSharingInviteToApps}
          isShareCopied={isCaptionCopied || isInviteCopied}
          setIsSharingToApps={setIsSharingToApps}
          navigate={navigate}
          isInviteCopied={isInviteCopied}
          inviteFriends={inviteFriends}
          toggleReviewForm={toggleReviewForm}
          shouldShowMiniNavBar={shouldShowMiniNavBar}
          isFirstTime={isFirstTime}
          setIsFirstTime={setIsFirstTime}
          setShouldShowMiniNavBar={setShouldShowMiniNavBar}
          logout={logout}
          setShouldPlaySoundEffects={setShouldPlaySoundEffects}
          shouldPlaySoundEffects={shouldPlaySoundEffects}
          toggleBuySubscriptionForm={toggleBuySubscriptionForm}
        />
      )}

      {shouldOpenBuyCoinsForm && stripe && elements ? (
        <BuyCoinsForm
          stripe={stripe}
          elements={elements}
          targetCustomer={targetCustomer}
          toggleCoinPurchaseSuccessfulForm={toggleCoinPurchaseSuccessfulForm}
          shouldOpenBuyCoinsForm={shouldOpenBuyCoinsForm}
          quizId={null}
          toggleBuyCoinsForm={toggleBuyCoinsForm}
        />
      ) : (
        <></>
      )}

      {shouldOpenReviewForm && (targetCustomer || targetGuestCustomer) ? (
        <TNReviewForm
          shouldOpenReviewForm={shouldOpenReviewForm}
          toggleReviewForm={toggleReviewForm}
          targetCustomer={targetCustomer}
          toggleReviewSentForm={toggleReviewSentForm}
        />
      ) : (
        <></>
      )}

      {subscriptionType !== null && stripe && elements && targetCustomer ? (
        <BuySubscriptionForm
          stripe={stripe}
          elements={elements}
          targetCustomer={targetCustomer}
          togglePaymentSuccessfulForm={toggleSubPaymentSuccessfulForm}
          subscriptionType={subscriptionType}
          toggleBuySubscriptionForm={toggleBuySubscriptionForm}
          toggleProInviteCodeForm={toggleProInviteCodeForm}
          // subPopupReason={""}
        />
      ) : (
        <></>
      )}

      {shouldOpenProInviteCodeForm && targetCustomer && (
        <CodeForm
          title="TKnight VIP Code"
          name="inviteCode"
          isCodeBoxOpen={shouldOpenProInviteCodeForm}
          setIsCodeBoxOpen={setShouldOpenProInviteCodeForm}
          typeOfCode={0}
          values={values}
          setValues={setValues}
          toggleJoinedProPlanPopup={toggleJoinedProPlanPopup}
          toggleProInviteCodeForm={toggleProInviteCodeForm}
        />
      )}
      {shouldOpenJoinedProPlanPopup && targetCustomer && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleJoinedProPlanPopup}
            targetCustomer={targetCustomer}
            message={"Welcome to TKnight VIP, " + targetCustomer.name + "!"}
            buttonMsg={"Thanks!"}
            popUpTitle={
              "Joined " +
              (targetCustomer.subscriptionType === 0
                ? "Individual"
                : targetCustomer.subscriptionType === 1
                ? "Duo"
                : targetCustomer.subscriptionType === 2
                ? "Family"
                : "Club") +
              " Successfully!"
            }
          />
          <div className="absolute z-[90] pointer-events-none inset-0">
            <Confetti
              width={size.width}
              height={size.height}
              recycle={false}
              numberOfPieces={size.width >= 640 ? 1500 : 1000}
              tweenDuration={30 * 1000}
              gravity={0.3}
            />
          </div>
        </>
      )}
      {shouldOpenSubPaymentSuccessfulForm && targetCustomer && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleSubPaymentSuccessfulForm}
            targetCustomer={targetCustomer}
            message={"Welcome to TKnight VIP, " + targetCustomer.name + "."}
            buttonMsg={"Thanks!"}
          />
          <div className="absolute z-[90] pointer-events-none inset-0">
            <Confetti
              width={size.width}
              height={size.height}
              recycle={false}
              numberOfPieces={size.width >= 640 ? 1500 : 1000}
              tweenDuration={30 * 1000}
              gravity={0.3}
            />
          </div>
        </>
      )}

      {shouldOpenCoinPurchaseSuccessfulForm && targetCustomer && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleCoinPurchaseSuccessfulForm}
            targetCustomer={targetCustomer}
            message={
              "Tokens added to stash. Good luck, " + targetCustomer.name + "!"
            }
            buttonMsg={"Awesome!"}
            popUpTitle={"Payment Successful!"}
          />
        </>
      )}

      {shouldOpenReviewSentForm && targetCustomer && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleReviewSentForm}
            targetCustomer={targetCustomer}
            popUpTitle={"Thank you!"}
            message={"This will help improve the game :)"}
            buttonMsg={"Awesome!"}
          />
        </>
      )}
      {shouldOpenPaymentSuccessfulForm && targetCustomer && (
        <PowerUpPaymentSuccessfulForm
          togglePaymentSuccessfulForm={togglePaymentSuccessfulForm}
          targetCustomer={targetCustomer}
        />
      )}

      <div
        className={`relative z-10 overflow-hidden flex flex-col h-full short:max-w-xl lg:max-w-xl 2xl:max-w-2xl 3xl:max-w-3xl mx-auto xl:px-0 px-4 pb-4 lg:pb-8`}
      >
        <TopBar
          topicShortForm={null}
          navigate={navigate}
          toggleBuyCoinsForm={toggleBuyCoinsForm}
          targetGuestCustomer={targetGuestCustomer}
          targetCustomer={targetCustomer}
          setShouldShowMiniNavBar={setShouldShowMiniNavBar}
          setIsFirstTime={setIsFirstTime}
          customer={customer}
          areFriendRequestsAvailable={false}
          toggleBuySubscriptionForm={toggleBuySubscriptionForm}
          page="Notifications"
          values={values}
          setValues={setValues}
        />
        <div className="flex h-full flex-col w-full overflow-y-auto z-50 items-center justify-start flex-1">
          {/* <p className="sm:hidden text-left mb-2 leading-none w-full short:text-xl text-2xl font-semibold text-white">
            Notifications
          </p> */}

          {ongoingBattleNotifs && ongoingBattleNotifs.length > 0 ? (
            <div className="mb-8 w-full shrink-0">
              <p className="px-2 sm:px-4 mb-2 sm:text-lg text-sm leading-tight font-semibold text-gray-100">
                Ongoing Battles
              </p>

              <div className="w-full bg-white sm:rounded-2xl rounded-xl p-2 short:p-1 flex flex-col items-start justify-start">
                {ongoingBattleNotifs.map((battleNotif, index) => (
                  <BattleNotifButton
                    navigate={navigate}
                    battleNotif={battleNotif}
                    key={battleNotif.id}
                    index={index}
                  />
                ))}
              </div>
            </div>
          ) : (
            <></>
          )}

          {battleRequestNotifs && battleRequestNotifs.length > 0 ? (
            <div className="mb-8 w-full shrink-0">
              <p className="px-2 sm:px-4 mb-2 sm:text-lg text-sm leading-tight font-semibold text-gray-100">
                Battle Requests
              </p>

              <div className="w-full bg-white sm:rounded-2xl rounded-xl p-2 short:p-1 flex flex-col items-start justify-start">
                {battleRequestNotifs.map((battleNotif, index) => (
                  <BattleNotifButton
                    navigate={navigate}
                    battleNotif={battleNotif}
                    key={battleNotif.id}
                    index={index}
                  />
                ))}
              </div>
            </div>
          ) : (
            <></>
          )}

          {battleResultNotifs && battleResultNotifs.length > 0 ? (
            <div className="mb-8 w-full shrink-0">
              <p className="px-2 sm:px-4 mb-2 sm:text-lg text-sm leading-tight font-semibold text-gray-100">
                Battle Results
              </p>

              <div className="w-full bg-white sm:rounded-2xl rounded-xl p-2 short:p-1 flex flex-col items-start justify-start">
                {battleResultNotifs.map((battleNotif, index) => (
                  <BattleNotifButton
                    navigate={navigate}
                    battleNotif={battleNotif}
                    key={battleNotif.id}
                    index={index}
                  />
                ))}
              </div>
            </div>
          ) : (
            <></>
          )}

          {friendRequestNotifs && friendRequestNotifs.length > 0 ? (
            <div className="mb-8 w-full shrink-0">
              <p className="px-2 sm:px-4 mb-2 sm:text-lg text-sm leading-tight font-semibold text-gray-100">
                Friend Requests
              </p>

              <div className="w-full bg-white sm:rounded-2xl rounded-xl p-2 short:p-1 flex flex-col items-start justify-start">
                {friendRequestNotifs.map((friendNotif, index) => (
                  <FriendNotifButton
                    navigate={navigate}
                    friendNotif={friendNotif}
                    key={friendNotif.id}
                    index={index}
                  />
                ))}
              </div>
            </div>
          ) : (
            <></>
          )}

          {loadingCustomerInfo ||
          loadingBattleRequestNotifs ||
          loadingBattleResultNotifs ||
          loadingFriendRequestNotifs ||
          loadingOngoingBattleNotifs ? (
            <div>
              <svg
                className={`h-6 text-white stroke-current m-auto motion-safe:animate-spin`}
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.636 3.636A9 9 0 0119 10"
                  strokeWidth={2}
                  strokeLinecap="round"
                />
              </svg>
            </div>
          ) : (ongoingBattleNotifs && ongoingBattleNotifs.length > 0) ||
            (battleRequestNotifs && battleRequestNotifs.length > 0) ||
            (battleResultNotifs && battleResultNotifs.length > 0) ||
            (friendRequestNotifs && friendRequestNotifs.length > 0) ? (
            <></>
          ) : (
            <div className="rounded-lg text-center mt-4 text-white font-semibold px-4 py-2 max-w-xs transition-all">
              <p className="text-xl short:text-lg whitespace-nowrap w-full">
                No new notifications
              </p>
              {/* <p className="whitespace-normal font-normal">
                Once more people play, you will see detailed rankings here
              </p> */}
            </div>
          )}
          {values.groupType === "Friends" && !targetCustomer && (
            <div className="rounded-lg text-center mt-4 text-white font-semibold px-4 py-2 max-w-xs transition-all">
              <button
                onClick={(e) => {
                  navigate("/register");
                }}
                className="text-xl text-yellow-200 whitespace-nowrap w-full hover:opacity-70 transition-all"
              >
                Sign in to add friends
              </button>
              <p className=" font-normal">
                Once your friends battle, you will see detailed rankings here
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Notifications;
