import React, { useCallback, useContext, useEffect, useState } from "react";
import usePageTracking from "../../util/usePageTracking";

import { useNavigate, useParams } from "react-router";

import { useQuery } from "@apollo/client";

import Confetti from "react-confetti";
import LiveStreamGradBack from "../../components/creator/LiveStreamGradBack";

import { customerClient, guestClient } from "../../GraphqlApolloClients";

import { CustomerAuthContext } from "../../context/customerAuth";
import { useForm, useWindowSize } from "../../util/hooks";

import { useElements, useStripe } from "@stripe/react-stripe-js";
import BuyCoinsForm from "../../components/creator/BuyCoinsForm";
import BuySubscriptionForm from "../../components/creator/BuySubscriptionForm";
import CodeForm from "../../components/creator/CodeForm";

import EmptyLeaderPodium from "../../components/creator/EmptyLeaderPodium";
import GenericPaymentSuccessfulForm from "../../components/creator/GenericPaymentSuccessfulForm";
import LeaderPodium from "../../components/creator/LeaderPodium";
import LeaderboardTable from "../../components/creator/LeaderboardTable";
import MiniNavBar from "../../components/creator/MiniNavBar";
import PowerUpPaymentSuccessfulForm from "../../components/creator/PowerUpPaymentSuccessfulForm";
import TNReviewForm from "../../components/creator/TNReviewForm";
import TopBar from "../../components/creator/TopBar";
import {
  FETCH_CITY_BY_ID,
  FETCH_CUSTOMER,
  FETCH_GUEST_CUSTOMER,
  FETCH_RANKED_CITIES,
  FETCH_RANKED_COUNTRIES,
  FETCH_RANKED_FRIENDS,
  FETCH_RANKED_GROUPS,
  FETCH_TOPIC_BY_SHORT_FORM,
} from "../../util/gqlFunctions";

const countryFlags = {
  All: "🌍",
  Australia: "🇦🇺",
  Austria: "🇦🇹",
  Belgium: "🇧🇪",
  Brazil: "🇧🇷",
  Bulgaria: "🇧🇬",
  Canada: "🇨🇦",
  Chile: "🇨🇱",
  Croatia: "🇭🇷",
  Cyprus: "🇨🇾",
  "Czech Republic": "🇨🇿",
  Denmark: "🇩🇰",
  Estonia: "🇪🇪",
  Finland: "🇫🇮",
  France: "🇫🇷",
  Germany: "🇩🇪",
  Greece: "🇬🇷",
  China: "🇨🇳",
  Hungary: "🇭🇺",
  India: "🇮🇳",
  Indonesia: "🇮🇩",
  Ireland: "🇮🇪",
  Italy: "🇮🇹",
  Japan: "🇯🇵",
  Latvia: "🇱🇻",
  Lithuania: "🇱🇹",
  Luxembourg: "🇱🇺",
  Malaysia: "🇲🇾",
  Malta: "🇲🇹",
  Mexico: "🇲🇽",
  Netherlands: "🇳🇱",
  "New Zealand": "🇳🇿",
  Norway: "🇳🇴",
  Pakistan: "🇵🇰",
  Philippines: "🇵🇭",
  Poland: "🇵🇱",
  Portugal: "🇵🇹",
  Romania: "🇷🇴",
  Serbia: "🇷🇸",
  Singapore: "🇸🇬",
  Slovakia: "🇸🇰",
  Slovenia: "🇸🇮",
  Spain: "🇪🇸",
  Sweden: "🇸🇪",
  Switzerland: "🇨🇭",
  Thailand: "🇹🇭",
  "United Arab Emirates": "🇦🇪",
  "United Kingdom": "🇬🇧",
  "United States": "🇺🇸",
};

function capitalizeFirstLetter(word) {
  return word
    ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    : null;
}

function Leaderboard(props) {
  usePageTracking();

  let navigate = useNavigate();
  const size = useWindowSize();

  const { customer, logoutCustomer: logout } = useContext(CustomerAuthContext);

  const { topicShortForm, category, subCategory } = useParams();
  const {
    data: { getTopicByShortForm: targetTopic } = {},
    loading: loadingTopic,
  } = useQuery(FETCH_TOPIC_BY_SHORT_FORM, {
    variables: { shortForm: topicShortForm },
  });
  useEffect(() => {
    if (targetTopic) {
      document.title = targetTopic.name + " Leaderboard | TKnight";
    } else {
      document.title = "Leaderboard | TKnight";
    }
  }, [targetTopic]);

  const [shouldPlaySoundEffects, setShouldPlaySoundEffects] = useState(false);
  const [initializedMuteVals, setInitializedMuteVals] = useState(false);

  const {
    data: { getCustomer: targetCustomer } = {},
    loading: loadingCustomerInfo,
  } = useQuery(FETCH_CUSTOMER, {
    client: customerClient,
  });

  const {
    data: { getGuestCustomer: targetGuestCustomer } = {},
    // loading: loadingGuestCustomer,
  } = useQuery(FETCH_GUEST_CUSTOMER, { client: guestClient });

  const [checkedIsCustomerLoggedIn, setCheckedIsCustomerLoggedIn] =
    useState(false);

  useEffect(() => {
    if (
      (customer &&
        !loadingCustomerInfo &&
        !targetCustomer &&
        !checkedIsCustomerLoggedIn) ||
      (targetCustomer && targetCustomer.isBlocked)
    ) {
      logout();
      setCheckedIsCustomerLoggedIn(true);
    }
  }, [
    customer,
    loadingCustomerInfo,
    targetCustomer,
    logout,
    checkedIsCustomerLoggedIn,
  ]);

  const resetInterval = 1000;

  const [isInviteCopied, setIsInviteCopied] = useState(null);
  const [isSharingInviteToApps, setIsSharingInviteToApps] = useState(false);

  const [isCaptionCopied, setIsCaptionCopied] = useState(null);
  const [isSharingToApps, setIsSharingToApps] = useState(false);

  const [isFirstTime, setIsFirstTime] = useState(true);

  const { values, setValues } = useForm(toggleFriendCallback, {
    timeRange: "All-Time",
    seenIds: [],
    sortBy: "Earnings",
    topicShortForm,
    orgId: category === "club" ? subCategory || "" : null,
    country: category === "country" ? subCategory || "" : null,
    cityId: category === "city" ? subCategory || "" : null,
    category: category,
    subCategory: subCategory,

    groupType: category ? capitalizeFirstLetter(category) : "City",

    orgIds: null,
    shortForm: null,

    topicId: targetTopic ? targetTopic.id : "",
  });

  useEffect(() => {
    let timeout;
    if (isCaptionCopied && resetInterval) {
      timeout = setTimeout(() => setIsCaptionCopied(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isCaptionCopied, resetInterval]);

  useEffect(() => {
    if (!values.topicId && targetTopic) {
      setValues({ ...values, topicId: targetTopic.id });
    }
  }, [setValues, targetTopic, values]);

  const [subscriptionType, setSubscriptionType] = useState(null);

  useEffect(() => {
    let timeout;
    if (isInviteCopied && resetInterval) {
      timeout = setTimeout(() => setIsInviteCopied(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isInviteCopied, resetInterval]);
  const stripe = useStripe();
  const elements = useElements();
  // const { speak, cancel } = useSpeechSynthesis();
  // const speakRef = useRef();
  // const cancelRef = useRef();

  const [shouldShowMiniNavBar, setShouldShowMiniNavBar] = useState(false);

  // useEffect(() => {
  //   speakRef.current = speak;
  // }, [speak]);

  // useEffect(() => {
  //   cancelRef.current = cancel;
  // }, [cancel]);

  const inviteFriends = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    var textToShare = "Check out tknight.live\n\n";
    textToShare +=
      "If you survive 7 trivia questions, you get to split the prize with other winners. ";
    // if (targetCustomer.totalEarnings) {
    //   textToShare +=
    //     "I've won $" +
    //     (targetCustomer.totalEarnings / 100).toFixed(2) +
    //     " so far! ";
    // }
    textToShare += "It's really fun!!\n\n";
    if (targetCustomer) {
      textToShare +=
        'Sign up with my extra ❤️ code: "' + targetCustomer.username + '"\n\n';
    }
    textToShare += "#TKnight";

    // textToShare +=
    //   " ".repeat(registrationAddress.length + 11) +
    //   "``" +
    //   "`".repeat(Math.ceil(targetCustomer.username.length * 1.7)) +
    //   "``\n\n";

    // await handleInviteDownload();

    if (navigator.share) {
      navigator
        .share({
          title: "Battle on TKnight!",
          text: textToShare,
          // url: "http://worldphraze.com/",
          // url:
          //   playersPhraze && playersPhraze.isEligibleForPrize
          //     ? "W/" + playersPhraze.id
          //     : "phraze",
        })
        .then(() => {
          setIsInviteCopied(true);

          setIsSharingInviteToApps(true);
        })
        .catch((error) => {
          setIsSharingInviteToApps(false);
          navigator.clipboard.writeText(textToShare);
          setIsInviteCopied(true);
        });
    } else {
      navigator.clipboard.writeText(textToShare);
      setIsInviteCopied(true);
    }
  };

  const {
    data: { getRankedCities: rankedCities } = {},
    loading: loadingCities,
  } = useQuery(FETCH_RANKED_CITIES, {
    client: customer ? customerClient : guestClient,
    variables: values,
  });

  const [oldRankedCities, setOldRankedCities] = useState(null);

  useEffect(() => {
    if (rankedCities && rankedCities.length > 0) {
      setOldRankedCities(rankedCities);
    }
  }, [rankedCities]);

  const {
    data: { getRankedFriends: rankedFriends } = {},
    loading: loadingFriends,
  } = useQuery(FETCH_RANKED_FRIENDS, {
    client: customer ? customerClient : guestClient,
    variables: values,
  });

  const [oldRankedFriends, setOldRankedFriends] = useState(null);

  useEffect(() => {
    if (rankedFriends) {
      setOldRankedFriends(rankedFriends);
    }
  }, [rankedFriends]);

  const {
    data: { getRankedCountries: rankedCountries } = {},
    loading: loadingCountries,
  } = useQuery(FETCH_RANKED_COUNTRIES, {
    client: customer ? customerClient : guestClient,
    variables: values,
  });

  const [oldRankedCountries, setOldRankedCountries] = useState(null);
  const [shouldOpenReviewForm, setShouldOpenReviewForm] = useState(false);
  useEffect(() => {
    if (!initializedMuteVals && targetCustomer) {
      setShouldPlaySoundEffects(
        targetCustomer.areSoundEffectsMuted === true ? false : true
      );
      setInitializedMuteVals(true);
    }
  }, [shouldPlaySoundEffects, initializedMuteVals, targetCustomer]);

  useEffect(() => {
    if (rankedCountries && rankedCountries.length > 0) {
      setOldRankedCountries(rankedCountries);
    }
  }, [rankedCountries]);

  const {
    data: { getRankedOrgs: rankedGroups } = {},
    loading: loadingRankedGroups,
  } = useQuery(FETCH_RANKED_GROUPS, {
    client: customer ? customerClient : guestClient,
    variables: values,
  });

  const [shouldOpenProInviteCodeForm, setShouldOpenProInviteCodeForm] =
    useState(false);
  const [oldRankedGroups, setOldRankedGroups] = useState(null);

  useEffect(() => {
    if (rankedGroups && rankedGroups.length > 0) {
      setOldRankedGroups(rankedGroups);
    }
  }, [rankedGroups]);

  function toggleFriendCallback() {}

  const [shouldOpenBuyCoinsForm, setShouldOpenBuyCoinsForm] = useState(false);
  const [
    shouldOpenCoinPurchaseSuccessfulForm,
    setShouldOpenCoinPurchaseSuccessfulForm,
  ] = useState(false);
  const [shouldOpenPaymentSuccessfulForm, setShouldOpenPaymentSuccessfulForm] =
    useState(false);
  const [
    shouldOpenSubPaymentSuccessfulForm,
    setShouldOpenSubPaymentSuccessfulForm,
  ] = useState(false);

  const [shouldOpenJoinedProPlanPopup, setShouldOpenJoinedProPlanPopup] =
    useState(false);
  const [shouldOpenReviewSentForm, setShouldOpenReviewSentForm] =
    useState(false);
  const toggleBuySubscriptionForm = (subscriptionType) => {
    setShouldOpenSubPaymentSuccessfulForm(false);

    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(false);

    setSubscriptionType(subscriptionType);

    if (subscriptionType === null) {
      setShouldOpenReviewForm(true);
    } else {
      setShouldOpenReviewForm(false);
    }
  };

  const toggleReviewForm = useCallback(() => {
    setShouldOpenSubPaymentSuccessfulForm(false);

    setShouldOpenPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(false);

    setShouldOpenReviewForm(!shouldOpenReviewForm);
  }, [shouldOpenReviewForm]);
  const toggleReviewSentForm = () => {
    setShouldOpenSubPaymentSuccessfulForm(false);

    setShouldOpenPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(false);

    setShouldOpenReviewSentForm(!shouldOpenReviewSentForm);
  };

  const toggleCoinPurchaseSuccessfulForm = () => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);

    setShouldOpenBuyCoinsForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(false);

    setShouldOpenCoinPurchaseSuccessfulForm(
      !shouldOpenCoinPurchaseSuccessfulForm
    );
  };
  const toggleBuyCoinsForm = () => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);

    setShouldOpenCoinPurchaseSuccessfulForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(false);

    setShouldOpenBuyCoinsForm(!shouldOpenBuyCoinsForm);
  };
  const toggleSubPaymentSuccessfulForm = () => {
    setSubscriptionType(null);

    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(false);

    setShouldOpenSubPaymentSuccessfulForm(!shouldOpenSubPaymentSuccessfulForm);
  };
  const toggleJoinedProPlanPopup = () => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(false);

    setShouldOpenJoinedProPlanPopup(!shouldOpenJoinedProPlanPopup);
  };
  const toggleProInviteCodeForm = () => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(!shouldOpenProInviteCodeForm);
  };
  const togglePaymentSuccessfulForm = () => {
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldShowMiniNavBar(false);
    setShouldOpenProInviteCodeForm(false);

    setShouldOpenPaymentSuccessfulForm(!shouldOpenPaymentSuccessfulForm);
  };

  useEffect(() => {
    if (topicShortForm !== values.topicShortForm) {
      setValues({
        ...values,

        topicShortForm,
      });
    }
  }, [topicShortForm, values, setValues]);

  const tableTitle = targetTopic ? targetTopic.name : "";

  useEffect(() => {
    if (
      topicShortForm !== values.topicShortForm ||
      category !== values.category ||
      subCategory !== values.subCategory
    ) {
      setValues({
        ...values,
        category,
        topicShortForm,
        subCategory,
        groupType: category ? capitalizeFirstLetter(category) : "City",
        country: category === "country" ? subCategory || "" : null,
        cityId: category === "city" ? subCategory || "" : null,
        shortForm: null,
      });
    }
  }, [topicShortForm, category, values, subCategory, setValues, targetTopic]);

  const { data: { getCityById: targetCity } = {} } = useQuery(
    FETCH_CITY_BY_ID,
    {
      client: customerClient,
      variables: { cityId: values.cityId || "" },
    }
  );

  return (
    <div className="w-screen overflow-hidden relative h-full min-h-screen">
      <LiveStreamGradBack
        gradIndex={targetTopic ? targetTopic.accentColor : 1}
        // gradIndex={targetCustomer ? targetCustomer.accentColor : 1}
      />

      {shouldShowMiniNavBar && targetCustomer && (
        <MiniNavBar
          targetCustomer={targetCustomer}
          isSharingInviteToApps={isSharingInviteToApps}
          isSharingToApps={isSharingToApps || isSharingInviteToApps}
          isShareCopied={isCaptionCopied || isInviteCopied}
          setIsSharingToApps={setIsSharingToApps}
          navigate={navigate}
          isInviteCopied={isInviteCopied}
          inviteFriends={inviteFriends}
          toggleReviewForm={toggleReviewForm}
          shouldShowMiniNavBar={shouldShowMiniNavBar}
          isFirstTime={isFirstTime}
          setIsFirstTime={setIsFirstTime}
          setShouldShowMiniNavBar={setShouldShowMiniNavBar}
          logout={logout}
          setShouldPlaySoundEffects={setShouldPlaySoundEffects}
          shouldPlaySoundEffects={shouldPlaySoundEffects}
          toggleBuySubscriptionForm={toggleBuySubscriptionForm}
        />
      )}

      {shouldOpenBuyCoinsForm && stripe && elements ? (
        <BuyCoinsForm
          stripe={stripe}
          elements={elements}
          targetCustomer={targetCustomer}
          toggleCoinPurchaseSuccessfulForm={toggleCoinPurchaseSuccessfulForm}
          shouldOpenBuyCoinsForm={shouldOpenBuyCoinsForm}
          quizId={null}
          toggleBuyCoinsForm={toggleBuyCoinsForm}
        />
      ) : (
        <></>
      )}

      {shouldOpenReviewForm && (targetCustomer || targetGuestCustomer) ? (
        <TNReviewForm
          shouldOpenReviewForm={shouldOpenReviewForm}
          toggleReviewForm={toggleReviewForm}
          targetCustomer={targetCustomer}
          toggleReviewSentForm={toggleReviewSentForm}
        />
      ) : (
        <></>
      )}

      {subscriptionType !== null && stripe && elements && targetCustomer ? (
        <BuySubscriptionForm
          stripe={stripe}
          elements={elements}
          targetCustomer={targetCustomer}
          togglePaymentSuccessfulForm={toggleSubPaymentSuccessfulForm}
          subscriptionType={subscriptionType}
          toggleBuySubscriptionForm={toggleBuySubscriptionForm}
          toggleProInviteCodeForm={toggleProInviteCodeForm}
          // subPopupReason={""}
        />
      ) : (
        <></>
      )}

      {shouldOpenProInviteCodeForm && targetCustomer && (
        <CodeForm
          title="TKnight VIP Code"
          name="inviteCode"
          isCodeBoxOpen={shouldOpenProInviteCodeForm}
          setIsCodeBoxOpen={setShouldOpenProInviteCodeForm}
          typeOfCode={0}
          values={values}
          setValues={setValues}
          toggleJoinedProPlanPopup={toggleJoinedProPlanPopup}
          toggleProInviteCodeForm={toggleProInviteCodeForm}
        />
      )}
      {shouldOpenJoinedProPlanPopup && targetCustomer && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleJoinedProPlanPopup}
            targetCustomer={targetCustomer}
            message={"Welcome to TKnight VIP, " + targetCustomer.name + "!"}
            buttonMsg={"Thanks!"}
            popUpTitle={
              "Joined " +
              (targetCustomer.subscriptionType === 0
                ? "Individual"
                : targetCustomer.subscriptionType === 1
                ? "Duo"
                : targetCustomer.subscriptionType === 2
                ? "Family"
                : "Club") +
              " Successfully!"
            }
          />
          <div className="absolute z-[90] pointer-events-none inset-0">
            <Confetti
              width={size.width}
              height={size.height}
              recycle={false}
              numberOfPieces={size.width >= 640 ? 1500 : 1000}
              tweenDuration={30 * 1000}
              gravity={0.3}
            />
          </div>
        </>
      )}
      {shouldOpenSubPaymentSuccessfulForm && targetCustomer && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleSubPaymentSuccessfulForm}
            targetCustomer={targetCustomer}
            message={"Welcome to TKnight VIP, " + targetCustomer.name + "."}
            buttonMsg={"Thanks!"}
          />
          <div className="absolute z-[90] pointer-events-none inset-0">
            <Confetti
              width={size.width}
              height={size.height}
              recycle={false}
              numberOfPieces={size.width >= 640 ? 1500 : 1000}
              tweenDuration={30 * 1000}
              gravity={0.3}
            />
          </div>
        </>
      )}

      {shouldOpenCoinPurchaseSuccessfulForm && targetCustomer && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleCoinPurchaseSuccessfulForm}
            targetCustomer={targetCustomer}
            message={
              "Tokens added to stash. Good luck, " + targetCustomer.name + "!"
            }
            buttonMsg={"Awesome!"}
            popUpTitle={"Payment Successful!"}
          />
        </>
      )}

      {shouldOpenReviewSentForm && targetCustomer && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleReviewSentForm}
            targetCustomer={targetCustomer}
            popUpTitle={"Thank you!"}
            message={"This will help improve the game :)"}
            buttonMsg={"Awesome!"}
          />
        </>
      )}
      {shouldOpenPaymentSuccessfulForm && targetCustomer && (
        <PowerUpPaymentSuccessfulForm
          togglePaymentSuccessfulForm={togglePaymentSuccessfulForm}
          targetCustomer={targetCustomer}
        />
      )}

      <div
        className={`relative z-10 overflow-hidden flex flex-col h-screen max-h-screen max-w-5xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto xl:px-0 px-4 pb-4 lg:pb-8`}
      >
        <TopBar
          topicShortForm={null}
          navigate={navigate}
          toggleBuyCoinsForm={toggleBuyCoinsForm}
          targetGuestCustomer={targetGuestCustomer}
          targetCustomer={targetCustomer}
          setShouldShowMiniNavBar={setShouldShowMiniNavBar}
          setIsFirstTime={setIsFirstTime}
          customer={customer}
          areFriendRequestsAvailable={false}
          toggleBuySubscriptionForm={toggleBuySubscriptionForm}
          page="Leaderboard"
          topicName={targetTopic?.name}
          topicLogoUrl={targetTopic?.logoUrl}
          topicId={targetTopic?.id}
          values={values}
          setValues={setValues}
        />
        <div className="flex h-full flex-col w-full overflow-hidden truncate z-50 items-center justify-start flex-1">
          {/* <p className="text-left sm:hidden mb-2 leading-none w-full short:text-xl text-2xl font-semibold text-white">
            {targetTopic ? targetTopic.name + " " : ""}Leaderboard
          </p> */}

          <div
            className={`flex short:text-sm  text-lg items-center w-full short:space-x-1.5 space-x-2 shrink-0`}
          >
            <button
              onClick={(e) => {
                e.preventDefault();
                setValues({
                  ...values,
                  groupType: "Friends",
                  cityId: null,
                  country: null,
                  category: "friend",
                  subCategory: null,
                  shortForm: null,
                  orgId: null,
                });
                navigate(`/leaderboard/${topicShortForm || "-"}/friends`);
              }}
              className={`${
                values.groupType === "Friends"
                  ? "bg-white text-black hover:bg-white"
                  : "bg-white/20 hover:bg-white/30 text-white"
              }  disabled:hover:text-white disabled:hover:bg-transparent disabled:opacity-50 font-medium text-sm sm:text-base px-2 sm:px-4 w-full py-1 transition-all rounded-lg`}
              disabled={
                targetCustomer &&
                (!targetCustomer.followingIds ||
                  targetCustomer.followingIds.length === 0)
              }
            >
              Friends
            </button>

            <button
              onClick={(e) => {
                e.preventDefault();
                setValues({
                  ...values,
                  groupType: "City",

                  orgId: null,
                  category: "city",
                  subCategory: null,
                  shortForm: null,
                });
                navigate(`/leaderboard/${topicShortForm || "-"}/city`);
              }}
              className={`${
                values.groupType === "City"
                  ? "bg-white text-black hover:bg-white"
                  : "bg-white/20 hover:bg-white/30 text-white"
              } font-medium text-sm sm:text-base px-2 sm:px-4 w-full py-1 transition-all rounded-lg`}
            >
              Cities
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                setValues({
                  ...values,
                  groupType: "Country",

                  orgId: null,
                  category: "country",
                  subCategory: null,
                  shortForm: null,
                });
                navigate(`/leaderboard/${topicShortForm || "-"}/country`);
              }}
              className={`${
                values.groupType === "Country"
                  ? "bg-white text-black hover:bg-white"
                  : "bg-white/20 hover:bg-white/30 text-white"
              } font-medium text-sm sm:text-base px-2 sm:px-4 w-full py-1 transition-all rounded-lg`}
            >
              Countries
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                setValues({
                  ...values,
                  groupType: "World",
                  cityId: null,
                  country: null,
                  orgId: null,
                  category: "world",
                  subCategory: null,
                  shortForm: null,
                });
                navigate(`/leaderboard/${topicShortForm || "-"}/world`);
              }}
              className={`${
                values.groupType === "World"
                  ? "bg-white text-black hover:bg-white"
                  : "bg-white/20 hover:bg-white/30 text-white"
              } font-medium text-sm sm:text-base px-2 sm:px-4 w-full py-1 transition-all rounded-lg`}
            >
              World
            </button>
          </div>

          {loadingRankedGroups === 2 &&
          (loadingRankedGroups ||
            loadingCities ||
            loadingCountries ||
            loadingFriends) ? (
            <div className="mt-24">
              <svg
                className={`h-7 m-auto stroke-current text-white motion-safe:animate-spin`}
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.636 3.636A9 9 0 0119 10"
                  strokeWidth={2}
                  strokeLinecap="round"
                />
              </svg>
            </div>
          ) : values.groupType === "Club" && !values.orgId ? (
            rankedGroups && rankedGroups.length > 0 ? (
              <LeaderPodium topRanks={rankedGroups} type="Club" />
            ) : oldRankedGroups && oldRankedGroups.length > 0 ? (
              <LeaderPodium topRanks={oldRankedGroups} type="Club" />
            ) : (
              <EmptyLeaderPodium />
            )
          ) : values.groupType === "Country" && !values.country ? (
            rankedCountries && rankedCountries.length > 0 ? (
              <LeaderPodium
                countryFlags={countryFlags}
                topRanks={rankedCountries}
                type="Country"
              />
            ) : oldRankedCountries && oldRankedCountries.length > 0 ? (
              <LeaderPodium
                countryFlags={countryFlags}
                topRanks={oldRankedCountries}
                type="Country"
              />
            ) : (
              <EmptyLeaderPodium />
            )
          ) : values.groupType === "City" && !values.cityId ? (
            rankedCities && rankedCities.length > 0 ? (
              <LeaderPodium topRanks={rankedCities} type="City" />
            ) : oldRankedCities && oldRankedCities.length > 0 ? (
              <LeaderPodium topRanks={oldRankedCities} type="City" />
            ) : (
              <EmptyLeaderPodium />
            )
          ) : rankedFriends && rankedFriends.length > 0 ? (
            <LeaderPodium topRanks={rankedFriends} />
          ) : oldRankedFriends && oldRankedFriends.length > 0 ? (
            <LeaderPodium topRanks={oldRankedFriends} />
          ) : (
            <EmptyLeaderPodium />
          )}

          {loadingCities ||
          loadingCountries ||
          loadingCustomerInfo ||
          loadingFriends ||
          loadingRankedGroups ||
          loadingTopic ? (
            <div>
              <svg
                className={`h-6 text-white stroke-current m-auto motion-safe:animate-spin`}
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.636 3.636A9 9 0 0119 10"
                  strokeWidth={2}
                  strokeLinecap="round"
                />
              </svg>
            </div>
          ) : values.groupType === "Club" ? (
            rankedGroups && rankedGroups.length > 0 ? (
              <LeaderboardTable
                tableTitle={tableTitle}
                rankedGroups={rankedGroups}
                rankedFriends={rankedFriends}
                values={values}
                type="Club"
                targetCustomer={targetCustomer}
                countryFlags={countryFlags}
                setValues={setValues}
                loading={
                  loadingRankedGroups ||
                  loadingCities ||
                  loadingCountries ||
                  loadingFriends
                }
              />
            ) : oldRankedGroups && oldRankedGroups.length > 0 ? (
              <LeaderboardTable
                tableTitle={tableTitle}
                targetCustomer={targetCustomer}
                rankedGroups={oldRankedGroups}
                rankedFriends={rankedFriends}
                values={values}
                type="Club"
                countryFlags={countryFlags}
                setValues={setValues}
                loading={
                  loadingRankedGroups ||
                  loadingCities ||
                  loadingCountries ||
                  loadingFriends
                }
              />
            ) : (
              <div className="rounded-lg text-center mt-4 text-white font-semibold px-4 py-2 max-w-xs transition-all">
                <p className="text-xl short:text-lg whitespace-nowrap w-full">
                  Not enough data
                </p>
                <p className="whitespace-normal font-normal">
                  Once more people play, you will see detailed rankings here
                </p>
              </div>
            )
          ) : values.groupType === "Country" ? (
            rankedCountries && rankedCountries.length > 0 ? (
              <LeaderboardTable
                tableTitle={tableTitle}
                targetCustomer={targetCustomer}
                rankedCountries={rankedCountries}
                rankedFriends={rankedFriends}
                values={values}
                type="Country"
                setValues={setValues}
                countryFlags={countryFlags}
              />
            ) : oldRankedCountries && oldRankedCountries.length > 0 ? (
              <LeaderboardTable
                tableTitle={tableTitle}
                targetCustomer={targetCustomer}
                rankedCountries={oldRankedCountries}
                rankedFriends={rankedFriends}
                values={values}
                type="Country"
                setValues={setValues}
                countryFlags={countryFlags}
              />
            ) : (
              <div className="rounded-lg text-center mt-4 text-white font-semibold px-4 py-2 max-w-xs transition-all">
                <p className="text-xl short:text-lg whitespace-nowrap w-full">
                  Not enough data
                </p>
                <p className="whitespace-normal font-normal">
                  Once more people play, you will see detailed rankings here
                </p>
              </div>
            )
          ) : values.groupType === "City" ? (
            rankedCities && rankedCities.length > 0 ? (
              <LeaderboardTable
                tableTitle={tableTitle}
                targetCustomer={targetCustomer}
                rankedCities={rankedCities}
                rankedFriends={rankedFriends}
                values={values}
                type="City"
                targetCity={targetCity}
                setValues={setValues}
                countryFlags={countryFlags}
              />
            ) : oldRankedCities && oldRankedCities.length > 0 ? (
              <LeaderboardTable
                tableTitle={tableTitle}
                targetCustomer={targetCustomer}
                rankedCities={oldRankedCities}
                rankedFriends={rankedFriends}
                values={values}
                type="City"
                targetCity={targetCity}
                setValues={setValues}
                countryFlags={countryFlags}
              />
            ) : (
              <div className="rounded-lg text-center mt-4 text-white font-semibold px-4 py-2 max-w-xs transition-all">
                <p className="text-xl short:text-lg whitespace-nowrap w-full">
                  Not enough data
                </p>
                <p className="whitespace-normal font-normal">
                  Once more people play, you will see detailed rankings here
                </p>
              </div>
            )
          ) : rankedFriends && rankedFriends.length > 0 ? (
            <LeaderboardTable
              tableTitle={tableTitle}
              targetCustomer={targetCustomer}
              rankedFriends={rankedFriends}
              values={values}
              countryFlags={countryFlags}
              setValues={setValues}
            />
          ) : oldRankedFriends && oldRankedFriends.length > 0 ? (
            <LeaderboardTable
              tableTitle={tableTitle}
              targetCustomer={targetCustomer}
              rankedFriends={oldRankedFriends}
              values={values}
              countryFlags={countryFlags}
              setValues={setValues}
            />
          ) : values.groupType !== "Friends" ? (
            <div className="rounded-lg text-center mt-4 text-white font-semibold px-4 py-2 max-w-xs transition-all">
              <p className="text-xl short:text-lg whitespace-nowrap w-full">
                Not enough data
              </p>
              <p className="whitespace-normal font-normal">
                Once more people play, you will see detailed rankings here
              </p>
            </div>
          ) : targetCustomer ? (
            <div className="rounded-lg text-center mt-4 text-white font-semibold px-4 py-2 max-w-xs transition-all">
              <button
                onClick={(e) => {
                  navigate("/addFriends");
                }}
                className="text-xl text-yellow-200 whitespace-nowrap w-full hover:opacity-70 transition-all"
              >
                Add friends
              </button>
              <p className="whitespace-normal font-normal">
                Once your friends battle, you will see detailed rankings here
              </p>
            </div>
          ) : (
            <></>
          )}
          {values.groupType === "Friends" && !targetCustomer && (
            <div className="rounded-lg text-center mt-4 text-white font-semibold px-4 py-2 max-w-xs transition-all">
              <button
                onClick={(e) => {
                  navigate("/register");
                }}
                className="text-xl text-yellow-200 whitespace-nowrap w-full hover:opacity-70 transition-all"
              >
                Sign in to add friends
              </button>
              <p className="whitespace-normal font-normal">
                Once your friends battle, you will see detailed rankings here
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Leaderboard;
