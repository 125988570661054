import { useMutation, useQuery } from "@apollo/client";

import React, { useContext, useEffect, useState } from "react";
import { adminClient, customerClient } from "../../GraphqlApolloClients";

import { AdminAuthContext } from "../../context/adminAuth";

import {
  CREATE_FORUM_MUTATION,
  FETCH_CUSTOMER,
  FETCH_CUSTOMER_ORGS,
  FETCH_RECOMMENDED_FORUMS,
  FETCH_RECOMMENDED_FRIENDS,
  FETCH_RECOMMENDED_ORGS,
  FETCH_RECOMMENDED_TOPICS,
} from "../../util/gqlFunctions";
import { useForm } from "../../util/hooks";
import ProfilePicInputField from "./ProfilePicInputField";
import SelectTopicWSearchInputField from "./SelectTopicWSearchInputField";
import SubmitButton from "./SubmitButton";
import TagsInput from "./TagsInput";
import TextInputField from "./TextInputField";

function MakeForumForm({
  setShouldOpenMakeForumForm,
  customerInfo,
  refetchRecommendedOrgs,
  refetchRecommendedForums,
  targetOrg,
}) {
  const { admin } = useContext(AdminAuthContext);

  const [errors, setErrors] = useState({});

  const { onChange, values, setValues, onChangeMedia } = useForm(
    createForumCallback,
    {
      title: "",
      name: "",
      shortForm: "",
      city: "",
      province: "",
      country: customerInfo ? customerInfo.country : "United States",

      logoFile: null,
      websiteLink: "",
      accentColor: 0,
      pageSize: 3,
      seenIds: [],

      orgId: targetOrg ? targetOrg.id : null,
      orgLogoUrl: targetOrg ? targetOrg.logoUrl : null,
      type: targetOrg ? (targetOrg.type < 2 ? 3 : 2) : 2,
    }
  );

  const [createForum, { loading: loadingCreateForum }] = useMutation(
    CREATE_FORUM_MUTATION,
    {
      refetchQueries: [
        { query: FETCH_RECOMMENDED_ORGS },

        { query: FETCH_RECOMMENDED_FRIENDS, variables: { values } },
        { query: FETCH_CUSTOMER },

        {
          query: FETCH_RECOMMENDED_FORUMS,
          variables: { pageSize: 10, seenIds: [] },
        },
      ],
      update(_, { data: { createForum: createdOrg } }) {
        setValues({
          ...values,
          ...createdOrg,
        });
        if (customerInfo) {
          refetchRecommendedOrgs();
          refetchRecommendedForums();
        }

        setShouldOpenMakeForumForm(false);
        setErrors({});
      },
      onError(err) {
        if (err.graphQLErrors[0]) {
          console.log(err.graphQLErrors[0].extensions.errors);

          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: values,
      client: customerInfo ? customerClient : adminClient,
    }
  );

  function createForumCallback(e) {
    e.preventDefault();
    createForum();
  }

  const {
    data: { getCustomerOrgs: customerOrgs } = {},
    loading: loadingCustomerOrgs,
  } = useQuery(FETCH_CUSTOMER_ORGS, {
    client: customerInfo ? customerClient : adminClient,
  });

  const {
    data: { getRecommendedOrgsBySearch: adminOrgs } = {},
    loading: loadingAdminOrgs,
  } = useQuery(FETCH_RECOMMENDED_ORGS, {
    client: customerInfo ? customerClient : adminClient,
  });

  const [orgInputValue, setOrgInputValue] = useState(
    targetOrg ? targetOrg.name : ""
  );
  const [isAutocompleteOrgNameOpen, setIsAutocompleteOrgNameOpen] =
    useState(false);

  const [isSchool, setIsSchool] = useState(
    targetOrg ? (targetOrg.type < 2 ? true : false) : false
  );

  useEffect(() => {
    if (
      !targetOrg &&
      customerOrgs &&
      customerOrgs.length > 0 &&
      !values.orgId &&
      !orgInputValue
    ) {
      setOrgInputValue(customerOrgs[0].name);
      setValues({
        ...values,
        orgId: customerOrgs[0].id,
        orgLogoUrl: customerOrgs[0].logoUrl,
        type: customerOrgs[0].type < 2 ? 3 : 2,
      });

      if (customerOrgs[0].type < 2) {
        setIsSchool(true);
      } else {
        setIsSchool(false);
      }
      setIsAutocompleteOrgNameOpen(false);
    } else if (
      adminOrgs &&
      adminOrgs.length > 0 &&
      !values.orgId &&
      !orgInputValue
    ) {
      setOrgInputValue(adminOrgs[0].name);
      setValues({
        ...values,
        orgId: adminOrgs[0].id,
        orgLogoUrl: adminOrgs[0].logoUrl,
        type: adminOrgs[0].type < 2 ? 3 : 2,
      });

      if (adminOrgs[0].type < 2) {
        setIsSchool(true);
      } else {
        setIsSchool(false);
      }
      setIsAutocompleteOrgNameOpen(false);
    }
  }, [customerOrgs, targetOrg, orgInputValue, values, setValues, adminOrgs]);

  const {
    data: { getRecommendedTopicsBySearch: topics } = {},
    loading: loadingTopics,
    // fetchMore: fetchMoreTopics,
  } = useQuery(FETCH_RECOMMENDED_TOPICS, {
    client: customerInfo ? customerClient : adminClient,
    variables: values,
  });
  const [topicInputValue, setTopicInputValue] = useState("");

  return (
    <div className="fixed pt-16 sm:py-10 z-50 overscroll-contain w-screen h-screen max-h-screen max-w-screen overflow-hidden flex items-end justify-end sm:items-center sm:justify-center">
      <button
        onClick={(e) => {
          e.preventDefault();
          setErrors({});
          setShouldOpenMakeForumForm(false);
        }}
        className="z-40 bg-gray-500 hide-button-flash overscroll-contain bg-opacity-10 motion-safe:animate-fadeInFast flex items-center justify-center inset-0 absolute backdrop-blur-sm"
        disabled={false}
      />
      <form
        // onSubmit={buyBandCallback}
        className={`overflow-x-hidden max-h-full flex-shrink motion-safe:animate-fadeUpFast overscroll-contain sm:m-auto w-full max-w-xl overflow-y-auto flex flex-col items-start justify-start z-40 rounded-xl px-6 pb-6 sm:p-8 bg-darker-gray shadow-xl rounded-b-none sm:rounded-3xl short:px-4 short:pb-4 `}
      >
        <button
          onClick={(e) => {
            e.preventDefault();
            setErrors({});
            setShouldOpenMakeForumForm(false);
          }}
          className="w-full pt-4 short:pt-3 short:pb-1 pb-2 sm:hidden"
        >
          <div className="rounded-full bg-gray-700 flex-shrink-0 h-1 mx-auto w-10 mb-2"></div>
        </button>

        <p className="text-white text-2xl font-bold w-full short:text-xl short:leading-tight">
          Add forum
        </p>
        <p className="text-gray-500 w-full leading-tight short:text-sm">
          This will create a modern group chat that people can use to organize
          information and discuss things
        </p>

        {/* <div className="mt-4 w-full flex items-start justify-start flex-col">
            <p className="font-medium">Full name</p>

          </div> */}
        <div className="w-full flex flex-col items-start justify-start space-y-6 mt-6">
          <div className="w-full">
            <div className="flex items-center justify-between text-sm sm:text-base">
              <label htmlFor="org" className="font-semibold text-gray-400 ">
                School/workplace{" "}
                {errors.orgId && (
                  <span className="text-red-500">{errors.orgId}</span>
                )}
              </label>
            </div>

            <div className="flex space-x-2 items-center justify-start w-full rounded-md  shadow-sm mt-1  pr-3 pl-2 py-2 border-gray-700 border focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500 text-white">
              {values.orgLogoUrl && orgInputValue ? (
                <img
                  className="w-8 h-8 object-contain rounded"
                  src={values.orgLogoUrl}
                  alt={"Org"}
                />
              ) : (
                <div className="w-8 h-8 rounded bg-gray-700"></div>
              )}
              <input
                name="org"
                className={`sm:text-lg w-full flex-1 focus:outline-none bg-transparent`}
                type={"text"}
                value={orgInputValue}
                onChange={(e) => {
                  const value = e.target.value;
                  setOrgInputValue(value);
                  setTopicInputValue("");
                  setIsAutocompleteOrgNameOpen(true);
                  setValues({
                    ...values,
                    orgLogoUrl: !value ? null : values.orgLogoUrl,
                  });
                }}
                autoComplete="off"
                placeholder={""}
                disabled={loadingCustomerOrgs || loadingAdminOrgs}
                maxLength={200}
                list={customerInfo ? "customerOrgs" : "adminOrgs"}
              />
            </div>
            {orgInputValue && isAutocompleteOrgNameOpen ? (
              <div className="relative w-full">
                <div className="max-h-20 w-full overflow-y-auto absolute bg-white">
                  {(customerInfo ? customerOrgs : adminOrgs)
                    .filter((org) =>
                      org.name
                        .toLowerCase()
                        .includes(orgInputValue.toLowerCase())
                    )
                    .map((org, index) => (
                      <button
                        key={index}
                        onClick={(e) => {
                          setValues({
                            ...values,
                            orgId: org.id,
                            orgLogoUrl: org.logoUrl,
                            topicLogoUrl: null,
                            topicId: null,
                          });

                          setOrgInputValue(org.name);
                          setIsAutocompleteOrgNameOpen(false);

                          if (org.type === 0 || org.type === 1) {
                            setIsSchool(true);
                          } else {
                            setIsSchool(false);
                          }
                        }}
                        className="w-full flex items-center justify-start space-x-2 text-lg hover:bg-gray-50 text-left p-2"
                      >
                        <img
                          className="w-8 rounded bg-white"
                          src={org.logoUrl}
                          alt={org.name}
                        />
                        <p>{org.name}</p>
                      </button>
                    ))}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>

          {isSchool && (
            <div className="w-full">
              <div className="flex items-center justify-between text-sm sm:text-base">
                <label htmlFor="type" className="font-semibold text-gray-400 ">
                  Group type{" "}
                  {errors.type && (
                    <span className="text-red-500">{errors.type}</span>
                  )}
                </label>
              </div>
              <select
                id="type"
                name="type"
                // autoComplete=""
                value={values.type}
                onChange={(e) => {
                  onChange(e, true);
                }}
                disabled={loadingCreateForum}
                className="sm:text-lg bg-transparent text-white mt-1 w-full shadow-sm flex-1 px-3 py-2 rounded-md border-gray-700 border focus:border-indigo-500 focus:ring-indigo-500"
              >
                {admin ? <option value={0}>Everyone</option> : <></>}
                <option value={1}>Graduation Year</option>
                <option value={2}>Cohort/Homeroom</option>
                <option value={3}>Class</option>
                <option value={4}>Club/Team</option>
                <option value={5}>Other</option>
              </select>
            </div>
          )}

          {values.type === 3 ? (
            <SelectTopicWSearchInputField
              error={errors.topicId}
              name={"topicId"}
              values={values}
              setValues={setValues}
              options={topics}
              loading={loadingTopics || loadingCreateForum}
              title={"Subject"}
              value={values.topicId}
              disabled={loadingTopics || loadingCreateForum}
              setTopicInputValue={setTopicInputValue}
              topicInputValue={topicInputValue}
            />
          ) : (
            <TextInputField
              title={"Title"}
              name="title"
              type="text"
              error={errors.title}
              disabled={loadingCreateForum}
              onChange={onChange}
              maxLen={14}
              value={values.title}
            />
          )}

          {isSchool &&
          (values.type === 2 || values.type === 3 || values.type === 5) ? (
            <>
              <div className="flex items-start justify-start space-x-4 w-full">
                <TextInputField
                  title={"Term (optional)"}
                  name="term"
                  type="text"
                  error={errors.term}
                  disabled={loadingCreateForum}
                  onChange={onChange}
                  maxLen={12}
                  placeholder={"W24"}
                  value={values.term}
                />
                <TextInputField
                  title={"Section (optional)"}
                  name="section"
                  placeholder={"001"}
                  type="text"
                  error={errors.section}
                  disabled={loadingCreateForum}
                  onChange={onChange}
                  maxLen={12}
                  value={values.section}
                />
              </div>
            </>
          ) : (
            <></>
          )}

          {values.type !== 3 && (
            <div className="flex w-full items-start justify-start space-x-4 sm:space-x-8">
              <ProfilePicInputField
                title="Logo"
                name="logoFile"
                onChangeMedia={onChangeMedia}
                value={values.logoUrl}
                shouldNotEdit={true}
                values={values}
                error={errors.logoFile}
                isSquare={true}
              />
              <div className="flex items-start flex-col justify-start space-y-2 sm:text-base text-sm">
                <label className="font-semibold text-gray-200">
                  Accent colour
                </label>
                <div className="grid grid-cols-4 sm:grid-cols-6 w-full gap-2 h-full">
                  {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(
                    (colour, index) => (
                      <button
                        className={`${
                          index === values.accentColor
                            ? "border-gray-700 border-4 box-border"
                            : "hover:border-gray-600 hover:border-4"
                        } sm:w-10 aspect-square w-8 rounded-full bg-accent-${index} bg-gradient-to-br from-[--gradient-color-1] via-purple-500 to-pink-500 transition-all`}
                        onClick={(e) => {
                          e.preventDefault();

                          setValues({
                            ...values,
                            fieldsToEdit: ["accentColor"],
                            accentColor: index,
                          });
                          // Gradient.apply
                        }}
                        key={index}
                      ></button>
                    )
                  )}
                </div>
              </div>
            </div>
          )}

          <TagsInput
            title="Folders (optional)"
            name="folders"
            type="multiline"
            value={values.folders}
            values={values}
            setValues={setValues}
            error={errors.folders}
            disabled={loadingCreateForum}
            maxTags={20}
            placeholder={"Q1, A1, A2, Midterm, Final"}
          />

          <SubmitButton
            onClickFunc={createForumCallback}
            disabled={loadingCreateForum}
            title={"SUBMIT"}
            errors={errors}
          />
        </div>
      </form>
    </div>
  );
}

export default MakeForumForm;
