import React, { useEffect, useState } from "react";

function useInterval(callback, delay) {
  const savedCallback = React.useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

function CountUpComponent({
  endValue = 10,
  duration = 10000,
  shouldReverse = false,
}) {
  const [count, setCount] = useState(0);
  const [isReversing, setIsReversing] = useState(false);

  // Calculate the interval based on the duration and endValue
  const interval = duration / (endValue + 1);

  // Toggle counting direction when shouldReverse changes
  useEffect(() => {
    if (isReversing !== shouldReverse) {
      setIsReversing(shouldReverse);
    }
  }, [shouldReverse, isReversing]);

  // Increment or decrement count based on counting direction
  useInterval(() => {
    if (isReversing) {
      if (count > 0) {
        setCount(count - 1);
      }
    } else {
      if (count < endValue) {
        setCount(count + 1);
      }
    }
  }, interval);

  return (
    <p className="tracking-wide text-left text-2xl leading-none xl 2xl:text-xl text-gray-200">
      {count}
    </p>
  );
}

export default CountUpComponent;
