import { useMutation } from "@apollo/client";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";

import { useInView } from "react-intersection-observer";
import { adminClient } from "../../GraphqlApolloClients";

import {
  APPROVE_PRODUCT_MUTATION,
  CANCEL_PRODUCT_SALE_MUTATION,
  CLOSE_PRODUCT_SALE_MUTATION,
  FETCH_PRODUCTS,
  REJECT_PRODUCT_MUTATION,
  START_PRODUCT_SALE_MUTATION,
} from "../../util/gqlFunctions";
import { useForm } from "../../util/hooks";
import PieTimer from "./PieTimer";

function AdminProductBox({
  index,

  product,
  productIndexToScrollIntoView,

  setProductIndexInView,
  setTargetProduct,
  disabled,
}) {
  const productRef = useRef();

  const {
    ref: boxRef,
    inView: isBoxVisible,
    // entry,
  } = useInView({
    /* Optional options */
    threshold: 0.7,
  });
  useEffect(() => {
    if (index === productIndexToScrollIntoView) {
      productRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  }, [productIndexToScrollIntoView, index]);

  useEffect(() => {
    if (isBoxVisible && !disabled) {
      setProductIndexInView(index);
    }
  }, [isBoxVisible, disabled, index, setProductIndexInView]);

  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const resetInterval = 1000;

  useEffect(() => {
    let timeout;
    if (isLinkCopied && resetInterval) {
      timeout = setTimeout(() => setIsLinkCopied(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isLinkCopied, resetInterval]);

  const [errors, setErrors] = useState({});

  const { values } = useForm(editProductCallback, {
    productId: product.id,
    cancellationReason: "Something went wrong",
    rejectionReason: "Things need to change",

    fieldsToEdit: [],
  });

  const [approveProduct] = useMutation(APPROVE_PRODUCT_MUTATION, {
    refetchQueries: [{ query: FETCH_PRODUCTS }],
    update(_, { data: { approveProduct: approvedProduct } }) {
      // product = { ...product, ...approvedProduct };
      console.log(errors);
    },
    onError(err) {
      console.log(err);
      // setValues({ ...values, shouldDelete: false });
      if (err.graphQLErrors[0]) {
        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
      }
    },
    variables: values,
    client: adminClient,
  });

  const [rejectProduct] = useMutation(REJECT_PRODUCT_MUTATION, {
    // refetchQueries: [{ query: FETCH_PRODUCTS }],
    update(_, { data: { rejectProduct: rejectedProduct } }) {
      console.log(errors);
    },
    onError(err) {
      console.log(err);
      console.log(values);
      if (err.graphQLErrors[0]) {
        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
      }
    },
    variables: values,
    client: adminClient,
  });

  const [startProductSale] = useMutation(START_PRODUCT_SALE_MUTATION, {
    refetchQueries: [{ query: FETCH_PRODUCTS }],
    update(_, { data: { startProductSale: startedProductSale } }) {
      // product = { ...product, ...startedProductSale };
      console.log(errors);
    },
    onError(err) {
      console.log(err);

      if (err.graphQLErrors[0]) {
        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
      }
    },
    variables: { productId: product.id },
    client: adminClient,
  });

  const [closeProductSale] = useMutation(CLOSE_PRODUCT_SALE_MUTATION, {
    refetchQueries: [{ query: FETCH_PRODUCTS }],
    update(_, { data: { closeProductSale: closedProductSale } }) {
      console.log(errors);
    },
    onError(err) {
      console.log(err);

      if (err.graphQLErrors[0]) {
        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
      }
    },
    variables: { productId: product.id },
    client: adminClient,
  });

  const [cancelProductSale] = useMutation(CANCEL_PRODUCT_SALE_MUTATION, {
    refetchQueries: [{ query: FETCH_PRODUCTS }],
    update(_, { data: { cancelProductSale: canceledProductSale } }) {
      // product = { ...product, ...canceledProductSale };
      console.log(errors);
    },
    onError(err) {
      console.log(err);

      if (err.graphQLErrors[0]) {
        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
      }
    },
    variables: values,
    client: adminClient,
  });

  function editProductCallback(e) {
    e.preventDefault();
  }

  return (
    <div ref={productRef} className="w-full shrink-0">
      <div
        ref={boxRef}
        className="w-full sm:px-10 px-5 snap-center shrink-0 pb-5 pt-4 sm:pb-10 sm:pt-9 flex items-center justify-start flex-col bg-darker-gray rounded-xl sm:rounded-3xl short:px-2 short:py-2"
      >
        <div className="flex items-center space-x-2 justify-start w-full truncate">
          <PieTimer
            endTime={product.status === 3 ? product.endSaleAt : new Date()}
            imgUrl={
              product.businessLogoUrl ||
              "https://tknight-media.s3.amazonaws.com/profile.png"
            }
            alt={"business logo"}
            width={50}
            lastXSeconds={0}
          />
          <div className="flex-1 w-full flex flex-col items-start justify-start truncate">
            <p className="font-semibold text-gray-200 text-xl short:text-lg leading-tight short:leading-tight w-full truncate ">
              {product.title}
            </p>
            <p className=" text-gray-400 leading-tight">
              {moment(product.createdAt).format("D MMM, YYYY")}
            </p>
          </div>

          {/* <a
            href={"/@" + product.username}
            target="_blank"
            rel="noreferrer"
            className="bg-black shrink-0 transition-all w-12 hover:bg-opacity-50 aspect-square rounded-full "
          >
            <svg
              className="p-2 fill-current text-white"
              viewBox="0 0 27 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.375 16.875H7.875C6.01875 16.875 4.5 15.3562 4.5 13.5C4.5 11.6437 6.01875 10.125 7.875 10.125H12.375V7.875H7.875C4.77 7.875 2.25 10.395 2.25 13.5C2.25 16.605 4.77 19.125 7.875 19.125H12.375V16.875ZM19.125 7.875H14.625V10.125H19.125C20.9813 10.125 22.5 11.6437 22.5 13.5C22.5 15.3562 20.9813 16.875 19.125 16.875H14.625V19.125H19.125C22.23 19.125 24.75 16.605 24.75 13.5C24.75 10.395 22.23 7.875 19.125 7.875ZM18 12.375H9V14.625H18V12.375Z"
              />
            </svg>
          </a> */}
          {/* <a
            href={"mailto:" + product.email}
            target="_blank"
            rel="noreferrer"
            className="bg-black shrink-0 transition-all w-12 hover:bg-opacity-50 aspect-square rounded-full "
          >
            <svg
              className="p-2 fill-current text-white"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM4 8L12 13L20 8V18H4V8ZM4 6L12 11L20 6H4Z"
              />
            </svg>
          </a> */}
        </div>
        <div className="py-2 px-4 text-gray-200 bg-gray-800 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-4 w-full rounded-lg">
          <p>ID</p>
          <p>{product.id}</p>
        </div>

        {product.discountedPrice && (
          <div className="py-2 px-4 text-gray-200 bg-gray-800 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg">
            <p>Discounted Price</p>
            <p>US${(product.discountedPrice / 100).toFixed(2)}</p>
          </div>
        )}

        {product.amountForMerchant && (
          <div className="py-2 px-4 text-gray-200 bg-gray-800 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg">
            <p>Net amount for merchant</p>
            <p>US${(product.amountForMerchant / 100).toFixed(2)}</p>
          </div>
        )}

        {product.originalStock && (
          <div className="py-2 px-4 text-gray-200 bg-gray-800 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg">
            <p>Stock</p>
            <p>{product.originalStock}</p>
          </div>
        )}

        {product.shippingFromCountry && (
          <div className="py-2 px-4 text-gray-200 bg-gray-800 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg">
            <p>Shipping from</p>
            <p>{product.shippingFromCountry}</p>
          </div>
        )}

        {product.saleLength && (
          <>
            <div className="py-2 px-4 text-gray-200 bg-gray-800 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg">
              <p>Sale starts at</p>
              <p>{moment(product.startSaleAt).format("D MMM, YYYY, h:mm a")}</p>
            </div>
            <div className="py-2 px-4 text-gray-200 bg-gray-800 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg">
              <p>Sale length</p>
              <p>{product.saleLength}</p>
            </div>

            <div className="py-2 px-4 text-gray-200 bg-gray-800 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg">
              <p>Sale ends at</p>
              <p> {moment(product.endSaleAt).format("D MMM, YYYY, h:mm a")}</p>
            </div>
          </>
        )}

        <div className="py-2 px-4 text-gray-200 bg-gray-800 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg">
          <p>Status</p>
          <p>
            {product.status === 0
              ? "Editing"
              : product.status === 1
              ? "In review"
              : product.status === 2
              ? "Approved"
              : product.status === 3
              ? "LIVE"
              : product.status === 4
              ? "Sale ended"
              : product.status === 5
              ? "Rejected approval request"
              : "Cancelled sale"}
          </p>
        </div>

        {product.status <= 4 && product.status !== -2 && (
          <div className="mt-4 text-center sm:text-lg flex items-center  text-gray-600 justify-start w-full overflow-hidden rounded-lg">
            {(product.status === 0 || product.status === 2) && (
              <button
                // onClick={copyTrackingLink}
                onClick={(e) => {
                  e.preventDefault();
                  setTargetProduct(index);
                }}
                className={` ${
                  product.status === 2 ? "border-r" : "flex-1"
                } px-8 short:px-4 flex items-center justify-center relative font-semibold border-gray-700 bg-blue-700 hover:bg-blue-800 transition-all text-white`}
              >
                <p className={`py-3`}>Edit</p>
              </button>
            )}
            {product.status === 1 && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  rejectProduct();
                  // toggleMakeRevisionPaymentRequestForm(index);
                }}
                className={`${
                  product.status === 1 ? "border-r border-gray-200" : ""
                } px-4 shrink-0 py-3 font-semibold  hover:bg-red-50 transition-all`}
              >
                <p className="">Reject</p>
              </button>
            )}
            {product.status === 1 && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setTargetProduct(index);

                  // toggleMakeRevisionPaymentRequestForm(index);
                }}
                className={`flex-1 border-r border-gray-200 shrink-0 py-3 font-semibold  hover:bg-gray-50 transition-all`}
              >
                <p className="">View</p>
              </button>
            )}
            {product.status === 1 && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  approveProduct();
                  // toggleMakeRevisionPaymentRequestForm(index);
                }}
                className={`px-4 shrink-0 py-3 font-semibold  hover:bg-green-50 transition-all`}
              >
                <p className="">Accept</p>
              </button>
            )}

            {product.status === 2 && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  startProductSale();
                  // toggleMakeRevisionPaymentRequestForm(index);
                }}
                className={`${
                  product.status === 0 ? "border-l border-gray-700" : ""
                } flex-1 shrink-0 py-3 font-semibold  hover:bg-gray-50 transition-all`}
              >
                <p className="">Start sale</p>
              </button>
            )}

            {product.status === 3 && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  cancelProductSale();
                  // toggleMakeRevisionPaymentRequestForm(index);
                }}
                className={`${
                  product.status === -1 ? "border-l border-gray-700" : ""
                } px-4 shrink-0 py-3 font-semibold  hover:bg-red-50 transition-all`}
              >
                <p className="">Cancel sale</p>
              </button>
            )}

            {product.status === 3 && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  closeProductSale();
                  // toggleMakeRevisionPaymentRequestForm(index);
                }}
                className={`${
                  product.status === 3 ? "border-l border-gray-700" : ""
                } flex-1 shrink-0 py-3 font-semibold  hover:bg-green-50 transition-all`}
              >
                <p className="">End sale</p>
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default AdminProductBox;
