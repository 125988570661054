import React from "react";
import { defaultPicUrl } from "../../util/hooks";

export default function LeaderPodium({ topRanks, countryFlags, type }) {
  return (
    <div className="flex items-end text-center w-full my-6 max-w-sm justify-center space-x-4 short:space-x-0 sm:space-x-0">
      <div className="flex w-1/3 truncate flex-col items-center justify-end">
        <div
          className={`bg-gray-700 shadow-lg relative flex items-center justify-end flex-col transition-all p-1.5 rounded-full aspect-square w-20 short:w-[4.2rem]`}
        >
          {type === "Club" ? (
            <img
              src={topRanks[1] ? topRanks[1].logoUrl : defaultPicUrl}
              alt={topRanks[1] ? topRanks[1].shortForm : "2nd place"}
              className="border-white bg-darker-gray border-2 box-border rounded-full object-cover aspect-square "
            />
          ) : type === "Country" ? (
            <div className="border-white bg-darker-gray border-2 box-border rounded-full object-cover aspect-square flex items-center justify-center w-full h-full">
              <p className="text-5xl short:text-4xl">
                {topRanks[1] ? countryFlags[topRanks[1]._id] : ""}
              </p>
            </div>
          ) : type === "City" ? (
            <img
              src={
                topRanks[1] && topRanks[1].logoUrl
                  ? topRanks[1].logoUrl
                  : defaultPicUrl
              }
              alt={topRanks[1] ? topRanks[1]._id : "2nd place"}
              className="border-white bg-darker-gray border-2 box-border rounded-full object-cover aspect-square "
            />
          ) : (
            <img
              src={topRanks[1] ? topRanks[1].profilePicUrl : defaultPicUrl}
              alt={topRanks[1] ? topRanks[1].username : "2nd place"}
              className="border-white bg-darker-gray border-2 box-border rounded-full object-cover aspect-square "
            />
          )}
          <p className=" absolute bottom-0 bg-white shadow rounded-full aspect-square w-8 -mb-3 flex items-center justify-center">
            <span className="text-lg short:text-base">2</span>
          </p>
        </div>

        <p className="font-semibold mt-12 short:mt-10 w-full text-white text-lg short:text-base truncate">
          {!topRanks[1]
            ? "-"
            : type === "Country"
            ? topRanks[1]._id
            : type === "City"
            ? topRanks[1].cityName
            : type === "Club"
            ? topRanks[1].shortForm
            : topRanks[1].name}
        </p>
      </div>
      <div className="flex flex-col items-center justify-end">
        <div
          className={`bg-gray-700 shadow-lg relative flex items-center justify-end flex-col transition-all p-2 rounded-full aspect-square w-32 short:w-24 short:p-2`}
        >
          {type === "Club" ? (
            <img
              src={topRanks[0] ? topRanks[0].logoUrl : defaultPicUrl}
              alt={topRanks[0] ? topRanks[0].shortForm : "1st place"}
              className="border-white bg-darker-gray border-2 box-border rounded-full object-cover aspect-square"
            />
          ) : type === "Country" ? (
            <div className="border-white bg-darker-gray border-2 box-border rounded-full object-cover aspect-square flex items-center justify-center w-full h-full">
              <p className="text-7xl short:text-6xl">
                {topRanks[0] ? countryFlags[topRanks[0]._id] : ""}
              </p>
            </div>
          ) : type === "City" ? (
            <img
              src={
                topRanks[0] && topRanks[0].logoUrl
                  ? topRanks[0].logoUrl
                  : defaultPicUrl
              }
              alt={topRanks[0] ? topRanks[0]._id : "1st place"}
              className="border-white bg-darker-gray border-2 box-border rounded-full object-cover aspect-square"
            />
          ) : (
            <img
              src={topRanks[0] ? topRanks[0].profilePicUrl : defaultPicUrl}
              alt={topRanks[0] ? topRanks[0].username : "1st place"}
              className="border-white bg-darker-gray border-2 box-border rounded-full object-cover aspect-square"
            />
          )}
          <p className=" absolute bottom-0 bg-white rounded-full aspect-square w-10 shadow -mb-3.5 flex items-center justify-center short:w-8">
            <span className="text-lg short:text-base">1</span>
          </p>
        </div>

        <p className="font-semibold truncate mt-8 text-white text-lg short:text-base">
          {!topRanks[0]
            ? "-"
            : type === "Country"
            ? topRanks[0]._id
            : type === "City"
            ? topRanks[0].cityName
            : type === "Club"
            ? topRanks[0].shortForm
            : topRanks[0].name}
        </p>
      </div>
      <div className="flex w-1/3 truncate flex-col items-center justify-end">
        <div
          className={`bg-gray-700 shadow-lg relative flex items-center justify-end flex-col transition-all p-1.5 rounded-full aspect-square w-20 short:w-[4.2rem]`}
        >
          {type === "Club" ? (
            <img
              src={
                topRanks[2] && topRanks[2].logoUrl
                  ? topRanks[2].logoUrl
                  : defaultPicUrl
              }
              alt={topRanks[2] ? topRanks[2].shortForm : "3rd place"}
              className="border-white bg-darker-gray border-2 box-border rounded-full object-cover aspect-square"
            />
          ) : type === "Country" ? (
            <div className="border-white bg-darker-gray border-2 box-border rounded-full object-cover aspect-square flex items-center justify-center w-full h-full">
              <p className="text-5xl short:text-4xl">
                {topRanks[2] ? countryFlags[topRanks[2]._id] : ""}
              </p>
            </div>
          ) : type === "City" ? (
            <img
              src={
                topRanks[2] && topRanks[2].logoUrl
                  ? topRanks[2].logoUrl
                  : defaultPicUrl
              }
              alt={topRanks[2] ? topRanks[2]._id : "3nd place"}
              className="border-white bg-darker-gray border-2 box-border rounded-full object-cover aspect-square "
            />
          ) : (
            <img
              src={topRanks[2] ? topRanks[2].profilePicUrl : defaultPicUrl}
              alt={topRanks[2] ? topRanks[2].username : "3rd place"}
              className="border-white bg-darker-gray border-2 box-border rounded-full object-cover aspect-square"
            />
          )}
          <p className=" absolute bottom-0 bg-white shadow rounded-full aspect-square w-8 -mb-3 flex items-center justify-center">
            <span className="text-lg short:text-base">3</span>
          </p>
        </div>

        <p className="font-semibold mt-12 short:mt-10 w-full text-white text-lg short:text-base truncate">
          {!topRanks[2]
            ? "-"
            : type === "Country"
            ? topRanks[2]._id
            : type === "City"
            ? topRanks[2].cityName
            : type === "Club"
            ? topRanks[2].shortForm
            : topRanks[2].name}
        </p>
      </div>
    </div>
  );
}
