import { useQuery } from "@apollo/client";
import React from "react";
import { useLocation } from "react-router-dom";
import { customerClient } from "../../GraphqlApolloClients";
import { FETCH_NUM_UNCLICKED_NOTIFICATIONS } from "../../util/gqlFunctions";
import { defaultPicUrl } from "../../util/hooks";
import PlayerCoinCounter from "./PlayerCoinCounter";

export default function TopBar({
  topicShortForm,
  orgShortForm,
  navigate,
  toggleBuyCoinsForm,
  targetGuestCustomer,
  targetCustomer,
  setShouldShowMiniNavBar,
  setIsFirstTime,
  customer,
  areFriendRequestsAvailable,
  toggleBuySubscriptionForm,
  page,
  topicName,
  topicLogoUrl,
  orgLogoUrl,
  toggleMultiTopicBattleForm,
  toggleOneTopicBattleForm,
  topicId,
  forumTitle,
  forumLogoUrl,
  orgNameForUrl,
  prevPath,
  postNumber,
  values,
  setValues,
  seller,
}) {
  const location = useLocation();
  const {
    data: { getNumUnclickedNotifications: numUnclickedNotifications } = 0,
  } = useQuery(FETCH_NUM_UNCLICKED_NOTIFICATIONS, {
    client: customerClient,
    fetchPolicy: "cache-and-network",
  });

  return (
    <div
      className={`${
        page === "Leaderboard" ||
        page === "Notifications" ||
        page === "Friends" ||
        (page === "Focus" && (orgShortForm || topicShortForm)) ||
        (page === "Comms" && forumTitle)
          ? ""
          : "space-x-2 sm:space-x-4"
      } relative z-20 ${
        page === "Topic" || page === "Organization"
          ? "px-2 sm:px-5"
          : page === "Seller Account" ||
            page === "Account" ||
            page === "Login" ||
            page === "Signup" ||
            page === "Forgot" ||
            page === "Friends" ||
            page === "Onboarding" ||
            page === "Leaderboard" ||
            page === "Notifications" ||
            page === "Admin Dash" ||
            page === "Seller Dash" ||
            page === "Seller Account"
          ? "px-2 py-4 sm:px-5 sm:pb-6"
          : "px-6 sm:px-5"
      } text-white flex items-center justify-between w-full`}
    >
      {page === "Topic" ||
      page === "Organization" ||
      page === "Leaderboard" ||
      page === "Notifications" ||
      page === "Seller Account" ||
      page === "Account" ||
      (page === "Focus" && (orgShortForm || topicShortForm)) ||
      (page === "Comms" && forumTitle) ||
      (page === "Friends" && (orgShortForm || topicShortForm)) ? (
        <button
          onClick={(e) => {
            e.preventDefault();

            if (page === "Comms" && forumTitle && !postNumber) {
              navigate("/comms");
            } else if (
              page === "Leaderboard" &&
              (values?.city || values?.country)
            ) {
              setValues({ ...values, city: null, country: null });
            } else if (location.key === "default") {
              navigate("/");
            } else {
              navigate(-1, { state: { from: location } });
            }
          }}
          className="transition-all h-[1.9rem] origin-left pr-2 rounded-full outline-none hover:opacity-50 focus:outline-none"
        >
          <svg
            className="h-4 fill-current"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z" />
          </svg>
        </button>
      ) : (
        <button
          onClick={(e) => {
            e.preventDefault();

            if (location.key === "default") {
              navigate("/");
            } else {
              navigate("/", { state: { from: location } });
            }
          }}
          className="transition-all outline-none hover:opacity-50 py-1 focus:outline-none"
        >
          <svg
            className="h-5 sm:h-6 xl:h-5 fill-current "
            viewBox="0 0 140 140"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M69.7018 0C51.1365 0 33.3636 7.37493 20.2921 20.5025C7.22057 33.63 -0.0786027 51.4347 0.000638512 69.9999C0.0798797 88.5647 7.531 106.37 20.7136 119.497C33.8971 132.625 51.7337 140 70.2982 140C61.7401 140 53.3626 137.462 46.226 132.707C39.0902 127.952 33.5148 121.194 30.2062 113.287C26.8969 105.38 26.0032 96.679 27.637 88.2849C29.2709 79.8908 33.3595 72.1805 39.3851 66.1287C45.4116 60.077 53.1045 55.9556 61.491 54.286C69.8783 52.6163 78.5826 53.4732 86.5034 56.7484C94.4243 60.0236 101.206 65.57 105.991 72.6861C110.777 79.8019 113.35 88.1688 113.386 96.7275C113.435 108.204 108.923 119.21 100.842 127.325C92.7618 135.44 81.7751 140 70.2982 140C88.8635 140 106.636 132.625 119.708 119.497C132.779 106.37 140.079 88.5647 139.999 69.9999C139.92 51.4347 132.469 33.63 119.286 20.5025C106.103 7.37493 88.2671 0 69.7018 0Z" />
          </svg>
        </button>
      )}

      {forumTitle ? (
        <>
          <div className="sm:text-lg flex items-center justify-start truncate 2xl:text-2xl text-white  overflow-hidden motion-safe:animate-fadeIn relative space-x-2 font-semibold transition-all leading-tight flex-1 sm:ml-2">
            <img
              src={forumLogoUrl}
              alt={forumTitle}
              className="border-white h-7 sm:h-8 group-hover:opacity-50 transition-all bg-white short:border-[1.5px] border-[1.75px] 2xl:border-[2px] box-border rounded-md sm:rounded-lg object-cover aspect-square"
            />
            <p className="font-semibold hidden sm:block truncate whitespace-nowrap drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.3)]">
              {forumTitle}
            </p>
          </div>
        </>
      ) : topicName ? (
        <>
          <div className="sm:text-lg flex items-center justify-start truncate 2xl:text-2xl text-white  overflow-hidden motion-safe:animate-fadeIn relative space-x-2 font-semibold transition-all leading-tight flex-1 sm:ml-2">
            <img
              src={topicLogoUrl}
              alt={topicName}
              className="h-7 sm:h-8 group-hover:opacity-50 transition-all bg-white rounded-md sm:rounded-lg object-cover aspect-square"
            />
            {page !== "Leaderboard" && page !== "Friends" && (
              <p className="font-semibold hidden sm:block truncate whitespace-nowrap drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.3)]">
                {topicName}
              </p>
            )}
          </div>
        </>
      ) : orgShortForm ? (
        <>
          <div className="sm:text-lg flex items-center justify-start truncate 2xl:text-2xl text-white  overflow-hidden motion-safe:animate-fadeIn relative space-x-2 font-semibold transition-all leading-tight flex-1 sm:ml-2">
            <img
              src={orgLogoUrl}
              alt={orgShortForm}
              className="border-white h-7 sm:h-8 group-hover:opacity-50 transition-all bg-white short:border-[1.5px] border-[1.75px] 2xl:border-[2px] box-border rounded-md sm:rounded-lg object-cover aspect-square"
            />
            {/* <p className="font-semibold hidden sm:block truncate whitespace-nowrap drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.3)]">
              {orgShortForm}
            </p> */}
          </div>
        </>
      ) : (
        <></>
      )}

      <div className=" flex items-center justify-end sm:space-x-4 space-x-2">
        <div className="flex items-center justify-end space-x-2">
          {(page === "Focus" ||
            page === "Search" ||
            page === "TKL" ||
            page === "Landing") && (
            <a
              href="/focus"
              rel="noreferrer"
              className={`${
                page === "Focus" ? "bg-red-900" : "hover:bg-white/20"
              } px-3 text-lg 2xl:text-2xl text-white overflow-hidden motion-safe:animate-fadeIn relative hidden sm:flex space-x-2 items-center justify-center font-semibold transition-all py-1 leading-tight rounded-lg`}
            >
              <p className="font-semibold whitespace-nowrap">Focus</p>
            </a>
          )}

          {(page === "Focus" ||
            page === "Search" ||
            page === "TKL" ||
            page === "Landing") && (
            <a
              href="/search"
              rel="noreferrer"
              className={`${
                page === "Search" ? "bg-red-900" : "hover:bg-white/20"
              } px-3 text-lg 2xl:text-2xl text-white overflow-hidden motion-safe:animate-fadeIn relative hidden sm:flex space-x-2 items-center justify-center font-semibold transition-all py-1 leading-tight rounded-lg`}
            >
              <p className="font-semibold whitespace-nowrap">Topics</p>
            </a>
          )}

          {(page === "Focus" ||
            page === "Search" ||
            page === "TKL" ||
            page === "Landing") && (
            <a
              href="/tkl"
              rel="noreferrer"
              className={`${
                page === "TKL" ? "bg-red-900" : "hover:bg-white/20"
              } px-3 text-lg 2xl:text-2xl text-white overflow-hidden motion-safe:animate-fadeIn relative hidden sm:flex space-x-2 items-center justify-center font-semibold transition-all py-1 leading-tight rounded-lg`}
            >
              <p className="font-semibold whitespace-nowrap">LIVE</p>
            </a>
          )}

          {(page === "Focus" ||
            page === "Search" ||
            page === "TKL" ||
            page === "Landing") &&
            targetCustomer &&
            !targetCustomer.isPaidSubscriber && (
              <button
                onClick={(e) => {
                  e.preventDefault();

                  toggleBuySubscriptionForm(0);
                }}
                rel="noreferrer"
                // className={`${
                //   page === "TKL" ? "bg-red-900" : "hover:bg-white/20"
                // } px-3 text-lg 2xl:text-2xl text-white overflow-hidden motion-safe:animate-fadeIn relative hidden sm:flex space-x-2 items-center justify-center font-semibold transition-all py-1 leading-tight rounded-lg`}

                className=" px-3 text-lg 2xl:text-2xl hover:bg-white/20 text-white overflow-hidden motion-safe:animate-fadeIn relative hidden sm:flex space-x-2 items-center justify-center font-semibold transition-all py-1 leading-tight rounded-lg whitespace-nowrap shadow focus:outline-none focus:ring focus:ring-slate-500/50 focus-visible:outline-none focus-visible:ring focus-visible:ring-slate-500/50 before:absolute before:inset-0 before:rounded-[inherit] before:bg-[linear-gradient(45deg,transparent_25%,theme(colors.white/.5)_50%,transparent_75%,transparent_100%)] before:bg-[length:250%_250%,100%_100%] before:bg-[position:200%_0,0_0] before:bg-no-repeat before:[transition:background-position_0s_ease] hover:before:bg-[position:-100%_0,0_0] hover:before:duration-[1500ms]"
              >
                <p className="font-semibold whitespace-nowrap">VIP</p>
              </button>
            )}
        </div>

        {(page === "Focus" ||
          page === "Search" ||
          page === "TKL" ||
          page === "Landing") && (
          <button
            onClick={(e) => {
              navigate("/registerSeller");
            }}
            className="group relative aspect-square h-[1.9rem] sm:h-auto flex text-white  transition-all items-center justify-center shrink-0 rounded-full py-0.5 text-sm sm:text-base "
          >
            <svg
              className="fill-current h-5 sm:h-6 opacity-100 group-hover:opacity-60"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.927 23.84C13.289 23.945 12.635 24 11.968 24C5.362 24 0 18.623 0 12C0 5.377 5.362 0 11.968 0C18.573 0 23.935 5.377 23.935 12C23.935 12.671 23.88 13.329 23.774 13.971L21.857 13.296C21.912 12.872 21.941 12.439 21.941 12C21.941 6.481 17.472 2 11.968 2C6.463 2 1.995 6.481 1.995 12C1.995 17.519 6.463 22 11.968 22C12.418 22 12.862 21.97 13.296 21.912L13.927 23.84ZM12.754 13.839C12.512 13.942 12.247 14 11.968 14C10.867 14 9.973 13.104 9.973 12C9.973 10.896 10.867 10 11.968 10C13.068 10 13.962 10.896 13.962 12C13.962 12.211 13.93 12.414 13.869 12.604L23.602 16.03L19.94 17.956L24 22.129L22.126 23.956L18.037 19.741L15.87 23.362L12.754 13.839ZM12.661 19.97C12.432 19.99 12.201 20 11.968 20C7.564 20 3.989 16.415 3.989 12C3.989 7.585 7.564 4 11.968 4C16.371 4 19.946 7.585 19.946 12C19.946 12.207 19.938 12.412 19.923 12.615L18.944 12.271L18.949 12C18.949 8.137 15.821 5 11.968 5C8.115 5 4.986 8.137 4.986 12C4.986 15.863 8.115 19 11.968 19L12.34 18.99L12.661 19.97ZM11.686 16.992C9.065 16.846 6.981 14.665 6.981 12C6.981 9.24 9.215 7 11.968 7C14.573 7 16.715 9.008 16.935 11.564L15.875 11.191C15.501 9.37 13.893 8 11.968 8C9.766 8 7.978 9.792 7.978 12C7.978 13.996 9.439 15.652 11.346 15.952L11.686 16.992Z"
              />
            </svg>

            <div className="sm:flex hidden font-semibold flex-col absolute items-center justify-center top-full left-1/2 transform -translate-x-1/2 whitespace-nowrap text-center text-xs text-white opacity-0 group-hover:opacity-100 z-10 transition-opacity duration-300">
              <svg
                className="h-1.5 rotate-180 fill-current text-gray-800"
                viewBox="0 0 20 10"
              >
                <path d="M0 0L20 0L10 10Z" />
              </svg>
              <span className="bg-gray-800 px-2 py-1  rounded-md shadow">
                Advertise on TKnight
              </span>
            </div>
          </button>
        )}

        {numUnclickedNotifications > 0 &&
        page !== "TKL" &&
        page !== "Battle" &&
        page !== "Notifications" &&
        !seller ? (
          <button
            onClick={(e) => {
              navigate("/notifications");
            }}
            className="group leading-tight text-center text-sm font-semibold bg-red-600 hover:bg-opacity-70 transition-all relative aspect-square h-[1.9rem] sm:h-7 flex text-white  items-center justify-center shrink-0 rounded-full py-0.5 sm:text-base "
          >
            <p className="text-center m-auto text-black">
              {numUnclickedNotifications}
            </p>

            <div className="sm:flex hidden font-semibold flex-col absolute items-center justify-center top-full left-1/2 transform -translate-x-1/2 whitespace-nowrap text-center text-xs text-white opacity-0 group-hover:opacity-100 z-10 transition-opacity duration-300">
              <svg
                className="h-1.5 rotate-180 fill-current text-gray-800"
                viewBox="0 0 20 10"
              >
                <path d="M0 0L20 0L10 10Z" />
              </svg>
              <span className="bg-gray-800 px-2 py-1  rounded-md shadow">
                Notifications
              </span>
            </div>
          </button>
        ) : (
          <></>
        )}
        {seller ? (
          <></>
        ) : customer ? (
          <PlayerCoinCounter
            targetCustomer={targetCustomer}
            targetGuestCustomer={targetGuestCustomer}
            toggleBuyCoinsForm={toggleBuyCoinsForm}
          />
        ) : (
          <button
            onClick={(e) => {
              if (page === "Login" || page === "Search") {
                navigate(
                  "/register" + (prevPath ? "/redirect/" + prevPath : "")
                );
              } else if (
                page === "Signup" ||
                page === "Forgot" ||
                page === "Admin Dash"
              ) {
                navigate("/login" + (prevPath ? "/redirect/" + prevPath : ""));
              } else if (page === "Focus") {
                if (topicShortForm) {
                  navigate("/register/redirect/focus>" + topicShortForm);
                } else if (orgNameForUrl) {
                  navigate("/register/redirect/focus>org>" + orgNameForUrl);
                } else {
                  navigate("/register/redirect/focus");
                }
              } else if (page === "Organization") {
                navigate("/register/redirect/org>" + orgNameForUrl);
              } else if (page === "Topic") {
                navigate("/register/redirect/topic>" + topicShortForm);
              } else {
                navigate("/register/redirect/focus");
              }
            }}
            className="text-sm  h-[1.9rem] sm:text-base group flex-shrink-0 flex hover:bg-opacity-30 transition-all items-center justify-center space-x-2 bg-white bg-opacity-20 rounded-full backdrop-blur-sm pl-4 pr-3 py-1 sm:h-full"
          >
            <p className="font-bold">
              {page === "Admin Dash"
                ? "User Login"
                : page === "Login"
                ? "Sign up instead"
                : page === "Signup" || page === "Forgot"
                ? "Sign in instead"
                : "Sign in"}
            </p>
            <svg
              className="h-2.5 fill-current"
              viewBox="0 0 11 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className=" -translate-x-1 group-hover:translate-x-0 transition-all"
                d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
              />
              <rect
                className="opacity-0 group-hover:opacity-100 transition-all"
                y="4"
                width="9"
                height="2"
              />
            </svg>
          </button>
        )}
        {customer && (
          <button
            className={`${
              targetCustomer
                ? targetCustomer.username === "humpty"
                  ? "bg-blue-300"
                  : // : targetCustomer.subscriptionType === 0
                    // ? "bg-yellow-300"
                    // : targetCustomer.subscriptionType === 1
                    // ? "bg-red-400"
                    "bg-black"
                : "bg-black"
            } hover:bg-opacity-50 group flex-shrink-0 transition-all overflow-hidden p-[.2rem] 2xl:p-1.5 rounded-full aspect-square w-8 flex items-center justify-center 2xl:w-14`}
            onClick={(e) => {
              e.preventDefault();
              setIsFirstTime(false);
              setShouldShowMiniNavBar(true);
            }}
            // hover:bg-blue-700
          >
            <img
              src={
                targetCustomer ? targetCustomer.profilePicUrl : defaultPicUrl
              }
              alt={targetCustomer ? targetCustomer.username : "Knight"}
              className="border-white group-hover:opacity-50 transition-all bg-black short:border-[1.5px] border-[1.75px] 2xl:border-[2px] box-border rounded-full object-cover aspect-square"
            />
          </button>
        )}

        {seller && (
          <button
            className={`${
              targetCustomer
                ? targetCustomer.username === "humpty"
                  ? "bg-blue-300"
                  : // : targetCustomer.subscriptionType === 0
                    // ? "bg-yellow-300"
                    // : targetCustomer.subscriptionType === 1
                    // ? "bg-red-400"
                    "bg-black"
                : "bg-black"
            } hover:bg-opacity-50 group flex-shrink-0 transition-all overflow-hidden p-[.2rem] 2xl:p-1.5 rounded-full aspect-square w-8 flex items-center justify-center 2xl:w-14`}
            onClick={(e) => {
              e.preventDefault();
              setIsFirstTime(false);
              setShouldShowMiniNavBar(true);
            }}
            // hover:bg-blue-700
          >
            <img
              src={
                targetCustomer ? targetCustomer.profilePicUrl : defaultPicUrl
              }
              alt={targetCustomer ? targetCustomer.username : "Knight"}
              className="border-white group-hover:opacity-50 transition-all bg-black short:border-[1.5px] border-[1.75px] 2xl:border-[2px] box-border rounded-full object-cover aspect-square"
            />
          </button>
        )}
      </div>
    </div>
  );
}
