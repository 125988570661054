import moment from "moment";
import React, { useEffect, useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import MysteryTopicBox from "./MysteryTopicBox";

function PieTimer({
  endTime,
  imgUrl,
  loadingPause,
  isFloating,
  alt,
  width,
  isLastQ,
  targetMediaUrl,
  shouldPlaySoundEffects,
  link,
  lastXSeconds,
  navigate,
  quiz,
  togglePauseQuizCallback,
  pauseErr,
  tempElapsedTime,
  loading,
  isPaused,
  questionView,
  extraLifeQView,
  ad,

  randomTopicLogoUrls,
  mysteryTopicLogoUrl,
  shouldOpenTN,
}) {
  // create canvas ref

  // const [playTimerSound] = useSound(timerSound, { volume: 0.1 });
  // const [playTimeUpSound] = useSound(timerUp, { volume: 0.1 });

  // const [isPlayingTimer, setIsPlayingTimer] = useState(true);
  const [isTimeUp, setIsTimeUp] = useState(
    quiz?.status === 5 ||
      questionView?.status === 1 ||
      extraLifeQView?.extraLifeAvailabilityEnded ||
      false
  );
  const [lastQuizStatus, setLastQuizStatus] = useState(quiz?.status);
  const [timerKey, setTimerKey] = useState(0);
  const [initialRemainingTime, setInitialRemainingTime] = useState(
    endTime && !isTimeUp
      ? Math.max(Math.round((new Date(endTime) - Date.now()) / 1000), 0)
      : null
  );

  const [secondsUntilNextQ, setSecondsUntilNextQ] = useState(null);

  useEffect(() => {
    const targetDate = moment(endTime).toDate(); // specify the target date here
    const updateSeconds = () => {
      const currentTime = Date.now();
      const msUntilNextQTemp = targetDate - currentTime;

      if (Math.floor(msUntilNextQTemp / 1000) > -1) {
        // if (
        //   shouldPlaySoundEffects &&
        //   (!quiz || quiz.status !== 3) &&
        //   Math.floor(msUntilNextQTemp / 1000) !== secondsUntilNextQ &&
        //   secondsUntilNextQ !== null &&
        //   Math.floor(msUntilNextQTemp / 1000) < 4
        // ) {
        //   if (Math.ceil(msUntilNextQTemp / 1000) <= 0) {
        //     // playTimeUpSound();
        //   } else {
        //     playTimerSound();
        //   }
        // }

        setSecondsUntilNextQ(Math.floor(msUntilNextQTemp / 1000));
      } else {
        setSecondsUntilNextQ(0);
      }
    };

    // Run the updateSeconds function immediately, and then set up an interval to run it every second
    updateSeconds();

    const intervalId = setInterval(updateSeconds, 1000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [
    endTime,
    // playTimerSound,
    secondsUntilNextQ,
    // shouldPlaySoundEffects,
    quiz,
    // playTimeUpSound,
  ]);
  useEffect(() => {
    if (quiz && !timerKey) {
      setTimerKey(Math.floor(Math.random() * 9000000) + 1000000 + 1);
      if (quiz.status < 5) {
        setIsTimeUp(false);
      }
    } else if (quiz && quiz.status !== lastQuizStatus) {
      setLastQuizStatus(quiz.status);
      setTimerKey(Math.floor(Math.random() * 9000000) + 1000000 + 1);
      if (quiz.status < 5) {
        setIsTimeUp(false);
      }
    }
  }, [quiz, lastQuizStatus, timerKey]);

  useEffect(() => {
    if (endTime && !isTimeUp) {
      const elapsed = new Date(endTime) - Date.now();

      if (initialRemainingTime === null && elapsed > 0) {
        setInitialRemainingTime(Math.round(elapsed / 1000));
      }
      if (!isTimeUp && (questionView || extraLifeQView || ad) && elapsed <= 0) {
        setIsTimeUp(true);
      }
    }
  }, [
    isTimeUp,
    endTime,
    questionView,
    extraLifeQView,
    ad,
    initialRemainingTime,
  ]);

  const isPlaying = !(
    isPaused ||
    quiz?.isPaused ||
    tempElapsedTime ||
    (quiz && quiz.gameTimingType !== 0 && !quiz.quizId)
  );

  const initialRemainingTimeForTimer = isPlaying
    ? initialRemainingTime
    : quiz?.isPaused
    ? quiz?.secondsLeftAtPause || tempElapsedTime || initialRemainingTime
    : tempElapsedTime || initialRemainingTime || 0;
  const timerDuration = Math.max(lastXSeconds / 1000, initialRemainingTime);

  return (
    <div className="relative select-none">
      <div className="rotate-180 rounded-full overflow-hidden">
        <CountdownCircleTimer
          timerKey={timerKey}
          key={quiz ? quiz.status : 0}
          // strokeDashoffset={"0"}
          isPlaying={isPlaying}
          initialRemainingTime={initialRemainingTimeForTimer}
          // isPlaying={quiz ? !quiz.isPaused : isPlayingTimer}
          duration={timerDuration}
          size={width}
          strokeLinecap="butt"
          rotation={"counterclockwise"}
          colors={[isFloating ? "#fff" : "#2563eb", "#b91c1c"]}
          trailColor={isFloating ? "transparent" : "#1f2937"}
          isSmoothColorTransition={false}
          colorsTime={[15, 5]}
          strokeWidth={width / 2}
          onComplete={(totalElapsedTime) => {
            if (!isTimeUp) {
              // if (shouldPlaySoundEffects) {
              //   if (
              //     !quiz ||
              //     quiz.status !== 3 ||
              //     (extraLifeQView && !extraLifeQView.extraLifeAvailabilityEnded)
              //   ) {
              //     playTimeUpSound();
              //   }
              // }
              setIsTimeUp(true);
            }
          }}
        ></CountdownCircleTimer>
      </div>
      {isLastQ ? (
        <MysteryTopicBox
          randomTopicLogoUrls={randomTopicLogoUrls}
          mysteryTopicLogoUrl={mysteryTopicLogoUrl}
          width={width}
          shouldOpenTN={shouldOpenTN}
          shouldScaleDown={
            isTimeUp ||
            !(questionView || quiz || extraLifeQView || ad) ||
            (quiz &&
              (isPaused ||
                quiz.isPaused ||
                tempElapsedTime ||
                (quiz.gameTimingType !== 0 && !quiz.quizId)) &&
              width <= 40) ||
            (quiz && quiz.status === 3)
              ? false
              : true
          }
        />
      ) : (
        <img
          src={imgUrl}
          onClick={(e) => {
            e.stopPropagation();
            if (link) {
              navigate(link);
            } else {
              e.preventDefault();
            }
          }}
          className={`${
            isTimeUp ||
            !(questionView || quiz || extraLifeQView || ad) ||
            (quiz &&
              (isPaused ||
                quiz.isPaused ||
                tempElapsedTime ||
                (quiz.gameTimingType !== 0 && !quiz.quizId)) &&
              width <= 40) ||
            (quiz && quiz.status === 3)
              ? ""
              : "scale-75"
          } ${
            link ? "hover:opacity-80 cursor-pointer" : ""
          } transition-all rounded-full origin-bottom mx-auto ${
            targetMediaUrl ? "" : isFloating ? "" : "bg-[#f7faff]"
          } inset-x-0 bottom-0 absolute aspect-square object-cover`}
          alt={alt}
          width={width}
          height={width}
        />
      )}

      {quiz &&
        // quiz.visibilityType === 1 &&
        [0, 1, 3].includes(quiz.status) &&
        !isTimeUp && (
          <button
            className={`${
              isTimeUp ||
              (quiz &&
                (isPaused ||
                  quiz.isPaused ||
                  tempElapsedTime ||
                  (quiz.gameTimingType !== 0 && !quiz.quizId)) &&
                width <= 40)
                ? ""
                : "scale-75"
            } ${
              link ? "hover:opacity-80 cursor-pointer" : ""
            } hide-button-flash flex items-center justify-center group transition-all w-full h-full origin-bottom mx-auto bottom-0 absolute aspect-square object-cover`}
            width={width}
            height={width}
            disabled={width <= 40 || loading || quiz.visibilityType !== 1}
            onClick={togglePauseQuizCallback}
          >
            {loadingPause ? (
              <svg
                className={`short:w-7 w-10 text-white stroke-current m-auto motion-safe:animate-spin`}
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.636 3.636A9 9 0 0119 10"
                  strokeWidth={2}
                  strokeLinecap="round"
                />
              </svg>
            ) : isPaused ||
              quiz.isPaused ||
              tempElapsedTime ||
              (quiz.gameTimingType !== 0 && !quiz.quizId) ? (
              <svg
                className={`${pauseErr ? " motion-safe:animate-shake" : ""} ${
                  width <= 40
                    ? "scale-60"
                    : width <= 90
                    ? "scale-100"
                    : "scale-150"
                } fill-current ${
                  quiz.visibilityType === 1 ? "group-hover:opacity-100" : ""
                } opacity-90 transition-all mx-auto text-white short:w-7 w-10`}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM16 12L10 7.5V16.5L16 12Z"
                />
              </svg>
            ) : (
              <svg
                className={`${pauseErr ? " motion-safe:animate-shake" : ""}  ${
                  width <= 40
                    ? "scale-60"
                    : width <= 90
                    ? "scale-100"
                    : "scale-125"
                } fill-current opacity-0 ${
                  quiz.visibilityType === 1 ? "group-hover:opacity-100" : ""
                } transition-all mx-auto text-white short:w-7 w-10`}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM11 16H9V8H11V16ZM13 16H15V8H13V16Z"
                />
              </svg>
            )}
          </button>
        )}
    </div>
  );
}

export default PieTimer;
