import moment from "moment";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Confetti from "react-confetti";
import { useNavigate, useParams } from "react-router";
import usePageTracking from "../../util/usePageTracking";

import { useMutation, useQuery } from "@apollo/client";
import { customerClient } from "../../GraphqlApolloClients";
import LiveStreamGradBack from "../../components/creator/LiveStreamGradBack";
import { CustomerAuthContext } from "../../context/customerAuth";
import { defaultPicUrl, useForm, useWindowSize } from "../../util/hooks";

import { useElements, useStripe } from "@stripe/react-stripe-js";
import BuyCoinsForm from "../../components/creator/BuyCoinsForm";
import BuySubscriptionForm from "../../components/creator/BuySubscriptionForm";
import CodeForm from "../../components/creator/CodeForm";
import GenericPaymentSuccessfulForm from "../../components/creator/GenericPaymentSuccessfulForm";

import MiniNavBar from "../../components/creator/MiniNavBar";
import PowerUpPaymentSuccessfulForm from "../../components/creator/PowerUpPaymentSuccessfulForm";
import {
  EDIT_CUSTOMER,
  FETCH_ARE_FRIEND_REQUESTS_AVAILABLE,
  FETCH_CITY_BY_ID,
  FETCH_CUSTOMER,
  FETCH_RECOMMENDED_CITIES,
  FETCH_RECOMMENDED_FRIENDS,
  TOGGLE_FRIEND_MUTATION,
} from "../../util/gqlFunctions";

const genders = {
  2: "Non-binary",
  1: "Female",
  0: "Male",
};

const stepToFieldToEdit = {
  0: "name",
  1: "username,birthday,gender",
  2: "cityId",
};

function CompleteRegistration(props) {
  usePageTracking();

  const [errors, setErrors] = useState({});

  let navigate = useNavigate();

  const { customer, logoutCustomer: logout } = useContext(CustomerAuthContext);
  // useEffect(() => {
  //   if (!customer) {
  //     navigate("/login/redirect/completeRegistration>0");
  //   }
  // }, [customer, navigate]);
  const stripe = useStripe();
  const elements = useElements();
  useEffect(() => {
    document.title = "Complete Sign Up | TKnight";
  }, []);

  const { values, setValues, onChange } = useForm(editCustomerCallback, {
    searchTerm: "",
    friendId: null,
    name: "",
    birthday: null,
    gender: null,
    city: "",
    country: "",
    cityId: "",
    displayCity: "",
    fieldsToEdit: [],
    pageSize: 20,
    seenIds: [],
    emails: [],
    phoneNumbers: [],
  });
  const { step, prevPath } = useParams();

  const [curStep, setCurStep] = useState(parseInt(step));

  useEffect(() => {
    if (step && parseInt(step) !== curStep) {
      setCurStep(parseInt(step));

      setValues({
        ...values,
        fieldsToEdit: [stepToFieldToEdit[parseInt(step)]],
      });
    }
  }, [step, curStep, values, setValues]);

  const [isFirstTime, setIsFirstTime] = useState(true);

  const [shouldShowMiniNavBar, setShouldShowMiniNavBar] = useState(false);

  const [isCaptionCopied, setIsCaptionCopied] = useState(null);
  const [isSharingToApps, setIsSharingToApps] = useState(false);
  const resetInterval = 1000;

  useEffect(() => {
    let timeout;
    if (isCaptionCopied && resetInterval) {
      timeout = setTimeout(() => setIsCaptionCopied(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isCaptionCopied, resetInterval]);
  const {
    data: { getCustomer: targetCustomer } = {},
    loading: loadingTargetCustomer,
  } = useQuery(FETCH_CUSTOMER, {
    client: customerClient,
  });

  const {
    data: { getRecommendedCitiesBySearch: recommendedCities } = {},

    refetch: refetchRecommendedCities,
  } = useQuery(FETCH_RECOMMENDED_CITIES, {
    client: customerClient,
    variables: values,
  });

  const {
    data: { getRecommendedFriends: recommendedFriends } = {},

    refetch: refetchRecommendedFriends,
  } = useQuery(FETCH_RECOMMENDED_FRIENDS, {
    client: customerClient,
    variables: values,
  });

  function editCustomerCallback(e) {
    e.preventDefault();
    editCustomer({ ...values });
  }

  const [openedProPlanPopupOnce, setOpenedProPlanPopupOnce] = useState(false);
  const [checkedIsCustomerLoggedIn, setCheckedIsCustomerLoggedIn] =
    useState(false);
  const [shouldOpenProInviteCodeForm, setShouldOpenProInviteCodeForm] =
    useState(false);
  useEffect(() => {
    if (
      (customer &&
        !loadingTargetCustomer &&
        !targetCustomer &&
        !checkedIsCustomerLoggedIn) ||
      (targetCustomer && targetCustomer.isBlocked)
    ) {
      logout();
      setCheckedIsCustomerLoggedIn(true);
    }
  }, [
    customer,
    loadingTargetCustomer,
    targetCustomer,
    logout,
    checkedIsCustomerLoggedIn,
  ]);

  const [toggleFriend, { loading: loadingToggleFriend }] = useMutation(
    TOGGLE_FRIEND_MUTATION,
    {
      refetchQueries: [
        { query: FETCH_CUSTOMER },
        { query: FETCH_RECOMMENDED_FRIENDS, variables: { values } },
        { query: FETCH_ARE_FRIEND_REQUESTS_AVAILABLE },
      ],
      update(_, { data: { toggleFriend: toggledFriend } }) {
        setValues({ ...values, friendId: null });
      },
      onError(err) {
        console.log(err);
        setValues({ ...values, friendId: null });
      },
      // variables: values,
      client: customerClient,
    }
  );
  const [editCustomer, { loading: loadingEditCustomer }] = useMutation(
    EDIT_CUSTOMER,
    {
      refetchQueries: [{ query: FETCH_CUSTOMER }],
      update(_, { data: { editCustomer: customerData } }) {
        setValues({
          ...values,
          name: values?.fieldsToEdit.includes("name")
            ? customerData.name
            : values.name,

          email: values?.fieldsToEdit.includes("email")
            ? customerData.email
            : values.email,
          phone: values?.fieldsToEdit.includes("phone")
            ? customerData.phone
            : values.phone,
          cityId: customerData.cityId || "",
          city: customerData.city || "",
          country: customerData.country || "",
          fieldsToEdit: [],
          oldPassword: "",
          password: "",
          username: values?.fieldsToEdit.includes("username")
            ? customerData.username
            : values.username,
          profilePicUrl: customerData.profilePicUrl,
          accentColor: customerData.accentColor || 0,
          instaHandle: customerData.instaHandle || "",
          redditHandle: customerData.redditHandle || "",
          twitterHandle: customerData.twitterHandle || "",
          tiktokHandle: customerData.tiktokHandle || "",
          remindersToReceive: customerData.remindersToReceive,
          birthday: customerData.birthday || null,
          gender: customerData.gender,
        });
        setErrors({});
        if (curStep === 3) {
          toggleBuySubscriptionForm(0);
        } else {
          navigate(
            "/completeRegistration/" +
              (curStep + 1) +
              (prevPath ? `/redirect/${prevPath}` : "")
          );
        }
      },
      onError(err) {
        console.log(err);

        if (err.graphQLErrors[0]) {
          console.log(err.graphQLErrors[0].extensions.errors);
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: { ...values },
      client: customerClient,
    }
  );

  const [isInviteCopied, setIsInviteCopied] = useState(null);
  const [isSharingInviteToApps, setIsSharingInviteToApps] = useState(false);

  useEffect(() => {
    let timeout;
    if (isInviteCopied && resetInterval) {
      timeout = setTimeout(() => setIsInviteCopied(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isInviteCopied, resetInterval]);
  const inviteFriends = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    var textToShare = "Check out tknight.live\n\n";
    textToShare +=
      "If you survive 7 trivia questions, you get to split the prize with other winners.";
    // if (targetCustomer.totalEarnings) {
    //   textToShare +=
    //     "I've won $" +
    //     (targetCustomer.totalEarnings / 100).toFixed(2) +
    //     " so far! ";
    // }
    textToShare += "It's really fun!!\n\n";
    if (targetCustomer) {
      textToShare +=
        'Sign up with my extra ❤️ code: "' + targetCustomer.username + '"\n\n';
    }
    textToShare += "#TKnight";

    // textToShare +=
    //   " ".repeat(registrationAddress.length + 11) +
    //   "``" +
    //   "`".repeat(Math.ceil(targetCustomer.username.length * 1.7)) +
    //   "``\n\n";

    // await handleInviteDownload();

    if (navigator.share) {
      navigator
        .share({
          title: "Battle on TKnight!",
          text: textToShare,
          // url: "http://worldphraze.com/",
          // url:
          //   playersPhraze && playersPhraze.isEligibleForPrize
          //     ? "W/" + playersPhraze.id
          //     : "phraze",
        })
        .then(() => {
          setIsInviteCopied(true);

          setIsSharingInviteToApps(true);
        })
        .catch((error) => {
          setIsSharingInviteToApps(false);
          navigator.clipboard.writeText(textToShare);
          setIsInviteCopied(true);
        });
    } else {
      navigator.clipboard.writeText(textToShare);
      setIsInviteCopied(true);
    }
  };

  const friendRef = useRef();
  const nameRef = useRef();
  const userDetailsRef = useRef();

  const cityRef = useRef();

  useEffect(() => {
    if (curStep === 0) {
      if (nameRef.current) {
        nameRef.current.scrollIntoView({
          behavior: "instant",
          block: "nearest",
          inline: "center",
        });
        nameRef.current.focus();
      }
    } else if (curStep === 1) {
      if (userDetailsRef.current) {
        userDetailsRef.current.scrollIntoView({
          behavior: "instant",
          block: "nearest",
          inline: "center",
        });
        userDetailsRef.current.focus();
      }
    } else if (curStep === 2) {
      if (cityRef.current) {
        cityRef.current.scrollIntoView({
          behavior: "instant",
          block: "nearest",
          inline: "center",
        });
        cityRef.current.focus();
      }
    } else if (curStep === 3) {
      if (friendRef.current) {
        friendRef.current.scrollIntoView({
          behavior: "instant",
          block: "nearest",
          inline: "center",
        });
      }
    }
  }, [curStep, openedProPlanPopupOnce, recommendedFriends]);
  const [shouldOpenReviewForm, setShouldOpenReviewForm] = useState(false);
  const [subscriptionType, setSubscriptionType] = useState(null);

  const [shouldOpenReviewSentForm, setShouldOpenReviewSentForm] =
    useState(false);
  const [shouldPlaySoundEffects, setShouldPlaySoundEffects] = useState(false);
  const [initializedMuteVals, setInitializedMuteVals] = useState(false);
  useEffect(() => {
    if (!initializedMuteVals && targetCustomer) {
      setShouldPlaySoundEffects(
        targetCustomer.areSoundEffectsMuted === true ? false : true
      );
      setInitializedMuteVals(true);
    }
  }, [shouldPlaySoundEffects, initializedMuteVals, targetCustomer]);
  const [shouldOpenBuyCoinsForm, setShouldOpenBuyCoinsForm] = useState(false);
  const [
    shouldOpenCoinPurchaseSuccessfulForm,
    setShouldOpenCoinPurchaseSuccessfulForm,
  ] = useState(false);

  const [shouldOpenPaymentSuccessfulForm, setShouldOpenPaymentSuccessfulForm] =
    useState(false);
  const [
    shouldOpenSubPaymentSuccessfulForm,
    setShouldOpenSubPaymentSuccessfulForm,
  ] = useState(false);
  const [shouldOpenJoinedProPlanPopup, setShouldOpenJoinedProPlanPopup] =
    useState(false);

  const toggleBuySubscriptionForm = (subscriptionType) => {
    if (subscriptionType === null && !openedProPlanPopupOnce) {
      setOpenedProPlanPopupOnce(true);
    }
    setShouldOpenSubPaymentSuccessfulForm(false);

    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(false);

    setSubscriptionType(subscriptionType);
  };

  const toggleReviewForm = useCallback(() => {
    setShouldOpenSubPaymentSuccessfulForm(false);

    setShouldOpenPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(false);

    setShouldOpenReviewForm(!shouldOpenReviewForm);
  }, [shouldOpenReviewForm]);
  const toggleReviewSentForm = () => {
    setShouldOpenSubPaymentSuccessfulForm(false);

    setShouldOpenPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(false);

    setShouldOpenReviewSentForm(!shouldOpenReviewSentForm);
  };

  const toggleCoinPurchaseSuccessfulForm = () => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);

    setShouldOpenBuyCoinsForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(false);

    setShouldOpenCoinPurchaseSuccessfulForm(
      !shouldOpenCoinPurchaseSuccessfulForm
    );
  };
  const toggleBuyCoinsForm = () => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);

    setShouldOpenCoinPurchaseSuccessfulForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(false);

    setShouldOpenBuyCoinsForm(!shouldOpenBuyCoinsForm);
  };
  const toggleSubPaymentSuccessfulForm = () => {
    setSubscriptionType(null);

    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(false);

    setShouldOpenSubPaymentSuccessfulForm(!shouldOpenSubPaymentSuccessfulForm);
  };
  const toggleJoinedProPlanPopup = () => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(false);

    setShouldOpenJoinedProPlanPopup(!shouldOpenJoinedProPlanPopup);
  };
  const toggleProInviteCodeForm = () => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(!shouldOpenProInviteCodeForm);
  };
  const togglePaymentSuccessfulForm = () => {
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldShowMiniNavBar(false);
    setShouldOpenProInviteCodeForm(false);

    setShouldOpenPaymentSuccessfulForm(!shouldOpenPaymentSuccessfulForm);
  };

  const size = useWindowSize();

  const handleChangeDate = (event) => {
    event.preventDefault();

    // Get the selected date and time in the user's timezone
    const localDate = moment(event.target.value).toDate();

    // Convert the local date and time to UTC
    const utcDate = moment.utc(localDate);

    // Save the UTC date and time to the state
    setValues({ ...values, birthday: utcDate });
  };

  const displayBirthday = () => {
    // Format and display the birthday value in the user's timezone
    return moment(values.birthday).local().format("YYYY-MM-DD");
  };

  const [valsAreInitialized, setValsAreInitialized] = useState(false);

  useEffect(() => {
    if (
      !valsAreInitialized &&
      targetCustomer &&
      !loadingEditCustomer &&
      !loadingTargetCustomer
    ) {
      setCurStep(targetCustomer.curRegistrationStep);

      setValues({
        ...values,
        fieldsToEdit: [stepToFieldToEdit[targetCustomer.curRegistrationStep]],
        name: targetCustomer.name || "",
        email: targetCustomer.email || "",
        phone: targetCustomer.phone,
        cityId: targetCustomer.cityId || "",
        city: targetCustomer.city || "",
        country: targetCustomer.country || "",
        oldPassword: "",
        username: targetCustomer.username || "",
        accentColor: targetCustomer.accentColor,
        profilePicUrl: targetCustomer.profilePicUrl,
        profilePicFile: null,
        instaHandle: targetCustomer.instaHandle || "",
        redditHandle: targetCustomer.redditHandle || "",
        twitterHandle: targetCustomer.twitterHandle || "",
        tiktokHandle: targetCustomer.tiktokHandle || "",
        remindersToReceive: targetCustomer.remindersToReceive,
        birthday: targetCustomer.birthday || null,
        gender: targetCustomer.gender,
        subscriptionType: targetCustomer.subscriptionType,
      });

      setValsAreInitialized(true);
    }
  }, [
    customer,
    loadingTargetCustomer,
    values,
    setValues,
    targetCustomer,
    loadingEditCustomer,
    valsAreInitialized,
  ]);

  const goToNext = (e) => {
    if (
      openedProPlanPopupOnce ||
      (targetCustomer && targetCustomer.isPaidSubscriber)
    ) {
      if (prevPath) {
        navigate("/" + prevPath.trim().split(">").join("/"));
      } else {
        navigate("/");
      }
    } else if (
      curStep === 3 &&
      (!recommendedFriends || recommendedFriends.length < 1)
    ) {
      if (
        openedProPlanPopupOnce ||
        (targetCustomer && targetCustomer.isPaidSubscriber)
      ) {
        if (prevPath) {
          navigate("/" + prevPath.trim().split(">").join("/"));
        } else {
          navigate("/");
        }
      } else {
        toggleBuySubscriptionForm(0);
      }
    } else if (curStep >= 3) {
      editCustomer({
        variables: { ...values, fieldsToEdit: ["curRegistrationStep"] },
      });
    } else {
      editCustomerCallback(e);
    }
  };

  const { data: { getCityById: targetCity } = {} } = useQuery(
    FETCH_CITY_BY_ID,
    {
      client: customerClient,
      variables: { cityId: values.cityId || "" },
    }
  );

  useEffect(() => {
    if (!customer) {
      navigate("/login");
    }
  }, [customer, navigate]);

  return (
    <div className="w-screen overflow-x-hidden h-full max-h-screen ">
      <LiveStreamGradBack
        gradIndex={
          targetCustomer && targetCustomer.accentColor
            ? targetCustomer.accentColor
            : 1
        }
      />
      {shouldShowMiniNavBar && targetCustomer && (
        <MiniNavBar
          targetCustomer={targetCustomer}
          isSharingInviteToApps={isSharingInviteToApps}
          isSharingToApps={isSharingToApps || isSharingInviteToApps}
          isShareCopied={isCaptionCopied || isInviteCopied}
          setIsSharingToApps={setIsSharingToApps}
          navigate={navigate}
          isInviteCopied={isInviteCopied}
          inviteFriends={inviteFriends}
          toggleReviewForm={toggleReviewForm}
          shouldShowMiniNavBar={shouldShowMiniNavBar}
          isFirstTime={isFirstTime}
          setIsFirstTime={setIsFirstTime}
          setShouldShowMiniNavBar={setShouldShowMiniNavBar}
          logout={logout}
          setShouldPlaySoundEffects={setShouldPlaySoundEffects}
          shouldPlaySoundEffects={shouldPlaySoundEffects}
          toggleBuySubscriptionForm={toggleBuySubscriptionForm}
        />
      )}

      {shouldOpenBuyCoinsForm && stripe && elements ? (
        <BuyCoinsForm
          stripe={stripe}
          elements={elements}
          targetCustomer={targetCustomer}
          toggleCoinPurchaseSuccessfulForm={toggleCoinPurchaseSuccessfulForm}
          shouldOpenBuyCoinsForm={shouldOpenBuyCoinsForm}
          quizId={null}
          toggleBuyCoinsForm={toggleBuyCoinsForm}
        />
      ) : (
        <></>
      )}

      {subscriptionType !== null && stripe && elements && targetCustomer ? (
        <BuySubscriptionForm
          stripe={stripe}
          elements={elements}
          targetCustomer={targetCustomer}
          togglePaymentSuccessfulForm={toggleSubPaymentSuccessfulForm}
          subscriptionType={subscriptionType}
          toggleBuySubscriptionForm={toggleBuySubscriptionForm}
          toggleProInviteCodeForm={toggleProInviteCodeForm}
          // subPopupReason={""}

          isOnCompleteRegistrationPage={true}
        />
      ) : (
        <></>
      )}

      {shouldOpenProInviteCodeForm && targetCustomer && (
        <CodeForm
          title="TKnight VIP Code"
          name="inviteCode"
          isCodeBoxOpen={shouldOpenProInviteCodeForm}
          setIsCodeBoxOpen={setShouldOpenProInviteCodeForm}
          typeOfCode={0}
          values={values}
          setValues={setValues}
          toggleJoinedProPlanPopup={toggleJoinedProPlanPopup}
          toggleProInviteCodeForm={toggleProInviteCodeForm}
        />
      )}
      {shouldOpenJoinedProPlanPopup && targetCustomer && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleJoinedProPlanPopup}
            targetCustomer={targetCustomer}
            message={
              targetCustomer.name
                ? `Welcome to TKnight VIP, ${targetCustomer.name}!`
                : "Welcome to TKnight VIP!"
            }
            buttonMsg={"Thanks!"}
            popUpTitle={
              "Joined " +
              (targetCustomer.subscriptionType === 0
                ? "Individual"
                : targetCustomer.subscriptionType === 1
                ? "Duo"
                : targetCustomer.subscriptionType === 2
                ? "Family"
                : "Club") +
              " Successfully!"
            }
          />
          <div className="absolute z-[90] pointer-events-none inset-0">
            <Confetti
              width={size.width}
              height={size.height}
              recycle={false}
              numberOfPieces={size.width >= 640 ? 1500 : 1000}
              tweenDuration={30 * 1000}
              gravity={0.3}
            />
          </div>
        </>
      )}
      {shouldOpenSubPaymentSuccessfulForm && targetCustomer && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleSubPaymentSuccessfulForm}
            targetCustomer={targetCustomer}
            message={
              targetCustomer.name
                ? `Welcome to TKnight VIP, ${targetCustomer.name}!`
                : "Welcome to TKnight VIP!"
            }
            buttonMsg={"Thanks!"}
          />
          <div className="absolute z-[90] pointer-events-none inset-0">
            <Confetti
              width={size.width}
              height={size.height}
              recycle={false}
              numberOfPieces={size.width >= 640 ? 1500 : 1000}
              tweenDuration={30 * 1000}
              gravity={0.3}
            />
          </div>
        </>
      )}

      {shouldOpenCoinPurchaseSuccessfulForm && targetCustomer && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleCoinPurchaseSuccessfulForm}
            targetCustomer={targetCustomer}
            message={
              "Tokens added to stash. Good luck, " + targetCustomer.name + "!"
            }
            buttonMsg={"Awesome!"}
            popUpTitle={"Payment Successful!"}
          />
        </>
      )}

      {shouldOpenReviewSentForm && targetCustomer && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleReviewSentForm}
            targetCustomer={targetCustomer}
            popUpTitle={"Thank you!"}
            message={"This will help improve the game :)"}
            buttonMsg={"Awesome!"}
          />
        </>
      )}
      {shouldOpenPaymentSuccessfulForm && targetCustomer && (
        <PowerUpPaymentSuccessfulForm
          togglePaymentSuccessfulForm={togglePaymentSuccessfulForm}
          targetCustomer={targetCustomer}
        />
      )}

      <div
        className={`relative z-10 overflow-hidden flex flex-col h-screen max-h-screen short:max-w-xl lg:max-w-xl 2xl:max-w-2xl 3xl:max-w-3xl mx-auto xl:px-0 px-4 pb-4 lg:pb-8`}
      >
        <div
          className={`space-x-2 sm:space-x-3 relative z-20 px-2 py-4 sm:px-2 sm:pb-6 text-white flex items-center justify-between w-full`}
        >
          {curStep && curStep > 0 && curStep < 4 && !openedProPlanPopupOnce ? (
            <button
              onClick={(e) => {
                navigate(
                  "/completeRegistration/" +
                    (curStep - 1) +
                    (prevPath ? `/redirect/${prevPath}` : "")
                );
              }}
              className="transition-all h-full origin-left px-3 bg-white/30 rounded-full outline-none hover:opacity-50 focus:outline-none"
            >
              <svg
                className="h-3 fill-current"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M7.41 1.41L6 0L0 6L6 12L7.41 10.59L2.83 6L7.41 1.41Z" />
              </svg>
            </button>
          ) : (
            <button
              onClick={(e) => {
                if (curStep === 3 && openedProPlanPopupOnce) {
                  if (prevPath) {
                    navigate("/" + prevPath.trim().split(">").join("/"));
                  } else {
                    navigate("/");
                  }
                } else {
                  e.preventDefault();
                }
              }}
              className="transition-opacity hover:opacity-50 disabled:hover:opacity-100"
              disabled={
                curStep !== 3 || !openedProPlanPopupOnce || loadingEditCustomer
              }
            >
              <svg
                className="h-5 fill-current "
                viewBox="0 0 140 140"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M69.7018 0C51.1365 0 33.3636 7.37493 20.2921 20.5025C7.22057 33.63 -0.0786027 51.4347 0.000638512 69.9999C0.0798797 88.5647 7.531 106.37 20.7136 119.497C33.8971 132.625 51.7337 140 70.2982 140C61.7401 140 53.3626 137.462 46.226 132.707C39.0902 127.952 33.5148 121.194 30.2062 113.287C26.8969 105.38 26.0032 96.679 27.637 88.2849C29.2709 79.8908 33.3595 72.1805 39.3851 66.1287C45.4116 60.077 53.1045 55.9556 61.491 54.286C69.8783 52.6163 78.5826 53.4732 86.5034 56.7484C94.4243 60.0236 101.206 65.57 105.991 72.6861C110.777 79.8019 113.35 88.1688 113.386 96.7275C113.435 108.204 108.923 119.21 100.842 127.325C92.7618 135.44 81.7751 140 70.2982 140C88.8635 140 106.636 132.625 119.708 119.497C132.779 106.37 140.079 88.5647 139.999 69.9999C139.92 51.4347 132.469 33.63 119.286 20.5025C106.103 7.37493 88.2671 0 69.7018 0Z" />
              </svg>
            </button>
          )}

          <div className="flex-1"></div>

          <div className=" flex items-center justify-end sm:space-x-4 space-x-2">
            <div className="text-base 2xl:text-2xl text-white overflow-hidden relative flex space-x-2 items-center justify-center font-semibold py-1 leading-tight rounded-full">
              <p className=" font-normal whitespace-nowrap">
                {curStep + 1} / 4
              </p>
            </div>
          </div>
        </div>

        <div className="relative w-full flex-1 flex flex-row gap-6 rounded-lg sm:rounded-none sm:gap-10 snap-x snap-mandatory overflow-hidden my-2 sm:my-0">
          <div
            className={`snap-center flex px-2 py-2 flex-col overflow-y-auto items-start justify-start shrink-0 w-full`}
          >
            <label
              htmlFor="name"
              className="font-semibold text-white sm:text-2xl text-xl drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.3)]"
            >
              Name
            </label>
            <p className="mb-2  text-gray-300 sm:text-lg drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.3)]">
              You can only change this once every 7 days.
            </p>
            <input
              ref={nameRef}
              type={"text"}
              name={"name"}
              id={"name"}
              value={values.name}
              onChange={onChange}
              className={`text-xl sm:text-2xl disabled:bg-white mt-1 focus:outline-none w-full shadow-sm px-3 py-2 rounded-md border-gray-200 border `}
              // placeholder={"Aubrey"}
              disabled={loadingEditCustomer}
              onKeyDown={(e) => {
                if (e.key === "Tab") {
                  goToNext(e);
                }
              }}
              maxLength={30}
              autoComplete="nope"
            />
            {errors && errors.name ? (
              <p className="sm:text-lg font-semibold w-full max-w-sm text-red-400 leading-tight sm:leading-tight drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.3)] mt-2">
                {errors.name.replace("- ", "")}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div
            className={`snap-center flex px-2 py-2 flex-col overflow-y-auto items-start justify-start shrink-0 w-full`}
          >
            <label
              htmlFor="username"
              className="font-semibold text-white sm:text-2xl text-xl drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.3)]"
            >
              Username
            </label>
            <p className="mb-2  text-gray-300 sm:text-lg drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.3)]">
              You can only change this once every 7 days.
            </p>
            <input
              ref={userDetailsRef}
              type={"text"}
              name={"username"}
              id={"username"}
              value={values.username || ""}
              onChange={onChange}
              className={`text-xl sm:text-2xl lowercase disabled:bg-white mt-1 text-left focus:outline-none w-full shadow-sm px-3 py-2 rounded-md border-gray-200 border `}
              // placeholder={"drake"}
              disabled={loadingEditCustomer}
              maxLength={30}
              autoComplete="nope"
              onKeyDown={(e) => {
                if (e.key === "Tab") {
                  goToNext(e);
                }
              }}
            />
            {errors && errors.username ? (
              <p className="sm:text-lg font-semibold w-full max-w-sm text-red-400 text-left leading-tight sm:leading-tight drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.3)] mt-2">
                {errors.username.replace("- ", "")}
              </p>
            ) : (
              <></>
            )}

            <label
              htmlFor="birthday"
              className="mt-10 font-semibold text-white sm:text-2xl text-xl drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.3)]"
            >
              Birthday
            </label>
            <p className="mb-2  text-gray-300 sm:text-lg drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.3)]">
              This helps us recommend more relevant topics to you. Other players
              will not see this.
            </p>

            <input
              type="date"
              id="birthday"
              min={moment(new Date())
                .subtract(100, "years")
                .format("YYYY-MM-DD")}
              // max={moment(new Date()).subtract(8, "years").format("YYYY-MM-DD")}
              className={`text-left text-xl sm:text-2xl disabled:bg-white mt-1 focus:outline-none w-full shadow-sm px-3 py-2 rounded-md border-gray-200 border `}
              value={displayBirthday()}
              onChange={handleChangeDate}
              disabled={loadingEditCustomer}
              onKeyDown={(e) => {
                if (e.key === "Tab") {
                  goToNext(e);
                }
              }}
            />

            {errors && errors.birthday ? (
              <p className="sm:text-lg font-semibold w-full max-w-sm text-red-400 leading-tight sm:leading-tight drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.3)] mt-2">
                {errors.birthday.replace("- ", "")}
              </p>
            ) : (
              <></>
            )}

            <label
              htmlFor="gender"
              className="mt-10 font-semibold text-white sm:text-2xl text-xl drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.3)]"
            >
              Gender
            </label>
            <p className="mb-2 text-gray-300 sm:text-lg drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.3)]">
              This helps us improve the game for everyone.
            </p>

            <div
              className={`gap-2 flex-wrap flex items-center justify-center mt-2`}
            >
              {[1, 0, 2].map((option, index) => (
                <button
                  key={index}
                  onClick={(e) => {
                    e.preventDefault();
                    if (values.gender === parseInt(option)) {
                      setValues({
                        ...values,
                        gender: parseInt(option),
                      });
                    } else {
                      setValues({
                        ...values,
                        gender: parseInt(option),
                      });
                    }
                  }}
                  className={`px-5 ${
                    values.gender !== parseInt(option)
                      ? "flex bg-blue-50"
                      : " bg-yellow-400  hover:bg-opacity-90"
                  } rounded-full text-base sm:text-xl bg-opacity-90 hover:bg-opacity-50 items-center space-x-2 transition-all justify-start overflow-hidden py-2`}
                  disabled={loadingEditCustomer}
                >
                  <p>{genders[option]}</p>
                </button>
              ))}
            </div>

            {errors && errors.gender ? (
              <p className="sm:text-lg font-semibold w-full max-w-sm text-red-400 leading-tight sm:leading-tight drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.3)] mt-2">
                {errors.gender.replace("- ", "")}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div
            className={`snap-center flex px-2 py-2 flex-col overflow-y-auto items-start justify-start shrink-0 w-full`}
            ref={cityRef}
          >
            <label
              htmlFor="username"
              className="font-semibold text-white sm:text-2xl text-xl drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.3)]"
            >
              City
            </label>
            <p className="mb-2  text-gray-300 sm:text-lg drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.3)]">
              Appear on city and country leaderboards.
            </p>
            <div
              className={`mb-2 text-xl sm:text-2xl lowercase text-black disabled:bg-white mt-1 text-left focus:outline-none w-full shadow-sm pl-3 px-2 py-2 rounded-md border-gray-200  bg-white space-x-2 duration-700 transition-height flex items-center justify-start border `}
            >
              <div className="transition-all">
                <svg
                  className="fill-current w-5 short:w-4"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 7C12 9.76142 9.76142 12 7 12C4.23858 12 2 9.76142 2 7C2 4.23858 4.23858 2 7 2C9.76142 2 12 4.23858 12 7ZM10.8482 12.8482C9.74407 13.5763 8.42152 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7C14 8.72211 13.3781 10.299 12.3467 11.5183L16.4142 15.5858L15 17L10.8482 12.8482Z"
                  />
                </svg>
              </div>

              <input
                name={"searchTerm"}
                id={"searchTerm"}
                value={values.searchTerm}
                onChange={(e) => {
                  setValues({ ...values, searchTerm: e.target.value });

                  if (curStep === 2) {
                    refetchRecommendedFriends({
                      searchTerm: e.target.value,
                      pageSize: 20,
                    });
                  } else {
                    refetchRecommendedCities({
                      searchTerm: e.target.value,
                      seenIds: [],
                    });
                  }
                }}
                className={`short:text-sm text-black text-lg sm:py-2 leading-tight w-full flex-1 bg-transparent focus:outline-none`}
                placeholder={"Find your city"}
                // disabled={loadingCreateComment}
                // onKeyDown={(e) => {
                //   if (e.key === "Enter" && e.shiftKey === false) {
                //     e.preventDefault();
                //     createComment();
                //   }
                // }}
                maxLength={100}
              />
            </div>

            {targetCity && (
              <div className="flex shrink-0 text-left group space-x-2 sm:space-x-3 overflow-hidden sm:hover:bg-white/20 transition-all rounded-md sm:hover:shadow-sm items-center w-full justify-start sm:px-2 py-2">
                <div className="bg-opacity-20  bg-white overflow-hidden shrink-0 shadow relative rounded-full">
                  <img
                    src={targetCity.logoUrl || defaultPicUrl}
                    alt={targetCity.name}
                    className={`${
                      targetCustomer &&
                      values.cityId &&
                      values.cityId === targetCity.id
                        ? "border-yellow-400"
                        : "border-gray-400"
                    } h-10 bg-white border-4 box-border rounded-full object-cover aspect-square`}
                  />
                </div>
                <div className="flex w-full truncate space-y-1 flex-col items-start justify-center">
                  <p className="font-semibold w-full truncate leading-none text-white sm:leading-none sm:text-lg text-base short:text-sm short:leading-none">
                    {targetCity.name}
                  </p>
                  <p className=" text-white text-base sm:text-base short:text-xs short:leading-none leading-tight">
                    {targetCity.country}
                  </p>
                </div>

                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setValues({
                      ...values,
                      cityId: targetCity.id,
                      city: targetCity.name,
                      country: targetCity.country,
                      searchTerm: "",
                    });
                  }}
                  className={`${
                    targetCustomer &&
                    values.cityId &&
                    values.cityId === targetCity.id
                      ? "bg-yellow-400 text-black bg-opacity-90"
                      : "bg-white text-black hover:bg-opacity-50 bg-opacity-90"
                  } shrink-0 px-4 h-10 short:text-sm transition-all rounded-full font-bold text-center  whitespace-nowrap py-2 `}
                >
                  {targetCustomer &&
                  values.cityId &&
                  values.cityId === targetCity.id
                    ? "Selected"
                    : "Select"}
                </button>
              </div>
            )}
            <div className="flex flex-col overflow-y-auto items-start justify-start w-full flex-1">
              {recommendedCities &&
              recommendedCities.length > 0 &&
              values.searchTerm ? (
                recommendedCities
                  .filter((city) => city.id !== targetCity?.id)
                  .map((city) => (
                    <div
                      key={city.id}
                      className="flex shrink-0 text-left group space-x-2 sm:space-x-3 overflow-hidden sm:hover:bg-white/20 transition-all rounded-md sm:hover:shadow-sm items-center w-full justify-start sm:px-2 py-2"
                    >
                      <div className="bg-opacity-20  bg-white overflow-hidden shrink-0 shadow relative rounded-full">
                        <img
                          src={city.logoUrl || defaultPicUrl}
                          alt={city.name}
                          className={`${
                            values && values.cityId && values.cityId === city.id
                              ? "border-white"
                              : "border-gray-400"
                          } h-10 bg-white border-4 box-border rounded-full object-cover aspect-square`}
                        />
                      </div>
                      <div className="flex w-full truncate space-y-1 flex-col items-start justify-center">
                        <p className="font-semibold w-full truncate leading-none text-white sm:leading-none sm:text-lg text-base short:text-sm short:leading-none">
                          {city.name}
                        </p>
                        <p className="text-white text-base sm:text-base short:text-xs short:leading-none leading-tight">
                          {city.country}
                        </p>
                      </div>

                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setValues({
                            ...values,
                            cityId: city.id,
                            city: city.name,
                            searchTerm: "",
                          });
                        }}
                        className={`${
                          values && values.cityId && values.cityId === city.id
                            ? "bg-yellow-400 text-black bg-opacity-90"
                            : "bg-white text-black hover:bg-opacity-50 bg-opacity-90"
                        } shrink-0 px-4 h-10 short:text-sm transition-all rounded-full font-bold text-center  whitespace-nowrap py-2 `}
                      >
                        {values && values.cityId && values.cityId === city.id
                          ? "Selected"
                          : "Select"}
                      </button>
                    </div>
                  ))
              ) : (
                <></>
              )}
            </div>
          </div>

          <div
            className={`snap-center flex px-2 py-2 flex-col overflow-y-auto items-start justify-start shrink-0 w-full`}
            ref={friendRef}
          >
            <label
              htmlFor="username"
              className="font-semibold text-white sm:text-2xl text-xl drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.3)]"
            >
              Friends
            </label>
            <p className="mb-2  text-gray-300 sm:text-lg drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.3)]">
              Add people you'd like to battle.{" "}
            </p>
            <div
              className={`mb-2 text-xl sm:text-2xl lowercase text-black disabled:bg-white mt-1 text-left focus:outline-none w-full shadow-sm pl-3 px-2 py-2 rounded-md border-gray-200  bg-white space-x-2 duration-700 transition-height flex items-center justify-start border `}
            >
              <div className="transition-all">
                <svg
                  className="fill-current w-5 short:w-4"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 7C12 9.76142 9.76142 12 7 12C4.23858 12 2 9.76142 2 7C2 4.23858 4.23858 2 7 2C9.76142 2 12 4.23858 12 7ZM10.8482 12.8482C9.74407 13.5763 8.42152 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7C14 8.72211 13.3781 10.299 12.3467 11.5183L16.4142 15.5858L15 17L10.8482 12.8482Z"
                  />
                </svg>
              </div>

              <input
                name={"searchTerm"}
                id={"searchTerm"}
                value={values.searchTerm}
                onChange={(e) => {
                  setValues({ ...values, searchTerm: e.target.value });

                  if (curStep === 5) {
                    refetchRecommendedFriends({
                      searchTerm: e.target.value,
                      pageSize: 20,
                    });
                  } else {
                    refetchRecommendedCities({
                      searchTerm: e.target.value,
                      seenIds: [],
                    });
                  }
                }}
                className={`short:text-sm text-black text-lg sm:py-2 leading-tight w-full flex-1 bg-transparent focus:outline-none`}
                placeholder={"Find your friends"}
                // disabled={loadingCreateComment}
                // onKeyDown={(e) => {
                //   if (e.key === "Enter" && e.shiftKey === false) {
                //     e.preventDefault();
                //     createComment();
                //   }
                // }}
                maxLength={100}
              />
            </div>

            <button
              onClick={inviteFriends}
              className={`w-full mb-2 flex rounded-md text-sm sm:text-base ${
                isSharingInviteToApps || isInviteCopied
                  ? "bg-green-200 hover:bg-green-400"
                  : "bg-gray-100 hover:bg-opacity-90"
              } transition-all py-1.5 items-center justify-center space-x-1.5`}
            >
              <p className=" leading-tight">
                {isSharingInviteToApps
                  ? "Invite shared!"
                  : isInviteCopied
                  ? "Invite copied!"
                  : "Invite for extra life"}
              </p>
              <svg
                className="h-3 sm:h-4 aspect-square fill-current "
                viewBox="0 0 21 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_8661_576)">
                  <path d="M15 14.1365C14.24 14.1365 13.56 14.4378 13.04 14.9096L5.91 10.743C5.96 10.512 6 10.2811 6 10.0402C6 9.7992 5.96 9.56827 5.91 9.33735L12.96 5.21084C13.5 5.71285 14.21 6.0241 15 6.0241C16.66 6.0241 18 4.67871 18 3.01205C18 1.34538 16.66 0 15 0C13.34 0 12 1.34538 12 3.01205C12 3.25301 12.04 3.48394 12.09 3.71486L5.04 7.84137C4.5 7.33936 3.79 7.02811 3 7.02811C1.34 7.02811 0 8.37349 0 10.0402C0 11.7068 1.34 13.0522 3 13.0522C3.79 13.0522 4.5 12.741 5.04 12.239L12.16 16.4157C12.11 16.6265 12.08 16.8474 12.08 17.0683C12.08 18.6847 13.39 20 15 20C16.61 20 17.92 18.6847 17.92 17.0683C17.92 15.4518 16.61 14.1365 15 14.1365Z"></path>
                </g>
              </svg>
            </button>
            <div className="flex flex-col overflow-y-auto items-start justify-start w-full flex-1">
              {targetCustomer &&
              recommendedFriends &&
              recommendedFriends.length > 0 ? (
                recommendedFriends.map((friend) => (
                  <div
                    key={friend.id}
                    className="flex shrink-0 text-left group space-x-2 sm:space-x-3 overflow-hidden sm:hover:bg-white/20 transition-all rounded-md sm:hover:shadow-sm items-center w-full justify-start sm:px-2 py-2"
                  >
                    <div
                      className={`overflow-hidden shrink-0 shadow relative rounded-full`}
                    >
                      <img
                        src={friend.profilePicUrl}
                        alt={friend.name}
                        className={`${
                          targetCustomer &&
                          targetCustomer.followingIds &&
                          targetCustomer.followingIds.includes(friend.id)
                            ? friend.followingIds.includes(targetCustomer.id)
                              ? "border-white"
                              : " border-gray-400"
                            : friend.followingIds.includes(targetCustomer.id)
                            ? "border-white"
                            : "border-gray-400"
                        } h-10 bg-white border-4 box-border rounded-full object-cover aspect-square`}
                      />
                    </div>
                    <div className="flex w-full truncate space-y-1 flex-col items-start justify-center">
                      <p className="font-semibold leading-none text-white sm:leading-none sm:text-lg short:text-sm text-base short:leading-none">
                        {friend.name}
                      </p>
                      <p className=" text-white text-sm short:text-xs sm:text-base short:leading-tight leading-tight">
                        @{friend.username}
                        {/* Active {moment(friend.lastActiveAt).fromNow()} */}
                      </p>
                    </div>

                    <button
                      className={`${
                        targetCustomer &&
                        targetCustomer.followingIds &&
                        targetCustomer.followingIds.includes(friend.id)
                          ? friend.followingIds.includes(targetCustomer.id)
                            ? "bg-green-600 text-white hover:bg-green-800 bg-opacity-90"
                            : "bg-purple-600 text-white  hover:bg-purple-800 bg-opacity-90"
                          : friend.followingIds.includes(targetCustomer.id)
                          ? "bg-blue-600 text-white hover:bg-blue-900 bg-opacity-90"
                          : "bg-white text-black hover:bg-opacity-50 bg-opacity-90"
                      } px-4 short:text-sm bg-opacity-90 transition-all rounded-full font-bold text-center text-black  whitespace-nowrap py-2 `}
                      onClick={(e) => {
                        e.preventDefault();

                        setValues({ ...values, friendId: friend.id });
                        toggleFriend({
                          variables: { username: friend.username },
                        });
                      }}
                      disabled={loadingToggleFriend}
                    >
                      {targetCustomer &&
                      targetCustomer.followingIds &&
                      targetCustomer.followingIds.includes(friend.id)
                        ? friend.followingIds.includes(targetCustomer.id)
                          ? "Added"
                          : "Requested"
                        : friend.followingIds.includes(targetCustomer.id)
                        ? "Accept"
                        : "Add friend"}
                    </button>
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <button
          disabled={
            loadingEditCustomer ||
            !targetCustomer ||
            (curStep === 0 && !values.name) ||
            (curStep === 1 && values.gender === null) ||
            (curStep === 1 && values.birthday === null) ||
            (curStep === 1 && !values.username) ||
            (curStep === 2 && !values.cityId)
          }
          onClick={goToNext}
          className="w-full relative p-3 uppercase overflow-hidden py-4 short:py-2 disabled:opacity-50 hover:bg-opacity-70 transition-all rounded-full font-bold bg-white text-black  text-2xl short:text-lg  sm:text-2xl"
        >
          <div
            className={`${
              !loadingEditCustomer
                ? "motion-safe:animate-fadeOutUpFast"
                : "motion-safe:animate-fadeDownFast"
            } bg-black absolute inset-0 flex items-center justify-center`}
          >
            <svg
              className={`w-6 motion-safe:animate-spin`}
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.636 3.636A9 9 0 0119 10"
                stroke="#fff"
                strokeWidth={2}
                strokeLinecap="round"
              />
            </svg>
          </div>
          <p
            className={`${
              loadingEditCustomer
                ? "motion-safe:animate-fadeOutDownFast"
                : "motion-safe:animate-fadeUpFast"
            } h-full`}
          >
            {openedProPlanPopupOnce ||
            (targetCustomer && targetCustomer.isPaidSubscriber)
              ? "Done"
              : "Continue"}
          </p>
        </button>
      </div>
    </div>
  );
}

export default CompleteRegistration;
