import React, { useState } from "react";

function EditProForm({
  targetCustomer,
  togglePaymentSuccessfulForm,
  subscriptionType,
  toggleBuySubscriptionForm,
  customerSubscription,
  toggleManageMembersForm,
}) {
  const [errors, setErrors] = useState({});
  console.log(errors);
  return (
    <div className="fixed pt-16 sm:py-10 z-[70] overscroll-contain w-screen h-screen max-h-screen max-w-screen overflow-hidden flex items-end justify-end sm:items-center sm:justify-center short:pt-12">
      <button
        onClick={(e) => {
          e.preventDefault();
          setErrors({});
          toggleBuySubscriptionForm(null);
        }}
        className="z-40 bg-gray-500 hide-button-flash bg-opacity-10 motion-safe:animate-fadeIn flex items-center justify-center inset-0 absolute backdrop-blur-sm"
        disabled={false}
      />
      <form
        // onSubmit={buyBandCallback}
        className={`overflow-x-hidden max-h-full flex-shrink motion-safe:animate-fadeUpFast overscroll-contain sm:m-auto w-full max-w-xl overflow-y-auto flex flex-col items-start justify-start z-40 rounded-xl px-6 pb-6 sm:p-8 sm:pb-0 bg-darker-gray shadow-xl rounded-b-none sm:rounded-3xl `}
      >
        <button
          onClick={(e) => {
            e.preventDefault();
            setErrors({});
            toggleBuySubscriptionForm(null);
          }}
          className="w-full pt-4 short:pt-3 short:pb-1 pb-2 sm:hidden"
        >
          <div className="rounded-full bg-gray-700 flex-shrink-0 h-1 mx-auto w-10 mb-2"></div>
        </button>

        <p className="text-gray-200 text-2xl short:text-xl text-center font-bold w-full leading-tight short:leading-tight">
          TKnight VIP
        </p>
        <p className="text-gray-400 w-full text-center mb-4 leading-tight mt-1">
          {targetCustomer.isPaidSubscriber && customerSubscription
            ? "Edit VIP Settings"
            : "Pick the plan that is right for you"}
        </p>

        <div className="w-full my-2">
          <div className="grid grid-cols-1 gap-2 w-full">
            {targetCustomer.subscriptionType !== 0 &&
              customerSubscription &&
              customerSubscription.customerId === targetCustomer.id && (
                <button
                  className={`border-gray-700 hover:bg-gray-800 text-gray-400 transition-all group relative text-left w-full shadow-sm flex-1 px-3 py-2 rounded-md border`}
                  onClick={(e) => {
                    e.preventDefault();

                    toggleManageMembersForm();
                  }}
                >
                  <div className="w-full flex items-center justify-between">
                    <p className=" text-sm sm:text-base">Members</p>
                  </div>
                  <p className={`text-xl text-white font-bold`}>
                    Add/remove members
                  </p>
                </button>
              )}
            <button
              className={`border-gray-700 hover:bg-gray-800 text-gray-400 transition-all group relative text-left w-full shadow-sm flex-1 px-3 py-2 rounded-md border`}
              onClick={(e) => {
                e.preventDefault();

                toggleBuySubscriptionForm(targetCustomer.subscriptionType);
              }}
            >
              <div className="w-full flex items-center justify-between">
                <p className=" text-sm sm:text-base">Plan</p>
              </div>
              <p className={`text-xl text-white font-bold`}>
                Manage subscription
              </p>
            </button>
          </div>
        </div>

        <p className="text-center w-full text-gray-400 mt-4 mb-0 sm:mb-6 text-base">
          Have a question?{" "}
          <a
            href="mailto:info@tknight.live"
            target="_blank"
            rel="noreferrer"
            className="text-blue-300 hover:text-blue-400 transition-all"
          >
            Contact support
          </a>
        </p>
      </form>
    </div>
  );
}

export default EditProForm;
