import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import {
  ANSWER_ADDED_SUBSCRIPTION,
  FETCH_ANSWER_BUBBLES_BY_QUESTION_QUERY,
} from "../../util/gqlFunctions";
import { useWindowSize } from "../../util/hooks";
import AnswerBubble from "./AnswerBubble";
import XPBubble from "./XPBubble";
// const defaultPicUrl = "https://tknight-media.s3.amazonaws.com/profile.png";

function AnswerBubbleCanvas({
  triviaNightId,
  quizId,
  questionId,
  lastQStartedAt,
  curAnsEndsAt,
  deltaXP,
  indexClicked,
  targetGuestCustomer,
  targetCustomer,
  personalQuiz,
  quiz,
  questionView,
  question,
  targetMediaUrl,
}) {
  const {
    data: { getAnswerBubblesByQuestion: answerBubbles } = {},

    subscribeToMore,
  } = useQuery(FETCH_ANSWER_BUBBLES_BY_QUESTION_QUERY, {
    variables: {
      triviaNightId,
      quizId,
      questionId,
      seenIds: [],
      pageSize: 13,
    },
  });

  useEffect(() => {
    const subscription = subscribeToMore({
      document: ANSWER_ADDED_SUBSCRIPTION,
      variables: { eventId: triviaNightId || quizId || questionId, questionId },

      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;

        const newAnswer = subscriptionData.data.answerAdded;

        if (newAnswer) {
          const newArray =
            prev.getAnswerBubblesByQuestion &&
            prev.getAnswerBubblesByQuestion.length > 0
              ? prev.getAnswerBubblesByQuestion.filter(
                  (obj) => obj.id !== newAnswer.id
                )
              : [];

          return {
            ...prev,

            getAnswerBubblesByQuestion: [...newArray, newAnswer],
          };
        }
      },
    });

    return () => {
      if (subscription && typeof subscription.unsubscribe === "function") {
        subscription.unsubscribe();
      }
    };
  }, [
    questionId,
    quizId,
    triviaNightId,
    targetGuestCustomer,
    targetCustomer,
    subscribeToMore,
  ]);

  const size = useWindowSize();
  const [pageLoadedAt] = useState(new Date());
  const [shouldShowBubble, setShouldShowBubble] = useState(false);

  useEffect(() => {
    if (!shouldShowBubble && indexClicked !== null) {
      setShouldShowBubble(true);
    }
  }, [shouldShowBubble, indexClicked]);

  return (
    <div className="w-[84%] sm:w-[88%] inset-y-0 p-0 h-full absolute overflow-hidden ">
      <div className="mt-0.5 w-full h-full relative overflow-hidden">
        {answerBubbles &&
          answerBubbles.length > 0 &&
          answerBubbles
            .filter((answerBub, index) =>
              shouldShowBubble &&
              ((targetCustomer && targetCustomer.id === answerBub.customerId) ||
                (targetGuestCustomer &&
                  targetGuestCustomer.id === answerBub.guestCustomerId))
                ? false
                : true
            )
            .map((answer, index) => (
              <AnswerBubble
                answer={answer}
                key={answer.id}
                shouldShowBubbleInit={shouldShowBubble}
                offset={size.width >= 768 ? 89 : 85.5}
                lastQStartedAt={lastQStartedAt}
                pageLoadedAt={pageLoadedAt}
              />
            ))}

        {shouldShowBubble && (targetCustomer || targetGuestCustomer) && (
          <AnswerBubble
            answer={{
              timeTaken: 0,
              id: 22,
              username: (targetCustomer || targetGuestCustomer).username,
              subscriptionType: targetCustomer
                ? targetCustomer.subscriptionType
                : null,
              profilePicUrl: (targetCustomer || targetGuestCustomer)
                .profilePicUrl,
            }}
            shouldShowBubbleInit={shouldShowBubble}
            offset={size.width >= 768 ? 89 : 85.5}
            lastQStartedAt={lastQStartedAt}
            pageLoadedAt={pageLoadedAt}
          />
        )}
        {deltaXP !== null &&
          deltaXP !== 0 &&
          (((personalQuiz || quiz) && (personalQuiz || quiz).status === 3) ||
            (questionView && questionView.status === 1)) && (
            <XPBubble
              deltaXP={deltaXP}
              offset={size.width >= 768 ? 22 : 18}
              // offset={size.width >= 768 ? 89 : 85.5}
              lastQStartedAt={lastQStartedAt}
              pageLoadedAt={pageLoadedAt}
              curAnsEndsAt={curAnsEndsAt}
            />
          )}
      </div>
      <div
        className={`w-full h-6 short:h-3 bg-gradient-to-b absolute top-0 ${
          targetMediaUrl ? "" : "from-darker-gray"
        } to-transparent`}
      ></div>
      <div
        className={`w-full absolute bottom-0 h-6 bg-gradient-to-t ${
          targetMediaUrl ? "" : "from-darker-gray"
        } to-transparent`}
      ></div>
    </div>
  );
}

export default AnswerBubbleCanvas;
