import { useMutation } from "@apollo/client";
import React, { useContext, useState } from "react";
import { adminClient, customerClient } from "../../GraphqlApolloClients";
import { AdminAuthContext } from "../../context/adminAuth";
import { useForm } from "../../util/hooks";

import {
  FETCH_CUSTOMER,
  FETCH_CUSTOMER_ORGS,
  FETCH_ORG_BY_NAME_FOR_URL,
  FETCH_RECOMMENDED_FRIENDS,
  FETCH_RECOMMENDED_ORGS,
  SEND_ORG_EMAIL_VERIFICATION_CODE_MUTATION,
  VERIFY_ORG_EMAIL_MUTATION,
} from "../../util/gqlFunctions";
import SubmitButton from "./SubmitButton";
import TextInputField from "./TextInputField";

function JoinOrgForm({
  setShouldOpenJoinOrgForm,
  triviaNightId,
  targetTopic,
  org,
  toggleJoinedOrgSuccessfullyForm,
  setIsJoinedCustomer,
}) {
  const [errors, setErrors] = useState({});
  const { admin } = useContext(AdminAuthContext);

  const { values, setValues, onChange } = useForm(
    sendOrgEmailVerificationCodeCallback,
    {
      topicNames: targetTopic ? [targetTopic.name] : [],
      videoFile: null,
      typeOfVideo: 0,
      orgId: org.id,
      title: "",
      email: "",
      verificationCode: "",
      searchTerm: "",
      youtubeClipUrl: "",
      tiktokClipUrl: "",
      instagramClipUrl: "",
      seenIds: [],
      pageSize: 20,
    }
  );
  const [verificationEmailIsSent, setVerificationEmailIsSent] = useState(false);

  const [
    sendOrgEmailVerificationCode,
    { loading: loadingSendOrgEmailVerificationCode },
  ] = useMutation(SEND_ORG_EMAIL_VERIFICATION_CODE_MUTATION, {
    update(_, { data: { sendOrgEmailVerificationCode: editedCust } }) {
      setErrors({});
      setValues({ ...values, verificationCode: "" });

      setVerificationEmailIsSent(true);
    },
    onError(err) {
      console.log(err);
      console.log(values);

      if (err.graphQLErrors[0]) {
        console.log(err.graphQLErrors[0].extensions.errors);

        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
      }
    },
    variables: values,
    client: admin ? adminClient : customerClient,
  });

  const [verifyOrgEmail, { loading: loadingVerifyOrgEmail }] = useMutation(
    VERIFY_ORG_EMAIL_MUTATION,
    {
      refetchQueries: [
        { query: FETCH_CUSTOMER },
        {
          query: FETCH_ORG_BY_NAME_FOR_URL,
          variables: { nameForUrl: org.nameForUrl },
        },
        { query: FETCH_RECOMMENDED_ORGS, variables: values },
        { query: FETCH_RECOMMENDED_FRIENDS, variables: values },
        { query: FETCH_CUSTOMER_ORGS },
      ],
      update(_, { data: { verifyOrgEmail: verifyiedOrg } }) {
        setIsJoinedCustomer(true);
        toggleJoinedOrgSuccessfullyForm();

        setErrors({});
      },
      onError(err) {
        console.log(err);

        if (err.graphQLErrors[0]) {
          console.log(err.graphQLErrors[0].extensions.errors);

          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: values,
      client: admin ? adminClient : customerClient,
    }
  );

  function sendOrgEmailVerificationCodeCallback(e) {
    e.preventDefault();
    e.stopPropagation();
    setErrors({});
    sendOrgEmailVerificationCode();
  }

  return (
    <div className="fixed pt-16 sm:py-10 z-50 overscroll-contain w-screen h-screen max-h-screen max-w-screen overflow-hidden flex items-end justify-end sm:items-center sm:justify-center">
      <button
        onClick={(e) => {
          e.preventDefault();
          setErrors({});
          setShouldOpenJoinOrgForm(false);
        }}
        className="z-40 hide-button-flash bg-opacity-10 motion-safe:animate-fadeInFast flex items-center justify-center inset-0 absolute backdrop-blur-sm"
        disabled={false}
      />
      <form
        className={`overflow-x-hidden max-h-full flex-shrink motion-safe:animate-fadeUpFast overscroll-contain sm:m-auto w-full max-w-xl overflow-y-auto flex flex-col items-start justify-start z-40 rounded-xl px-6 pb-6 sm:p-8 bg-darker-gray shadow-xl rounded-b-none sm:rounded-3xl short:px-4 short:pb-4 `}
      >
        <button
          onClick={(e) => {
            e.preventDefault();
            setErrors({});
            setShouldOpenJoinOrgForm(false);
          }}
          className="w-full pt-4 short:pt-3 short:pb-1 pb-2 sm:hidden"
        >
          <div className="rounded-full bg-gray-700 flex-shrink-0 h-1 mx-auto w-10 mb-2"></div>
        </button>

        <p className="text-white text-2xl font-bold w-full short:text-xl short:leading-tight">
          Join {org.shortForm}
        </p>
        <p className="text-gray-400 w-full leading-tight ">
          Verify your {org.name} email to join.
          {verificationEmailIsSent
            ? " NOTE: it may take up to 5 minutes for you to receive the email."
            : ""}
        </p>

        <div className="w-full flex flex-col items-start justify-start space-y-2 sm:space-y-4 mt-6 mb-4">
          <TextInputField
            title={"Email"}
            name="email"
            type="email"
            placeholder={
              org.emailSuffixes && org.emailSuffixes.length > 0
                ? `abc@${
                    org.emailSuffixes.length === 1
                      ? org.emailSuffixes[0]
                      : `[${org.emailSuffixes.map(
                          (emailSuffix, index) =>
                            (index === 0 ? "" : " ") + emailSuffix
                        )}]`
                  }`
                : ""
            }
            error={errors.email}
            disabled={loadingSendOrgEmailVerificationCode}
            onChange={onChange}
            value={values.email}
            suffix={
              verificationEmailIsSent || values.verificationCode
                ? "Resend"
                : "Get code"
            }
            onClickSuffix={sendOrgEmailVerificationCodeCallback}
            successMsg={
              verificationEmailIsSent &&
              !errors.email &&
              !errors.verificationCode &&
              "- Code sent!"
            }
          />

          <TextInputField
            title="Verification code"
            name="verificationCode"
            error={errors.verificationCode}
            isError={errors && Object.keys(errors).length > 0 ? true : false}
            disabled={
              loadingSendOrgEmailVerificationCode || !verificationEmailIsSent
            }
            onChange={onChange}
            value={values.verificationCode}
            placeholder="______"
          />
        </div>

        <SubmitButton
          onClickFunc={(e) => {
            e.preventDefault();
            verifyOrgEmail();
          }}
          title={"Verify"}
          errors={verificationEmailIsSent ? errors : {}}
          isNotLoading={!loadingVerifyOrgEmail && !verificationEmailIsSent}
          disabled={loadingVerifyOrgEmail || !verificationEmailIsSent}
        />
      </form>
    </div>
  );
}

export default JoinOrgForm;
