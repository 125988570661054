import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";

import { customerClient, guestClient } from "../../GraphqlApolloClients";
import {
  CREATE_COMMENT_MUTATION,
  FETCH_COMMENTS_BY_EVENT_QUERY,
  FETCH_SUGGESTED_MSGS_BY_EVENT_QUERY,
} from "../../util/gqlFunctions";
import { useForm } from "../../util/hooks";
import HorAdSmallBox from "./HorAdSmallBox";
import JustComments from "./JustComments";
import ProductAdBox from "./ProductAdBox";
import QuickPowerupSelection from "./QuickPowerupSelection";

function CommentSection({
  triviaNightId,
  quizId,
  areCommentsInFocus,
  productId,

  narrowHorAd,
  personalQuiz,
  isOutOfLives,
  targetAdBookmark,
  targetCustomer,
  targetGuestCustomer,
  size,
  productAds,
  toggleQuizForm,
  livesCount,
  triviaNight,
  quiz,
  regularHorAd,
  togglePurchasePowerUpForm,
  toggleBuySubscriptionForm,
  navigate,
  country,

  toggleShowQRCode,
  shouldOpenQRCodeBox,
}) {
  const [errors, setErrors] = useState({});

  const {
    data: { getCommentsByEvent: comments } = {},

    subscribeToMore,
  } = useQuery(FETCH_COMMENTS_BY_EVENT_QUERY, {
    variables: {
      triviaNightId,
      quizId,
      productId,
      seenIds: [],
      pageSize: 13,
    },
  });

  const { values, setValues } = useForm(createCommentCallback, {
    triviaNightId,
    quizId,
    productId,
    message: "",
    commentRepliedToId: null,
    repliedToUsername: "",
  });

  const inputRef = useRef();

  useEffect(() => {
    if (inputRef.current && areCommentsInFocus && size && size.width >= 640) {
      inputRef.current.focus();
    }
  });
  const [sentMessages, setSentMessages] = useState([]);
  const resetInterval = 1000;
  const [isMsgError, setIsMsgError] = useState(false);
  useEffect(() => {
    let timeout;
    if (isMsgError && resetInterval) {
      timeout = setTimeout(() => setIsMsgError(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isMsgError, resetInterval]);

  const [createComment, { loading: loadingCreateComment }] = useMutation(
    CREATE_COMMENT_MUTATION,
    {
      update(_, { data: { createComment: comment } }) {
        if (comment.attachedQuizId && !areCommentsInFocus) {
          toggleQuizForm();
        }
        console.log(errors);
        setValues({
          ...values,
          message: "",
          commentRepliedToId: null,
          repliedToUsername: null,
        });

        setErrors({});
      },
      onError(err) {
        console.log(err);
        if (!values.message || values.message.trim().length === 0) {
          setValues({ ...values, message: "" });
        }
        setIsMsgError(true);
        if (err.graphQLErrors[0]) {
          console.log(err.graphQLErrors[0].extensions.errors);
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: values,
      client: targetCustomer ? customerClient : guestClient,
    }
  );

  function createCommentCallback(e) {
    e.preventDefault();

    createComment();
  }

  const {
    data: { getSuggestedMessagesByEvent: suggestedMessages } = {},
    refetch: refetchSuggestedMessages,
  } = useQuery(FETCH_SUGGESTED_MSGS_BY_EVENT_QUERY, {
    variables: {
      triviaNightId: triviaNight ? triviaNight.id : null,
      quizId: quiz ? quiz.id : null,
      productId,
      questionViewId: null,
      seenIds: [],
      pageSize: 10,
    },
    client: targetCustomer ? customerClient : guestClient,
  });

  const [lastStatus, setLastStatus] = useState(-1);

  useEffect(() => {
    if (values.quizId !== quizId || values.triviaNightId !== triviaNightId) {
      setValues({ ...values, quizId, triviaNightId });
    }
  }, [quizId, setValues, values, triviaNightId]);

  useEffect(() => {
    if (
      (personalQuiz || quiz || triviaNight) &&
      (personalQuiz || quiz || triviaNight).status !== lastStatus
    ) {
      refetchSuggestedMessages({
        triviaNightId: triviaNight ? triviaNight.id : null,
        quizId: quiz ? quiz.id : null,
        productId,
        seenIds: [],
        pageSize: 10,
      });

      setSentMessages([]);
      setLastStatus((personalQuiz || quiz || triviaNight).status);
    }
  }, [
    quiz,
    triviaNight,
    lastStatus,
    personalQuiz,
    productId,
    refetchSuggestedMessages,
  ]);

  return (
    <div className="flex flex-col relative h-full items-center justify-end w-full">
      {/* {livesCount !== null &&
      (triviaNight || quiz) &&
      ((triviaNight || quiz).status === 0 ||
        ((triviaNight || quiz).status === 1 &&
          !(triviaNight || quiz).lastCompletedQ) ||
        livesCount === 0 ||
        (triviaNight || quiz).status === 5) ? ( */}
      <QuickPowerupSelection
        targetCustomer={targetCustomer}
        togglePurchasePowerUpForm={togglePurchasePowerUpForm}
      />

      {productAds && productAds.length > 0 ? (
        <div className="flex z-10 mb-4 w-full shrink-0 items-start justify-start space-x-4 overflow-x-auto">
          {productAds.map((productAd, index) => (
            <ProductAdBox
              key={productAd.id}
              product={productAd}
              size={size}
              customer={targetCustomer}
              triviaNight={triviaNight}
            />
          ))}
        </div>
      ) : (
        <></>
      )}

      {/* {targetCustomer && ( */}
      <JustComments
        comments={comments}
        country={country}
        navigate={navigate}
        setValues={setValues}
        values={values}
        quizId={quizId}
        targetCustomer={targetCustomer}
        targetGuestCustomer={targetGuestCustomer}
        toggleShowQRCode={toggleShowQRCode}
        shouldOpenQRCodeBox={shouldOpenQRCodeBox}
        quiz={quiz}
        triviaNightId={triviaNightId}
        subscribeToNewComments={subscribeToMore}
      />

      {(targetCustomer || targetGuestCustomer) && (
        <div
          className={` ${
            areCommentsInFocus || (quiz || triviaNight).status !== 2
              ? "h-9 short:h-8"
              : "h-0 sm:h-9 short:h-8"
          } relative shrink-0 overflow-hidden transition-all flex w-full mb-1 sm:mb-2 items-center space-x-2`}
        >
          <div className="w-full z-10 flex gap-2 snap always-stop snap-y snap-mandatory overflow-y-scroll scrollbar-hide flex-wrap grow items-center justify-start overflow-x-auto h-full short:text-sm ">
            {suggestedMessages &&
              suggestedMessages.length > 0 &&
              suggestedMessages
                .filter((msg) => !sentMessages.includes(msg))
                .map((msg, index) => (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      if (msg !== "Battle again!") {
                        setSentMessages([...sentMessages, msg]);
                      }

                      if (areCommentsInFocus && msg !== "Battle again!") {
                        setValues({
                          ...values,
                          message: (values.message || "") + msg,
                        });
                      } else {
                        createComment({
                          variables: {
                            ...values,
                            message: msg,
                          },
                        });
                      }
                    }}
                    key={msg}
                    className={`${
                      quiz?.status >= 5 &&
                      index === 0 &&
                      msg === "Battle again!"
                        ? "bg-red-950 hover:bg-red-900"
                        : "bg-gray-800 hover:bg-gray-700"
                    } leading-tight text-white h-full transition-all rounded-lg px-2 py-1`}
                  >
                    {msg}
                  </button>
                ))}
          </div>
          <button
            onClick={(e) => {
              e.preventDefault();
              toggleQuizForm();
            }}
            className="h-full shrink-0 z-50 relative"
            disabled={size.width >= 768}
          >
            <svg
              className="h-full shrink-0 z-10 fill-current text-gray-800"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M18 0H2C0.9 0 0 0.9 0 2V20L4 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0Z" />
            </svg>

            <div className="absolute sm:hidden inset-0 bottom-1 flex items-center justify-center">
              <svg
                className={` w-3.5 aspect-square ${
                  areCommentsInFocus ? "" : "rotate-180"
                } transition-all fill-current text-gray-200 z-10`}
                viewBox="0 0 8 4"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1.2 0H4H6.8H7.5L4 4L0.5 0H1.2Z" />
              </svg>
            </div>
          </button>
        </div>
      )}

      {!targetCustomer || !targetCustomer.isPaidSubscriber ? (
        !targetCustomer && size.width >= 768 ? (
          <div className="flex z-10 w-full shrink-0 mb-1 sm:mb-2 aspect-[58/7] h-auto items-center justify-center space-x-2">
            <a
              href="https://apps.apple.com/us/app/tknight/id6476206206"
              target="_blank"
              rel="noreferrer"
              className="flex w-full h-full pl-5 pr-6 border-gray-700 border bg-black hover:bg-gray-800 transition-all text-white rounded-lg items-center justify-center"
            >
              <div className="mr-3">
                <svg
                  viewBox="0 0 384 512"
                  className="aspect-square w-10 2xl:w-12"
                >
                  <path
                    fill="currentColor"
                    d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
                  />
                </svg>
              </div>
              <div>
                <div className="text-xs 2xl:text-base truncate">
                  Download on the
                </div>
                <div className="text-xl 2xl:text-2xl truncate font-semibold font-sans -mt-1">
                  App Store
                </div>
              </div>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=live.tknight"
              target="_blank"
              rel="noreferrer"
              className="flex w-full h-full border-gray-700 border bg-black hover:bg-gray-800 transition-all text-white rounded-lg items-center justify-center"
            >
              <div className="mr-3">
                <svg
                  viewBox="30 336.7 120.9 129.2"
                  className="aspect-square w-8 2xl:w-12"
                >
                  <path
                    fill="#FFD400"
                    d="M119.2,421.2c15.3-8.4,27-14.8,28-15.3c3.2-1.7,6.5-6.2,0-9.7  c-2.1-1.1-13.4-7.3-28-15.3l-20.1,20.2L119.2,421.2z"
                  />
                  <path
                    fill="#FF3333"
                    d="M99.1,401.1l-64.2,64.7c1.5,0.2,3.2-0.2,5.2-1.3  c4.2-2.3,48.8-26.7,79.1-43.3L99.1,401.1L99.1,401.1z"
                  />
                  <path
                    fill="#48FF48"
                    d="M99.1,401.1l20.1-20.2c0,0-74.6-40.7-79.1-43.1  c-1.7-1-3.6-1.3-5.3-1L99.1,401.1z"
                  />
                  <path
                    fill="#3BCCFF"
                    d="M99.1,401.1l-64.3-64.3c-2.6,0.6-4.8,2.9-4.8,7.6  c0,7.5,0,107.5,0,113.8c0,4.3,1.7,7.4,4.9,7.7L99.1,401.1z"
                  />
                </svg>
              </div>
              <div>
                <div className="text-xs 2xl:text-base truncate">GET IT ON</div>
                <div className="text-xl 2xl:text-2xl truncate font-semibold font-sans -mt-1">
                  Google Play
                </div>
              </div>
            </a>
          </div>
        ) : (
          <HorAdSmallBox
            triviaNight={triviaNight}
            quiz={quiz}
            personalQuiz={personalQuiz}
            horAdLink={
              areCommentsInFocus && size.width < 1024
                ? regularHorAd && regularHorAd.link
                  ? regularHorAd.link
                  : ""
                : narrowHorAd && narrowHorAd.link
                ? narrowHorAd.link
                : ""
            }
            isOutOfLives={isOutOfLives}
            targetCustomer={targetCustomer}
            targetGuestCustomer={targetGuestCustomer}
            targetAdBookmark={targetAdBookmark}
            areCommentsInFocus={areCommentsInFocus && size.width < 1024}
            horAdUrl={
              areCommentsInFocus && size.width < 1024
                ? regularHorAd && regularHorAd.regularHorAdUrl
                  ? regularHorAd.regularHorAdUrl
                  : ""
                : narrowHorAd && narrowHorAd.narrowHorAdUrl
                ? narrowHorAd.narrowHorAdUrl
                : ""
            }
            adId={
              areCommentsInFocus && size.width < 1024
                ? regularHorAd && regularHorAd.regularHorAdUrl
                  ? regularHorAd.id
                  : ""
                : narrowHorAd && narrowHorAd.narrowHorAdUrl
                ? narrowHorAd.id
                : ""
            }
          />
        )
      ) : (
        <></>
      )}

      <div
        className={`${
          isMsgError
            ? "border-red-600 motion-safe:animate-shake"
            : "border-gray-700 "
        } ${
          areCommentsInFocus
            ? "h-auto"
            : "h-0 lg:h-36 overflow-hidden sm:border-opacity-100"
        } rounded-xl text-white duration-700 transition-height pl-4 short:pl-3 flex items-start justify-start border w-full z-10`}
      >
        {/* {isMsgError && errors.message && (
          <p className="absolute text-red-600 bottom-2 font-semibold">
            {errors.message}
          </p>
        )} */}
        <textarea
          ref={inputRef}
          name={"message"}
          id={"message"}
          rows="1"
          value={
            values.repliedToUsername
              ? "@" + values.repliedToUsername + ": " + values.message
              : values.message
          }
          onChange={(e) => {
            e.preventDefault();

            var newMsg = e.target.value;
            if (values.commentRepliedToId) {
              if (!newMsg.includes("@" + values.repliedToUsername + ": ")) {
                setValues({
                  ...values,
                  commentRepliedToId: null,
                  repliedToUsername: null,
                });
              } else {
                newMsg = newMsg.replace(
                  "@" + values.repliedToUsername + ": ",
                  ""
                );
                setValues({
                  ...values,
                  message: newMsg,
                });
              }
            } else {
              setValues({
                ...values,
                message: newMsg,
              });
            }
          }}
          className={`${
            isMsgError ? "" : ""
          } text-lg 2xl:text-2xl py-4 leading-tight w-full flex-1 bg-transparent placeholder:text-gray-200 focus:outline-none`}
          placeholder={"Add a comment..."}
          disabled={loadingCreateComment}
          onKeyDown={(e) => {
            if (e.key === "Enter" && e.shiftKey === false) {
              e.preventDefault();
              createComment();
            }
          }}
          maxLength={200}
        ></textarea>
        <button
          onClick={createCommentCallback}
          className={`${
            isMsgError ? "text-red-600" : "text-white"
          } p-4  hover:opacity-50 transition-all`}
          disabled={loadingCreateComment}
        >
          <svg
            className="w-6 2xl:w-7 short:w-5 fill-current"
            viewBox="0 0 26 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M26 0L19.5 24L10.6936 16.1029L19.1458 7.12036L7.81625 15.0044L0 13.0909L26 0Z" />
          </svg>
        </button>
      </div>
    </div>
  );
}

export default CommentSection;
