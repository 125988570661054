import { useMutation } from "@apollo/client";

import React, { useState } from "react";
import { adminClient } from "../../GraphqlApolloClients";

import { decryptQCipher, defaultPicUrl, useForm } from "../../util/hooks";

import {
  EDIT_QUESTION_MUTATION,
  FETCH_QUESTIONS_BY_TN_FOR_ADMIN,
  FETCH_TRIVIA_NIGHTS,
} from "../../util/gqlFunctions";
import TextInputField from "./TextInputField";

function EditQuestion({ setEditQIndex, question, navigate }) {
  //   const decodedQ = jwt.verify(token, process.env.REACT_APP_SECRET_QUESTION_KEY);

  const [errors, setErrors] = useState({});

  const { onChange, values, setValues } = useForm(editQuestionCallback, {
    questionId: question.id,
    fieldsToEdit: [],
    questionDesc: decryptQCipher(question.questionDesc),
    option0: question.option0,
    option1: question.option1,
    option2: question.option2,
    option3: question.option3,
    correctAnswer: question.correctAnswer,
    topicId: question.topicId,
    questionType: question.questionType,
  });

  const [editQuestion, { loading: loadingEditQ }] = useMutation(
    EDIT_QUESTION_MUTATION,
    {
      refetchQueries: [
        { query: FETCH_TRIVIA_NIGHTS },

        {
          query: FETCH_QUESTIONS_BY_TN_FOR_ADMIN,
          variables: { triviaNightId: question.triviaNightId },
        },
      ],
      update(_, { data: { editQuestion: editQ } }) {
        setValues({
          ...values,
          questionDesc: decryptQCipher(editQ.questionDesc),
          option0: editQ.option0,
          option1: editQ.option1,
          option2: editQ.option2,
          option3: editQ.option3,
          topic: editQ.topic,
          fieldsToEdit: [],
          questionType: editQ.questionType,
        });
        // setEditQIndex(null);
        setErrors({});
      },
      onError(err) {
        console.log(err);

        if (err.graphQLErrors[0]) {
          console.log(err.graphQLErrors[0].extensions.errors);

          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: values,
      client: adminClient,
    }
  );

  function editQuestionCallback(e) {
    e.preventDefault();
    editQuestion();
  }

  function setFieldsToEdit(name) {
    if (values.fieldsToEdit && values.fieldsToEdit.includes(name) && question) {
      if (name.includes("Amount")) {
        setValues({
          ...values,
          fieldsToEdit: [],
          [name]: question[name] ? Math.floor(question[name] / 100) : 10,
        });
      } else if (name === "questionDesc") {
        setValues({
          ...values,
          fieldsToEdit: [],
          [name]: decryptQCipher(question[name]) || "",
        });
      } else {
        setValues({
          ...values,
          fieldsToEdit: [],
          [name]: question[name] || "",
        });
      }
    } else {
      setValues({
        ...values,
        fieldsToEdit: [name],
      });
    }
  }

  return (
    <div className="fixed pt-16 sm:py-10 z-50 overscroll-contain w-screen h-screen max-h-screen max-w-screen overflow-hidden flex items-end justify-end sm:items-center sm:justify-center">
      <button
        onClick={(e) => {
          e.preventDefault();
          setErrors({});
          setEditQIndex(null);
        }}
        className="z-40 hide-button-flash bg-opacity-10 motion-safe:animate-fadeInFast flex items-center justify-center inset-0 absolute backdrop-blur-sm"
        disabled={false}
      />
      <div
        // onSubmit={buyBandCallback}
        className={`overflow-x-hidden max-h-full flex-shrink motion-safe:animate-fadeUpFast overscroll-contain sm:m-auto w-full max-w-xl overflow-y-auto flex flex-col items-start justify-start z-40 rounded-xl px-6 pb-6 sm:p-8 bg-darker-gray shadow-xl rounded-b-none sm:rounded-3xl short:px-4 short:pb-4 `}
      >
        <button
          onClick={(e) => {
            e.preventDefault();
            setErrors({});
            setEditQIndex(null);
          }}
          className="w-full pt-4 short:pt-3 short:pb-1 pb-2 sm:hidden"
        >
          <div className="rounded-full bg-gray-700 flex-shrink-0 h-1 mx-auto w-10 mb-2"></div>
        </button>

        <div className="flex items-center justify-start border-b border-gray-200 pb-4 short:pb-2 space-x-4 w-full short:space-x-2">
          <button
            onClick={(e) => {
              navigate("/topic/" + question.topicShortForm);
            }}
            className="rounded-xl hover:opacity-80 transition-all bg-blue-200 h-12 aspect-square"
          >
            <img
              src={question.topicLogoUrl || defaultPicUrl}
              className="h-12 aspect-square rounded-md mr-2 short:mr-1 object-cover"
              alt={question.topicName}
            />
          </button>
          <div className="">
            <p className="short:text-base text-lg text-left text-gray-200 leading-tight">
              Edit Question {question.index !== null ? question.index + 1 : ""}
            </p>
            <div className="flex leading-tight items-center justify-start text-gray-700">
              <p className=" font-semibold ">{question.topicName}</p>
            </div>
          </div>
        </div>

        {/* <div className="mt-4 w-full flex items-start justify-start flex-col">
            <p className="font-medium">Full name</p>

          </div> */}
        <div className="w-full flex flex-col items-start justify-start space-y-6 mt-4 mb-4">
          <div className="w-full">
            <div className="flex items-center justify-between text-sm sm:text-base">
              <label
                htmlFor="questionType"
                className="font-semibold text-gray-400 "
              >
                Type{" "}
                {errors.questionType &&
                  (!values.fieldsToEdit ||
                    (values.fieldsToEdit &&
                      values.fieldsToEdit.includes("questionType"))) && (
                    <span className="text-red-500">{errors.questionType}</span>
                  )}
              </label>
              {values.fieldsToEdit.length === 0 && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setValues({
                      ...values,
                      fieldsToEdit: ["questionType"],
                    });
                  }}
                  className="text-blue-300 transition-all font-semibold hover:text-blue-400"
                >
                  Edit
                </button>
              )}
              {values.fieldsToEdit.includes("questionType") && (
                <div className="flex items-center justify-end space-x-2">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setFieldsToEdit("questionType");
                    }}
                    className="text-red-500 transition-all font-semibold hover:text-red-700"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      editQuestion();
                    }}
                    className="text-green-500 transition-all font-semibold hover:text-green-700"
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
            <select
              id="questionType"
              name="questionType"
              // autoComplete=""
              value={values.questionType}
              onChange={(e) => {
                onChange(e, true);
              }}
              disabled={
                loadingEditQ ||
                (values.fieldsToEdit &&
                  !values.fieldsToEdit.includes("questionType"))
              }
              className="sm:text-lg bg-transparent text-white mt-1 w-full shadow-sm flex-1 px-3 py-2 rounded-md border-gray-700 border focus:border-indigo-500 focus:ring-indigo-500"
            >
              <option value={0}>Regular</option>
              <option value={1}>Majority-everyone</option>
              <option value={2}>Majority-friends </option>
              {/* <option value={3}>Majority-based from grade</option> */}
            </select>
          </div>

          <TextInputField
            title="Question"
            name="questionDesc"
            value={values.questionDesc}
            onChange={onChange}
            type="multiline"
            error={errors.questionDesc}
            disabled={loadingEditQ}
            setFieldsToEdit={setFieldsToEdit}
            fieldsToEdit={values.fieldsToEdit}
            editFunc={editQuestion}
          />

          <TextInputField
            title="Option 0"
            name="option0"
            value={values.option0}
            onChange={onChange}
            error={errors.option0}
            disabled={loadingEditQ}
            setFieldsToEdit={setFieldsToEdit}
            fieldsToEdit={values.fieldsToEdit}
            editFunc={editQuestion}
            isCorrectAnswer={
              values.questionType === 0 && values.correctAnswer === 0
                ? true
                : null
            }
          />
          <TextInputField
            title="Option 1"
            name="option1"
            value={values.option1}
            onChange={onChange}
            error={errors.option1}
            disabled={loadingEditQ}
            setFieldsToEdit={setFieldsToEdit}
            fieldsToEdit={values.fieldsToEdit}
            editFunc={editQuestion}
            isCorrectAnswer={
              values.questionType === 0 && values.correctAnswer === 1
                ? true
                : null
            }
          />

          <TextInputField
            title="Option 2"
            name="option2"
            value={values.option2}
            onChange={onChange}
            error={errors.option2}
            disabled={loadingEditQ}
            setFieldsToEdit={setFieldsToEdit}
            fieldsToEdit={values.fieldsToEdit}
            editFunc={editQuestion}
            isCorrectAnswer={
              values.questionType === 0 && values.correctAnswer === 2
                ? true
                : null
            }
          />
          <TextInputField
            title="Option 3"
            name="option3"
            value={values.option3}
            onChange={onChange}
            error={errors.option3}
            disabled={loadingEditQ}
            setFieldsToEdit={setFieldsToEdit}
            fieldsToEdit={values.fieldsToEdit}
            editFunc={editQuestion}
            isCorrectAnswer={
              values.questionType === 0 && values.correctAnswer === 3
                ? true
                : null
            }
          />
        </div>
      </div>
    </div>
  );
}

export default EditQuestion;
