import { useMutation, useQuery } from "@apollo/client";

import React, { useContext, useState } from "react";
import { adminClient } from "../../GraphqlApolloClients";

import { useForm } from "../../util/hooks";
import ProfilePicInputField from "./ProfilePicInputField";

import { AdminAuthContext } from "../../context/adminAuth";

import {
  ARCHIVE_FORUM_MUTATION,
  EDIT_FORUM_MUTATION,
  FETCH_ORG_BY_ID,
  FETCH_RECOMMENDED_FORUMS,
  FETCH_RECOMMENDED_ORGS,
  FETCH_TOPIC,
} from "../../util/gqlFunctions";
import TagsInput from "./TagsInput";
import TextInputField from "./TextInputField";

function EditForumForm({ targetForum, setTargetForum }) {
  const { admin } = useContext(AdminAuthContext);
  const [errors, setErrors] = useState({});
  const [wantsToArchive, setWantsToArchive] = useState(false);

  const { onChange, values, setValues, onChangeMedia } = useForm(
    editOrgCallback,
    {
      forumId: targetForum.id,
      fieldsToEdit: [],
      ...targetForum,
    }
  );

  const [editForum, { loading: loadingEditForum }] = useMutation(
    EDIT_FORUM_MUTATION,
    {
      refetchQueries: [
        { query: FETCH_RECOMMENDED_ORGS },

        {
          query: FETCH_RECOMMENDED_FORUMS,
          variables: { pageSize: 10, seenIds: [] },
        },
      ],
      update(_, { data: { editForum: editedForm } }) {
        setValues({
          ...values,
          ...editedForm,
          fieldsToEdit: [],
        });

        // setTargetForum(null);
        setErrors({});
      },
      onError(err) {
        console.log(err);
        if (err.graphQLErrors[0]) {
          console.log(err.graphQLErrors[0].extensions.errors);

          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: values,
      client: adminClient,
    }
  );

  function setFieldsToEdit(name) {
    if (
      values.fieldsToEdit &&
      values.fieldsToEdit.includes(name) &&
      targetForum
    ) {
      if (
        name.includes("Amount") ||
        name.includes("Price") ||
        name === "discount"
      ) {
        setValues({
          ...values,
          fieldsToEdit: [],
          [name]: targetForum[name] ? Math.floor(targetForum[name] / 100) : 10,
        });
      } else {
        setValues({
          ...values,
          fieldsToEdit: [],
          [name]: targetForum[name],
        });
      }
    } else {
      setValues({
        ...values,
        fieldsToEdit: [name],
      });
    }
  }

  function editOrgCallback(e) {
    e.preventDefault();
    editForum();
  }

  const { data: { getOrgById: org } = {} } = useQuery(FETCH_ORG_BY_ID, {
    client: adminClient,
    variables: { orgId: targetForum.orgId },
  });

  const { data: { getTopicById: topic } = {} } = useQuery(FETCH_TOPIC, {
    client: adminClient,
    variables: { topicId: targetForum.topicId || "" },
  });

  const [archiveForum] = useMutation(ARCHIVE_FORUM_MUTATION, {
    refetchQueries: [
      { query: FETCH_RECOMMENDED_ORGS },
      {
        query: FETCH_RECOMMENDED_FORUMS,
        variables: { pageSize: 10, seenIds: [] },
      },
    ],
    update(_, { data: { archiveForum: archivedForum } }) {
      console.log(errors);
      setTargetForum(null);
    },
    onError(err) {
      console.log(err);

      if (err.graphQLErrors[0]) {
        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
      }
    },
    variables: values,
    client: adminClient,
  });

  return (
    <div className="fixed pt-16 sm:py-10 z-50 overscroll-contain w-screen h-screen max-h-screen max-w-screen overflow-hidden flex items-end justify-end sm:items-center sm:justify-center">
      <button
        onClick={(e) => {
          e.preventDefault();
          setErrors({});
          setTargetForum(null);
        }}
        className="z-40 bg-gray-500 hide-button-flash overscroll-contain bg-opacity-10 motion-safe:animate-fadeInFast flex items-center justify-center inset-0 absolute backdrop-blur-sm"
        disabled={false}
      />
      <form
        // onSubmit={buyBandCallback}
        className={`overflow-x-hidden max-h-full flex-shrink motion-safe:animate-fadeUpFast overscroll-contain sm:m-auto w-full max-w-xl overflow-y-auto flex flex-col items-start justify-start z-40 rounded-xl px-6 pb-6 sm:p-8 bg-darker-gray shadow-xl rounded-b-none sm:rounded-3xl short:px-4 short:pb-4 `}
      >
        <button
          onClick={(e) => {
            e.preventDefault();
            setErrors({});
            setTargetForum(null);
          }}
          className="w-full pt-4 short:pt-3 short:pb-1 pb-2 sm:hidden"
        >
          <div className="rounded-full bg-gray-700 flex-shrink-0 h-1 mx-auto w-10 mb-2"></div>
        </button>

        <p className="text-white text-2xl font-bold w-full short:text-xl short:leading-tight">
          {targetForum.title}
        </p>
        <p className="text-gray-400 w-full">Edit forum info</p>

        {/* <div className="mt-4 w-full flex items-start justify-start flex-col">
            <p className="font-medium">Full name</p>

          </div> */}

        <div className="w-full flex flex-col items-start justify-start space-y-6 mt-6">
          <div className="w-full">
            <div className="flex items-center justify-between text-sm sm:text-base">
              <label htmlFor="orgId" className="font-semibold text-gray-400 ">
                School/workplace{" "}
                {errors.orgId && (
                  <span className="text-red-500">{errors.orgId}</span>
                )}
              </label>
            </div>

            <div className="flex space-x-2 items-center justify-start w-full rounded-md  shadow-sm mt-1  pr-3 pl-2 py-2 border-gray-700 border focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500 text-white">
              {org ? (
                <img
                  className="w-8 h-8 object-contain rounded"
                  src={org.logoUrl}
                  alt={"Org"}
                />
              ) : (
                <div className="w-8 h-8 rounded bg-gray-700"></div>
              )}
              {org && (
                <input
                  name="org"
                  className={`sm:text-lg bg-transparent w-full flex-1 focus:outline-none`}
                  type={"text"}
                  value={org.name}
                  disabled={true}
                  autoComplete="off"
                  placeholder={""}
                  maxLength={200}
                />
              )}
            </div>
          </div>

          {org && org.type < 2 && (
            <div className="w-full">
              <div className="flex items-center justify-between text-sm sm:text-base">
                <label htmlFor="type" className="font-semibold text-gray-400 ">
                  Group type{" "}
                  {errors.type &&
                    (!values.fieldsToEdit ||
                      (values.fieldsToEdit &&
                        values.fieldsToEdit.includes("type"))) && (
                      <span className="text-red-500">{errors.type}</span>
                    )}
                </label>
                {values.fieldsToEdit.length === 0 && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setValues({
                        ...values,
                        fieldsToEdit: ["type"],
                      });
                    }}
                    className="text-blue-300 transition-all font-semibold hover:text-blue-400"
                  >
                    Edit
                  </button>
                )}
                {values.fieldsToEdit.includes("type") && (
                  <div className="flex items-center justify-end space-x-2">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setFieldsToEdit("type");
                      }}
                      className="text-red-500 transition-all font-semibold hover:text-red-700"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={editOrgCallback}
                      className="text-green-500 transition-all font-semibold hover:text-green-700"
                    >
                      Save
                    </button>
                  </div>
                )}
              </div>
              <select
                id="type"
                name="type"
                // autoComplete=""
                value={values.type}
                onChange={(e) => {
                  onChange(e, true);
                }}
                disabled={
                  loadingEditForum ||
                  (values.fieldsToEdit && !values.fieldsToEdit.includes("type"))
                }
                className="sm:text-lg bg-transparent text-white mt-1 w-full shadow-sm flex-1 px-3 py-2 rounded-md border-gray-700 border focus:border-indigo-500 focus:ring-indigo-500"
              >
                {admin ? <option value={0}>Everyone</option> : <></>}
                <option value={1}>Graduation Year</option>
                <option value={2}>Cohort/Homeroom</option>
                <option value={3}>Class</option>
                <option value={4}>Club/Team</option>
                <option value={5}>Other</option>
              </select>
            </div>
          )}

          {values.type === 3 ? (
            <div className="w-full">
              <div className="flex items-center justify-between text-sm sm:text-base">
                <label htmlFor="orgId" className="font-semibold text-gray-400 ">
                  Subject{" "}
                  {errors.topicId && (
                    <span className="text-red-500">{errors.topicId}</span>
                  )}
                </label>
              </div>

              <div className="flex space-x-2 items-center justify-start w-full rounded-md  shadow-sm mt-1  pr-3 pl-2 py-2 border-gray-700 border focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500 text-white">
                {topic ? (
                  <img
                    className="w-8 h-8 object-contain rounded"
                    src={topic.logoUrl}
                    alt={"Org"}
                  />
                ) : (
                  <div className="w-8 h-8 rounded bg-gray-700"></div>
                )}
                {topic && (
                  <input
                    name="topic"
                    className={`sm:text-lg bg-transparent w-full flex-1 focus:outline-none`}
                    type={"text"}
                    value={topic.name}
                    disabled={true}
                    autoComplete="off"
                    placeholder={""}
                    maxLength={200}
                  />
                )}
              </div>
            </div>
          ) : (
            <TextInputField
              title={"Title"}
              name="name"
              type="text"
              error={errors.name}
              disabled={loadingEditForum}
              onChange={onChange}
              maxLen={14}
              setFieldsToEdit={setFieldsToEdit}
              fieldsToEdit={values.fieldsToEdit}
              editFunc={editForum}
              value={values.name}
            />
          )}

          {org &&
          org.type < 2 &&
          (values.type === 2 || values.type === 3 || values.type === 5) ? (
            <>
              <div className="flex items-start justify-start space-x-4 w-full">
                <TextInputField
                  title={"Term (optional)"}
                  name="term"
                  type="text"
                  error={errors.term}
                  disabled={loadingEditForum}
                  onChange={onChange}
                  maxLen={12}
                  placeholder={"W24"}
                  value={values.term}
                  setFieldsToEdit={setFieldsToEdit}
                  fieldsToEdit={values.fieldsToEdit}
                  editFunc={editForum}
                />
                <TextInputField
                  title={"Section (optional)"}
                  name="section"
                  placeholder={"001"}
                  type="text"
                  error={errors.section}
                  disabled={loadingEditForum}
                  onChange={onChange}
                  maxLen={12}
                  value={values.section}
                  setFieldsToEdit={setFieldsToEdit}
                  fieldsToEdit={values.fieldsToEdit}
                  editFunc={editForum}
                />
              </div>
            </>
          ) : (
            <></>
          )}

          {values.type !== 3 && (
            <div className="flex w-full items-start justify-start space-x-4 sm:space-x-8">
              <ProfilePicInputField
                title="Logo (optional)"
                name="logoFile"
                onChangeMedia={onChangeMedia}
                value={values.logoUrl}
                editFunc={editForum}
                values={values}
                error={errors.logoFile}
                isSquare={true}
              />
              <div className="flex items-start flex-col justify-start space-y-2 sm:text-base text-sm">
                <label className="font-semibold text-gray-200">
                  Accent colour
                </label>
                <div className="grid grid-cols-4 sm:grid-cols-6 w-full gap-2 h-full">
                  {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(
                    (colour, index) => (
                      <button
                        className={`${
                          index === values.accentColor
                            ? "border-gray-700 border-4 box-border"
                            : "hover:border-gray-600 hover:border-4"
                        } sm:w-10 aspect-square w-8 rounded-full bg-accent-${index} bg-gradient-to-br from-[--gradient-color-1] via-purple-500 to-pink-500 transition-all`}
                        onClick={(e) => {
                          e.preventDefault();

                          editForum({
                            variables: {
                              forumId: targetForum.id,
                              fieldsToEdit: ["accentColor"],
                              accentColor: index,
                            },
                          });
                          // Gradient.apply
                        }}
                        key={index}
                      ></button>
                    )
                  )}
                </div>
              </div>
            </div>
          )}

          <TagsInput
            title="Folders (optional)"
            name="folders"
            type="multiline"
            value={values.folders}
            values={values}
            setValues={setValues}
            setFieldsToEdit={setFieldsToEdit}
            fieldsToEdit={values.fieldsToEdit}
            editFunc={editForum}
            error={errors.folders}
            disabled={
              loadingEditForum || !values.fieldsToEdit.includes("folders")
            }
            maxTags={20}
            placeholder={"Q1, A1, A2, Midterm, Final"}
          />

          {wantsToArchive ? (
            <div className="w-full flex items-center justify-center space-x-2">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setWantsToArchive(false);
                }}
                className="w-1/3 rounded-lg border-gray-300 font-semibold hover:bg-gray-100 transition-all border p-3"
              >
                Cancel
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  archiveForum();
                }}
                className="w-full rounded-lg text-white bg-red-600 hover:bg-red-700 border-red-300 font-semibold  transition-all border p-3"
              >
                Confirm archive
              </button>
            </div>
          ) : (
            <button
              onClick={(e) => {
                e.preventDefault();
                setWantsToArchive(true);
              }}
              className="w-full uppercase rounded-lg text-white bg-red-600 hover:bg-red-700 border-red-300 font-semibold  transition-all border p-3"
              disabled={loadingEditForum}
            >
              Archive
            </button>
          )}
        </div>
      </form>
    </div>
  );
}

export default EditForumForm;
