import React from "react";
import TopicLevelCircle from "./TopicLevelCircle";

function TopicSummaryBox({
  navigate,
  topic,
  setTargetTopic,
  targetCustomer,
  targetGuestCustomer,
  isOnTopicPage,
}) {
  return (
    <div
      onClick={(e) => {
        navigate("/topic/" + topic.shortForm);
      }}
      className={`${
        isOnTopicPage ? "shadow-md" : ""
      } hide-button-flash w-full cursor-pointer p-4 short:p-3 hover:bg-gray-900 transition-all bg-darker-gray rounded-2xl`}
    >
      <div className="flex items-center justify-start space-x-4 short:space-x-3">
        <div className="rounded-xl bg-darker-gray h-14 aspect-square">
          <img
            src={topic.logoUrl}
            className="h-14 aspect-square rounded-md mr-2 object-cover"
            alt={topic.name}
          />
        </div>
        <div className="truncate">
          <p className="text-lg leading-tight truncate font-semibold text-white">
            {topic.name}
          </p>
          <div className="flex leading-tight items-center justify-start text-gray-200">
            <svg
              className="h-4 fill-current"
              viewBox="0 0 13 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.24404 3.7559C3.24404 1.96349 4.69581 0.511719 6.48822 0.511719C8.28064 0.511719 9.73241 1.96349 9.73241 3.7559C9.73241 5.54832 8.28064 7.00009 6.48822 7.00009C4.69581 7.00009 3.24404 5.54832 3.24404 3.7559ZM0 11.0554C0 8.898 4.32288 7.8112 6.48837 7.8112C8.65387 7.8112 12.9767 8.898 12.9767 11.0554V13.4885H0V11.0554Z"
              />
            </svg>
            <p className="ml-1 text-sm">{topic.numOfPlayers}</p>

            <svg
              className="h-4 ml-2 fill-current"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.65257 13C10.043 13 12.7915 10.2515 12.7915 6.86106C12.7915 3.47064 10.043 0.722168 6.65257 0.722168C3.26215 0.722168 0.513672 3.47064 0.513672 6.86106C0.513672 10.2515 3.26215 13 6.65257 13Z"
                fill="#F4900C"
              />
              <path
                d="M6.65257 12.2778C10.043 12.2778 12.7915 9.52931 12.7915 6.1389C12.7915 2.74848 10.043 0 6.65257 0C3.26215 0 0.513672 2.74848 0.513672 6.1389C0.513672 9.52931 3.26215 12.2778 6.65257 12.2778Z"
                fill="#FFCC4D"
              />
              <path
                d="M6.65224 11.5555C9.44435 11.5555 11.7078 9.29201 11.7078 6.4999C11.7078 3.70779 9.44435 1.44434 6.65224 1.44434C3.86013 1.44434 1.59668 3.70779 1.59668 6.4999C1.59668 9.29201 3.86013 11.5555 6.65224 11.5555Z"
                fill="#FFE8B6"
              />
              <path
                d="M6.65224 11.1946C9.44435 11.1946 11.7078 8.93117 11.7078 6.13906C11.7078 3.34695 9.44435 1.0835 6.65224 1.0835C3.86013 1.0835 1.59668 3.34695 1.59668 6.13906C1.59668 8.93117 3.86013 11.1946 6.65224 11.1946Z"
                fill="#FFAC33"
              />
              <path
                d="M6.63662 2.40527C5.64537 2.40527 4.69642 2.79904 3.99849 3.49996C3.30057 4.20088 2.91084 5.15152 2.91507 6.14277C2.9193 7.134 3.31714 8.08468 4.021 8.78559C4.7249 9.4865 5.67725 9.88028 6.66846 9.88028C6.21152 9.88028 5.76422 9.74476 5.38318 9.49089C5.00218 9.23703 4.70449 8.87616 4.52784 8.45399C4.35114 8.03182 4.30342 7.56724 4.39066 7.11906C4.4779 6.67087 4.6962 6.2592 5.01793 5.93608C5.33969 5.61296 5.75044 5.39291 6.19822 5.30376C6.64604 5.21461 7.11079 5.26036 7.53371 5.43523C7.95662 5.61011 8.3187 5.90624 8.57421 6.2862C8.82972 6.66613 8.96712 7.11286 8.96904 7.56984C8.97165 8.18259 8.73075 8.77026 8.29928 9.20354C7.86786 9.63683 7.28125 9.88028 6.66846 9.88028C7.65972 9.88028 8.60867 9.4865 9.30659 8.78559C10.0045 8.08468 10.3942 7.134 10.39 6.14277C10.3858 5.15152 9.98794 4.20088 9.28409 3.49996C8.58018 2.79904 7.62788 2.40527 6.63662 2.40527Z"
                fill="#FFD983"
              />
            </svg>
            <p className="ml-1 text-sm">{topic.minimumBet}</p>
          </div>
        </div>
      </div>
      <div className="flex mt-3 items-start justify-center w-full space-x-2">
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setTargetTopic(topic);
          }}
          className="font-semibold hover:bg-red-900 transition-all shadow-xl rounded-full text-white bg-red-950 w-full p-2 short:p-1.5"
        >
          Battle
        </button>
        <TopicLevelCircle
          topic={topic}
          targetCustomer={targetCustomer}
          targetGuestCustomer={targetGuestCustomer}
        />
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            navigate("/focus/" + topic.shortForm);
          }}
          className="font-semibold hover:bg-blue-900 shadow-xl transition-all rounded-full text-white bg-blue-950 w-full p-2 short:p-1.5"
        >
          Focus
        </button>
      </div>
    </div>
  );
}

export default TopicSummaryBox;
