import React, { useEffect, useRef, useState } from "react";

export default function MultiPlaceSelectWSearchInputField({
  error,
  name,
  values,
  targetAd,
  setValues,
  setFieldsToEdit,
  setTextInputValue,
  options,
  maxTags,
  edit,
  loading,
  textInputValue,
  title,
  canEdit,
  value,
  fieldsToEdit,
}) {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const dropdownRef = useRef(null);

  // Function to handle clicks outside the component
  const handleOutsideClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsDropdownVisible(false);
    }
  };
  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener("click", handleOutsideClick);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  // Initialize the Autocomplete object
  // var autocomplete = new window.google.maps.places.Autocomplete(
  //   document.getElementById("place"),
  //   { types: ["address"] }
  //   // fields: ["place_id", "geometry", "formatted_address", "name"],
  // );

  // // Listen for the "place_changed" event of the Autocomplete object
  // autocomplete.addListener("place_changed", function () {
  //   // Get the selected place
  //   var place = autocomplete.getPlace();
  //   if (place && place.address_components) {
  //     // Extract the address components
  //     var streetAddress = "";
  //     var city = "";
  //     var province = "";
  //     var country = "";
  //     var postalCode = "";
  //     for (var i = 0; i < place.address_components.length; i++) {
  //       var component = place.address_components[i];
  //       if (component.types.indexOf("street_number") !== -1) {
  //         streetAddress += component.long_name + " ";
  //       } else if (component.types.indexOf("route") !== -1) {
  //         streetAddress += component.long_name;
  //       } else if (component.types.indexOf("locality") !== -1) {
  //         city = component.long_name;
  //       } else if (
  //         component.types.indexOf("administrative_area_level_1") !== -1
  //       ) {
  //         province = component.long_name;
  //       } else if (component.types.indexOf("country") !== -1) {
  //         country = component.long_name;
  //       } else if (component.types.indexOf("postal_code") !== -1) {
  //         postalCode = component.long_name;
  //       }
  //     }
  //     // Update the input fields
  //     setValues({
  //       ...values,
  //       fieldsToEdit: [
  //         "streetAddress",
  //         "city",
  //         "province",
  //         "country",
  //         "postalCode",
  //       ],
  //       streetAddress,
  //       city,
  //       province,
  //       country,
  //       postalCode,
  //     });
  //   }
  // });

  return (
    <div className="w-full">
      <div className="flex items-center justify-between text-sm sm:text-base">
        <label htmlFor={name} className="font-semibold text-gray-400 ">
          {title}{" "}
          {error &&
            (!fieldsToEdit ||
              (fieldsToEdit && fieldsToEdit.includes(name))) && (
              <span className="text-red-500">{error}</span>
            )}
        </label>
        {canEdit && fieldsToEdit && fieldsToEdit.length === 0 && (
          <button
            onClick={(e) => {
              e.preventDefault();
              setValues({
                ...values,
                fieldsToEdit: [name],
              });
            }}
            className="text-blue-300 transition-all font-semibold hover:text-blue-400"
          >
            Edit
          </button>
        )}
        {fieldsToEdit && fieldsToEdit.includes(name) && (
          <div className="flex items-center justify-end space-x-2">
            <button
              onClick={(e) => {
                e.preventDefault();
                setFieldsToEdit(name);
                setTextInputValue("");
                setIsDropdownVisible(false);
              }}
              className="text-red-500 transition-all font-semibold hover:text-red-700"
            >
              Cancel
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                edit();
                setTextInputValue("");
                setIsDropdownVisible(false);
              }}
              className="text-green-500 transition-all font-semibold hover:text-green-700"
            >
              Save
            </button>
          </div>
        )}
      </div>

      {value && value.length > 0 && (
        <div className={`gap-2 flex-wrap flex items-center justify-start mt-2`}>
          {value.map((option, index) => (
            <div
              key={index}
              className={`${
                fieldsToEdit && fieldsToEdit.includes(name)
                  ? "pl-4 pr-3"
                  : "px-4"
              } rounded-full flex items-center space-x-2 transition-all justify-start  text-white border bg-gray-800 border-gray-700 overflow-hidden py-1`}
            >
              <p>{option}</p>
              {fieldsToEdit && fieldsToEdit.includes(name) && (
                <button
                  onClick={(e) => {
                    e.preventDefault();

                    const optionsCopy = value ? [...value] : [];
                    if (optionsCopy[index]) {
                      optionsCopy.splice(index, 1);
                    }
                    setValues({ ...values, [name]: optionsCopy });
                  }}
                  disabled={
                    loading || (fieldsToEdit && !fieldsToEdit.includes(name))
                  }
                  className="text-gray-400 disabled:opacity-50 hover:text-gray-500 transition-all"
                >
                  <svg
                    className="w-4 fill-current"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
                  </svg>
                </button>
              )}
            </div>
          ))}
        </div>
      )}
      {fieldsToEdit && fieldsToEdit.includes(name) && (
        <input
          className={`sm:text-lg mt-3 w-full shadow-sm flex-1 px-3 py-2 rounded-md border-gray-200 border focus:border-indigo-500 focus:ring-indigo-500`}
          type={"text"}
          id="place"
          value={textInputValue}
          onFocus={() => setIsDropdownVisible(true)} // Toggle the drop-down visibility
          // onBlur={() => setIsDropdownVisible(false)}
          onChange={(e) => {
            const tempValue = e.target.value;
            setTextInputValue(tempValue);
            const tags = tempValue.split(",").map((adj) => adj.trim());
            if (tags.length > 1) {
              if (value) {
                const newTags = [...tags].filter(
                  (item, pos, self) => self.indexOf(item) === pos && item
                );

                setValues({
                  ...values,
                  [name]: maxTags
                    ? [...value, ...newTags.slice(0, maxTags)]
                    : newTags,
                });
              } else {
                setValues({
                  ...values,
                  [name]: maxTags ? tags.slice(0, maxTags) : tags,
                });
              }
              setTextInputValue("");
            }
          }}
          autoComplete={"off"}
          ref={dropdownRef}
          placeholder={""}
          disabled={loading || (fieldsToEdit && !fieldsToEdit.includes(name))}
          maxLength={200}
          list={"options"}
        />
      )}
      {/* Show the drop-down only if isDropdownVisible is true */}
      {isDropdownVisible || textInputValue ? (
        <div className="relative w-full">
          <div className="max-h-40 rounded overscroll-contain shadow-xl w-full overflow-y-auto absolute bg-white z-50">
            {options
              .filter((option) =>
                option
                  .toLowerCase()
                  .trim()
                  .includes(textInputValue.toLowerCase().trim())
              )
              .map((option, index) => (
                <button
                  key={index}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (value) {
                      if (value.includes(option)) {
                      } else {
                        setValues({
                          ...values,
                          [name]: [...value, option],
                        });
                      }
                    } else {
                      setValues({
                        ...values,
                        [name]: [option],
                      });
                    }
                    setTextInputValue("");
                    setIsDropdownVisible(false); // Close the drop-down after selecting a option
                  }}
                  className="w-full flex items-center justify-between text-lg hover:bg-gray-50 text-left py-2 px-3"
                >
                  <p>{option}</p>
                  {value && value.includes(option) && (
                    <svg
                      className="fill-current h-4"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M8.79508 15.875L4.62508 11.705L3.20508 13.115L8.79508 18.705L20.7951 6.70498L19.3851 5.29498L8.79508 15.875Z" />
                    </svg>
                  )}
                </button>
              ))}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
