import React from "react";
import AdMediaInputField from "./AdMediaInputField";

export default function AdMediaField({
  values,
  title,
  urlFieldName,
  editAd,
  setFieldsToEdit,
  name,
  targetAd,
  maxSize,
  setValues,
  errors,
  previewVideoURL,
  onChangeMedia,
  setPreviewVideoURL,
  setPreviewPhotoURL,
  loadingEditAd,
  previewPhotoURL,
  aspect,
  setErrors,
  isAdmin,
}) {
  return (
    <div className="w-full">
      <div className="flex items-center justify-between text-sm sm:text-base mb-2">
        <label htmlFor={name} className="font-semibold text-gray-400 ">
          {title}{" "}
          {errors[name] &&
            (!values.fieldsToEdit ||
              (values.fieldsToEdit && !values.fieldsToEdit.includes(name)) ||
              errors[name].includes("30s")) && (
              <span className="text-red-500">{errors[name]}</span>
            )}
        </label>

        {(targetAd.status === 0 ||
          (isAdmin && targetAd.status === 3) ||
          targetAd.status === 5) &&
          values.fieldsToEdit &&
          values.fieldsToEdit.length === 0 && (
            <button
              onClick={(e) => {
                e.preventDefault();
                setValues({
                  ...values,
                  fieldsToEdit: [name],
                });
              }}
              className="text-blue-300 transition-all font-semibold hover:text-blue-400"
            >
              Edit
            </button>
          )}
        {values.fieldsToEdit && values.fieldsToEdit.includes(name) && (
          <div className="flex items-center justify-end space-x-2">
            <button
              onClick={(e) => {
                e.preventDefault();
                setPreviewPhotoURL(null);
                setPreviewVideoURL(null);

                setErrors({ ...errors, [name]: null });
                setFieldsToEdit(name);
              }}
              className="text-red-500 disabled:opacity-50 transition-all font-semibold hover:text-red-700"
              disabled={loadingEditAd}
            >
              Cancel
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();

                if (values[name]) {
                  editAd();
                } else {
                  setFieldsToEdit(name);
                }
              }}
              className="text-green-500 disabled:opacity-50 transition-all font-semibold hover:text-green-700"
              disabled={loadingEditAd}
            >
              Save
            </button>
          </div>
        )}
      </div>
      <div
        className={`w-full ${aspect} h-full flex space-x-4 items-center snap-x snap-mandatory justify-start rounded overflow-x-auto border border-gray-700`}
      >
        <AdMediaInputField
          value={values[urlFieldName]}
          name={name}
          onChangeMedia={onChangeMedia}
          disabled={loadingEditAd || !values.fieldsToEdit.includes(name)}
          loading={loadingEditAd && values.fieldsToEdit.includes(name)}
          previewVideoURL={previewVideoURL}
          setPreviewVideoURL={setPreviewVideoURL}
          previewPhotoURL={previewPhotoURL}
          setPreviewPhotoURL={setPreviewPhotoURL}
          shouldEdit={false}
          editFunc={editAd}
          errors={errors}
          setErrors={setErrors}
          type={values[name + "Type"]}
          maxSize={maxSize}
        />
      </div>
    </div>
  );
}
