import { useMutation, useQuery } from "@apollo/client";

import React, { useState } from "react";
import { customerClient, guestClient } from "../../GraphqlApolloClients";

import { useForm } from "../../util/hooks";
import SubmitButton from "./SubmitButton";

import {
  CREATE_OR_JOIN_QUIZ_MUTATION,
  FETCH_CUSTOMER,
  FETCH_GUEST_CUSTOMER,
  FETCH_RECOMMENDED_FRIENDS,
  FETCH_RECOMMENDED_ORG_SECTIONS,
  FETCH_RECOMMENDED_ORGS,
  FETCH_RECOMMENDED_TOPICS,
} from "../../util/gqlFunctions";
import MultiSelectAndOrderTopicsWSearch from "./MultiSelectAndOrderTopicsWSearch";
import TopicIcon from "./TopicIcon";

function MultiTopicBattleForm({
  topic,
  setTargetTopic,
  navigate,
  targetCustomer,

  lastQuizId,
  loginGuestContext,
  shouldOpenQuiz,
  setShouldOpenQuiz,
  toggleMultiTopicBattleForm,

  setSubPopupReason,

  initialTopicIds,
}) {
  const [errors, setErrors] = useState({});

  const { values, setValues } = useForm(createOrJoinQuizCallback, {
    topicIds:
      initialTopicIds && initialTopicIds.length > 0 ? initialTopicIds : [],
    gameTimingType: 0,

    country: "",
    lastQuizId,
    shouldShareLinkInChat: lastQuizId ? true : false,
    searchTerm: "",
    pageSize: 3,
    seenIds: [],
    isMultiTopic: true,
  });

  const [createOrJoinQuiz, { loading: loadingCreateOrJoinQuiz }] = useMutation(
    CREATE_OR_JOIN_QUIZ_MUTATION,
    {
      refetchQueries: [
        { query: FETCH_RECOMMENDED_ORGS },
        { query: FETCH_RECOMMENDED_ORG_SECTIONS },
        { query: FETCH_RECOMMENDED_FRIENDS, variables: { values } },
        { query: FETCH_CUSTOMER },
        { query: FETCH_GUEST_CUSTOMER },
      ],
      update(_, { data: { createOrJoinQuiz: createdQuiz } }) {
        setValues({
          ...values,
          ...createdQuiz,
        });

        if (createdQuiz.token) {
          loginGuestContext({ token: createdQuiz.token });
        }

        setErrors({});
        setTargetTopic(null);
        if (shouldOpenQuiz) {
          setShouldOpenQuiz(false);
        }
        if (!lastQuizId || !createdQuiz.sharedLinkOnCreation) {
          navigate("/battle/" + createdQuiz.id);
        }
      },
      onError(err) {
        console.log(err);
        console.log(values);
        if (err.graphQLErrors[0]) {
          console.log(err.graphQLErrors[0].extensions.errors);

          if (!targetCustomer) {
            navigate("/register");
          } else if (
            err.graphQLErrors[0].extensions.errors &&
            err.graphQLErrors[0].extensions.errors.quizCreation
          ) {
            setSubPopupReason(
              err.graphQLErrors[0].extensions.errors.quizCreation
            );

            toggleMultiTopicBattleForm();
          }
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: values,
      client: targetCustomer ? customerClient : guestClient,
    }
  );

  function createOrJoinQuizCallback(e) {
    e.preventDefault();
    createOrJoinQuiz();
  }

  const [curStep, setCurStep] = useState(1);

  const {
    data: { getRecommendedTopicsBySearch: topics } = {},
    // loading: loadingTopics,
    // fetchMore: fetchMoreTopics,
  } = useQuery(FETCH_RECOMMENDED_TOPICS, {
    client: targetCustomer ? customerClient : guestClient,
    variables: values,
  });

  return (
    <div className="fixed pt-16 sm:py-10 z-[60] overscroll-contain w-screen h-screen max-h-screen max-w-screen overflow-hidden flex items-end justify-end sm:items-center sm:justify-center">
      <button
        onClick={(e) => {
          e.preventDefault();
          setErrors({});
          setTargetTopic(null);
          toggleMultiTopicBattleForm();
        }}
        className="z-40 bg-gray-500 hide-button-flash overscroll-contain bg-opacity-10 motion-safe:animate-fadeInFast flex items-center justify-center inset-0 absolute backdrop-blur-sm"
        disabled={false}
      />
      <form
        // onSubmit={buyBandCallback}
        className={`overflow-x-hidden relative  max-h-full flex-shrink motion-safe:animate-fadeUpFast overscroll-contain sm:m-auto w-full max-w-xl overflow-y-auto flex flex-col items-start justify-start z-40 rounded-xl px-6 pb-6  bg-darker-gray shadow-xl rounded-b-none sm:rounded-3xl short:px-4 sm:p-8`}
      >
        <button
          onClick={(e) => {
            e.preventDefault();
            setErrors({});
            setTargetTopic(null);
            toggleMultiTopicBattleForm();
          }}
          className="w-full pt-4 short:pt-3 short:pb-1 pb-2 sm:hidden"
        >
          <div className="rounded-full bg-gray-700 flex-shrink-0 h-1 mx-auto w-10 mb-2"></div>
        </button>

        <p className="text-white text-2xl font-bold w-full short:text-xl short:leading-tight">
          {curStep === 0 ? "Select battle topic(s)" : "Trivia Battle"}
          {/* {topic.name} Battle */}
        </p>
        <p className="text-gray-500 w-full leading-tight short:leading-tight short:text-sm">
          Compete in a 7-question{" "}
          {/* {topic.tags && topic.tags.includes("education") ? "" : "trivia"}{" "} */}
          battle against your friends. Survive all questions, share the prize!
        </p>

        {/* <div className="mt-4 w-full flex items-start justify-start flex-col">
            <p className="font-medium">Full name</p>

          </div> */}
        <div className="w-full flex flex-1  flex-col items-start justify-start mt-5">
          {curStep === 1 && (
            <div className="w-full overflow-visible">
              <div className="flex items-center justify-between text-sm sm:text-base">
                <label
                  htmlFor="topics"
                  className="font-semibold text-gray-400 "
                >
                  Topics
                  {errors.topicIds ? (
                    <span className="text-red-500"> {errors.topicIds}</span>
                  ) : values.topicIds.length < 7 ? (
                    <span className="text-red-500">
                      {" "}
                      - Add {7 - values.topicIds.length} more
                    </span>
                  ) : (
                    <></>
                  )}
                </label>

                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setCurStep(0);
                  }}
                  className="text-blue-300 ml-auto text-right transition-all font-semibold hover:text-blue-400"
                >
                  Edit
                </button>
              </div>
              <div className="flex mt-1 items-center justify-start space-x-2">
                {values.topicIds &&
                  values.topicIds.length > 0 &&
                  values.topicIds.map((topicId, index) => (
                    <TopicIcon
                      key={index}
                      navigate={navigate}
                      topicId={topicId}
                      disabled={true}
                    />
                  ))}
              </div>
            </div>
          )}

          {curStep === 0 && (
            <MultiSelectAndOrderTopicsWSearch
              error={errors.topicIds}
              name={"topicIds"}
              values={values}
              setValues={setValues}
              options={topics}
              // loading={loadingEditOrg}
              title={"Search"}
              value={values.topicIds}
            />
          )}

          {curStep === 1 && (
            <div className="w-full">
              <div className="flex items-center justify-between text-sm sm:text-base">
                <label
                  htmlFor="gameTimingType"
                  className="font-semibold text-gray-400 "
                >
                  Timer{" "}
                  {errors.gameTimingType && (
                    <span className="text-red-500">
                      {errors.gameTimingType}
                    </span>
                  )}
                </label>
              </div>
              <div className="flex mt-1 overflow-x-auto items-center justify-start space-x-2 mb-5">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setValues({ ...values, gameTimingType: 0 });
                  }}
                  className={`${
                    values.gameTimingType === 0
                      ? "bg-gray-700 border-gray-800 "
                      : "hover:bg-gray-700 border-gray-800 "
                  } text-base shrink-0 sm:text-lg shadow-sm rounded-lg space-x-1 px-4 py-2 border transition-all flex items-center justify-center focus:border-indigo-500 focus:ring-indigo-500`}
                >
                  <p>Live</p>
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setValues({ ...values, gameTimingType: 1 });
                  }}
                  className={`${
                    values.gameTimingType === 1
                      ? "bg-gray-700 border-gray-800 "
                      : "hover:bg-gray-700 border-gray-800 "
                  } disabled:opacity-20 text-base text-center sm:text-lg shadow-sm rounded-lg space-x-1 px-4 py-2 border transition-all flex items-center justify-center focus:border-indigo-500 focus:ring-indigo-500`}
                >
                  <p>Async</p>
                </button>
              </div>
            </div>
          )}

          <div className="flex-1 "></div>

          <SubmitButton
            onClickFunc={(e) => {
              if (!targetCustomer) {
                navigate("/register");
              } else if (curStep === 0) {
                e.preventDefault();
                setCurStep(1);
              } else {
                createOrJoinQuizCallback(e);
              }
            }}
            disabled={loadingCreateOrJoinQuiz}
            title={curStep === 0 ? "Continue" : "Create"}
            errors={errors}
          />
          {/* 
          {errors && errors.quizCreation && (
            <p className="text-center leading-tight w-full text-red-600 mt-4 sm:mb-0 mb-2 text-base">
              {errors.quizCreation}
            </p>
          )} */}
          {!targetCustomer && (
            <p className="text-center leading-tight w-full text-gray-400 mt-4 sm:mb-0 mb-2 text-base">
              By clicking start you agree to our{" "}
              <button
                onClick={(e) => {
                  navigate("/terms");
                }}
                className="text-blue-300 hover:text-blue-400 transition-all"
              >
                Terms and Conditions
              </button>
            </p>
          )}
        </div>
      </form>
    </div>
  );
}

export default MultiTopicBattleForm;
