import moment from "moment";
import React, { useEffect, useState } from "react";
import { getRandomInt } from "../../util/hooks";

function XPBubble({ deltaXP, offset, pageLoadedAt, curAnsEndsAt }) {
  const [sideChosen] = useState(getRandomInt(2));
  const [startX] = useState(Math.random() * offset);
  const [timeDifferenceInSeconds, setTimeDifferenceInSeconds] = useState(0);
  const [shouldShowBubble, setShouldShowBubble] = useState(false);

  const [finishedSettingUpBubble, setFinishedSettingUpBubble] = useState(false); // const [isImageLoaded, setIsImageLoaded] = useState(true);
  useEffect(() => {
    let interval;

    if (!shouldShowBubble && !finishedSettingUpBubble) {
      interval = setInterval(() => {
        const now = moment();
        var diffInMills = now.diff(moment(curAnsEndsAt), "milliseconds");

        if (diffInMills > 1000 * 10) {
          diffInMills = now.diff(moment(pageLoadedAt), "milliseconds");

          if (diffInMills >= 1000) {
            setShouldShowBubble(true);
            setFinishedSettingUpBubble(true);
          }
        } else {
          if (diffInMills >= -3000) {
            setShouldShowBubble(true);
            setFinishedSettingUpBubble(true);
          }
        }
        setTimeDifferenceInSeconds(diffInMills);
      }, 50);
    }

    return () => {
      clearInterval(interval);
    };
  }, [
    curAnsEndsAt,
    finishedSettingUpBubble,
    timeDifferenceInSeconds,
    pageLoadedAt,
    shouldShowBubble,
  ]);

  return shouldShowBubble ? (
    <div
      className={`flex-shrink-0 ${
        deltaXP > 0
          ? "text-xl sm:text-2xl 2xl:text-3xl"
          : "text-base sm:text-lg 2xl:text-xl"
      } text-gray-200 font-light whitespace-nowrap motion-safe:animate-flyUpFast absolute bottom-0 transition-all -mb-0 sm:-mb-14 overflow-hidden aspect-square`}
      style={
        sideChosen < 1
          ? {
              left: `${startX}%`, // Randomly position the bubbles
            }
          : {
              right: `${startX}%`, // Randomly position the bubbles
            }
      }
      // hover:bg-blue-700
    >
      {deltaXP > 0 ? "+" : ""}
      {parseInt(deltaXP * 1000)} XP
      {/* <img
        src={answer.profilePicUrl}
        alt={answer.username}
        onLoad={() => setIsImageLoaded(true)}
        className="border-white bg-white short:border-[1.5px] border-2 2xl:border-4 box-border rounded-full object-cover aspect-square"
      /> */}
    </div>
  ) : (
    <></>
  );
}

export default XPBubble;
