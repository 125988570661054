import React, { useEffect, useState } from "react";
import { getRandomInt } from "../../util/hooks";

const powerUpPurchaseConfirmationMessages = [
  "Excellent choice!",
  "Wise move!",
  "Now go get em!",
  "Nice choice!",
  "Solid strategy!",
  // "This could be the difference!",
  // "It's comeback time!",
];

export default function PowerUpPaymentSuccessfulForm({
  togglePaymentSuccessfulForm,
  // targetCustomer,
}) {
  const [reenforcementInd, setReenforcementInd] = useState(
    getRandomInt(powerUpPurchaseConfirmationMessages.length)
  );
  useEffect(() => {
    if (reenforcementInd === null) {
      setReenforcementInd(
        getRandomInt(powerUpPurchaseConfirmationMessages.length)
      );
    }
  }, [reenforcementInd]);

  return (
    <div className="fixed pt-16 sm:py-10 z-[70] overscroll-contain w-screen h-screen max-h-screen max-w-screen overflow-hidden flex items-end justify-end sm:items-center sm:justify-center short:pt-12">
      <button
        onClick={(e) => {
          e.preventDefault();

          togglePaymentSuccessfulForm();
        }}
        className="z-40 hide-button-flash bg-opacity-10 motion-safe:animate-fadeInFast flex items-center justify-center inset-0 absolute backdrop-blur-sm"
        disabled={false}
      />
      <form
        // onSubmit={buyBandCallback}
        className={`overflow-x-hidden  text-center sm:max-h-full flex-shrink motion-safe:animate-fadeUpFast overscroll-contain sm:m-auto w-full max-w-md overflow-y-auto flex flex-col items-center justify-center z-40 rounded-xl px-6 pb-6 sm:p-8 bg-darker-gray shadow-xl rounded-b-none sm:rounded-3xl `}
      >
        <button
          onClick={(e) => {
            e.preventDefault();

            togglePaymentSuccessfulForm();
          }}
          className="w-full pt-4 short:pt-3 short:pb-1 pb-2 sm:hidden"
        >
          <div className="rounded-full bg-gray-700 flex-shrink-0 h-1 mx-auto w-10 mb-2"></div>
        </button>

        <p className="text-white text-2xl font-bold w-full short:text-xl short:leading-tight">
          {powerUpPurchaseConfirmationMessages[reenforcementInd]}
        </p>
        <p className="text-gray-500 w-full leading-tight text-lg mt-1">
          Powerup added to stash.
        </p>

        {/* <div className="mt-4 w-full flex items-start justify-start flex-col">
            <p className="font-medium">Full name</p>

          </div> */}

        <button
          onClick={(e) => {
            e.preventDefault();
            togglePaymentSuccessfulForm();
          }}
          className="text-lg font-semibold mt-4 uppercase sm:text-xl w-full bg-blue-600 hover:bg-blue-700 transition-all rounded-full shadow-sm text-white py-3 px-2"
        >
          Thanks!
        </button>

        {/* <p className="text-center w-full text-gray-400 mt-4 sm:mb-0 mb-2 text-base">
          Have a question?{" "}
          <a
            href={"mailto:info@tknight.live"}
            target="_blank"
            rel="noreferrer"
            className="text-blue-300 hover:text-blue-400 transition-all"
          >
            Contact us
          </a>
        </p> */}
      </form>
    </div>
  );
}
