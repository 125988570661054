import React, { useContext, useEffect, useState } from "react";

import usePageTracking from "../../util/usePageTracking";
//import  FeatureSummary from "../../components/creator/FeatureSummary";
//import  Footer from "../../components/creator/Footer";
//import  LinksAndNext from "../../components/creator/LinksAndNext";
//import  SliderBar from "../../components/creator/SliderBar";
import { useNavigate, useParams } from "react-router";
import TextInputField from "../../components/creator/TextInputField";

import { useMutation } from "@apollo/client";
import BlueFlatGradBack from "../../components/creator/BlueFlatGradBack";
import SubmitButton from "../../components/creator/SubmitButton";
import { BrandContactAuthContext } from "../../context/brandContactAuth";
import {
  RESET_SELLER_PASSWORD_MUTATION,
  SEND_PASSWORD_RESET_CODE_SELLER,
} from "../../util/gqlFunctions";
import { useForm } from "../../util/hooks";

function ResetSellerPassword() {
  usePageTracking();

  let navigate = useNavigate();

  const [errors, setErrors] = useState({});

  const { resetCode } = useParams();

  const { brandContact, loginBrandContact: loginBrandContactContext } =
    useContext(BrandContactAuthContext);

  useEffect(() => {
    if (brandContact) {
      navigate("/business/dashboard");
    }
  }, [brandContact, navigate]);

  useEffect(() => {
    document.title = "Reset Password | TKnight";
  }, []);

  const { onChange, values } = useForm(requestSellerPasswordResetCallback, {
    email: "",
    passwordResetCode: resetCode ? resetCode : "",
    newPassword: "",
  });

  const [isResetCodeEmailSent, setIsResetCodeEmailSent] = useState(
    resetCode ? true : false
  );

  const [sendBrandContactResetPasswordCode, { loading }] = useMutation(
    SEND_PASSWORD_RESET_CODE_SELLER,
    {
      update(_, { data: { sendBrandContactResetPasswordCode } }) {
        setErrors({});
        setIsResetCodeEmailSent(true);
      },
      onError(err) {
        console.log(err);
        if (err.graphQLErrors[0]) {
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: values,
    }
  );

  const [resetBrandContactPassword, { loading: loadingResetPassword }] =
    useMutation(RESET_SELLER_PASSWORD_MUTATION, {
      update(_, { data: { resetBrandContactPassword: customerData } }) {
        loginBrandContactContext(customerData);
        navigate("/");
      },
      onError(err) {
        console.log(err);
        if (err.graphQLErrors[0]) {
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: values,
    });

  function requestSellerPasswordResetCallback(e) {
    e.preventDefault();
    sendBrandContactResetPasswordCode();
  }

  function resetSellerPasswordCallback(e) {
    e.preventDefault();
    resetBrandContactPassword();
  }

  return (
    <div className="w-screen overflow-x-hidden h-full min-h-screen bg-black">
      <BlueFlatGradBack gradIndex={1} />
      <div className="relative z-10 short:max-w-xl lg:max-w-xl 2xl:max-w-2xl 3xl:max-w-3xl mx-auto xl:px-0 px-4 pb-10 lg:pb-0">
        <div className="space-x-10 relative z-20 px-2 sm:px-5 pt-4 pb-6 short:py-2 text-white flex items-center justify-between w-full">
          <button
            onClick={(e) => {
              e.preventDefault();
              navigate("/");
            }}
            className="transition-opacity hover:opacity-50 py-1 focus:outline-none"
          >
            <svg
              className="h-5 fill-current"
              viewBox="0 0 140 140"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M69.7018 0C51.1365 0 33.3636 7.37493 20.2921 20.5025C7.22057 33.63 -0.0786027 51.4347 0.000638512 69.9999C0.0798797 88.5647 7.531 106.37 20.7136 119.497C33.8971 132.625 51.7337 140 70.2982 140C61.7401 140 53.3626 137.462 46.226 132.707C39.0902 127.952 33.5148 121.194 30.2062 113.287C26.8969 105.38 26.0032 96.679 27.637 88.2849C29.2709 79.8908 33.3595 72.1805 39.3851 66.1287C45.4116 60.077 53.1045 55.9556 61.491 54.286C69.8783 52.6163 78.5826 53.4732 86.5034 56.7484C94.4243 60.0236 101.206 65.57 105.991 72.6861C110.777 79.8019 113.35 88.1688 113.386 96.7275C113.435 108.204 108.923 119.21 100.842 127.325C92.7618 135.44 81.7751 140 70.2982 140C88.8635 140 106.636 132.625 119.708 119.497C132.779 106.37 140.079 88.5647 139.999 69.9999C139.92 51.4347 132.469 33.63 119.286 20.5025C106.103 7.37493 88.2671 0 69.7018 0Z" />
            </svg>
          </button>
        </div>

        <div className="w-full pt-5 px-5 pb-3 sm:pt-10 sm:px-10 sm:pb-6 mb-10 flex items-center justify-start flex-col bg-darker-gray rounded-xl sm:rounded-3xl shadow-2xl">
          <p className="text-white text-2xl font-bold w-full short:text-xl short:leading-tight">
            Reset your password
          </p>

          <p className="text-gray-400 w-full mt-2 flex-shrink-0 leading-tight">
            {isResetCodeEmailSent
              ? "Password reset code sent. Please enter the code below."
              : "Enter the email address associated with your seller account and we'll send you a link to reset your password."}
          </p>

          {/* <div className="mt-4 w-full flex items-start justify-start flex-col">
            <p className="font-medium">Full name</p>

          </div> */}
          <div className="w-full flex flex-col items-start justify-start space-y-6 mt-6 mb-8">
            <TextInputField
              title="Email"
              name="email"
              type="email"
              disabled={loading || loadingResetPassword}
              error={errors.email}
              onChange={onChange}
              value={values.email}
            />
            {isResetCodeEmailSent && (
              <>
                <TextInputField
                  title="Reset code"
                  name="passwordResetCode"
                  placeholder={"______"}
                  disabled={loading || loadingResetPassword}
                  error={errors.passwordResetCode}
                  onChange={onChange}
                  value={values.passwordResetCode}
                />
                <TextInputField
                  title="New password"
                  name="newPassword"
                  type="password"
                  disabled={loading || loadingResetPassword}
                  error={errors.newPassword}
                  onChange={onChange}
                  value={values.newPassword}
                />
              </>
            )}
          </div>

          <SubmitButton
            title={!isResetCodeEmailSent ? "Generate Code" : "Confirm"}
            disabled={loading || loadingResetPassword}
            onClickFunc={(e) => {
              if (isResetCodeEmailSent) {
                resetSellerPasswordCallback(e);
              } else {
                requestSellerPasswordResetCallback(e);
              }
            }}
            errors={errors}
          />

          <p className="text-center text-gray-400 mt-4 sm:mb-0 mb-2 text-sm sm:text-base">
            <button
              onClick={(e) => {
                navigate("/loginSeller");
              }}
              className="text-blue-300 hover:text-blue-400 transition-all"
            >
              Return to sign in
            </button>
          </p>
        </div>

        <p className="text-gray-500 transition-all short:px-3 px-6 font-semibold mb-10 short:mb-4 text-center text-sm sm:text-base">
          <button
            className="hover:text-gray-700 font-semibold transition-all"
            onClick={(e) => {
              e.preventDefault();
              navigate("/");
            }}
          >
            © TKnight
          </button>{" "}
          <span className="text-gray-600 mx-1 sm:mx-2"> - </span>
          <a
            className="hover:text-gray-700 font-semibold transition-all"
            href={"mailto:info@tknight.live"}
            target="_blank"
            rel="noreferrer"
          >
            Contact
          </a>
          <span className="text-gray-600 mx-1 sm:mx-2"> - </span>
          <button
            className="hover:text-gray-700 font-semibold transition-all"
            onClick={(e) => {
              e.preventDefault();
              navigate("/terms");
            }}
          >
            Privacy &amp; terms
          </button>
        </p>
      </div>
    </div>
  );
}

export default ResetSellerPassword;
