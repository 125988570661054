import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CustomerAuthContext } from "../../context/customerAuth";
import { GuestAuthContext } from "../../context/guestAuth";
import AccuracyIcon from "./AccuracyIcon";
import CycloneIcon from "./CycloneIcon";
import StreakIcon from "./StreakIcon";
import TokenIcon from "./TokenIcon";
import WinsIcon from "./WinsIcon";

export default function LeaderboardTable({
  rankedFriends,
  values,
  setValues,
  type,
  rankedCountries,
  rankedCities,
  targetCustomer,
  rankedGroups,
  countryFlags,
  loading,
  targetCity,
  tableTitle,
  quiz,
  triviaNight,
  allPlayerBattleStats,
  logTriviaShare,
}) {
  const [isCaptionCopied, setIsCaptionCopied] = useState(false);
  const [isSharingLeaderboardToApps, setIsSharingToApps] = useState(false);

  const { customer } = useContext(CustomerAuthContext);
  const { guest } = useContext(GuestAuthContext);
  let navigate = useNavigate();

  const shareBattleLeaderboard = async () => {
    var textToShare = null;
    var concatTopicNames = (quiz || triviaNight).topicNames.join(", ");
    var prefix = triviaNight ? "TKL " + triviaNight.dayNum : "TKL ⚔️ ";

    if (quiz) {
      textToShare = prefix + concatTopicNames + "\n\n";
    } else {
      textToShare = prefix + "\n\n";
    }

    if (allPlayerBattleStats && allPlayerBattleStats.length > 0) {
      textToShare += allPlayerBattleStats[1]
        ? countryFlags[
            allPlayerBattleStats[1].cCountry ||
              allPlayerBattleStats[1].gCountry ||
              "All"
          ] + "   "
        : "         ";
      textToShare += allPlayerBattleStats[0]
        ? countryFlags[
            allPlayerBattleStats[0].cCountry ||
              allPlayerBattleStats[0].gCountry ||
              "All"
          ] + "   "
        : "            ";
      textToShare += allPlayerBattleStats[2]
        ? countryFlags[
            allPlayerBattleStats[2].cCountry ||
              allPlayerBattleStats[2].gCountry ||
              "All"
          ] + "   "
        : "";
      textToShare += "\n";

      textToShare += "🥈   ";

      textToShare += "🥇   ";

      textToShare += "🥉";

      textToShare += "\n";
      if (
        allPlayerBattleStats[1] &&
        ((customer && allPlayerBattleStats[1].customerId === customer.id) ||
          (guest && allPlayerBattleStats[1].guestCustomerId === guest.id))
      ) {
        textToShare += "🙌";
        textToShare += "\n";
      }
      if (
        allPlayerBattleStats[0] &&
        ((customer && allPlayerBattleStats[0].customerId === customer.id) ||
          (guest && allPlayerBattleStats[0].guestCustomerId === guest.id))
      ) {
        textToShare += "         🙌";
        textToShare += "\n";
      }
      if (
        allPlayerBattleStats[2] &&
        ((customer && allPlayerBattleStats[2].customerId === customer.id) ||
          (guest && allPlayerBattleStats[2].guestCustomerId === guest.id))
      ) {
        textToShare += "                 🙌";
        textToShare += "\n";
      }

      textToShare += "\n";
    }

    const link = quiz
      ? "https://tknight.live/battle/" + quiz.id
      : "https://tknight.live/tnl";

    const options = {
      subject: quiz
        ? "TKnight " + concatTopicNames[0] + " Battle Leaderboard!"
        : "TKnight Leaderboard",
      message: textToShare,
      url: link,
    };

    if (navigator.share) {
      navigator
        .share({
          title: options.subject,
          text: options.message,
          url: options.url,
        })
        .then(() => {
          // setHasCaptionBeenCopiedBefore(true);
          setIsCaptionCopied(true);

          setIsSharingToApps(true);
        })
        .catch((error) => {
          setIsSharingToApps(false);
          navigator.clipboard.writeText(textToShare);
          // navigator.clipboard.writeText(textToShare + " worldphraze.com");
          setIsCaptionCopied(true);
          // setHasCaptionBeenCopiedBefore(true);
        });
    } else {
      navigator.clipboard.writeText(textToShare);
      // setHasCaptionBeenCopiedBefore(true);

      setIsCaptionCopied(true);
    }

    logTriviaShare({
      variables: {
        quizId: quiz ? quiz.id : null,
        triviaNightId: triviaNight ? triviaNight.id : null,
        type,
      },
    });
  };

  return (
    <>
      <div className="w-full space-x-2 pb-2 px-2 sm:px-4 flex justify-between items-center">
        <p className="sm:text-lg truncate text-sm leading-tight font-semibold text-gray-100">
          {tableTitle ? tableTitle + " " : ""}
          {values.country
            ? (tableTitle ? "- " : "") + values.country
            : values.shortForm
            ? (tableTitle ? "- " : "") + values.shortForm
            : values.city
            ? (tableTitle ? "- " : "") + values.city
            : targetCity
            ? (tableTitle ? "- " : "") + targetCity.name
            : "Leaderboard"}
        </p>

        {!triviaNight && !quiz ? (
          <button
            className="bg-white flex-shrink-0 hover:bg-white/90 transition-all py-1 px-1.5 sm:px-2 pr-2 sm:pr-3 rounded-full items-center justify-center flex "
            onClick={() => {
              if (values.sortBy === "Earnings") {
                setValues({ ...values, sortBy: "Streak" });
              } else if (values.sortBy === "Streak") {
                setValues({ ...values, sortBy: "Wins" });
              } else if (values.sortBy === "Wins") {
                setValues({ ...values, sortBy: "Accuracy" });
              } else {
                setValues({ ...values, sortBy: "Earnings" });
              }
            }}
          >
            {values.sortBy === "Earnings" ? (
              <TokenIcon width={"w-5"} />
            ) : values.sortBy === "Streak" ? (
              values.topicShortForm && values.topicShortForm.length > 1 ? (
                <CycloneIcon width={"w-5"} />
              ) : (
                <StreakIcon width={"w-5"} />
              )
            ) : values.sortBy === "Wins" ? (
              <WinsIcon width={"w-5"} />
            ) : (
              <AccuracyIcon width={"w-5"} />
            )}
            <p className="font-semibold text-sm sm:text-base pr-1.5 sm:pr-2 ml-1 leading-none text-left text-gray-500">
              {values.sortBy === "Streak" ? "Top " : ""}
              {values.sortBy}
            </p>
            <svg
              className="aspect-square w-2"
              viewBox="0 0 22 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M2.2 0H11H19.8H22L11 13L0 0H2.2Z" fill="#666666" />
            </svg>
          </button>
        ) : (quiz || triviaNight)?.status >= 5 ? (
          <button
            className="bg-white flex-shrink-0 p-1 pr-2 sm:pr-3 rounded-full items-center justify-center flex"
            onClick={async () => {
              await shareBattleLeaderboard();
            }}
          >
            <p className="font-semibold text-sm sm:text-base pr-1.5 sm:pr-2 ml-1 leading-none text-left text-gray-500">
              {isSharingLeaderboardToApps
                ? "Shared!"
                : isCaptionCopied
                ? "Copied!"
                : "Share"}
            </p>
            <svg
              className="aspect-square w-3 fill-current text-gray-500"
              style={{ width: 12, aspectRatio: 1 }}
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_8661_576)">
                <path d="M15 14.1365C14.24 14.1365 13.56 14.4378 13.04 14.9096L5.91 10.743C5.96 10.512 6 10.2811 6 10.0402C6 9.7992 5.96 9.56827 5.91 9.33735L12.96 5.21084C13.5 5.71285 14.21 6.0241 15 6.0241C16.66 6.0241 18 4.67871 18 3.01205C18 1.34538 16.66 0 15 0C13.34 0 12 1.34538 12 3.01205C12 3.25301 12.04 3.48394 12.09 3.71486L5.04 7.84137C4.5 7.33936 3.79 7.02811 3 7.02811C1.34 7.02811 0 8.37349 0 10.0402C0 11.7068 1.34 13.0522 3 13.0522C3.79 13.0522 4.5 12.741 5.04 12.239L12.16 16.4157C12.11 16.6265 12.08 16.8474 12.08 17.0683C12.08 18.6847 13.39 20 15 20C16.61 20 17.92 18.6847 17.92 17.0683C17.92 15.4518 16.61 14.1365 15 14.1365Z"></path>
              </g>
            </svg>
          </button>
        ) : (
          <></>
        )}
      </div>
      <div className="w-full bg-darker-gray h-full sm:rounded-2xl rounded-xl p-2 short:p-1 flex flex-col items-start justify-start overflow-y-auto">
        {loading ? (
          <></>
        ) : type === "Country" && !values.country ? (
          rankedCountries &&
          rankedCountries.map((country, index) => (
            <button
              key={index}
              className="w-full shrink-0 space-x-2 rounded sm:rounded-lg truncate transition-all group hover:bg-gray-800 flex items-center justify-start p-2 sm:p-3"
              onClick={(e) => {
                navigate(
                  `/leaderboard/${
                    values.topicShortForm || "-"
                  }/country/${country._id.split(" ").join("-")}`
                );
                setValues({
                  ...values,
                  groupType: "Country",
                  country: country._id,
                  category: "country",
                  subCategory: country._id,
                  shortForm: null,
                  orgId: null,
                });
              }}
            >
              <p
                className={`${
                  index < 3
                    ? "bg-gray-200 text-black"
                    : "bg-gray-700 text-white"
                } short:text-[10px] text-xs items-center justify-center rounded-full text-center short:w-4 w-5 flex aspect-square`}
              >
                {index + 1}
              </p>

              <p className="flex-1 text-sm font-semibold text-white text-left">
                {country._id} {countryFlags[country._id]}
              </p>

              <p className="text-sm font-semibold text-white">
                {values.sortBy === "Earnings"
                  ? Math.round(country.totalEarnings)
                  : values.sortBy === "Streak"
                  ? country.maxFocusStreak
                  : values.sortBy === "Wins"
                  ? values.topicId
                    ? country.totalNumOfBattlesWon || 0
                    : country.totalNumOfTKLsWon || 0
                  : triviaNight || quiz
                  ? `${country.avgAccuracy || 0}% | ${
                      country.avgTimeTakenForCorrectAns || 0
                    }ms`
                  : `${country.avgAccuracy || 0}%`}
              </p>
            </button>
          ))
        ) : type === "City" && !values.cityId ? (
          rankedCities &&
          rankedCities.map((city, index) => (
            <button
              key={index}
              className="w-full shrink-0 space-x-2 rounded sm:rounded-lg truncate transition-all group hover:bg-gray-800 flex items-center justify-start p-2 sm:p-3"
              onClick={(e) => {
                navigate(
                  `/leaderboard/${values.topicShortForm || "-"}/city/${city._id
                    .split(" ")
                    .join("-")}`
                );
                setValues({
                  ...values,
                  groupType: "City",
                  cityId: city._id,
                  category: "city",
                  subCategory: city._id,
                  shortForm: null,
                  orgId: null,
                });
              }}
            >
              <p
                className={`${
                  index < 3
                    ? "bg-gray-200 text-black"
                    : "bg-gray-700 text-white"
                } short:text-[10px] text-xs items-center justify-center rounded-full text-center short:w-4 w-5 flex aspect-square`}
              >
                {index + 1}
              </p>

              <p className="flex-1 text-sm font-semibold text-white text-left">
                {city.cityName} {countryFlags[city.countryName]}
              </p>

              <p className="text-sm font-semibold text-white">
                {values.sortBy === "Earnings"
                  ? Math.round(city.totalEarnings)
                  : values.sortBy === "Streak"
                  ? city.maxFocusStreak
                  : values.sortBy === "Wins"
                  ? values.topicId
                    ? city.totalNumOfBattlesWon || 0
                    : city.totalNumOfTKLsWon || 0
                  : triviaNight || quiz
                  ? `${city.avgAccuracy || 0}% | ${
                      city.avgTimeTakenForCorrectAns || 0
                    }ms`
                  : `${city.avgAccuracy || 0}%`}
              </p>
            </button>
          ))
        ) : type === "Club" && !values.orgId ? (
          rankedGroups &&
          rankedGroups.map((group, index) => (
            <button
              key={group._id}
              className="w-full shrink-0 space-x-2 rounded sm:rounded-lg truncate transition-all group hover:bg-gray-800 flex items-center justify-start p-2 sm:p-3"
              onClick={(e) => {
                if (customer) {
                  setValues({
                    ...values,
                    // groupType: "Group",
                    country: null,
                    category: "club",
                    subCategory: group._id,
                    shortForm: group.shortForm,
                    orgId: group._id,
                  });
                }
              }}
            >
              <p
                className={`${
                  index < 3
                    ? "bg-gray-200 text-black"
                    : "bg-gray-700 text-white"
                } short:text-[10px] text-xs items-center justify-center rounded-full text-center short:w-4 w-5 flex aspect-square`}
              >
                {index + 1}
              </p>

              <p className="flex-1 text-sm font-semibold text-white text-left">
                {group.shortForm}{" "}
                {countryFlags ? " " + countryFlags[group.country] : ""}
              </p>

              <p className="text-sm font-semibold text-white">
                {values.sortBy === "Earnings"
                  ? Math.round(group.totalEarnings)
                  : values.sortBy === "Streak"
                  ? group.maxFocusStreak
                  : values.sortBy === "Wins"
                  ? values.topicId
                    ? group.totalNumOfBattlesWon || 0
                    : group.totalNumOfTKLsWon || 0
                  : triviaNight || quiz
                  ? `${group.avgAccuracy || 0}% | ${
                      group.avgTimeTakenForCorrectAns || 0
                    }ms`
                  : `${group.avgAccuracy || 0}%`}
              </p>
            </button>
          ))
        ) : rankedFriends && rankedFriends.length > 0 ? (
          rankedFriends.map((friend, index) => (
            <button
              key={index}
              className={`${
                customer && friend.id === customer.id ? "bg-blue-700" : ""
              } w-full shrink-0 space-x-2 rounded sm:rounded-lg truncate transition-all group hover:bg-gray-800 flex items-center justify-start p-2 sm:p-3`}
              disabled={true}
            >
              <p
                className={`${
                  index < 3
                    ? "bg-gray-200 text-black"
                    : "bg-gray-700 text-white"
                } short:text-[10px] text-xs items-center justify-center rounded-full text-center short:w-4 w-5 flex aspect-square`}
              >
                {index + 1}
              </p>

              <p className="flex-1 text-sm font-semibold text-white text-left">
                {customer &&
                friend.id === customer.id &&
                values.groupType === "Friends"
                  ? "You"
                  : values.groupType === "Friends" && friend.name
                  ? friend.name
                  : friend.username}
                {countryFlags ? " " + countryFlags[friend.country] : ""}
              </p>

              <p className="text-sm font-semibold text-white">
                {values.sortBy === "Earnings"
                  ? Math.round(friend.totalEarnings)
                  : values.sortBy === "Streak"
                  ? friend.maxFocusStreak
                  : values.sortBy === "Wins"
                  ? values.topicId
                    ? friend.totalNumOfBattlesWon || 0
                    : friend.totalNumOfTKLsWon || 0
                  : triviaNight || quiz
                  ? `${friend.avgAccuracy || 0}% | ${
                      friend.avgTimeTakenForCorrectAns || 0
                    }ms`
                  : `${friend.accuracy || 0}%`}
              </p>
            </button>
          ))
        ) : type === "Club" &&
          values.orgId &&
          !(targetCustomer && targetCustomer.orgIds.includes(values.orgId)) ? (
          <div className="rounded-lg shrink-0 text-center mt-4 text-black font-semibold px-4 py-2 w-full transition-all">
            <button
              onClick={(e) => {
                navigate("/addFriends");
              }}
              className="text-xl text-blue-600 whitespace-nowrap w-full hover:opacity-70 transition-all"
            >
              Join {values.shortForm}
            </button>
            <p className=" font-normal">
              Once you join, you will see everyone's detailed rankings here
            </p>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
