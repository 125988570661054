import React, { useContext, useEffect, useState } from "react";
import usePageTracking from "../../util/usePageTracking";
//import  FeatureSummary from "../../components/creator/FeatureSummary";
//import  Footer from "../../components/creator/Footer";
//import  LinksAndNext from "../../components/creator/LinksAndNext";
//import  SliderBar from "../../components/creator/SliderBar";
import { useNavigate } from "react-router";
import TextInputField from "../../components/creator/TextInputField";

import { useMutation, useQuery } from "@apollo/client";
import { brandContactClient } from "../../GraphqlApolloClients";
import {
  appVersion,
  defaultPicUrl,
  useForm,
  useWindowSize,
} from "../../util/hooks";

import ProfilePicInputField from "../../components/creator/ProfilePicInputField";

import Confetti from "react-confetti";
import GenericPaymentSuccessfulForm from "../../components/creator/GenericPaymentSuccessfulForm";
import LiveStreamGradBack from "../../components/creator/LiveStreamGradBack";
import LoginFooter from "../../components/creator/LoginFooter";
import TopBar from "../../components/creator/TopBar";
import { BrandContactAuthContext } from "../../context/brandContactAuth";
import { EDIT_SELLER, FETCH_SELLER } from "../../util/gqlFunctions";

const countries = [
  "",
  "Argentina",
  "Australia",
  "Austria",
  "Belgium",
  "Brazil",
  "Bulgaria",
  "Canada",
  "Chile",
  "China",
  "Croatia",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Estonia",
  "Finland",
  "France",
  "Germany",
  "Greece",
  "China",
  "Hungary",
  "India",
  "Indonesia",
  "Ireland",
  "Italy",
  "Japan",
  "Latvia",
  "Lithuania",
  "Luxembourg",
  "Malaysia",
  "Malta",
  "Mexico",
  "Netherlands",
  "New Zealand",
  "Norway",
  "Pakistan",
  "Philippines",
  "Poland",
  "Portugal",
  "Romania",
  "Russia",
  "Serbia",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "South Africa",
  "Spain",
  "Sweden",
  "Switzerland",
  "Turkey",
  "Thailand",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
];

function SellerAccountSettings(props) {
  usePageTracking();

  let navigate = useNavigate();

  const { brandContact, logoutBrandContact: logout } = useContext(
    BrandContactAuthContext
  );
  useEffect(() => {
    if (!brandContact) {
      navigate("/loginSeller");
    }
  }, [brandContact, navigate]);

  // const { shouldUnsubscribe } = useParams();

  useEffect(() => {
    document.title = "Seller Settings | TKnight";
  }, []);

  const [errors, setErrors] = useState({});

  const [valsAreInitialized, setValsAreInitialized] = useState(false);

  const { onChange, values, setValues, onChangeMedia } = useForm(
    editSellerCallback,
    {
      name: "",
      username: "",
      profilePicUrl: defaultPicUrl,
      email: "",
      phone: "",
      password: "",
      country: "",
      phoneCountryCode: "+1",
      phoneCountryShortForm: "CA",
      // remindersToReceive: [0, 1, 2],
      fieldsToEdit: [],
      oldPassword: "",
      birthday: null,
      gender: null,
    }
  );

  const { data: { getBrandContact: sellerInfo } = {}, loading: loadingSeller } =
    useQuery(FETCH_SELLER, {
      client: brandContactClient,
    });

  const [editBrandContact, { loading: loadingEditBrandContact }] = useMutation(
    EDIT_SELLER,
    {
      refetchQueries: [{ query: FETCH_SELLER }],
      update(_, { data: { editBrandContact: brandContactData } }) {
        setValues({
          ...values,
          name: brandContactData.name,
          email: brandContactData.email,
          phone: brandContactData.phone,
          country: brandContactData.country || "",
          fieldsToEdit: [],
          oldPassword: "",
          password: "",
          username: brandContactData.username,
          profilePicUrl: brandContactData.profilePicUrl,
          accentColor: brandContactData.accentColor || 0,
          remindersToReceive: brandContactData.remindersToReceive,
          birthday: brandContactData.birthday || null,
          gender: brandContactData.gender || null,
        });
        setErrors({});
        navigate("/business/settings");
      },
      onError(err) {
        console.log(err);

        if (err.graphQLErrors[0]) {
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: values,
      client: brandContactClient,
    }
  );

  function editSellerCallback(e) {
    e.preventDefault();
    editBrandContact();
  }

  function setFieldsToEdit(name) {
    if (
      values.fieldsToEdit &&
      values.fieldsToEdit.includes(name) &&
      sellerInfo
    ) {
      if (name === "name") {
        setValues({
          ...values,
          fieldsToEdit: [],
          [name]: sellerInfo.name || "",
        });
      } else {
        setValues({
          ...values,
          fieldsToEdit: [],
          [name]: sellerInfo[name] || "",
        });
      }
    } else {
      setValues({
        ...values,
        fieldsToEdit: [name],
      });
    }
  }

  const [checkedIsSellerLoggedIn, setCheckedIsSellerLoggedIn] = useState(false);

  useEffect(() => {
    if (
      brandContact &&
      !loadingSeller &&
      !sellerInfo &&
      !checkedIsSellerLoggedIn
    ) {
      logout();
      setCheckedIsSellerLoggedIn(true);
    }
  }, [
    brandContact,
    loadingSeller,
    sellerInfo,
    logout,
    checkedIsSellerLoggedIn,
  ]);

  useEffect(() => {
    if (
      !valsAreInitialized &&
      sellerInfo &&
      !loadingEditBrandContact &&
      !loadingSeller
    ) {
      setValues({
        ...values,
        name: sellerInfo.name,
        email: sellerInfo.email,
        phone: sellerInfo.phone,
        country: sellerInfo.country || "",
        oldPassword: "",
        username: sellerInfo.username,
        accentColor: sellerInfo.accentColor,
        profilePicUrl: sellerInfo.profilePicUrl,
        profilePicFile: null,

        remindersToReceive: sellerInfo.remindersToReceive,
        // birthday: sellerInfo.birthday || null,
        // gender: sellerInfo.gender,
      });
      setValsAreInitialized(true);
    }
  }, [
    brandContact,
    loadingSeller,
    values,
    setValues,
    sellerInfo,
    loadingEditBrandContact,
    valsAreInitialized,
  ]);
  // const [isUnsubscribedChecked, setIsUnsubscribedChecked] = useState(false);

  // useEffect(() => {
  //   if (
  //     shouldUnsubscribe === "unsubscribe" &&
  //     valsAreInitialized &&
  //     !isUnsubscribedChecked
  //   ) {
  //     editBrandContact({
  //       variables: {
  //         fieldsToEdit: ["remindersToReceive"],
  //         remindersToReceive: [],
  //       },
  //     });
  //     setValues({
  //       ...values,
  //       fieldsToEdit: ["remindersToReceive"],
  //       remindersToReceive: [],
  //     });
  //     setIsUnsubscribedChecked(true);
  //     setIsUnsubscribePopupOpen(true);
  //   }
  // }, [
  //   editBrandContact,
  //   shouldUnsubscribe,
  //   valsAreInitialized,
  //   values,
  //   setValues,
  //   isUnsubscribedChecked,
  // ]);

  const [shouldOpenPaymentSuccessfulForm, setShouldOpenPaymentSuccessfulForm] =
    useState(false);

  const togglePaymentSuccessfulForm = () => {
    setShouldOpenPaymentSuccessfulForm(!shouldOpenPaymentSuccessfulForm);
  };

  const [isUnsubscribePopupOpen, setIsUnsubscribePopupOpen] = useState(false);

  const resetInterval = 3000;

  useEffect(() => {
    let timeout;
    if (isUnsubscribePopupOpen && resetInterval) {
      timeout = setTimeout(
        () => setIsUnsubscribePopupOpen(false),
        resetInterval
      );
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isUnsubscribePopupOpen, resetInterval]);

  // const handleChangeDate = (event) => {
  //   event.preventDefault();

  //   // Get the selected date and time in the user's timezone
  //   const localDate = moment(event.target.value).toDate();

  //   // Convert the local date and time to UTC
  //   const utcDate = moment.utc(localDate);

  //   // Save the UTC date and time to the state
  //   setValues({ ...values, birthday: utcDate });
  // };

  // const displayBirthday = () => {
  //   // Format and display the birthday value in the user's timezone
  //   return moment(values.birthday).local().format("YYYY-MM-DD");
  // };
  const { width, height } = useWindowSize();

  return (
    <div className="w-screen overflow-x-hidden h-full min-h-screen bg-black">
      <LiveStreamGradBack gradIndex={2} />
      {shouldOpenPaymentSuccessfulForm && sellerInfo && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={togglePaymentSuccessfulForm}
            targetCustomer={sellerInfo}
            message={"Welcome to TKnight VIP, " + sellerInfo.name + "."}
            buttonMsg={"Thanks!"}
          />
          <div className="absolute z-[90] pointer-events-none inset-0">
            <Confetti
              width={width}
              height={height}
              recycle={false}
              numberOfPieces={width >= 640 ? 1500 : 1000}
              tweenDuration={30 * 1000}
              gravity={0.3}
            />
          </div>
        </>
      )}

      <div className="relative z-10 short:max-w-xl lg:max-w-xl 2xl:max-w-2xl 3xl:max-w-3xl mx-auto xl:px-0 px-4 pb-10 lg:pb-0">
        {isUnsubscribePopupOpen ? (
          <div className="fixed px-4 sm:px-0 left-0 w-full sm:max-w-xl sm:inset-x-auto top-5 sm:top-4 z-50">
            <div className="w-full motion-safe:animate-fadeDown font-semibold text-center text-lg sm:max-w-xl rounded-lg py-2 bg-red-600 text-white">
              Unsubscribed from TN reminder emails
            </div>
          </div>
        ) : (
          <></>
        )}

        <TopBar
          navigate={navigate}
          page="Seller Account"
          isSeller={true}
          seller={sellerInfo}
        />

        <div className="w-full pt-5 px-5 pb-3 sm:pt-10 sm:px-10 sm:pb-6 mb-10 flex items-center justify-start flex-col bg-darker-gray rounded-xl sm:rounded-3xl shadow-2xl">
          <p className="text-white text-2xl font-bold w-full short:text-xl short:leading-tight">
            Seller account settings
          </p>

          <div className="w-full flex flex-col items-center justify-start space-y-6 mt-4 short:mb-4">
            <TextInputField
              title="Username"
              name="username"
              value={values.username}
              onChange={onChange}
              error={errors.username}
              disabled={loadingSeller || loadingEditBrandContact}
              setFieldsToEdit={setFieldsToEdit}
              fieldsToEdit={values.fieldsToEdit}
              editFunc={editBrandContact}
              prefix="@"
              maxLen={30}
            />

            <div className="flex w-full items-start justify-start space-x-8 short:space-x-3">
              <ProfilePicInputField
                title="Business logo"
                name="profilePicFile"
                onChangeMedia={onChangeMedia}
                value={values.profilePicUrl}
                editFunc={editBrandContact}
                values={values}
                error={errors.profilePicFile}
              />
              {/* <div className="flex items-start flex-col justify-start space-y-2 sm:text-base text-sm">
                <label className="font-semibold text-gray-200">
                  Accent colour
                </label>
                <div className="grid grid-cols-4 sm:grid-cols-6 w-full gap-2 h-full">
                  {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(
                    (colour, index) => (
                      <button
                        className={`${
                          index === values.accentColor
                            ? "border-gray-700 border-4 box-border"
                            : "hover:border-gray-600 hover:border-4"
                        } sm:w-10 aspect-square w-8 rounded-full bg-accent-${index} bg-gradient-to-br from-[--gradient-color-1] via-purple-500 to-pink-500 transition-all`}
                        onClick={(e) => {
                          e.preventDefault();

                          editBrandContact({
                            variables: {
                              fieldsToEdit: ["accentColor"],
                              accentColor: index,
                            },
                          });
                          // Gradient.apply
                        }}
                        key={index}
                      ></button>
                    )
                  )}
                </div>
              </div> */}
            </div>
            <TextInputField
              title="Name"
              name="name"
              value={values.name}
              onChange={onChange}
              error={errors.name}
              disabled={loadingSeller || loadingEditBrandContact}
              setFieldsToEdit={setFieldsToEdit}
              fieldsToEdit={values.fieldsToEdit}
              editFunc={editBrandContact}
            />
            <TextInputField
              title="Email"
              name="email"
              type="email"
              value={values.email}
              onChange={onChange}
              error={errors.email}
              disabled={loadingSeller || loadingEditBrandContact}
              setFieldsToEdit={setFieldsToEdit}
              fieldsToEdit={values.fieldsToEdit}
              editFunc={editBrandContact}
            />

            <div className="w-full">
              <div className="flex items-center justify-between text-sm sm:text-base">
                <label
                  htmlFor="country"
                  className="font-semibold text-gray-400 "
                >
                  Country{" "}
                  {errors.country &&
                    (!values.fieldsToEdit ||
                      (values.fieldsToEdit &&
                        values.fieldsToEdit.includes("country"))) && (
                      <span className="text-red-500">{errors.country}</span>
                    )}
                </label>
                {values.fieldsToEdit.length === 0 && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setValues({
                        ...values,
                        fieldsToEdit: ["country"],
                      });
                    }}
                    className="text-blue-300 transition-all font-semibold hover:text-blue-400"
                  >
                    Edit
                  </button>
                )}
                {values.fieldsToEdit.includes("country") && (
                  <div className="flex items-center justify-end space-x-2">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setFieldsToEdit("country");
                      }}
                      className="text-red-500 transition-all font-semibold hover:text-red-700"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        editBrandContact();
                      }}
                      className="text-green-500 transition-all font-semibold hover:text-green-700"
                    >
                      Save
                    </button>
                  </div>
                )}
              </div>
              <select
                id="country"
                name="country"
                autoComplete="country-name"
                value={values.country}
                onChange={onChange}
                disabled={
                  loadingSeller ||
                  loadingEditBrandContact ||
                  (values.fieldsToEdit &&
                    !values.fieldsToEdit.includes("country"))
                }
                className="sm:text-lg text-white bg-transparent mt-1 w-full shadow-sm flex-1 px-3 py-2 rounded-md border-gray-300 border focus:border-indigo-500 focus:ring-indigo-500"
              >
                {countries.map((countryName, index) => (
                  <option key={index} value={countryName}>
                    {countryName}
                  </option>
                ))}
              </select>
            </div>

            {/* <div className="w-full">
              <div className="flex items-center justify-between text-sm sm:text-base">
                <label
                  htmlFor="birthday"
                  className="font-semibold text-gray-400 "
                >
                  Birthday{" "}
                  {errors.birthday &&
                    (!values.fieldsToEdit ||
                      (values.fieldsToEdit &&
                        values.fieldsToEdit.includes("birthday"))) && (
                      <span className="text-red-500">{errors.birthday}</span>
                    )}
                </label>
                {values.fieldsToEdit.length === 0 && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setValues({
                        ...values,
                        fieldsToEdit: ["birthday"],
                      });
                    }}
                    className="text-blue-300 transition-all font-semibold hover:text-blue-400"
                  >
                    Edit
                  </button>
                )}
                {values.fieldsToEdit.includes("birthday") && (
                  <div className="flex items-center justify-end space-x-2">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setFieldsToEdit("birthday");
                      }}
                      className="text-red-500 transition-all font-semibold hover:text-red-700"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        editBrandContact();
                      }}
                      className="text-green-500 transition-all font-semibold hover:text-green-700"
                    >
                      Save
                    </button>
                  </div>
                )}
              </div>

              <input
                type="date"
                id="date"
                min={moment(new Date())
                  .subtract(100, "years")
                  .format("YYYY-MM-DD")}
                // max={moment(new Date())
                //   .subtract(8, "years")
                //   .format("YYYY-MM-DD")}
                className="sm:text-lg bg-transparent text-white mt-1 w-full shadow-sm flex-1 px-3 py-2 rounded-md border-gray-700 border focus:border-indigo-500 focus:ring-indigo-500"
                value={displayBirthday()}
                onChange={handleChangeDate}
                disabled={
                  loadingEditBrandContact ||
                  (values.fieldsToEdit &&
                    !values.fieldsToEdit.includes("birthday"))
                }
              />
            </div> */}
            {/* <div className="w-full">
              <div className="flex items-center justify-between text-sm sm:text-base">
                <label
                  htmlFor="gender"
                  className="font-semibold text-gray-400 "
                >
                  Gender{" "}
                  {errors.gender &&
                    (!values.fieldsToEdit ||
                      (values.fieldsToEdit &&
                        values.fieldsToEdit.includes("gender"))) && (
                      <span className="text-red-500">{errors.gender}</span>
                    )}
                </label>
                {values.fieldsToEdit.length === 0 && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setValues({
                        ...values,
                        fieldsToEdit: ["gender"],
                      });
                    }}
                    className="text-blue-300 transition-all font-semibold hover:text-blue-400"
                  >
                    Edit
                  </button>
                )}
                {values.fieldsToEdit.includes("gender") && (
                  <div className="flex items-center justify-end space-x-2">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setFieldsToEdit("gender");
                      }}
                      className="text-red-500 transition-all font-semibold hover:text-red-700"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={editSellerCallback}
                      className="text-green-500 transition-all font-semibold hover:text-green-700"
                    >
                      Save
                    </button>
                  </div>
                )}
              </div>
              <select
                id="gender"
                name="gender"
                // autoComplete=""
                value={values.gender}
                onChange={(e) => {
                  onChange(e, true);
                }}
                disabled={
                  loadingEditBrandContact ||
                  (values.fieldsToEdit &&
                    !values.fieldsToEdit.includes("gender"))
                }
                className="sm:text-lg bg-transparent text-white mt-1 w-full shadow-sm flex-1 px-3 py-2 rounded-md border-gray-700 border focus:border-indigo-500 focus:ring-indigo-500"
              >
                <option value={null}></option>

                <option value={0}>Boy</option>
                <option value={1}>Girl</option>
                <option value={2}>Non-binary</option>
              </select>
            </div> */}
            {/* <TextInputField
              title="Phone"
              name="phone"
              type="tel"
              value={values.phone}
              onChange={onChange}
              values={values}
              setValues={setValues}
              error={errors.phone}
              phoneCodePrefix={
                values.phoneCountryShortForm + " " + values.phoneCountryCode
              }
              phoneCountryCode={values.phoneCountryCode}
              disabled={
                loadingSeller ||
                loadingEditBrandContact ||
                (values.fieldsToEdit && !values.fieldsToEdit.includes("phone"))
              }
              setFieldsToEdit={setFieldsToEdit}
              fieldsToEdit={values.fieldsToEdit}
              editFunc={editBrandContact}
            /> */}

            <TextInputField
              title="Password"
              name="password"
              type="password"
              value={values.password}
              oldPasswordVal={values.oldPassword}
              onChange={onChange}
              error={errors.password}
              placeholder="••••••••••"
              disabled={loadingSeller || loadingEditBrandContact}
              setFieldsToEdit={setFieldsToEdit}
              fieldsToEdit={values.fieldsToEdit}
              editFunc={editBrandContact}
            />

            <div className="pt-2">
              <button
                className="bg-blue-600 hover:bg-blue-700 text-xl relative font-semibold overflow-hidden py-2 transition-all rounded-full shadow-lg text-white px-6"
                onClick={() => {
                  logout();
                }}
              >
                Log out
              </button>
              <p className="text-gray-400 text-center mt-2">v{appVersion}</p>
            </div>
          </div>
        </div>
        <LoginFooter navigate={navigate} />
      </div>
    </div>
  );
}

export default SellerAccountSettings;
