import React, { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";

const welcomeBackMessages = ["Welcome back!", "You're back!", "Missed you!"];

const welcomeMsgInd = Math.floor(Math.random() * welcomeBackMessages.length);

function EmptyFocusQuestionBox({
  title,
  caption,
  topicName,
  loadingRecommendedQs,
  index,
  question,
  questionIdToScrollIntoView,
  setIndexVisible,
  indexVisible,
}) {
  const [isCaptionCopied, setIsCaptionCopied] = useState(false);
  const [isSharingToApps, setIsSharingToApps] = useState(false);
  const [hasCaptionBeenCopiedBefore, setHasCaptionBeenCopiedBefore] =
    useState(false);

  useEffect(() => {
    let timeout;
    if (isCaptionCopied) {
      timeout = setTimeout(() => setIsCaptionCopied(false), 3000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isCaptionCopied]);
  const shareEndOfQsResult = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    var textToShare = topicName ? "#TKnight 🥹 " + topicName : "#TKnight 🥹";

    if (navigator.share) {
      navigator
        .share({
          title: `I finished all the ${topicName} questions on TKnight!`,

          text: textToShare,
          // url: "http://worldphraze.com/",
          // url:
          //   playersPhraze && playersPhraze.isEligibleForPrize
          //     ? "W/" + playersPhraze.id
          //     : "phraze",
        })
        .then(() => {
          setHasCaptionBeenCopiedBefore(true);
          setIsCaptionCopied(true);

          setIsSharingToApps(true);
        })
        .catch((error) => {
          setIsSharingToApps(false);
          navigator.clipboard.writeText(textToShare);
          // navigator.clipboard.writeText(textToShare + " worldphraze.com");
          setIsCaptionCopied(true);
          setHasCaptionBeenCopiedBefore(true);
        });
    } else {
      navigator.clipboard.writeText(textToShare);
      setHasCaptionBeenCopiedBefore(true);

      setIsCaptionCopied(true);
    }
  };

  const questionRef = useRef();

  useEffect(() => {
    if (
      question &&
      question.id === questionIdToScrollIntoView &&
      !loadingRecommendedQs
    ) {
      questionRef.current.scrollIntoView({
        behavior: "instant",
        block: "nearest",
        inline: "center",
      });
    }
  }, [questionIdToScrollIntoView, question, index, loadingRecommendedQs]);

  const {
    ref: boxRef,
    inView: isBoxVisible,
    // entry,
  } = useInView({
    /* Optional options */
    threshold: 0.7,
  });

  useEffect(() => {
    if (isBoxVisible && index !== indexVisible && !loadingRecommendedQs) {
      setIndexVisible(index);
    }
  }, [
    index,
    indexVisible,
    setIndexVisible,

    isBoxVisible,

    loadingRecommendedQs,
  ]);

  return (
    <div
      ref={questionRef}
      className="w-full h-full select-none snap-start snap-always shrink-0"
    >
      <div
        ref={boxRef}
        className={`bg-darker-gray relative flex px-3 sm:px-8 sm:pt-7 pt-3 rounded-2xl sm:rounded-3xl flex-col items-center justify-center shrink-0 w-full h-full grow-0`}
      >
        <div
          className={`w-full flex z-40 space-x-4 px-1 sm:px-0 items-center justify-center`}
        >
          <div className="w-1/5 flex items-center justify-start space-x-2">
            <div className="text-gray-200 flex items-center justify-start space-x-1.5 sm:space-x-3">
              <svg
                className="h-4 fill-current 2xl:h-6 short:h-3 "
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.5 4.5C4.5 2.01375 6.51375 0 9 0C11.4862 0 13.5 2.01375 13.5 4.5C13.5 6.98625 11.4862 9 9 9C6.51375 9 4.5 6.98625 4.5 4.5ZM0 14.625C0 11.6325 5.99625 10.125 9 10.125C12.0037 10.125 18 11.6325 18 14.625V18H0V14.625Z"
                />
              </svg>
              <p className="text-sm short:text-xs 2xl:text-xl font-semibold">
                31
              </p>
            </div>
          </div>

          <div className="w-full group truncate sm:text-xl lg:text-lg 2xl:text-2xl text-sm">
            <p
              className={`text-blue-300 font-semibold w-full truncate transition-all uppercase focus:outline-none tracking-wide whitespace-nowrap text-center`}
            >
              {title || welcomeBackMessages[welcomeMsgInd]}
            </p>
          </div>

          <div className="w-1/5 flex items-center justify-end space-x-2">
            <div
              className={`text-gray-200 flex items-center justify-end space-x-1.5 transition-all sm:space-x-3 `}
            >
              <svg
                className={`h-4 2xl:h-6 short:h-3 stroke-current`}
                viewBox="0 0 14 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.39392 14.0809L7 13.912L6.60608 14.0809L1 16.4835V2C1 1.45228 1.45228 1 2 1H12C12.5477 1 13 1.45228 13 2V16.4835L7.39392 14.0809Z"
                  strokeWidth="2"
                />
              </svg>

              <p className="text-sm 2xl:text-lg short:text-xs font-semibold">
                13
              </p>
            </div>
          </div>
        </div>

        <div className="justify-center h-full transition-all w-full z-40 flex relative flex-col items-center space-y-1 flex-1 py-4">
          <p
            className={`${
              caption ? "" : "invisible"
            }  transition-all leading-tight sm:leading-tight overflow-y-auto max-h-24 sm:max-h-36 lg:max-h-28 select-none text-base sm:text-lg z-20 2xl:text-2xl font-semibold text-center max-w-xs px-2`}
          >
            {caption || "How are you doing today?"}
          </p>
        </div>

        {caption ? (
          // <button
          //   onClick={(e) => {
          //     shareEndOfQsResult(e);
          //   }}
          //   type="button"
          //   className={`${
          //     isCaptionCopied ? " motion-safe:animate-popUp" : " "
          //   } relative text-xl sm:text-2xl sm:py-3 lg:text-xl lg:py-1 2xl:text-2xl overflow-hidden shadow-sm  group w-full font-bold uppercase flex transition-all items-center justify-center bg-blue-600 text-white rounded-full pl-4 pr-3 py-1`}
          // >
          //   <p
          //     className={`${
          //       isCaptionCopied
          //         ? "motion-safe:animate-fadeDownFast"
          //         : hasCaptionBeenCopiedBefore
          //         ? "motion-safe:animate-fadeOutUpFast"
          //         : "hidden"
          //     } pb-2 text-black bg-blue-300 inset-0 pt-2.5 w-full absolute`}
          //   >
          //     {isSharingToApps ? "Shared!" : "Copied"}
          //   </p>

          //   <p
          //     className={`${
          //       isCaptionCopied
          //         ? "motion-safe:animate-fadeOutDownFast"
          //         : hasCaptionBeenCopiedBefore
          //         ? "motion-safe:animate-fadeUpFast"
          //         : ""
          //     } xshort:py-1 py-2`}
          //   >
          //     Share
          //   </p>
          // </button>
          <div className="space-y-2 sm:space-y-3 short:space-y-1.5 flex z-40 flex-col items-center justify-center w-full">
            {/* <div
              className={`border-gray-300 invisible py-4 2xl:py-6 short:py-2 xshort:py-1.5 px-5 sm:px-8 short:px-3 focus:outline-none hide-button-flash rounded-full transition-colors border-2 overflow-hidden shadow-sm flex items-center relative justify-between w-full`}
            >
              <p
                className={`text-xl invisible text-gray-800 short:text-base text-left truncate select-none 2xl:text-3xl leading-tight font-semibold z-20`}
              >
                Thanks sis!
              </p>
            </div> */}

            <button
              onClick={(e) => {
                shareEndOfQsResult(e);
              }}
              type="button"
              className={`${
                isCaptionCopied ? " motion-safe:animate-popUp" : " "
              } relative text-xl sm:text-2xl sm:py-3 lg:py-1 2xl:py-4 lg:text-xl 2xl:text-2xl overflow-hidden shadow-sm  group w-full font-bold uppercase flex transition-all items-center justify-center bg-gray-200 text-blue-600 rounded-full pl-4 pr-3 py-1`}
            >
              <p
                className={`${
                  isCaptionCopied
                    ? "motion-safe:animate-fadeDownFast"
                    : hasCaptionBeenCopiedBefore
                    ? "motion-safe:animate-fadeOutUpFast"
                    : "hidden"
                } pb-2 text-black bg-blue-300 inset-0 pt-2.5 w-full absolute`}
              >
                {isSharingToApps ? "Shared!" : "Copied"}
              </p>

              <p
                className={`${
                  isCaptionCopied
                    ? "motion-safe:animate-fadeOutDownFast"
                    : hasCaptionBeenCopiedBefore
                    ? "motion-safe:animate-fadeUpFast"
                    : ""
                } py-2 short:py-1`}
              >
                Share
              </p>
            </button>
          </div>
        ) : (
          <div className="space-y-2 sm:space-y-3 short:space-y-1.5 flex z-40 flex-col items-center justify-center w-full">
            {/* {[0, 1, 2, 3].map((index) => ( */}
            <div
              className={`border-gray-700 py-4 2xl:py-6 short:py-2 xshort:py-1.5 px-5 sm:px-8 short:px-3 focus:outline-none hide-button-flash rounded-full transition-colors border-2 overflow-hidden shadow-sm flex items-center relative justify-between w-full`}
            >
              <p
                className={`text-xl invisible text-gray-800 short:text-base text-left truncate select-none 2xl:text-3xl leading-tight font-semibold z-20`}
              >
                Great!
              </p>
            </div>
            <div
              className={`border-gray-700 py-4 2xl:py-6 short:py-2 xshort:py-1.5 px-5 sm:px-8 short:px-3 focus:outline-none hide-button-flash rounded-full transition-colors border-2 overflow-hidden shadow-sm flex items-center relative justify-between w-full`}
            >
              <p
                className={`text-xl invisible text-gray-800 short:text-base text-left truncate select-none 2xl:text-3xl leading-tight font-semibold z-20`}
              >
                Great!
              </p>
            </div>
            <div
              className={`border-gray-700 py-4 2xl:py-6 short:py-2 xshort:py-1.5 px-5 sm:px-8 short:px-3 focus:outline-none hide-button-flash rounded-full transition-colors border-2 overflow-hidden shadow-sm flex items-center relative justify-between w-full`}
            >
              <p
                className={`text-xl invisible text-gray-800 short:text-base text-left truncate select-none 2xl:text-3xl leading-tight font-semibold z-20`}
              >
                Great!
              </p>
            </div>
            <div
              className={`border-gray-700 py-4 2xl:py-6 short:py-2 xshort:py-1.5 px-5 sm:px-8 short:px-3 focus:outline-none hide-button-flash rounded-full transition-colors border-2 overflow-hidden shadow-sm flex items-center relative justify-between w-full`}
            >
              <p
                className={`text-xl invisible text-gray-800 short:text-base text-left truncate select-none 2xl:text-3xl leading-tight font-semibold z-20`}
              >
                Great!
              </p>
            </div>
          </div>
        )}
        <div className="w-full z-40 short:text-sm flex items-center justify-between space-x-2 rounded-lg">
          <button className="opacity-50 text-gray-200  hide-button-flash focus:outline-none w-1/5 px-2 pb-3 pt-2 sm:py-4 xshort:py-2 flex transition-colors items-center justify-start short:space-x-1 space-x-2">
            <svg
              className="h-5 2xl:h-7 short:h-4 fill-current shrink-0"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.6668 0.833374H1.75016C1.246 0.833374 0.833496 1.24587 0.833496 1.75004V14.5834L4.50016 10.9167H13.6668C14.171 10.9167 14.5835 10.5042 14.5835 10V1.75004C14.5835 1.24587 14.171 0.833374 13.6668 0.833374ZM12.7502 2.66671V9.08337H3.73933L2.66683 10.1559V2.66671H12.7502ZM16.4168 4.50004H18.2502C18.7543 4.50004 19.1668 4.91254 19.1668 5.41671V19.1667L15.5002 15.5H5.41683C4.91266 15.5 4.50016 15.0875 4.50016 14.5834V12.75H16.4168V4.50004Z"
              ></path>
            </svg>
            <p className="leading-none">19</p>
          </button>
          <div className="text-gray-500 flex pb-0.5 items-center space-x-1 sm:space-x-2 justify-center">
            <div className="transition-all rounded aspect-square">
              <svg
                className="h-7 short:h-4.5 2xl:h-8 fill-current"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M7.41 15.7049L12 11.1249L16.59 15.7049L18 14.2949L12 8.29492L6 14.2949L7.41 15.7049Z"></path>
              </svg>
            </div>
            <div className=" min-w-[32px] lg:min-w-[28px] short:min-w-[24px] relative flex text-center items-center justify-center">
              <svg
                className=" h-6 short:h-5 2xl:h-7 absolute inset-auto fill-current"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.65257 13C10.043 13 12.7915 10.2515 12.7915 6.86106C12.7915 3.47064 10.043 0.722168 6.65257 0.722168C3.26215 0.722168 0.513672 3.47064 0.513672 6.86106C0.513672 10.2515 3.26215 13 6.65257 13Z"
                  fill="#F4900C"
                ></path>
                <path
                  d="M6.65257 12.2778C10.043 12.2778 12.7915 9.52931 12.7915 6.1389C12.7915 2.74848 10.043 0 6.65257 0C3.26215 0 0.513672 2.74848 0.513672 6.1389C0.513672 9.52931 3.26215 12.2778 6.65257 12.2778Z"
                  fill="#FFCC4D"
                ></path>
                <path
                  d="M6.65224 11.5555C9.44435 11.5555 11.7078 9.29201 11.7078 6.4999C11.7078 3.70779 9.44435 1.44434 6.65224 1.44434C3.86013 1.44434 1.59668 3.70779 1.59668 6.4999C1.59668 9.29201 3.86013 11.5555 6.65224 11.5555Z"
                  fill="#FFE8B6"
                ></path>
                <path
                  d="M6.65224 11.1946C9.44435 11.1946 11.7078 8.93117 11.7078 6.13906C11.7078 3.34695 9.44435 1.0835 6.65224 1.0835C3.86013 1.0835 1.59668 3.34695 1.59668 6.13906C1.59668 8.93117 3.86013 11.1946 6.65224 11.1946Z"
                  fill="#FFAC33"
                ></path>
                <path
                  d="M6.63662 2.40527C5.64537 2.40527 4.69642 2.79904 3.99849 3.49996C3.30057 4.20088 2.91084 5.15152 2.91507 6.14277C2.9193 7.134 3.31714 8.08468 4.021 8.78559C4.7249 9.4865 5.67725 9.88028 6.66846 9.88028C6.21152 9.88028 5.76422 9.74476 5.38318 9.49089C5.00218 9.23703 4.70449 8.87616 4.52784 8.45399C4.35114 8.03182 4.30342 7.56724 4.39066 7.11906C4.4779 6.67087 4.6962 6.2592 5.01793 5.93608C5.33969 5.61296 5.75044 5.39291 6.19822 5.30376C6.64604 5.21461 7.11079 5.26036 7.53371 5.43523C7.95662 5.61011 8.3187 5.90624 8.57421 6.2862C8.82972 6.66613 8.96712 7.11286 8.96904 7.56984C8.97165 8.18259 8.73075 8.77026 8.29928 9.20354C7.86786 9.63683 7.28125 9.88028 6.66846 9.88028C7.65972 9.88028 8.60867 9.4865 9.30659 8.78559C10.0045 8.08468 10.3942 7.134 10.39 6.14277C10.3858 5.15152 9.98794 4.20088 9.28409 3.49996C8.58018 2.79904 7.62788 2.40527 6.63662 2.40527Z"
                  fill="#FFD983"
                ></path>
              </svg>
            </div>
            <div className="transition-all rounded aspect-square">
              <svg
                className="h-7 short:h-4.5 2xl:h-8 fill-current"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M16.59 8.29508L12 12.8751L7.41 8.29508L6 9.70508L12 15.7051L18 9.70508L16.59 8.29508Z"></path>
              </svg>
            </div>
          </div>
          <div className="text-gray-200 w-1/5 px-2 pb-3 pt-2 sm:py-4 xshort:py-2 flex items-center justify-end short:space-x-1 space-x-2">
            <svg
              className="h-4 short:h-3.5 2xl:h-6 fill-current shrink-0"
              viewBox="0 0 18 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M11 4.5V0.5L18 7.5L11 14.5V10.4C6 10.4 2.5 12 0 15.5C1 10.5 4 5.5 11 4.5Z"></path>
            </svg>
            <p className="leading-none">1</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmptyFocusQuestionBox;
