import React, { useState } from "react";

function TagsInput({
  title,
  error,
  value,
  name,
  disabled,
  maxTags,
  editFunc,
  setValues,
  values,
  setFieldsToEdit,
  fieldsToEdit,
  placeholder,
  type,
  hideInput,
}) {
  const [inputValue, setInputValue] = useState("");
  return (
    <div className="w-full">
      <div className="flex items-center justify-between text-sm sm:text-base">
        <label htmlFor={name} className="font-semibold text-gray-400 ">
          {title} {error && <span className="text-red-500">{error}</span>}
        </label>

        {fieldsToEdit && fieldsToEdit.length === 0 && (
          <button
            onClick={(e) => {
              e.preventDefault();
              setFieldsToEdit(name);
            }}
            className="text-blue-300 ml-auto text-right transition-all font-semibold hover:text-blue-400"
          >
            Edit
          </button>
        )}
        {fieldsToEdit && fieldsToEdit.includes(name) && (
          <div className="flex items-center justify-end space-x-2">
            <button
              onClick={(e) => {
                e.preventDefault();

                setFieldsToEdit(name);
              }}
              className="text-red-500 transition-all font-semibold hover:text-red-700"
            >
              Cancel
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                editFunc();
              }}
              className="text-green-500 transition-all font-semibold hover:text-green-700"
            >
              Save
            </button>
          </div>
        )}
      </div>

      {value && value.length > 0 && (
        <div className={`gap-2 flex-wrap flex items-center justify-start mt-2`}>
          {value.map((tag, index) => (
            <div
              key={index}
              className={`rounded-full ${
                placeholder ? "" : "lowercase"
              } flex items-center justify-start bg-gray-800 text-white pl-4 overflow-hidden py-1`}
            >
              <p>{tag}</p>
              {disabled ||
              (maxTags && value && value.length >= maxTags) ||
              (fieldsToEdit && !fieldsToEdit.includes(name)) ? (
                <div className="w-4"></div>
              ) : (
                <button
                  onClick={(e) => {
                    e.preventDefault();

                    const tagsCopy = value ? [...value] : [];
                    if (tagsCopy[index]) {
                      tagsCopy.splice(index, 1);
                    }
                    setValues({
                      ...values,
                      [name]: tagsCopy,
                    });
                  }}
                  disabled={disabled}
                  className="text-gray-400 disabled:opacity-50 hover:text-gray-500 transition-all pl-2 pr-3"
                >
                  <svg
                    className="w-4 fill-current"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
                  </svg>
                </button>
              )}
            </div>
          ))}
        </div>
      )}
      {(!maxTags || !(value && value.length >= maxTags)) &&
        (!fieldsToEdit || (fieldsToEdit && fieldsToEdit.includes(name))) &&
        !hideInput && (
          <input
            className={`sm:text-lg ${
              placeholder ? "" : "lowercase"
            } mt-3 w-full shadow-sm flex-1 px-3 py-2 rounded-md border-gray-200 border focus:border-indigo-500 focus:ring-indigo-500`}
            type={type === "email" ? type : "text"}
            id={name}
            value={inputValue}
            onChange={(e) => {
              const tempValue = e.target.value;
              setInputValue(tempValue);
              const tags = tempValue.split(",").map((adj) => adj.trim());
              if (tags.length > 1) {
                if (value) {
                  const newTags = [...tags].filter(
                    (item, pos, self) => self.indexOf(item) === pos
                  );
                  console.log(newTags);
                  setValues({
                    ...values,
                    [name]: maxTags
                      ? [...value, ...newTags.slice(0, maxTags)]
                      : newTags,
                  });
                } else {
                  setValues({
                    ...values,
                    [name]: maxTags ? tags.slice(0, maxTags) : tags,
                  });
                }
                setInputValue("");
              }
            }}
            onKeyDown={(event) => {
              if (
                event.key === "Enter" ||
                event.key === "," ||
                (type !== "email" && event.key === ".")
              ) {
                event.preventDefault();
                const tag = inputValue.trim();
                if (tag) {
                  if (value) {
                    if (value.includes(tag)) {
                      setInputValue("");
                    } else {
                      setValues({
                        ...values,
                        [name]: [...value, tag],
                      });
                    }
                  } else {
                    setValues({ ...values, [name]: [tag] });
                  }
                  setInputValue("");
                }
              } else if (event.key === "Backspace" && !inputValue) {
                event.preventDefault();
                const tagsCopy = value ? [...value] : [];
                tagsCopy.pop();

                setValues({ ...values, [name]: tagsCopy });
              }
            }}
            placeholder={
              placeholder || "space, neil degrasse, exploration, NASA"
            }
            disabled={
              disabled ||
              (maxTags && value && value.length >= maxTags) ||
              (fieldsToEdit && !fieldsToEdit.includes(name))
            }
            maxLength={200}
          />
        )}
    </div>
  );
}

export default TagsInput;
