import { useQuery } from "@apollo/client";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";

import { useNavigate, useParams } from "react-router";
import backgroundMusic from "../../music/backgroundMusic.mp3";
import usePageTracking from "../../util/usePageTracking";

import { customerClient, guestClient } from "../../GraphqlApolloClients";
import correctAnsSound from "../../sounds/answer-correct.mp3";

import Confetti from "react-confetti";

import { CustomerAuthContext } from "../../context/customerAuth";

import { useElements, useStripe } from "@stripe/react-stripe-js";
import BuySubscriptionForm from "../../components/creator/BuySubscriptionForm";
import GenericPaymentSuccessfulForm from "../../components/creator/GenericPaymentSuccessfulForm";
import PowerUpPaymentSuccessfulForm from "../../components/creator/PowerUpPaymentSuccessfulForm";
import PurchasePowerUpForm from "../../components/creator/PurchasePowerUpForm";
import {
  defaultPicUrl,
  generateGreetings,
  getNumOfPlayIconsToShow,
  useForm,
  useWindowSize,
} from "../../util/hooks";

import BuyCoinsForm from "../../components/creator/BuyCoinsForm";
import CreateBattleModal from "../../components/creator/CreateBattleModal";
import TNReviewForm from "../../components/creator/TNReviewForm";
import { GuestAuthContext } from "../../context/guestAuth";

import useSound from "use-sound";
import CodeForm from "../../components/creator/CodeForm";
import CommentsModal from "../../components/creator/CommentsModal";
import CountdownTimer from "../../components/creator/CountdownTimer";
import EmptyFocusQuestionBox from "../../components/creator/EmptyFocusQuestionBox";
import FocusQuestionBox from "../../components/creator/FocusQuestionBox";
import HorAdOverlayBox from "../../components/creator/HorAdOverlayBox";
import LiveStreamGradBack from "../../components/creator/LiveStreamGradBack";
import MiniNavBar from "../../components/creator/MiniNavBar";
import MobileFooter from "../../components/creator/MobileFooter";
import MultiTopicBattleForm from "../../components/creator/MultiTopicBattleForm";
import QuestionPlayIcon from "../../components/creator/QuestionPlayIcon";
import QuestionWideCommentSection from "../../components/creator/QuestionWideCommentSection";
import RefreshQuestionsBox from "../../components/creator/RefreshQuestionsBox";
import TopBar from "../../components/creator/TopBar";

import {
  FETCH_AD_BOOKMARK_BY_CUSTOMER,
  FETCH_ARE_FRIEND_REQUESTS_AVAILABLE,
  FETCH_CUR_TRIVIA_NIGHT,
  FETCH_CUSTOMER,
  FETCH_GUEST_CUSTOMER,
  FETCH_LIVES_COUNT_BY_CUSTOMER,
  FETCH_ORG_BY_NAME_FOR_URL,
  FETCH_PLAYER_TOPIC_STATS,
  FETCH_RECOMMENDED_QUESTIONS_BY_TOPIC,
  FETCH_RELEVANT_AD,
  FETCH_RELEVANT_PRODUCT_ADS,
  FETCH_SHOULD_ASK_FOR_REVIEW,
  FETCH_TOPIC_BY_SHORT_FORM,
} from "../../util/gqlFunctions";

function Focus(props) {
  usePageTracking();

  let navigate = useNavigate();

  const { values, setValues } = useForm(() => {}, {
    country: "",

    inviteCode: "",
  });

  const { customer, logoutCustomer: logout } = useContext(CustomerAuthContext);
  const { loginGuest: loginGuestContext, guest } = useContext(GuestAuthContext);
  const { topicShortForm, questionId, orgNameForUrl } = useParams();

  // TODO
  const [lastOrgNameForUrl, setLastOrgNameForUrl] = useState(null);

  const [lastTopicShortForm, setLastTopicShortForm] = useState(null);
  const size = useWindowSize();

  const isChubby = false;

  const [skippedQuestionIds, setSkippedQuestionIds] = useState([]);

  const {
    data: { getTopicByShortForm: mainTopic } = {},
    loading: loadingMainTopic,
  } = useQuery(FETCH_TOPIC_BY_SHORT_FORM, {
    variables: { shortForm: topicShortForm || "" },
    onError: (err) => {
      console.log(err);
    },
  });

  const {
    data: { getOrgByNameForUrl: mainOrg } = {},
    loading: loadingMainOrg,
  } = useQuery(FETCH_ORG_BY_NAME_FOR_URL, {
    variables: { nameForUrl: orgNameForUrl || "" },
    onError: (err) => {
      console.log(err);
    },
  });

  useEffect(() => {
    if (
      topicShortForm &&
      topicShortForm !== "all" &&
      (!orgNameForUrl || (orgNameForUrl && !mainOrg)) &&
      !mainTopic &&
      !loadingMainTopic &&
      !loadingMainOrg
    ) {
      navigate("/focus");
    }
  }, [
    topicShortForm,
    mainTopic,
    loadingMainTopic,
    mainOrg,
    orgNameForUrl,
    loadingMainOrg,
    navigate,
  ]);

  const [loadingRecommendedQs, setLoadingRecommendedQs] = useState(false);
  const [lastIncorrectQIndex, setLastIncorrectQIndex] = useState(null);
  const [subPopupReason, setSubPopupReason] = useState(null);
  const [initialClickedQId, setInitialClickedQId] = useState(
    questionId || null
  );
  const {
    data: { getCustomer: targetCustomer } = {},
    loading: loadingCustomerInfo,
    refetch: refetchCustomer,
  } = useQuery(FETCH_CUSTOMER, {
    client: customerClient,
    // fetchPolicy: "network-only",
  });
  const [wantToScrollToQId, setWantToScrollToQId] = useState(null);
  const [questionIdToScrollIntoView, setQuestionIdToScrollIntoView] =
    useState(null);

  const {
    data: { getRecommendedQuestions: recommendedQuestions } = {},
    refetch: refetchRecommendedQuestions,
    loading: loadingRecommendedQuestions,
  } = useQuery(FETCH_RECOMMENDED_QUESTIONS_BY_TOPIC, {
    variables: {
      topicShortForm:
        !orgNameForUrl && topicShortForm
          ? topicShortForm !== "all"
            ? topicShortForm
            : null
          : null,
      orgNameForUrl: orgNameForUrl || null,
      pageSize: getNumOfPlayIconsToShow(size) + 1,
      seenIds: [],
      clickedQuestionId: initialClickedQId,
    },
    client: customer ? customerClient : guestClient,
    // notifyOnNetworkStatusChange: true,
    // fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setLastIncorrectQIndex(null);
      if (wantToScrollToQId) {
        setQuestionIdToScrollIntoView(wantToScrollToQId);
      }
      setLoadingRecommendedQs(false);
    },
    // errorPolicy:"none",
    onError: (err) => {
      console.log(err);
      console.log("err");
      setLoadingRecommendedQs(false);

      if (err.graphQLErrors[0]) {
        if (
          err.graphQLErrors[0].extensions.errors &&
          err.graphQLErrors[0].extensions.errors.subErr
        ) {
          console.log("err");
          setSubPopupReason(err.graphQLErrors[0].extensions.errors.subErr);
        }

        if (
          err.graphQLErrors[0].extensions.errors &&
          err.graphQLErrors[0].extensions.errors.qFetchErr
        ) {
          setQuestionFetchErr(err.graphQLErrors[0].extensions.errors.qFetchErr);
          setQFetchErrShownAtQId(questionIdInView);
          setIsQFetchErrShown(true);
        }
      }

      if (targetCustomer) {
        toggleBuySubscriptionForm(0);
      } else {
        navigate("/register");
      }
    },
  });

  // console.log(recommendedQuestions);

  useEffect(() => {
    if (
      targetCustomer &&
      (!targetCustomer.curRegistrationStep ||
        targetCustomer.curRegistrationStep < 3)
    ) {
      navigate("/completeRegistration/" + targetCustomer.curRegistrationStep);
    }
  }, [targetCustomer, navigate]);

  useEffect(() => {
    if (topicShortForm !== lastTopicShortForm) {
      setLastTopicShortForm(topicShortForm);
    }
  }, [topicShortForm, lastTopicShortForm]);

  useEffect(() => {
    if (orgNameForUrl !== lastOrgNameForUrl) {
      setLastOrgNameForUrl(orgNameForUrl);
    }
  }, [orgNameForUrl, lastOrgNameForUrl]);

  useEffect(() => {
    if (!loadingMainTopic) {
      if (mainTopic) {
        document.title = "Focus - " + mainTopic.name + " | TKnight";
      } else {
        document.title = "Focus | TKnight";
      }
    }
  }, [mainTopic, loadingMainTopic]);

  const [finishedQuestionViewInFocus, setFinishedQuestionViewInFocus] =
    useState(null);
  const [selectedAnswerInFocus, setSelectedAnswerInFocus] = useState(null);

  const [isPlayingBGMusic, setIsBGMusicPlaying] = useState(false);
  const audioRef = useRef(null);

  useEffect(() => {
    const audio = audioRef.current;
    if (isPlayingBGMusic) {
      audio.play();
    } else {
      audio.pause();
    }
  }, [isPlayingBGMusic]);
  useEffect(() => {
    audioRef.current.volume = 0.4;
  }, []);

  useEffect(() => {
    if (targetCustomer && targetCustomer.username === "humpty") {
      setIsBGMusicPlaying(false);
    }
  }, [targetCustomer]);
  const [shouldOpenQuiz, setShouldOpenQuiz] = useState(false);
  const [shouldOpenQuestions, setShouldOpenQuestions] = useState(false);
  const [finishedSettingUpQuiz, setFinishedSettingUpQuiz] = useState(false);

  useEffect(() => {
    if (
      !finishedSettingUpQuiz &&
      !shouldOpenQuestions &&
      recommendedQuestions &&
      recommendedQuestions.length > 0
    ) {
      setInitialClickedQId(null);
      setShouldOpenQuestions(true);
      setFinishedSettingUpQuiz(true);
    }
  }, [
    finishedSettingUpQuiz,
    recommendedQuestions,
    setShouldOpenQuestions,
    shouldOpenQuestions,
    initialClickedQId,
    topicShortForm,
  ]);

  const [checkedIsCustomerLoggedIn, setCheckedIsCustomerLoggedIn] =
    useState(false);

  useEffect(() => {
    if (
      (customer &&
        !loadingCustomerInfo &&
        !targetCustomer &&
        !checkedIsCustomerLoggedIn) ||
      (targetCustomer && targetCustomer.isBlocked)
    ) {
      logout();
      setCheckedIsCustomerLoggedIn(true);
    }
  }, [
    customer,
    loadingCustomerInfo,
    targetCustomer,
    logout,
    checkedIsCustomerLoggedIn,
  ]);

  const resetInterval = 1000;

  const [isInviteCopied, setIsInviteCopied] = useState(null);
  const [isSharingInviteToApps, setIsSharingInviteToApps] = useState(false);

  useEffect(() => {
    let timeout;
    if (isInviteCopied && resetInterval) {
      timeout = setTimeout(() => setIsInviteCopied(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isInviteCopied, resetInterval]);

  const [questionFetchErr, setQuestionFetchErr] = useState(false);
  const [qFetchErrShownAtQId, setQFetchErrShownAtQId] = useState(null);
  const [isQFetchErrShown, setIsQFetchErrShown] = useState(false);

  useEffect(() => {
    let timeout;
    if (questionFetchErr && qFetchErrShownAtQId && isQFetchErrShown) {
      timeout = setTimeout(() => setIsQFetchErrShown(false), 4000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [questionFetchErr, resetInterval, isQFetchErrShown, qFetchErrShownAtQId]);

  const [seenIds, setSeenIds] = useState([]);
  const stripe = useStripe();
  const elements = useElements();

  const [questionIdInView, setQuestionIdInView] = useState(null);

  const inviteFriends = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    var textToShare =
      "Bet you can't beat my " +
      (mainTopic ? mainTopic.name : "focus") +
      " streak on #TKnight\n\n";

    textToShare +=
      "tknight.live/focus/" + (mainTopic ? mainTopic.shortForm : "");

    if (navigator.share) {
      navigator
        .share({
          title: "Play TKnight!",
          text: textToShare,
          url: "http://tknight.live/",
        })
        .then(() => {
          setIsInviteCopied(true);

          setIsSharingInviteToApps(true);
        })
        .catch((error) => {
          setIsSharingInviteToApps(false);
          navigator.clipboard.writeText(textToShare);

          setIsInviteCopied(true);
        });
    } else {
      navigator.clipboard.writeText(textToShare);
      setIsInviteCopied(true);
    }
  };

  const { data: { getLivesCountByCustomer: livesCount } = {} } = useQuery(
    FETCH_LIVES_COUNT_BY_CUSTOMER,
    {
      client: customerClient,
    }
  );

  const {
    data: { getShouldAskForReview: shouldAskForReview } = {},

    refetch: refetchShouldAskForReview,
  } = useQuery(FETCH_SHOULD_ASK_FOR_REVIEW, {
    client: customer ? customerClient : guestClient,
    variables: { askingFromLoc: 0, siteId: 0 },
  });

  const [isCaptionCopied, setIsCaptionCopied] = useState(null);
  const [isSharingToApps, setIsSharingToApps] = useState(false);
  const [playerTopicStatsInView, setPlayerTopicStatsInView] = useState(null);
  const [isFirstTime, setIsFirstTime] = useState(true);

  const [shouldShowMiniNavBar, setShouldShowMiniNavBar] = useState(false);

  const [shouldShowCommentSection, setShouldShowCommentSection] =
    useState(false);

  useEffect(() => {
    let timeout;
    if (isCaptionCopied && resetInterval) {
      timeout = setTimeout(() => setIsCaptionCopied(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isCaptionCopied, resetInterval]);

  const { data: { getPlayerTopicStatsByTopic: playerTopicStats } = {} } =
    useQuery(FETCH_PLAYER_TOPIC_STATS, {
      client: customer ? customerClient : guestClient,
      variables: {
        topicId: mainTopic ? mainTopic.id : "",
      },
    });

  const { data: { getRelevantAd: regularHorAd } = {} } = useQuery(
    FETCH_RELEVANT_AD,
    {
      client: customer ? customerClient : guestClient,
      variables: { format: 6, siteId: 0 },
    }
  );

  const { data: { getRelevantAd: regularVerAd } = {} } = useQuery(
    FETCH_RELEVANT_AD,
    {
      client: customer ? customerClient : guestClient,
      variables: { format: 7, siteId: 0 },
    }
  );

  const { data: { getAdBookmarkByCustomer: regularHorAdBookmark } = {} } =
    useQuery(FETCH_AD_BOOKMARK_BY_CUSTOMER, {
      client: customer ? customerClient : guestClient,

      variables: { adId: regularHorAd ? regularHorAd.id : "" },
    });
  const { data: { getAdBookmarkByCustomer: regularVerAdBookmark } = {} } =
    useQuery(FETCH_AD_BOOKMARK_BY_CUSTOMER, {
      client: customer ? customerClient : guestClient,

      variables: {
        adId: regularVerAd ? regularVerAd.id : "",
      },
    });

  const { data: { getAdBookmarkByCustomer: targetAdBookmark } = {} } = useQuery(
    FETCH_AD_BOOKMARK_BY_CUSTOMER,
    {
      client: customer ? customerClient : guestClient,

      variables: { adId: regularHorAd ? regularHorAd.id : "" },
    }
  );

  const { data: { getRelevantAd: narrowHorAd } = {} } = useQuery(
    FETCH_RELEVANT_AD,
    {
      client: customer ? customerClient : guestClient,
      variables: { format: 0, siteId: 0 },
    }
  );

  const { data: { getAdBookmarkByCustomer: narrowAdBookmark } = {} } = useQuery(
    FETCH_AD_BOOKMARK_BY_CUSTOMER,
    {
      client: customer ? customerClient : guestClient,
      variables: {
        adId: narrowHorAd ? narrowHorAd.id : "",
      },
    }
  );

  const [lastAnsweredQ, setLastAnsweredQ] = useState(null);
  const [shouldPlaySoundEffects, setShouldPlaySoundEffects] = useState(false);
  const [playCorrectAnswerSound] = useSound(correctAnsSound, { volume: 0.2 });
  // const [playWrongAnswerSound] = useSound(wrongAnsSound, { volume: 0.4 });

  const { data: { getRelevantProductAds: productAds } = {} } = useQuery(
    FETCH_RELEVANT_PRODUCT_ADS,
    {
      client: customer ? customerClient : guestClient,
    }
  );

  const [shouldOpenProInviteCodeForm, setShouldOpenProInviteCodeForm] =
    useState(false);
  const [powerUpToPurchase, setPowerUpToPurchase] = useState(null);
  const [shouldOpenPaymentSuccessfulForm, setShouldOpenPaymentSuccessfulForm] =
    useState(false);
  const [shouldOpenJoinedProPlanPopup, setShouldOpenJoinedProPlanPopup] =
    useState(false);
  const [subscriptionType, setSubscriptionType] = useState(null);
  const [shouldOpenMultiTopicBattleForm, setShouldOpenMultiTopicBattleForm] =
    useState(false);
  const [
    shouldOpenSubPaymentSuccessfulForm,
    setShouldOpenSubPaymentSuccessfulForm,
  ] = useState(false);
  const [shouldOpenReviewForm, setShouldOpenReviewForm] = useState(false);

  const [shouldOpenReviewSentForm, setShouldOpenReviewSentForm] =
    useState(false);
  const [shouldOpenBuyCoinsForm, setShouldOpenBuyCoinsForm] = useState(false);
  const [
    shouldOpenCoinPurchaseSuccessfulForm,
    setShouldOpenCoinPurchaseSuccessfulForm,
  ] = useState(false);
  const [shouldOpenAdPopup, setShouldOpenAdPopup] = useState(false);

  const [shouldShowBattleLeaderboard, setShouldShowBattleLeaderboard] =
    useState(false);

  const togglePurchasePowerUpForm = (powerUpToPurchase) => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldOpenAdPopup(false);
    setShouldShowMiniNavBar(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenProInviteCodeForm(false);
    setShouldOpenMultiTopicBattleForm(false);
    setTargetTopic(null);

    setPowerUpToPurchase(powerUpToPurchase);
  };

  const toggleProInviteCodeForm = () => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldOpenAdPopup(false);
    setShouldShowMiniNavBar(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenMultiTopicBattleForm(false);
    setTargetTopic(null);

    setShouldOpenProInviteCodeForm(!shouldOpenProInviteCodeForm);
  };

  const toggleJoinedProPlanPopup = () => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldOpenAdPopup(false);
    setShouldShowMiniNavBar(false);
    setShouldShowBattleLeaderboard(false);
    setPowerUpToPurchase(null);
    setShouldOpenProInviteCodeForm(false);
    setShouldOpenMultiTopicBattleForm(false);
    setTargetTopic(null);

    setShouldOpenJoinedProPlanPopup(!shouldOpenJoinedProPlanPopup);
  };

  const toggleAdPopup = (shouldOpen) => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setPowerUpToPurchase(null);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldShowMiniNavBar(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenProInviteCodeForm(false);
    setShouldOpenMultiTopicBattleForm(false);
    setTargetTopic(null);

    setShouldOpenAdPopup(shouldOpen);
  };
  // console.log("hi");
  useEffect(() => {
    let timeout;
    if (shouldOpenAdPopup) {
      timeout = setTimeout(() => {
        if (shouldOpenAdPopup) {
          toggleAdPopup(false);
        }
      }, 20000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [shouldOpenAdPopup]);

  const toggleBuyCoinsForm = () => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setPowerUpToPurchase(null);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenAdPopup(false);
    setShouldShowMiniNavBar(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenProInviteCodeForm(false);
    setShouldOpenMultiTopicBattleForm(false);
    setTargetTopic(null);

    setShouldOpenBuyCoinsForm(!shouldOpenBuyCoinsForm);
  };

  const toggleCoinPurchaseSuccessfulForm = () => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setPowerUpToPurchase(null);

    setShouldOpenBuyCoinsForm(false);
    setShouldOpenAdPopup(false);
    setShouldShowMiniNavBar(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenProInviteCodeForm(false);
    setShouldOpenMultiTopicBattleForm(false);
    setTargetTopic(null);

    setShouldOpenCoinPurchaseSuccessfulForm(
      !shouldOpenCoinPurchaseSuccessfulForm
    );
  };

  const togglePaymentSuccessfulForm = () => {
    setPowerUpToPurchase(null);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldOpenAdPopup(false);
    setShouldShowMiniNavBar(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenProInviteCodeForm(false);
    setShouldOpenMultiTopicBattleForm(false);
    setTargetTopic(null);

    console.log(shouldShowBattleLeaderboard);
    console.log(targetAdBookmark);
    setShouldOpenPaymentSuccessfulForm(!shouldOpenPaymentSuccessfulForm);
  };
  const [viewedReviewForm, setViewedReviewForm] = useState(false);

  const toggleBuySubscriptionForm = useCallback(
    (subscriptionType) => {
      setShouldOpenSubPaymentSuccessfulForm(false);
      setPowerUpToPurchase(null);
      setShouldOpenPaymentSuccessfulForm(false);
      setShouldOpenReviewSentForm(false);
      setShouldOpenCoinPurchaseSuccessfulForm(false);
      setShouldOpenBuyCoinsForm(false);
      setShouldOpenAdPopup(false);
      setShouldShowMiniNavBar(false);
      setShouldShowBattleLeaderboard(false);
      setShouldOpenProInviteCodeForm(false);
      setShouldOpenMultiTopicBattleForm(false);
      setTargetTopic(null);

      setSubscriptionType(subscriptionType);

      if (
        subscriptionType === null &&
        subPopupReason ===
          "You've reached 6 topic focus questions per hour on TKnight Free."
      ) {
        navigate(-1);
      }

      if (
        subscriptionType === null &&
        !viewedReviewForm &&
        shouldAskForReview
      ) {
        setShouldOpenReviewForm(true);
      } else {
        setShouldOpenReviewForm(false);
      }
    },
    [viewedReviewForm, shouldAskForReview, subPopupReason, navigate]
  );
  const location = useLocation();

  const toggleSubPaymentSuccessfulForm = () => {
    setSubscriptionType(null);
    setPowerUpToPurchase(null);
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldOpenAdPopup(false);
    setShouldShowMiniNavBar(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenProInviteCodeForm(false);
    setShouldOpenMultiTopicBattleForm(false);
    setTargetTopic(null);

    setShouldOpenSubPaymentSuccessfulForm(!shouldOpenSubPaymentSuccessfulForm);
  };

  const toggleReviewForm = useCallback(() => {
    setShouldOpenSubPaymentSuccessfulForm(false);
    setPowerUpToPurchase(null);
    setShouldOpenPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldOpenAdPopup(false);
    setShouldShowMiniNavBar(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenProInviteCodeForm(false);
    setTargetTopic(null);

    setShouldOpenReviewForm(!shouldOpenReviewForm);
  }, [shouldOpenReviewForm]);

  const toggleReviewSentForm = () => {
    setShouldOpenSubPaymentSuccessfulForm(false);
    setPowerUpToPurchase(null);
    setShouldOpenPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldOpenAdPopup(false);
    setShouldShowMiniNavBar(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenProInviteCodeForm(false);
    setShouldOpenMultiTopicBattleForm(false);
    setTargetTopic(null);

    setShouldOpenReviewSentForm(!shouldOpenReviewSentForm);
  };

  const toggleShouldShowCommentSection = () => {
    setShouldOpenSubPaymentSuccessfulForm(false);
    setPowerUpToPurchase(null);
    setShouldOpenPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldOpenAdPopup(false);
    setShouldShowMiniNavBar(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenProInviteCodeForm(false);
    setShouldOpenMultiTopicBattleForm(false);
    setTargetTopic(null);

    setShouldShowCommentSection(!shouldShowCommentSection);
  };

  const toggleMultiTopicBattleForm = () => {
    setShouldOpenSubPaymentSuccessfulForm(false);
    setPowerUpToPurchase(null);
    setShouldOpenPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldOpenAdPopup(false);
    setShouldShowMiniNavBar(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenProInviteCodeForm(false);
    setShouldShowCommentSection(false);
    setTargetTopic(null);

    setShouldOpenMultiTopicBattleForm(!shouldOpenMultiTopicBattleForm);
  };

  const toggleOneTopicBattleForm = (tempTargetTopic) => {
    setShouldOpenSubPaymentSuccessfulForm(false);
    setPowerUpToPurchase(null);
    setShouldOpenPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldOpenAdPopup(false);
    setShouldShowMiniNavBar(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenProInviteCodeForm(false);
    setShouldShowCommentSection(false);
    setShouldOpenMultiTopicBattleForm(false);

    setTargetTopic(targetTopic ? null : mainTopic);
  };

  const { data: { getCurTriviaNight: triviaNight } = {} } = useQuery(
    FETCH_CUR_TRIVIA_NIGHT,
    {
      client: customerClient,
      pollInterval: 30000,
    }
  );

  const {
    data: { getGuestCustomer: targetGuestCustomer } = {},
    // loading: loadingGuestCustomer,
    refetch: refetchGuestCustomer,
  } = useQuery(FETCH_GUEST_CUSTOMER, {
    client: guestClient,
    // fetchPolicy: "network-only",
  });

  const [indexVisible, setIndexVisible] = useState(0);

  const [targetTopic, setTargetTopic] = useState(null);

  const [initializedMuteVals, setInitializedMuteVals] = useState(false);
  useEffect(() => {
    if (!initializedMuteVals && (targetCustomer || targetGuestCustomer)) {
      setShouldPlaySoundEffects(
        (targetCustomer || targetGuestCustomer).areSoundEffectsMuted === true
          ? false
          : true
      );
      setInitializedMuteVals(true);
    }
  }, [
    targetGuestCustomer,
    shouldPlaySoundEffects,
    initializedMuteVals,
    targetCustomer,
  ]);
  const { data: { areFriendRequestsAvailable } = {} } = useQuery(
    FETCH_ARE_FRIEND_REQUESTS_AVAILABLE,
    {
      client: customer ? customerClient : guestClient,
    }
  );

  const [finishedLoading, setFinishedLoading] = useState(false);

  useEffect(() => {
    if (!finishedLoading) {
      if (
        !loadingCustomerInfo &&
        !loadingMainTopic &&
        !loadingRecommendedQuestions &&
        !loadingRecommendedQs
      ) {
        setFinishedLoading(true);
      }
    }
  }, [
    finishedLoading,
    loadingCustomerInfo,
    loadingMainTopic,
    loadingRecommendedQuestions,
    loadingRecommendedQs,
  ]);

  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  useEffect(() => {
    if (
      !shouldOpenAdPopup &&
      finishedQuestionViewInFocus &&
      finishedQuestionViewInFocus.status === 1 &&
      selectedAnswerInFocus &&
      finishedQuestionViewInFocus.questionId ===
        selectedAnswerInFocus.questionId &&
      selectedAnswerInFocus.questionId !== lastAnsweredQ
    ) {
      if (selectedAnswerInFocus.isCorrect) {
        setLastAnsweredQ(selectedAnswerInFocus.questionId);
        if (!answeredQuestions.includes(selectedAnswerInFocus.questionId)) {
          if (shouldPlaySoundEffects) {
            playCorrectAnswerSound();
          }

          setAnsweredQuestions([
            ...answeredQuestions,
            selectedAnswerInFocus.questionId,
          ]);
        }
      } else {
        setLastAnsweredQ(selectedAnswerInFocus.questionId);
      }
    }
  }, [
    shouldPlaySoundEffects,
    selectedAnswerInFocus,
    finishedQuestionViewInFocus,
    // playWrongAnswerSound,
    shouldOpenAdPopup,
    lastAnsweredQ,
    playCorrectAnswerSound,
    answeredQuestions,
  ]);
  // console.log(getNumOfPlayIconsToShow(size) + 1);
  const [lastQuestionId, setLastQuestionId] = useState(null);
  useEffect(() => {
    async function fetchData() {
      if (
        recommendedQuestions &&
        recommendedQuestions.length > 0 &&
        questionIdInView ===
          recommendedQuestions[recommendedQuestions.length - 1].id &&
        recommendedQuestions[recommendedQuestions.length - 1].id !==
          lastQuestionId &&
        !loadingRecommendedQs &&
        finishedQuestionViewInFocus &&
        finishedQuestionViewInFocus.status === 1 &&
        finishedQuestionViewInFocus.questionId === questionIdInView
      ) {
        setLoadingRecommendedQs(true);

        const result = await refetchRecommendedQuestions({
          topicShortForm: topicShortForm
            ? topicShortForm !== "all"
              ? topicShortForm
              : null
            : null,
          pageSize: getNumOfPlayIconsToShow(size) + 1,
          seenIds,
          clickedQuestionId: questionIdInView,
        });

        if (result && result.data) {
          setLoadingRecommendedQs(false);
          setLastIncorrectQIndex(null);
          if (
            result.data.getRecommendedQuestions &&
            result.data.getRecommendedQuestions.length > 0
          ) {
            setLastQuestionId(questionIdInView);
            setQuestionIdToScrollIntoView(questionIdInView);
          } else {
            setLastQuestionId(null);
            setQuestionIdToScrollIntoView(null);
          }
        }
      }
    }

    fetchData();
  }, [
    recommendedQuestions,
    loadingRecommendedQs,
    refetchRecommendedQuestions,
    lastQuestionId,
    questionIdInView,
    topicShortForm,
    lastAnsweredQ,
    finishedQuestionViewInFocus,
    seenIds,
    size,
  ]);

  useEffect(() => {
    if (
      recommendedQuestions &&
      recommendedQuestions.length > 0 &&
      questionIdInView &&
      !seenIds.includes(questionIdInView) &&
      finishedSettingUpQuiz &&
      shouldOpenQuestions
    ) {
      setSeenIds([questionIdInView, ...seenIds]);
      // const rQIds = recommendedQuestions.map((rQ) => rQ.id);
      // if (!viewedANewQuestion && rQIds.indexOf(questionIdInView)) {
      //   setViewedANewQuestion(true);
      // } else {
      //   setViewedANewQuestion(false);
      // }
    }
  }, [
    questionIdInView,
    recommendedQuestions,
    seenIds,
    finishedSettingUpQuiz,
    shouldOpenQuestions,
  ]);

  useEffect(() => {
    if (
      indexVisible >= 0 &&
      recommendedQuestions &&
      recommendedQuestions.length > 1 &&
      recommendedQuestions[indexVisible] &&
      recommendedQuestions[indexVisible].id !== questionIdInView &&
      !questionIdToScrollIntoView &&
      !loadingRecommendedQs &&
      !loadingRecommendedQuestions
    ) {
      setQuestionIdInView(recommendedQuestions[indexVisible].id);
    }
  }, [
    questionIdInView,
    setQuestionIdInView,
    loadingRecommendedQs,
    questionIdToScrollIntoView,
    recommendedQuestions,
    indexVisible,
    loadingRecommendedQuestions,
  ]);

  // useEffect(() => {
  //   if (
  //     recommendedQuestions &&
  //     recommendedQuestions.length > 0 &&
  //     !loadingRecommendedQuestions &&
  //     loadingRecommendedQs &&
  //     !questionIdToScrollIntoView &&
  //     !wantToScrollToQId
  //   ) {
  //     setQuestionIdToScrollIntoView(recommendedQuestions[0].id);
  //   }
  // }, [
  //   loadingRecommendedQs,
  //   loadingRecommendedQuestions,
  //   recommendedQuestions,
  //   seenIds,
  //   questionIdToScrollIntoView,
  //   wantToScrollToQId,
  // ]);

  const [timeZone, setTimeZone] = useState("");

  // Get the user's time zone on component mount
  useEffect(() => {
    if (!timeZone) {
      const date = new Date();
      let timezone = date.toString().match(/([A-Z]+[+-][0-9]+.*)/)[1];
      timezone = timezone.split(" (")[1].split(")")[0];
      setTimeZone(timezone);
    }
  }, [timeZone]);

  return (
    <div
      className={`${
        ((size.width >= 1024 && regularHorAd) ||
          (size.width < 1024 && regularVerAd)) &&
        finishedQuestionViewInFocus &&
        finishedQuestionViewInFocus.status === 1 &&
        selectedAnswerInFocus &&
        selectedAnswerInFocus.isCorrect &&
        shouldOpenAdPopup
          ? "pointer-events-none"
          : ""
      } w-screen overflow-hidden relative h-full min-h-screen`}
      // style={{ marginTop: `-${getKeyboardHeight()}px` }}
    >
      <audio ref={audioRef} src={backgroundMusic} loop volume={0.1} />
      {/* {!loadingMainTopic ? ( */}
      <LiveStreamGradBack gradIndex={mainTopic ? mainTopic.accentColor : 1} />
      {/* ) : (
        <></>
      )} */}
      {shouldShowCommentSection && questionIdInView && size.width < 640 && (
        <CommentsModal
          questionId={questionIdInView}
          customer={customer}
          navigate={navigate}
          targetGuestCustomer={targetGuestCustomer}
          targetCustomer={targetCustomer}
          question={
            recommendedQuestions && recommendedQuestions.length > 0
              ? recommendedQuestions[indexVisible]
              : null
          }
          playerTopicStatsInView={playerTopicStatsInView}
          country={values.country}
          shouldShowCommentSection={shouldShowCommentSection}
          setShouldShowCommentSection={setShouldShowCommentSection}
        />
      )}
      {shouldShowMiniNavBar && targetCustomer && (
        <MiniNavBar
          targetCustomer={targetCustomer}
          isSharingInviteToApps={isSharingInviteToApps}
          isSharingToApps={isSharingToApps || isSharingInviteToApps}
          isShareCopied={isCaptionCopied || isInviteCopied}
          setIsSharingToApps={setIsSharingToApps}
          navigate={navigate}
          isInviteCopied={isInviteCopied}
          inviteFriends={inviteFriends}
          toggleReviewForm={toggleReviewForm}
          shouldShowMiniNavBar={shouldShowMiniNavBar}
          isFirstTime={isFirstTime}
          setIsFirstTime={setIsFirstTime}
          setShouldShowMiniNavBar={setShouldShowMiniNavBar}
          logout={logout}
          setShouldPlaySoundEffects={setShouldPlaySoundEffects}
          shouldPlaySoundEffects={shouldPlaySoundEffects}
          toggleBuySubscriptionForm={toggleBuySubscriptionForm}
        />
      )}

      {shouldOpenPaymentSuccessfulForm && targetCustomer && (
        <PowerUpPaymentSuccessfulForm
          togglePaymentSuccessfulForm={togglePaymentSuccessfulForm}
          targetCustomer={targetCustomer}
        />
      )}

      {powerUpToPurchase !== null && stripe && elements ? (
        <PurchasePowerUpForm
          stripe={stripe}
          elements={elements}
          targetCustomer={targetCustomer}
          targetGuestCustomer={targetGuestCustomer}
          togglePaymentSuccessfulForm={togglePaymentSuccessfulForm}
          powerUpToPurchase={powerUpToPurchase}
          quizId={null}
          // questionId={curQuestion ? curQuestion.id : null}
          togglePurchasePowerUpForm={togglePurchasePowerUpForm}
        />
      ) : (
        <></>
      )}

      {shouldOpenBuyCoinsForm && stripe && elements ? (
        <BuyCoinsForm
          stripe={stripe}
          elements={elements}
          targetCustomer={targetCustomer}
          targetGuestCustomer={targetGuestCustomer}
          toggleCoinPurchaseSuccessfulForm={toggleCoinPurchaseSuccessfulForm}
          shouldOpenBuyCoinsForm={shouldOpenBuyCoinsForm}
          quizId={null}
          toggleBuyCoinsForm={toggleBuyCoinsForm}
        />
      ) : (
        <></>
      )}

      {shouldOpenReviewForm && (targetCustomer || targetGuestCustomer) ? (
        <TNReviewForm
          shouldOpenReviewForm={shouldOpenReviewForm}
          toggleReviewForm={toggleReviewForm}
          targetCustomer={targetCustomer}
          toggleReviewSentForm={toggleReviewSentForm}
        />
      ) : (
        <></>
      )}

      {subscriptionType !== null && stripe && elements && targetCustomer ? (
        <BuySubscriptionForm
          stripe={stripe}
          elements={elements}
          targetCustomer={targetCustomer}
          togglePaymentSuccessfulForm={toggleSubPaymentSuccessfulForm}
          subscriptionType={subscriptionType}
          toggleBuySubscriptionForm={toggleBuySubscriptionForm}
          toggleProInviteCodeForm={toggleProInviteCodeForm}
          subPopupReason={subPopupReason}
        />
      ) : (
        <></>
      )}

      {targetTopic ? (
        <CreateBattleModal
          topic={targetTopic}
          navigate={navigate}
          toggleOneTopicBattleForm={toggleOneTopicBattleForm}
          shouldOpenQuiz={shouldOpenQuiz}
          toggleBuySubscriptionForm={toggleBuySubscriptionForm}
          setShouldOpenQuiz={setShouldOpenQuiz}
          targetCustomer={targetCustomer}
          targetGuest={targetGuestCustomer}
          lastQuizId={null}
          loginGuestContext={loginGuestContext}
          subPopupReason={subPopupReason}
          setSubPopupReason={setSubPopupReason}
        />
      ) : (
        <></>
      )}

      {shouldOpenMultiTopicBattleForm ? (
        <MultiTopicBattleForm
          topic={targetTopic}
          setTargetTopic={setTargetTopic}
          navigate={navigate}
          toggleMultiTopicBattleForm={toggleMultiTopicBattleForm}
          shouldOpenQuiz={shouldOpenQuiz}
          toggleBuySubscriptionForm={toggleBuySubscriptionForm}
          setShouldOpenQuiz={setShouldOpenQuiz}
          targetCustomer={targetCustomer}
          targetGuest={targetGuestCustomer}
          lastQuizId={null}
          loginGuestContext={loginGuestContext}
          subPopupReason={subPopupReason}
          setSubPopupReason={setSubPopupReason}
          initialTopicIds={
            recommendedQuestions &&
            recommendedQuestions.slice(0, 7).map((rQ) => rQ.topicId)
          }
        />
      ) : (
        <></>
      )}
      {shouldOpenProInviteCodeForm && targetCustomer && (
        <CodeForm
          title="TKnight VIP Code"
          name="inviteCode"
          isCodeBoxOpen={shouldOpenProInviteCodeForm}
          setIsCodeBoxOpen={setShouldOpenProInviteCodeForm}
          typeOfCode={0}
          values={values}
          setValues={setValues}
          toggleJoinedProPlanPopup={toggleJoinedProPlanPopup}
          toggleProInviteCodeForm={toggleProInviteCodeForm}
        />
      )}
      {shouldOpenJoinedProPlanPopup && targetCustomer && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleJoinedProPlanPopup}
            targetCustomer={targetCustomer}
            message={"Welcome to TKnight VIP, " + targetCustomer.name + "!"}
            buttonMsg={"Thanks!"}
            popUpTitle={
              "Joined " +
              (targetCustomer.subscriptionType === 0
                ? "Individual"
                : targetCustomer.subscriptionType === 1
                ? "Duo"
                : targetCustomer.subscriptionType === 2
                ? "Family"
                : "Club") +
              " Successfully!"
            }
          />
          <div className="absolute z-[90] pointer-events-none inset-0">
            <Confetti
              width={size.width}
              height={size.height}
              recycle={false}
              numberOfPieces={size.width >= 640 ? 1500 : 1000}
              tweenDuration={30 * 1000}
              gravity={0.3}
            />
          </div>
        </>
      )}
      {shouldOpenSubPaymentSuccessfulForm && targetCustomer && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleSubPaymentSuccessfulForm}
            targetCustomer={targetCustomer}
            message={"Welcome to TKnight VIP, " + targetCustomer.name + "."}
            buttonMsg={"Thanks!"}
          />
          <div className="absolute z-[90] pointer-events-none inset-0">
            <Confetti
              width={size.width}
              height={size.height}
              recycle={false}
              numberOfPieces={size.width >= 640 ? 1500 : 1000}
              tweenDuration={30 * 1000}
              gravity={0.3}
            />
          </div>
        </>
      )}

      {shouldOpenCoinPurchaseSuccessfulForm && targetCustomer && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleCoinPurchaseSuccessfulForm}
            targetCustomer={targetCustomer}
            message={
              "Tokens added to stash. Good luck, " + targetCustomer.name + "!"
            }
            buttonMsg={"Awesome!"}
            popUpTitle={"You're all set!"}
          />
        </>
      )}

      {shouldOpenReviewSentForm && targetCustomer && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleReviewSentForm}
            targetCustomer={targetCustomer}
            popUpTitle={"Thank you!"}
            message={"This will help improve the game :)"}
            buttonMsg={"Awesome!"}
          />
        </>
      )}

      <div
        className={`grow-0 h-screen z-10 flex flex-col items-center justify-start max-w-5xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto xl:px-0 pt-4 xshort:pt-2 sm:pt-6 2xl:pt-12`}
      >
        <TopBar
          topicShortForm={
            topicShortForm
              ? topicShortForm !== "all"
                ? topicShortForm
                : null
              : null
          }
          orgName={mainOrg?.name}
          orgLogoUrl={mainOrg?.logoUrl}
          orgId={mainOrg?.id}
          orgShortForm={mainOrg ? mainOrg.shortForm : null}
          navigate={navigate}
          toggleBuyCoinsForm={toggleBuyCoinsForm}
          targetGuestCustomer={targetGuestCustomer}
          targetCustomer={targetCustomer}
          setShouldShowMiniNavBar={setShouldShowMiniNavBar}
          setIsFirstTime={setIsFirstTime}
          customer={customer}
          areFriendRequestsAvailable={areFriendRequestsAvailable}
          toggleBuySubscriptionForm={toggleBuySubscriptionForm}
          toggleMultiTopicBattleForm={toggleMultiTopicBattleForm}
          toggleOneTopicBattleForm={toggleOneTopicBattleForm}
          topicId={mainTopic ? mainTopic.id : null}
          topicName={mainTopic?.name}
          topicLogoUrl={mainTopic?.logoUrl}
          page="Focus"
        />

        <div
          className={`flex z-10 w-full pt-4 sm:pt-6 short:pt-2 items-start justify-center flex-1 h-full`}
        >
          <div className={`relative overflow-visible mt-10 h-full`}>
            <button
              onClick={(e) => {
                navigate("/tkl", { state: { from: location } });
              }}
              className="shrink-0 cursor-pointer group lg:absolute bottom-0 flex-col flex items-center justify-end overflow-hidden h-full right-full mx-1 md:mx-2 top-0"
            >
              {triviaNight &&
              triviaNight.status > 0 &&
              triviaNight.status < 5 ? (
                <>
                  <span className="relative flex h-2 w-2 mb-2 group-hover:bg-opacity-20">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full  bg-yellow-400 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-2 w-2 bg-yellow-500"></span>
                  </span>
                  <p
                    className={`text-left leading-none group-hover:opacity-70 transition-all mb-2 sm:mb-3 text-sm sm:text-base 2xl:text-lg text-white block rotate-180 whitespace-nowrap`}
                    style={{ writingMode: "vertical-rl" }}
                  >
                    Good {generateGreetings(targetCustomer)}
                    {targetCustomer
                      ? ", " + targetCustomer.name.split(" ")[0]
                      : ""}{" "}
                    - <span className="text-yellow-300">TKnight is LIVE!</span>
                  </p>
                </>
              ) : (
                <p
                  className={`text-left leading-none group-hover:opacity-70 transition-all mb-2 sm:mb-3 text-sm sm:text-base 2xl:text-lg text-white block rotate-180 whitespace-nowrap`}
                  style={{ writingMode: "vertical-rl" }}
                >
                  Good {generateGreetings(targetCustomer)}
                  {targetCustomer
                    ? ", " + targetCustomer.name.split(" ")[0]
                    : ""}{" "}
                  - TKnight LIVE starts in{" "}
                  <CountdownTimer
                    endDate={
                      triviaNight
                        ? triviaNight.status === 0
                          ? triviaNight.releaseDate
                          : triviaNight.nextGameAt
                        : new Date()
                    }
                    hideSpace={true}
                  />
                </p>
              )}
              <div className="short:w-[1.25rem] w-[1.35rem] sm:w-7 2xl:w-10 aspect-square flex items-center justify-center border 2xl:border-2 border-white rounded-full overflow-hidden -rotate-90 transition-all shrink-0">
                <img
                  src={defaultPicUrl}
                  alt="Y"
                  className="object-cover group-hover:opacity-70 transition-all w-full h-full"
                />
              </div>
              <div
                className={`2xl:h-52 h-48 lg:h-42 short:h-40 xshort:h-36 bg-gradient-to-b from-white to-transparent w-[1px] 2xl:w-[2px]`}
              ></div>
            </button>
          </div>
          <div
            className={`w-full scrollbar-hide h-full xshort:pb-14 short:pb-24 pb-28 sm:pb-24 2xl:pb-28 xshort:space-y-5 short:space-y-12 space-y-16 sm:space-y-10 snap-mandatory snap-y rounded-t-2xl flex flex-col items-center justify-start overflow-y-auto`}
          >
            <RefreshQuestionsBox
              questionIdToScrollIntoView={questionIdToScrollIntoView}
              refetchRecommendedQuestions={refetchRecommendedQuestions}
              setLastIncorrectQIndex={setLastIncorrectQIndex}
              topicShortForm={
                topicShortForm
                  ? topicShortForm !== "all"
                    ? topicShortForm
                    : null
                  : null
              }
              pageSize={getNumOfPlayIconsToShow(size) + 1}
              seenIds={seenIds}
              setLoadingRecommendedQs={setLoadingRecommendedQs}
              loadingRecommendedQs={loadingRecommendedQs}
              setQuestionIdToScrollIntoView={setQuestionIdToScrollIntoView}
              setWantToScrollToQId={setWantToScrollToQId}
            />

            {recommendedQuestions && recommendedQuestions.length > 1 ? (
              recommendedQuestions.map((recommendedQuestion, index) =>
                !indexVisible || Math.abs(index - indexVisible) <= 1 ? (
                  <FocusQuestionBox
                    qFetchErrShownAtQId={qFetchErrShownAtQId}
                    setSubPopupReason={setSubPopupReason}
                    questionFetchErr={questionFetchErr}
                    setQuestionFetchErr={setQuestionFetchErr}
                    skippedQuestionIds={skippedQuestionIds}
                    setSkippedQuestionIds={setSkippedQuestionIds}
                    timeZone={timeZone}
                    guest={guest}
                    customer={customer}
                    isQFetchErrShown={isQFetchErrShown}
                    loginGuest={loginGuestContext}
                    setLoadingRecommendedQs={setLoadingRecommendedQs}
                    refetchShouldAskForReview={refetchShouldAskForReview}
                    togglePurchasePowerUpForm={togglePurchasePowerUpForm}
                    key={recommendedQuestion.id + index}
                    question={recommendedQuestion}
                    shouldShowCommentSection={shouldShowCommentSection}
                    targetCustomer={targetCustomer}
                    targetGuestCustomer={targetGuestCustomer}
                    shouldPlaySoundEffects={shouldPlaySoundEffects}
                    country={values.country}
                    shouldAskForReview={shouldAskForReview}
                    viewedReviewForm={viewedReviewForm}
                    toggleReviewForm={toggleReviewForm}
                    setViewedReviewForm={setViewedReviewForm}
                    lastIncorrectQIndex={lastIncorrectQIndex}
                    setLastIncorrectQIndex={setLastIncorrectQIndex}
                    questionIdInView={questionIdInView}
                    setQuestionIdInView={setQuestionIdInView}
                    playerTopicStatsInView={playerTopicStats}
                    setPlayerTopicStatsInView={setPlayerTopicStatsInView}
                    questionIdToScrollIntoView={questionIdToScrollIntoView}
                    setQuestionIdToScrollIntoView={
                      setQuestionIdToScrollIntoView
                    }
                    toggleShouldShowCommentSection={
                      toggleShouldShowCommentSection
                    }
                    indexVisible={indexVisible}
                    setIndexVisible={setIndexVisible}
                    loadingRecommendedQs={loadingRecommendedQs}
                    index={index}
                    finishedQuestionViewInFocus={finishedQuestionViewInFocus}
                    selectedAnswerInFocus={selectedAnswerInFocus}
                    setSelectedAnswerInFocus={setSelectedAnswerInFocus}
                    setFinishedQuestionViewInFocus={
                      setFinishedQuestionViewInFocus
                    }
                    refetchCustomer={refetchCustomer}
                    refetchGuestCustomer={refetchGuestCustomer}
                    shouldOpenAdPopup={
                      ((size.width >= 1024 && regularHorAd) ||
                        (size.width < 1024 && regularVerAd)) &&
                      shouldOpenAdPopup
                    }
                    setShouldOpenAdPopup={setShouldOpenAdPopup}
                    topicShortForm={
                      topicShortForm
                        ? topicShortForm !== "all"
                          ? topicShortForm
                          : null
                        : null
                    }
                    orgNameForUrl={orgNameForUrl}
                    isChubby={isChubby}
                    toggleBuySubscriptionForm={toggleBuySubscriptionForm}
                  />
                ) : (
                  <EmptyFocusQuestionBox
                    key={recommendedQuestion.id + index}
                    loadingRecommendedQs={loadingRecommendedQs}
                    index={index}
                    question={recommendedQuestion}
                    questionIdToScrollIntoView={questionIdToScrollIntoView}
                    setIndexVisible={setIndexVisible}
                    indexVisible={indexVisible}
                  />
                )
              )
            ) : recommendedQuestions && recommendedQuestions.length <= 1 ? (
              <>
                <EmptyFocusQuestionBox
                  title="WOW"
                  caption="Thank you for playing 🙏 🙏"
                  topicName={mainTopic ? mainTopic.name : ""}
                />

                <EmptyFocusQuestionBox
                  title="WOW"
                  caption="You've reached the end 🥹"
                  topicName={mainTopic ? mainTopic.name : ""}
                />
              </>
            ) : (
              <>
                <EmptyFocusQuestionBox />
                <EmptyFocusQuestionBox />
              </>
            )}
          </div>
          {size.width >= 1024 && (
            <QuestionWideCommentSection
              productAds={productAds}
              size={size}
              togglePurchasePowerUpForm={togglePurchasePowerUpForm}
              questionId={questionIdInView}
              customer={customer}
              navigate={navigate}
              targetAdBookmark={narrowAdBookmark}
              livesCount={livesCount}
              targetGuestCustomer={targetGuestCustomer}
              targetCustomer={targetCustomer}
              question={
                recommendedQuestions &&
                recommendedQuestions.find((rQ) => rQ.id === questionIdInView)
              }
              narrowHorAd={narrowHorAd}
              country={values.country}
              shouldShowCommentSection={shouldShowCommentSection}
              setShouldShowCommentSection={setShouldShowCommentSection}
              regularHorAd={regularHorAd}
            />
          )}

          <div
            className={`relative overflow-visible h-full mt-10 short:mt-5 sm:mt-8 xshort:mt-10`}
          >
            <div className="lg:absolute left-0 h-full">
              <div className="flex-col relative flex items-center justify-start h-full ">
                <button
                  onClick={async (e) => {
                    e.preventDefault();

                    if (!loadingRecommendedQs) {
                      setLoadingRecommendedQs(true);

                      const result = await refetchRecommendedQuestions({
                        topicShortForm: topicShortForm
                          ? topicShortForm !== "all"
                            ? topicShortForm
                            : null
                          : null,
                        pageSize: getNumOfPlayIconsToShow(size) + 1,
                        seenIds,
                        clickedQuestionId: null,
                      });

                      if (result && result.data) {
                        setLoadingRecommendedQs(false);
                        setLastIncorrectQIndex(null);
                        if (
                          result.data.getRecommendedQuestions &&
                          result.data.getRecommendedQuestions.length > 0
                        ) {
                          setQuestionIdToScrollIntoView(
                            result.data.getRecommendedQuestions[0].id
                          );
                          setWantToScrollToQId(null);
                        } else {
                          setQuestionIdToScrollIntoView(null);
                          setWantToScrollToQId(null);
                        }
                      }
                    }
                  }}
                  className="w-4 2xl:w-6 hover:bg-white/20 transition-all shrink-0 aspect-square sm:w-5 flex items-center justify-center border border-white rounded-full "
                >
                  <svg
                    className={`${
                      loadingRecommendedQuestions || loadingRecommendedQs
                        ? "motion-safe:animate-spin"
                        : ""
                    } m-auto h-2 sm:h-2.5 2xl:h-3`}
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.34455 1.65463C7.48546 0.796078 6.30643 0.263184 4.99706 0.263184C2.37832 0.263184 0.263184 2.38292 0.263184 5.00003C0.263184 7.61713 2.37832 9.73687 4.99706 9.73687C7.207 9.73687 9.0496 8.227 9.5769 6.18424H8.34455C7.85872 7.56384 6.54342 8.55266 4.99706 8.55266C3.03597 8.55266 1.44221 6.95989 1.44221 5.00003C1.44221 3.04016 3.03597 1.44739 4.99706 1.44739C5.98057 1.44739 6.85744 1.85595 7.49731 2.50134L5.58954 4.40792H9.73687V0.263184L8.34455 1.65463Z"
                      fill="white"
                    />
                  </svg>
                </button>
                <div className="w-[1px] 2xl:w-[2px] shrink-0 h-1 sm:h-1.5 bg-white"></div>
                <div className="w-[1px] 2xl:w-[2px] shrink-0 h-0.5 sm:h-1 bg-white mt-0.5"></div>
                <div className="w-[1px] 2xl:w-[2px] shrink-0 h-[0.2rem] sm:h-0.5 bg-white mt-0.5"></div>
                <div className="w-[1px] 2xl:w-[2px] shrink-0 h-full bg-gradient-to-b from-white via-white/80 to-transparent  absolute top-[2.9rem]"></div>

                <div className="z-10 flex-col relative flex items-center justify-center">
                  {recommendedQuestions && recommendedQuestions.length > 0
                    ? recommendedQuestions
                        .filter(
                          (rQ, index) => index <= getNumOfPlayIconsToShow(size)
                        )
                        .map((recommendedQuestion, index) => (
                          <QuestionPlayIcon
                            key={recommendedQuestion.id + index}
                            question={recommendedQuestion}
                            setLastIncorrectQIndex={setLastIncorrectQIndex}
                            index={index}
                            setQuestionFetchErr={setQuestionFetchErr}
                            lastQuestionId={
                              index > 0 && recommendedQuestions[index - 1]
                                ? recommendedQuestions[index - 1].id
                                : 0
                            }
                            setIsQFetchErrShown={setIsQFetchErrShown}
                            indexVisible={indexVisible}
                            questionIdInView={questionIdInView}
                            questionIdToScrollIntoView={
                              questionIdToScrollIntoView
                            }
                            offset={
                              lastIncorrectQIndex !== null
                                ? lastIncorrectQIndex + 1
                                : 0
                            }
                            setQFetchErrShownAtQId={setQFetchErrShownAtQId}
                            targetCustomer={targetCustomer}
                            setQuestionIdToScrollIntoView={
                              setQuestionIdToScrollIntoView
                            }
                            setWantToScrollToQId={setWantToScrollToQId}
                            seenIds={
                              recommendedQuestions &&
                              recommendedQuestions.length > 0
                                ? recommendedQuestions.map(
                                    (val, index) => val.id
                                  )
                                : []
                            }
                            setLoadingRecommendedQs={setLoadingRecommendedQs}
                            loadingRecommendedQs={loadingRecommendedQs}
                            topicShortForm={
                              topicShortForm
                                ? topicShortForm !== "all"
                                  ? topicShortForm
                                  : null
                                : null
                            }
                            disabled={
                              loadingRecommendedQs || questionIdToScrollIntoView
                                ? true
                                : false
                            }
                            pageSize={getNumOfPlayIconsToShow(size) + 1}
                            wantToScrollToQId={wantToScrollToQId}
                            refetchRecommendedQuestions={
                              refetchRecommendedQuestions
                            }
                            targetGuestCustomer={targetGuestCustomer}
                          />
                        ))
                    : [
                        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                        16, 17, 18,
                      ]
                        .filter(
                          (rQ, index) => index <= getNumOfPlayIconsToShow(size)
                        )
                        .map((emptyQ, index) => (
                          <div
                            className={`px-1 md:px-2 group ${
                              index === 0
                                ? "pb-1.5 2xl:pb-2"
                                : "py-1.5 2xl:py-2"
                            }`}
                            key={index}
                          >
                            <div className="rounded-md group bg-black sm:rounded-lg flex items-center justify-center relative border-white box-border overflow-hidden border 2xl:border-2 short:w-[1.25rem] w-[1.35rem] sm:w-7 2xl:w-10 aspect-square">
                              <img
                                src={defaultPicUrl}
                                alt={"Default topic"}
                                className="w-full h-full   object-cover group-hover:opacity-80 transition-all"
                              ></img>
                            </div>
                          </div>
                        ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <MobileFooter
          navigate={navigate}
          page="focus"
          finishedLoading={finishedLoading}
        />
      </div>
      {/* ) : (
        <></>
      )} */}

      {((size.width >= 1024 && regularHorAd) ||
        (size.width < 1024 && regularVerAd)) &&
        finishedQuestionViewInFocus &&
        finishedQuestionViewInFocus.status === 1 &&
        selectedAnswerInFocus &&
        selectedAnswerInFocus.isCorrect &&
        shouldOpenAdPopup && (
          <HorAdOverlayBox
            targetCustomer={targetCustomer}
            questionView={finishedQuestionViewInFocus}
            targetAdBookmark={
              size.width >= 1024 ? regularHorAdBookmark : regularVerAdBookmark
            }
            toggleAdPopup={toggleAdPopup}
            overlayAd={size.width >= 1024 ? regularHorAd : regularVerAd}
            shouldOpenAdPopup={shouldOpenAdPopup}
            values={values}
            isOutOfLives={false}
            endTime={
              new Date(
                new Date(finishedQuestionViewInFocus.qEndsAt).getTime() +
                  20 * 1000
              )
            }
            imgUrl={
              size.width >= 1024
                ? regularHorAd.businessLogoUrl
                : regularVerAd.businessLogoUrl
              // quiz.visibilityType === 1
              //   ? quiz.hostPFPUrl
              //   : targetCustomer
              //   ? targetCustomer.profilePicUrl
              //   : targetGuestCustomer
              //   ? targetGuestCustomer.profilePicUrl
              //   : quiz.hostPFPUrl
            }
            alt={
              size.width >= 1024
                ? regularHorAd.businessName
                : regularVerAd.businessName
            }
            width={size.width > 1536 ? 250 : 200}
            lastXSeconds={20 * 1000}
          />
        )}
    </div>
  );
}

export default Focus;
