// import  { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { customerClient, guestClient } from "../../GraphqlApolloClients";

import useSound from "use-sound";

import fastAnsSound from "../../sounds/answer-click_f.mp3";
import slowAnsSound from "../../sounds/answer-click_s.mp3";
import veryFastAnsSound from "../../sounds/answer-click_vf.mp3";
import verySlowAnsSound from "../../sounds/answer-click_vs.mp3";
import {
  FETCH_CUR_SELECTED_ANSWER,
  FETCH_CUSTOMER,
  FETCH_SELECTED_ANSWER_BY_QUESTION,
  SUBMIT_ANSWER_MUTATION,
} from "../../util/gqlFunctions";

function SelectAnswerBox({
  selectedAnswer,
  question,
  triviaNight,
  quiz,
  updateQuestionViewStatus,
  personalQuiz,
  answerIndex,
  targetCustomer,
  targetGuestCustomer,
  disabled,
  shouldPlaySoundEffects,
  selectedAnswerStats,
  setIndexClicked,
  indexClicked,
  setLoadingSubmitAnswer,

  ansOptions,

  setAnswerErr,
  setIsErrMsgShown,

  questionView,
  isForFocusMode,
  targetMediaUrl,
  isChubby,
}) {
  const [lastSubmittedAnswerIndex, setLastSubmittedAnswerIndex] =
    useState(null);
  const [submittedAnsWithQViewId, setSubmittedAnsWithQViewId] = useState(null);
  const [numOfRetries, setNumOfRetries] = useState(0);
  const [submitAnswer] = useMutation(SUBMIT_ANSWER_MUTATION, {
    refetchQueries: [
      { query: FETCH_CUSTOMER },
      {
        query: FETCH_CUR_SELECTED_ANSWER,
        variables: {
          quizId: quiz ? quiz.id : null,
          triviaNightId: triviaNight ? triviaNight.id : null,
        },
      },
      {
        query: FETCH_SELECTED_ANSWER_BY_QUESTION,
        variables: {
          questionId: question ? question.id : "",
        },
      },
    ],
    update(_, { data: { submitAnswer: answer } }) {
      if (indexClicked !== answer.answerSelected) {
        setIndexClicked(answer.answerSelected);
      }
      setAnswerErr(null);
      setLoadingSubmitAnswer(false);
      setLastSubmittedAnswerIndex(answer.answerSelected);
    },
    onError(err) {
      console.log(err);
      setLoadingSubmitAnswer(false);
      if (lastSubmittedAnswerIndex !== null) {
        setIndexClicked(lastSubmittedAnswerIndex);
      } else if (
        isForFocusMode &&
        !submittedAnsWithQViewId &&
        numOfRetries < 15
      ) {
        setNumOfRetries(numOfRetries + 1);
        //meaning we have
        submitAnswer({
          variables: {
            questionId: question.id,
            answerSelected: answerIndex,
            quizId: quiz ? quiz.id : null,
            questionViewId: questionView ? questionView.id : null,
            triviaNightId: triviaNight ? triviaNight.id : null,
          },
        });
        return;
      } else {
        setIndexClicked(null);
      }

      if (err.graphQLErrors[0]) {
        if (
          err.graphQLErrors[0].extensions.errors &&
          err.graphQLErrors[0].extensions.errors.answer
        ) {
          setAnswerErr(err.graphQLErrors[0].extensions.errors.answer);
        }

        // setErrors({
        //   ...err.graphQLErrors[0].extensions.errors,
        // });
        setIsErrMsgShown(true);
      }
    },

    client: targetCustomer ? customerClient : guestClient,
  });

  function submitAnswerCallback() {
    setSubmittedAnsWithQViewId(questionView ? questionView.id : null);
    submitAnswer({
      variables: {
        questionId: question.id,
        answerSelected: answerIndex,
        quizId: quiz ? quiz.id : null,
        questionViewId: questionView ? questionView.id : null,
        triviaNightId: triviaNight ? triviaNight.id : null,
      },
    });
  }
  const [playFastAnswerSound] = useSound(fastAnsSound, { volume: 0.1 });
  const [playVeryFastAnswerSound] = useSound(veryFastAnsSound, { volume: 0.1 });
  const [playSlowAnswerSound] = useSound(slowAnsSound, { volume: 0.1 });
  const [playVerySlowAnswerSound] = useSound(verySlowAnsSound, { volume: 0.1 });

  const [isInAnswerMode, setIsAnswerMode] = useState(false);
  useEffect(() => {
    if (!isInAnswerMode) {
      setIsAnswerMode(
        ((personalQuiz || quiz || triviaNight) &&
          (personalQuiz || quiz || triviaNight).status >= 3) ||
          (questionView && questionView.status === 1)
      );
    }
  }, [isInAnswerMode, personalQuiz, questionView, quiz, triviaNight]);

  useEffect(() => {
    if (selectedAnswer && indexClicked === null) {
      setIndexClicked(selectedAnswer.answerSelected);
    }
  }, [selectedAnswer, indexClicked, setIndexClicked]);

  return (
    <>
      <button
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          if (shouldPlaySoundEffects) {
            const curQEndsAt = new Date(
              personalQuiz || quiz
                ? (personalQuiz || quiz).curQEndsAt
                : questionView
                ? questionView.qEndsAt
                : ""
            );
            const timeLeftInMilli = curQEndsAt.getTime() - new Date().getTime();

            const ansTime =
              (personalQuiz || quiz
                ? (personalQuiz || quiz).nextQLastsXSeconds * 1000
                : questionView
                ? questionView.lastXSeconds * 1000
                : 0) - timeLeftInMilli;

            if (ansTime < 2000) {
              playVeryFastAnswerSound();
            } else if (ansTime < 4000) {
              playFastAnswerSound();
            } else if (ansTime < 7000) {
              playSlowAnswerSound();
            } else {
              playVerySlowAnswerSound();
            }
          }
          if (
            (indexClicked === answerIndex ||
              (selectedAnswer &&
                selectedAnswer.answerSelected === answerIndex)) &&
            ((personalQuiz && personalQuiz.status === 2) ||
              (questionView && questionView.status === 0))
          ) {
            if (personalQuiz && personalQuiz.status === 2) {
            } else if (targetCustomer || targetGuestCustomer) {
              updateQuestionViewStatus();
            }
          } else if (
            ((personalQuiz || quiz || triviaNight) &&
              (personalQuiz || quiz || triviaNight).status === 2) ||
            !questionView ||
            (questionView && questionView.status === 0)
          ) {
            setIndexClicked(answerIndex);
            if (disabled) {
              setLoadingSubmitAnswer(true);

              setTimeout(() => {
                submitAnswerCallback();
              }, 1000);
            } else {
              setLoadingSubmitAnswer(true);

              submitAnswerCallback();
            }
          } else if (indexClicked !== answerIndex) {
            setAnswerErr("Too late");
            setIsErrMsgShown(true);
          }
        }}
        className={` ${
          selectedAnswerStats
            ? selectedAnswerStats.correctAnswerIndex === answerIndex
              ? `border-green-700`
              : selectedAnswer && selectedAnswer.answerSelected === answerIndex
              ? `border-red-700 motion-safe:animate-shake `
              : "border-gray-600 opacity-50"
            : indexClicked !== null || selectedAnswer
            ? indexClicked === answerIndex
              ? // ||
                //   (selectedAnswer && selectedAnswer.answerSelected === answerIndex)
                `border-blue-600 motion-safe:animate-popUp ${
                  targetMediaUrl
                    ? ""
                    : " disabled:bg-blue-900 disabled:hover:bg-blue-900 hover:bg-blue-900 bg-blue-900"
                } `
              : "border-gray-600 focus:hide-button-flash focus:bg-transparent  disabled:hover:bg-transparent"
            : " disabled:hover:bg-transparent disabled:opacity-50 border-gray-700 hover:border-blue-600 disabled:hover:border-gray-700"
        } ${
          ((personalQuiz || quiz || triviaNight) &&
            (personalQuiz || quiz || triviaNight).status === 2) ||
          (questionView && questionView.status === 0)
            ? "hover:bg-blue-900"
            : ""
        } ${targetMediaUrl ? "bg-white/70 backdrop-blur-sm " : ""} ${
          isForFocusMode
            ? `py-4 2xl:py-6 short:py-2 xshort:py-1.5 px-5 sm:px-8 short:px-3`
            : `py-4 2xl:py-6 short:py-2 px-5 sm:px-8 short:px-4`
        } focus:outline-none hide-button-flash rounded-full transition-colors border-2 overflow-hidden shadow-sm flex items-center relative justify-between w-full`}
        style={{
          WebkitBackfaceVisibility: "hidden",
          MozBackfaceVisibility: "hidden",
          WebkitTransform: "translate3d(0, 0, 0)",
          MozTransform: "translate3d(0, 0, 0)",
        }}
      >
        {isInAnswerMode && selectedAnswerStats && (
          <div
            className={`${
              selectedAnswerStats.correctAnswerIndex === answerIndex
                ? "bg-green-700 motion-safe:animate-moveRight"
                : selectedAnswer &&
                  selectedAnswer.answerSelected === answerIndex
                ? "bg-red-700 motion-safe:animate-moveRight"
                : "bg-gray-600 motion-safe:animate-moveRight"
            }  left-0 h-full rounded-full absolute transition-all`}
            style={{
              width:
                answerIndex === 0
                  ? Math.ceil(
                      (selectedAnswerStats.numOfOption0s /
                        selectedAnswerStats.totalAnswers) *
                        100
                    ) + "%"
                  : answerIndex === 1
                  ? Math.ceil(
                      (selectedAnswerStats.numOfOption1s /
                        selectedAnswerStats.totalAnswers) *
                        100
                    ) + "%"
                  : answerIndex === 2
                  ? Math.ceil(
                      (selectedAnswerStats.numOfOption2s /
                        selectedAnswerStats.totalAnswers) *
                        100
                    ) + "%"
                  : Math.ceil(
                      (selectedAnswerStats.numOfOption3s /
                        selectedAnswerStats.totalAnswers) *
                        100
                    ) + "%",
            }}
          ></div>
        )}
        <p
          className={`${
            (answerIndex === 0
              ? question.option0
              : answerIndex === 1
              ? question.option1
              : answerIndex === 2
              ? question.option2
              : question.option3
            ).length > 15
              ? "text-xl"
              : "text-xl"
          } text-white short:text-base text-left truncate select-none 2xl:text-3xl leading-tight font-semibold z-20`}
        >
          {question.questionType === 2 &&
          ansOptions &&
          ansOptions.length > 0 &&
          ansOptions[answerIndex]
            ? ansOptions[answerIndex].optionCustomerName
            : answerIndex === 0
            ? question.option0
            : answerIndex === 1
            ? question.option1
            : answerIndex === 2
            ? question.option2
            : question.option3}
        </p>
        {isInAnswerMode && selectedAnswerStats && (
          <p className={`text-gray-200 short:text-sm select-none z-20`}>
            {answerIndex === 0
              ? selectedAnswerStats.numOfOption0s
              : answerIndex === 1
              ? selectedAnswerStats.numOfOption1s
              : answerIndex === 2
              ? selectedAnswerStats.numOfOption2s
              : selectedAnswerStats.numOfOption3s}
          </p>
        )}
      </button>
    </>
  );
}

export default SelectAnswerBox;
