import React, { useContext, useEffect, useState } from "react";
import usePageTracking from "../../util/usePageTracking";
//import  FeatureSummary from "../../components/creator/FeatureSummary";
//import  Footer from "../../components/creator/Footer";
//import  LinksAndNext from "../../components/creator/LinksAndNext";
//import  SliderBar from "../../components/creator/SliderBar";
import { useNavigate } from "react-router";

import { useQuery } from "@apollo/client";

import { brandContactClient } from "../../GraphqlApolloClients";

import EditSellerAd from "../../components/creator/EditSellerAd";
import { BrandContactAuthContext } from "../../context/brandContactAuth";

import moment from "moment";
import Footer from "../../components/creator/Footer";
import LiveStreamGradBack from "../../components/creator/LiveStreamGradBack";
import SellerMiniNavBar from "../../components/creator/SellerMiniNavBar";
import SellerNav from "../../components/creator/SellerNav";
import TNReviewForm from "../../components/creator/TNReviewForm";
import TopBar from "../../components/creator/TopBar";
import {
  FETCH_ADS_BY_BIZ,
  FETCH_BUSINESSES_BY_BRAND_CONTACT,
  FETCH_SELLER,
} from "../../util/gqlFunctions";

function SellerAds(props) {
  usePageTracking();

  let navigate = useNavigate();
  const { brandContact, logoutBrandContact: logout } = useContext(
    BrandContactAuthContext
  );
  useEffect(() => {
    if (!brandContact) {
      navigate("/loginSeller");
    }
  }, [brandContact, navigate]);
  useEffect(() => {
    document.title = "Seller Dashboard | The  Pet";
  }, []);
  // const pageTopRef = useRef();

  // const [targetProduct, setTargetProduct] = useState(null);

  const [targetAd, setTargetAd] = useState(null);

  const [isFirstTime, setIsFirstTime] = useState(true);

  const [shouldShowMiniNavBar, setShouldShowMiniNavBar] = useState(false);

  // const [productIndexInView, setProductIndexInView] = useState(0);

  const { data: { getBrandContact: sellerInfo } = {}, loading: loadingSeller } =
    useQuery(FETCH_SELLER, {
      client: brandContactClient,
      fetchPolicy: "cache-and-network",
    });

  const [checkedIsSellerLoggedIn, setCheckedIsSellerLoggedIn] = useState(false);

  useEffect(() => {
    if (
      brandContact &&
      !loadingSeller &&
      !sellerInfo &&
      !checkedIsSellerLoggedIn
    ) {
      logout();

      setCheckedIsSellerLoggedIn(true);
    }
  }, [
    brandContact,
    loadingSeller,
    sellerInfo,
    logout,
    checkedIsSellerLoggedIn,
  ]);

  // const newMonthlyEarningsSummaryRef = useRef();

  // const [
  //   monthlyEarningsSummaryIndexToScrollIntoView,
  //   setMonthlyEarningsSummaryIndexToScrollIntoView,
  // ] = useState(null);
  // const [
  //   monthlyEarningsSummaryIndexInView,
  //   setMonthlyEarningsSummaryIndexInView,
  // ] = useState(0);

  const {
    data: { getBusinessesByBrandContact: businessesByBrandContact } = {},
    // loading: loadingBusinessesByBrandContact,
  } = useQuery(FETCH_BUSINESSES_BY_BRAND_CONTACT, {
    client: brandContactClient,
    variables: { brandContactId: brandContact ? brandContact.id : null },
    fetchPolicy: "cache-and-network",
  });

  // const {
  //   data: { getSellerMonthlyEarnings: sellerMonthlyEarnings } = {},
  //   loading: loadingSellerMonthlyEarnings,
  // } = useQuery(FETCH_SELLER_MONTHLY_EARNINGS, {
  //   client: brandContactClient,
  //   variables: {
  //     brandContactId: brandContact ? brandContact.id : null,
  //     businessId:
  //       businessesByBrandContact && businessesByBrandContact.length > 0
  //         ? businessesByBrandContact[0].id
  //         : null,
  //   },
  //   fetchPolicy: "cache-and-network",
  // });

  // const [createProduct, { loading: loadingCreateProduct }] = useMutation(
  //   CREATE_PRODUCT,
  //   {
  //     refetchQueries: [
  //       {
  //         query: FETCH_PRODUCTS_BY_BIZ,
  //         variables: {
  //           businessId:
  //             businessesByBrandContact && businessesByBrandContact.length > 0
  //               ? businessesByBrandContact[0].id
  //               : null,
  //         },
  //       },
  //     ],
  //     update(_, { data: { createProduct: product } }) {},
  //     onError(err) {
  //       console.log(err);
  //       console.log(errors);

  //       if (err.graphQLErrors[0]) {
  //         setErrors({
  //           ...err.graphQLErrors[0].extensions.errors,
  //         });
  //       }
  //     },
  //     client: brandContactClient,
  //     variables: {
  //       businessId:
  //         businessesByBrandContact && businessesByBrandContact.length > 0
  //           ? businessesByBrandContact[0].id
  //           : null,
  //     },
  //   }
  // );

  // const {
  //   data: { getProductsByBiz: products } = {},
  //   loading: loadingProducts,
  // } = useQuery(FETCH_PRODUCTS_BY_BIZ, {
  //   client: brandContactClient,
  //   variables: {
  //     businessId:
  //       businessesByBrandContact && businessesByBrandContact.length > 0
  //         ? businessesByBrandContact[0].id
  //         : null,
  //   },
  // });

  // function createProductCallback(e) {
  //   e.preventDefault();
  //   createProduct();
  // }
  const [tempSearchTerm, setTempSeachTerm] = useState("");

  const { data: { getAdsByBiz: ads } = {} } = useQuery(FETCH_ADS_BY_BIZ, {
    client: brandContactClient,
    variables: {
      businessId:
        businessesByBrandContact && businessesByBrandContact.length > 0
          ? businessesByBrandContact[0].id
          : null,
    },
  });

  const [isCaptionCopied, setIsCaptionCopied] = useState(null);
  const [isSharingToApps, setIsSharingToApps] = useState(false);
  const resetInterval = 1000;

  const [showComingSoon, setShowComingSoon] = useState(false);

  useEffect(() => {
    let timeout;
    if (showComingSoon && resetInterval) {
      timeout = setTimeout(() => setShowComingSoon(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [showComingSoon, resetInterval]);

  useEffect(() => {
    let timeout;
    if (isCaptionCopied && resetInterval) {
      timeout = setTimeout(() => setIsCaptionCopied(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isCaptionCopied, resetInterval]);
  const [initializedMuteVals, setInitializedMuteVals] = useState(false);

  const [shouldPlaySoundEffects, setShouldPlaySoundEffects] = useState(false);

  useEffect(() => {
    if (!initializedMuteVals && sellerInfo) {
      setShouldPlaySoundEffects(
        sellerInfo.areSoundEffectsMuted === true ? false : true
      );
      setInitializedMuteVals(true);
    }
  }, [shouldPlaySoundEffects, initializedMuteVals, sellerInfo]);

  const [isInviteCopied, setIsInviteCopied] = useState(null);
  const [isSharingInviteToApps, setIsSharingInviteToApps] = useState(false);

  useEffect(() => {
    let timeout;
    if (isInviteCopied && resetInterval) {
      timeout = setTimeout(() => setIsInviteCopied(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isInviteCopied, resetInterval]);

  const inviteFriends = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    var textToShare = "Advertise on #TheLuckyPet\n\n";
    console.log(isSharingInviteToApps);
    textToShare += "tknight.live/business";

    if (navigator.share) {
      navigator
        .share({
          title: "Play TKnight!",
          text: textToShare,
          url: "http://tknight.live/",
        })
        .then(() => {
          setIsInviteCopied(true);

          setIsSharingInviteToApps(true);
        })
        .catch((error) => {
          setIsSharingInviteToApps(false);
          navigator.clipboard.writeText(textToShare);

          setIsInviteCopied(true);
        });
    } else {
      navigator.clipboard.writeText(textToShare);
      setIsInviteCopied(true);
    }
  };

  const [shouldOpenReviewForm, setShouldOpenReviewForm] = useState(false);
  const [shouldOpenPaymentSuccessfulForm, setShouldOpenPaymentSuccessfulForm] =
    useState(false);
  const [shouldOpenReviewSentForm, setShouldOpenReviewSentForm] =
    useState(false);

  const toggleReviewForm = () => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldShowMiniNavBar(false);
    console.log(shouldOpenPaymentSuccessfulForm);
    setShouldOpenReviewForm(!shouldOpenReviewForm);
  };

  const toggleReviewSentForm = () => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldShowMiniNavBar(false);
    setShouldOpenReviewForm(false);

    setShouldOpenReviewSentForm(!shouldOpenReviewSentForm);
  };

  return (
    <div
      // ref={pageTopRef}
      className="w-screen overflow-x-hidden h-full min-h-screen bg-black"
    >
      <LiveStreamGradBack gradIndex={2} />
      {targetAd !== null && ads && ads.length > 0 && ads[targetAd] && (
        <EditSellerAd targetAd={ads[targetAd]} setTargetAd={setTargetAd} />
      )}

      {shouldShowMiniNavBar && sellerInfo && (
        <SellerMiniNavBar
          targetSeller={sellerInfo}
          business={
            businessesByBrandContact ? businessesByBrandContact[0] : null
          }
          isSharingToApps={isSharingToApps}
          isShareCopied={isCaptionCopied || isInviteCopied}
          setIsSharingToApps={setIsSharingToApps}
          navigate={navigate}
          isInviteCopied={isInviteCopied}
          inviteFriends={inviteFriends}
          toggleReviewForm={toggleReviewForm}
          shouldShowMiniNavBar={shouldShowMiniNavBar}
          isFirstTime={isFirstTime}
          setIsFirstTime={setIsFirstTime}
          setShouldShowMiniNavBar={setShouldShowMiniNavBar}
          logout={logout}
          setShouldPlaySoundEffects={setShouldPlaySoundEffects}
          shouldPlaySoundEffects={shouldPlaySoundEffects}
        />
      )}

      {shouldOpenReviewForm && sellerInfo ? (
        <TNReviewForm
          shouldOpenReviewForm={shouldOpenReviewForm}
          toggleReviewForm={toggleReviewForm}
          targetSeller={sellerInfo}
          toggleReviewSentForm={toggleReviewSentForm}
        />
      ) : (
        <></>
      )}

      <div className="relative pt-2 min-h-screen z-10 sm:px-0 sm:max-w-5xl 2xl:max-w-7xl 3xl:max-w-8xl xl:px-0 px-4 mx-auto pb-10 lg:pb-0 ">
        <TopBar
          topicShortForm={null}
          topicId={null}
          navigate={navigate}
          seller={sellerInfo}
          page="Seller Dash"
          setShouldShowMiniNavBar={setShouldShowMiniNavBar}
          setIsFirstTime={setIsFirstTime}
        />

        {/* <div className="relative mb-8">
          <button
            onClick={(e) => {
              e.preventDefault();
              setMonthlyEarningsSummaryIndexToScrollIntoView(
                monthlyEarningsSummaryIndexInView - 1
              );
            }}
            disabled={
              loadingSeller ||
              loadingSellerMonthlyEarnings ||
              monthlyEarningsSummaryIndexInView <= 0
            }
            className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-16 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
          >
            <svg
              className="text-gray-400 fill-current mx-auto pr-1"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
            </svg>
          </button>

          <button
            onClick={(e) => {
              e.preventDefault();
              setMonthlyEarningsSummaryIndexToScrollIntoView(
                monthlyEarningsSummaryIndexInView + 1
              );
            }}
            disabled={
              sellerMonthlyEarnings &&
              monthlyEarningsSummaryIndexInView >=
                sellerMonthlyEarnings.length - 1
                ? true
                : loadingSeller || loadingSellerMonthlyEarnings
                ? true
                : false
            }
            className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-16 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
          >
            <svg
              className="text-gray-400 fill-current mx-auto pl-1"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
            </svg>
          </button>
          <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
            {sellerInfo &&
              sellerMonthlyEarnings &&
              sellerMonthlyEarnings.length > 0 &&
              sellerMonthlyEarnings.map((sellerMonthlyEarning, index) => (
                <SellerMonthlyEarningBox
                  key={sellerMonthlyEarning.id}
                  index={index}
                  sellerMonthlyEarning={sellerMonthlyEarning}
                  sellerInfo={sellerInfo}
                  monthlyEarningsSummaryIndexToScrollIntoView={
                    monthlyEarningsSummaryIndexToScrollIntoView
                  }
                  setMonthlyEarningsSummaryIndexToScrollIntoView={
                    setMonthlyEarningsSummaryIndexToScrollIntoView
                  }
                  numOfPendingJobs={sellerMonthlyEarnings.length}
                  setMonthlyEarningsSummaryIndexInView={
                    setMonthlyEarningsSummaryIndexInView
                  }
                  disabled={loadingSeller || loadingSellerMonthlyEarnings}
                />
              ))}

            {(!sellerMonthlyEarnings || sellerMonthlyEarnings.length === 0) && (
              <NewSellerMonthlyEarningBox
                newMonthlyEarningsSummaryRef={newMonthlyEarningsSummaryRef}
                index={0}
                monthlyEarningsSummaryIndexInView={
                  monthlyEarningsSummaryIndexInView
                }
                setMonthlyEarningsSummaryIndexInView={
                  setMonthlyEarningsSummaryIndexInView
                }
                disabled={loadingSeller || loadingSellerMonthlyEarnings}
                monthlyEarningsSummaryIndexToScrollIntoView={
                  monthlyEarningsSummaryIndexToScrollIntoView
                }
              />
            )}
          </div>

          <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
            <div className="flex items-center justify-start space-x-3 overflow-x-auto pb-4 sm:space-x-4">
              {sellerMonthlyEarnings &&
                sellerMonthlyEarnings.length > 1 &&
                sellerMonthlyEarnings.map((sellerMonthlyEarning, index) => (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setMonthlyEarningsSummaryIndexToScrollIntoView(index);
                    }}
                    className={`${
                      index === monthlyEarningsSummaryIndexInView
                        ? "bg-blue-500"
                        : "bg-gray-300 hover:bg-blue-300"
                    } aspect-ratio w-2 h-2 rounded-full transition-colors`}
                    key={sellerMonthlyEarning._id}
                  ></button>
                ))}
            </div>
          </div>
        </div> */}
        {/* 
        {businessesByBrandContact &&
        businessesByBrandContact[0] &&
        !businessesByBrandContact[0].sentShoutoutRequest ? (
          <button
            onClick={(e) => {
              navigate("/sellerShoutout/" + businessesByBrandContact[0].id);
            }}
            className="w-full group text-2xl hover:bg-opacity-60 transition-all font-semibold mb-10 sm:mb-20 -mt-20 snap-center shrink-0 py-3 sm:py-4 flex items-center justify-center space-x-2 bg-yellow-300 rounded-full sm:rounded-full"
          >
            <p className={`py-2`}>Get a shoutout</p>
            <svg
              className="h-3 fill-current"
              viewBox="0 0 11 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className=" -translate-x-1 group-hover:translate-x-0 group-disabled:group-hover:-translate-x-1 transition-all"
                d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
              />
              <rect
                className="opacity-0 group-hover:opacity-100 group-disabled:group-hover:opacity-0 transition-all"
                y="4"
                width="9"
                height="2"
              />
            </svg>
          </button>
        ) : (
          <></>
        )} */}

        <div className="w-full mb-16 flex items-start justify-start lg:space-x-4">
          <SellerNav
            page="Ads"
            setShowComingSoon={setShowComingSoon}
            showComingSoon={showComingSoon}
          />
          <div className="flex-1">
            <div className="gap-2 flex flex-wrap">
              <div
                className={`bg-darker-gray space-x-2 short:space-x-1.5 rounded-lg text-gray-200 duration-700 transition-height px-3 short:pl-2 short:pr-2 sm:px-4 flex items-center justify-start w-full sm:w-auto sm:flex-1`}
              >
                <svg
                  className="fill-gray-400 w-5 short:w-4"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 7C12 9.76142 9.76142 12 7 12C4.23858 12 2 9.76142 2 7C2 4.23858 4.23858 2 7 2C9.76142 2 12 4.23858 12 7ZM10.8482 12.8482C9.74407 13.5763 8.42152 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7C14 8.72211 13.3781 10.299 12.3467 11.5183L16.4142 15.5858L15 17L10.8482 12.8482Z"
                  />
                </svg>

                <input
                  name={"searchTerm"}
                  id={"searchTerm"}
                  value={tempSearchTerm}
                  onChange={(e) => {
                    const { value } = e.target;

                    setTempSeachTerm(value);
                  }}
                  className={`sm:text-lg text-base xshort:text-xs short:py-2 py-3 font-bold leading-none w-full flex-1 bg-transparent placeholder:text-gray-400 focus:outline-none`}
                  placeholder={`Search ads...`}
                  maxLength={100}
                />
              </div>
              <button
                className={`bg-gray-800 hover:bg-gray-900 transition-all space-x-2 short:space-x-1.5 rounded-lg text-black px-3 short:pl-2 short:pr-2 sm:px-4 flex items-center justify-start`}
              >
                <svg
                  className="fill-gray-400 w-5 short:w-4"
                  viewBox="0 0 15 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M15 0H0L6 7.35778V12.4444L9 14V7.35778L15 0Z" />
                </svg>
              </button>

              <button
                onClick={() => {
                  navigate("/business/dashboard");
                }}
                className={`bg-blue-700  hover:bg-blue-800 space-x-2 short:space-x-1.5 rounded-lg text-black transition-all px-4 sm:px-6`}
              >
                <p
                  className={`sm:text-lg text-base text-white xshort:text-xs short:py-2 py-3 font-bold leading-none w-full bg-transparent placeholder:text-gray-400 focus:outline-none`}
                >
                  Create ad <span className="font-normal">(2 min)</span>
                </p>
              </button>
            </div>

            <div className="mt-4 text-gray-200 border-b border-gray-700 flex items-baseline justify-start space-x-2">
              <p className="font-semibold flex-1 py-4">All ads</p>

              <p className="font-semibold w-14 text-center py-4">Views</p>
              <p className="font-semibold w-14 text-center py-4">Clicks</p>

              <p className="font-semibold w-14 text-center py-4">Status</p>
            </div>

            {ads &&
              ads.map((ad, index) => (
                <div className="w-full flex items-center justify-start space-x-2 text-white">
                  <div className="flex-1 flex py-4">
                    <div></div>

                    <div className="flex items-start justify-center flex-col">
                      <div className=" font-semibold  flex items-center justify-start space-x-2">
                        <p>
                          {ad.title || ad.campaignName} / {ad.id}
                        </p>
                      </div>
                      <p>Last edited {moment(ad.lastEditedAt).fromNow()}</p>
                    </div>
                  </div>

                  <p className="w-14 text-center py-4">{ad.rNumOfViews}</p>
                  <p className="w-14 text-center py-4">{ad.rNumOfClicks}</p>

                  <p className="w-14 text-center py-4 truncate">
                    {ad.status === 0
                      ? "Editing"
                      : ad.status === 1
                      ? "In review"
                      : ad.status === 2
                      ? "Approved"
                      : ad.status === 3
                      ? "Live"
                      : ad.status === 4
                      ? "Ended"
                      : ad.status === 5
                      ? "Rejected"
                      : "Cancelled"}
                  </p>
                </div>
              ))}
          </div>
        </div>
        {/* <div className="relative mb-16">
          <p className="text-gray-400 text-2xl short:px-2 px-5 sm:px-10 mb-5 font-bold w-full">
            Products{" "}
            {products && products.length > 0 ? "(" + products.length + ")" : ""}
          </p>
          <button
            onClick={(e) => {
              e.preventDefault();
              setAdIndexToScrollIntoView(productIndexInView - 1);
            }}
            disabled={
              loadingSeller ||
              loadingProducts ||
              loadingAds ||
              productIndexInView <= 0
            }
            className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
          >
            <svg
              className="text-gray-400 fill-current mx-auto pr-1"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
            </svg>
          </button>

          <button
            onClick={(e) => {
              e.preventDefault();
              console.log(productIndexToScrollIntoView);
              setProductIndexToScrollIntoView(productIndexInView + 1);
            }}
            disabled={
              products && productIndexInView >= products.length - 1
                ? true
                : loadingSeller || loadingProducts || loadingAds
                ? true
                : false
            }
            className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
          >
            <svg
              className="text-gray-400 fill-current mx-auto pl-1"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
            </svg>
          </button>
          <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
            {sellerInfo &&
              products &&
              products.length > 0 &&
              products.map((product, index) => (
                <SellerProductBox
                  key={index}
                  index={index}
                  product={product}
                  sellerInfo={sellerInfo}
                  adIndexToScrollIntoView={adIndexToScrollIntoView}
                  setProductIndexInView={setProductIndexInView}
                  setProductIndexToScrollIntoView={
                    setProductIndexToScrollIntoView
                  }
                  setTargetProduct={setTargetProduct}
                  productIndexInView={productIndexInView}
                  disabled={loadingSeller || loadingProducts || loadingAds}
                  navigate={navigate}
                />
              ))}

            {(!products || products.length === 0) && (
              <NewSellerProductBox
                createProductCallback={createProductCallback}
                disabled={loadingSeller || loadingSellerMonthlyEarnings}
              />
            )}
          </div>

          <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
            <div className="flex items-center justify-start space-x-3 overflow-x-auto mr-4 py-6 sm:space-x-4">
              {products &&
                products.length > 1 &&
                products.map((product, index) => (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setAdIndexToScrollIntoView(index);
                    }}
                    className={`${
                      index === productIndexInView
                        ? "bg-blue-500"
                        : "bg-gray-300 hover:bg-blue-300"
                    } shrink-0 aspect-ratio w-2 h-2 rounded-full transition-colors`}
                    key={index}
                  ></button>
                ))}
            </div>

            <button
              onClick={(e) => {
                e.preventDefault();
                createProduct();
              }}
              disabled={loadingCreateProduct}
              className="flex items-center shrink-0 justify-between space-x-4 hover:opacity-70 transition-all"
            >
              <p className="text-gray-400 whitespace-nowrap font-bold transition-all">
                Add product
              </p>

              <div className="w-14 h-14 shadow-lg rounded-full bg-blue-700 transition-all">
                {loadingCreateProduct ? (
                  <svg
                    className={`p-4 text-white stroke-current m-auto motion-safe:animate-spin`}
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.636 3.636A9 9 0 0119 10"
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                  </svg>
                ) : (
                  <svg
                    className=" text-white fill-current p-3"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                  </svg>
                )}
              </div>
            </button>
          </div>
        </div> */}
      </div>

      <Footer navigate={navigate} logout={logout} seller={brandContact} />
    </div>
  );
}

export default SellerAds;
