import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { customerClient, guestClient } from "../../GraphqlApolloClients";
import {
  CREATE_FORUM_POST_MUTATION,
  FETCH_CUSTOMER,
  FETCH_GUEST_CUSTOMER,
  FETCH_QUESTION_VIEW_BY_CUSTOMER,
  FETCH_RECOMMENDED_FORUM_POSTS,
  FETCH_SUGGESTED_MSGS_BY_EVENT_QUERY,
} from "../../util/gqlFunctions";
import { defaultPicUrl, useForm } from "../../util/hooks";

import JustModalComments from "./JustModalComments";

function CommentsModal({
  navigate,

  setShouldShowCommentSection,
  questionId,
  questionViewId,
  targetCustomer,
  targetGuestCustomer,
  question,
  shouldShowCommentSection,
  country,
}) {
  const [errors, setErrors] = useState({});
  const [lastAnswerResult, setLastAnswerResult] = useState(-1);

  const { data: { getQuestionViewByCustomer: questionView } = {} } = useQuery(
    FETCH_QUESTION_VIEW_BY_CUSTOMER,
    {
      client: targetCustomer ? customerClient : guestClient,
      variables: { questionId },
    }
  );

  const {
    data: { getRecommendedForumPostsBySearch: comments } = {},
    // refetch: refetchRecommendedForumPosts,
    fetchMore: fetchMoreFPosts,

    loading: loadingRecommendedForumPosts,
  } = useQuery(FETCH_RECOMMENDED_FORUM_POSTS, {
    variables: {
      forumId: null,
      questionId: question?.id,
      triviaNightId: null,
      quizId: null,
      productId: null,
      questionViewId: questionView ? questionView.id : null,
      seenIds: [],
      folders: [],
      forumPostRepliedToId: null,
      searchTerm: null,
      type: 0,
      pageSize: 6,
    },
    fetchPolicy: "cache-and-network",

    client: targetCustomer ? customerClient : guestClient,
  });

  const { values, setValues } = useForm(createCommentCallback, {
    triviaNightId: null,
    quizId: null,
    productId: null,
    questionId: question?.id,
    questionViewId: questionView ? questionView.id : null,
    details: "",
    forumPostRepliedToId: null,
    repliedToUsername: "",
    pageSize: 10,
    seenIds: [],
    type: 0,

    title: "",
    mediaFiles: [],
    folders: [],
    visibility: 2,
  });
  const [sentMessages, setSentMessages] = useState([]);
  const resetInterval = 1000;
  const [isMsgError, setIsMsgError] = useState(false);
  useEffect(() => {
    let timeout;
    if (isMsgError && resetInterval) {
      timeout = setTimeout(() => setIsMsgError(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isMsgError, resetInterval]);
  const [headCommentToNewReplies, setHeadCommentToNewReplies] = useState({});
  const [newComments, setNewComments] = useState([]);

  const [createForumPost, { loading: loadingCreateForumPost }] = useMutation(
    CREATE_FORUM_POST_MUTATION,
    {
      refetchQueries: [
        { query: FETCH_CUSTOMER },
        { query: FETCH_GUEST_CUSTOMER },
      ],
      update(_, { data: { createForumPost: createdForumPost } }) {
        setValues({
          ...values,
          details: "",
          msg: "",
          forumPostRepliedToId: null,
          repliedToDisplayName: null,
          type: 0,
          searchTerm: null,
        });

        if (createdForumPost.type === 0) {
          setNewComments([createdForumPost, ...newComments]);
        } else {
          setHeadCommentToNewReplies({
            ...headCommentToNewReplies,
            [createdForumPost.headForumPostId]:
              headCommentToNewReplies[createdForumPost.headForumPostId] &&
              headCommentToNewReplies[createdForumPost.headForumPostId].length >
                0
                ? [
                    ...headCommentToNewReplies[
                      createdForumPost.headForumPostId
                    ],
                    createdForumPost,
                  ]
                : [createdForumPost],
          });
        }

        setErrors({});
      },
      onError(err) {
        setIsMsgError(true);

        console.log(err);

        if (err.graphQLErrors[0]) {
          console.log(err.graphQLErrors[0].extensions.errors);

          if (!targetCustomer) {
            navigate("/register");
          }

          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: values,
      client: targetCustomer ? customerClient : guestClient,
    }
  );

  const {
    data: { getSuggestedMessagesByEvent: suggestedMessages } = {},
    refetch: refetchSuggestedMessages,
    // loading: loadingSuggestedMessages,
  } = useQuery(FETCH_SUGGESTED_MSGS_BY_EVENT_QUERY, {
    variables: {
      triviaNightId: null,
      quizId: null,
      productId: null,
      questionViewId: questionView ? questionView.id : null,
      seenIds: [],
      pageSize: 10,
    },
    skip:
      !questionView ||
      questionView.answerResult === -1 ||
      questionView.status !== 1
        ? true
        : false,
    client: targetCustomer ? customerClient : guestClient,
  });

  useEffect(() => {
    if (
      questionView &&
      (values.questionViewId !== questionView.id ||
        values.questionId !== questionView.questionId)
    ) {
      setValues({
        ...values,
        questionViewId: questionView.id,
        questionId: questionView.questionId,
      });
    }
  }, [questionView, setValues, values]);

  useEffect(() => {
    if (
      questionView &&
      questionView.status === 1 &&
      questionView.answerResult !== lastAnswerResult
    ) {
      refetchSuggestedMessages({
        triviaNightId: null,
        quizId: null,
        productId: null,
        questionViewId: questionView.id,
        seenIds: [],
        pageSize: 10,
      });

      setSentMessages([]);
      setLastAnswerResult(questionView.answerResult);
    }
  }, [lastAnswerResult, questionView, refetchSuggestedMessages]);

  function createCommentCallback(e) {
    e.preventDefault();

    createForumPost();
  }

  return (
    <div
      className={`fixed pt-32 xshort:pt-12 sm:py-10 z-[70] overscroll-contain w-screen h-screen max-h-screen max-w-screen overflow-hidden flex items-end justify-end sm:items-center sm:justify-center`}
    >
      <button
        onClick={(e) => {
          e.preventDefault();

          setShouldShowCommentSection(false);
        }}
        className="z-40 hide-button-flash bg-opacity-10 motion-safe:animate-fadeInFast flex items-center justify-center inset-0 absolute"
        disabled={false}
      />
      <form
        // onSubmit={buyBandCallback}
        className={`overflow-x-hidden  text-center sm:max-h-full flex-shrink motion-safe:animate-fadeUpFast overscroll-contain sm:m-auto h-full w-full max-w-md overflow-y-auto flex flex-col items-center justify-start z-40 rounded-xl short:px-4 short:pb-4 px-4 pb-6 sm:p-4 sm:pb-4 bg-darker-gray shadow-white shadow-2xl rounded-b-none sm:rounded-3xl `}
      >
        <button
          onClick={(e) => {
            e.preventDefault();

            setShouldShowCommentSection(false);
          }}
          className="w-full pt-4 short:pt-3 short:pb-1 pb-2 sm:hidden"
        >
          <div className="rounded-full bg-gray-700 flex-shrink-0 h-1 mx-auto w-10 mb-2"></div>
        </button>

        <div
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setValues({
              ...values,
              commentRepliedToId: null,
              repliedToUsername: null,
              // message:
              //   "@" + comment.commentorUsername + ": " + values.message,
            });
          }}
          className="flex items-center justify-center pb-4 short:pb-2 sm:pt-4 space-x-4 px-2 w-full short:space-x-2"
        >
          <div className="flex leading-tight items-center justify-start text-gray-200 w-2/5">
            <svg
              className="h-5 fill-current text-gray-200"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 0H1C0.45 0 0 0.45 0 1V15L4 11H14C14.55 11 15 10.55 15 10V1C15 0.45 14.55 0 14 0ZM13 2V9H3.17L2.58 9.59L2 10.17V2H13ZM17 4H19C19.55 4 20 4.45 20 5V20L16 16H5C4.45 16 4 15.55 4 15V13H17V4Z"
              />
            </svg>

            <p className="ml-2 text-sm">
              {comments && comments.length > 0 ? comments.length : ""}
            </p>
          </div>

          <button
            onClick={(e) => {
              navigate("/topic/" + question?.topicShortForm);
            }}
            className="rounded-xl hover:opacity-80 transition-all bg-blue-200 h-10 aspect-square"
          >
            <img
              src={question?.topicLogoUrl || defaultPicUrl}
              className="h-10 aspect-square rounded-md mr-2 object-cover"
              alt={question?.topicName}
            />
          </button>
          <div className="flex leading-tight items-center justify-end text-gray-200 w-2/5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 fill-current text-gray-200"
              viewBox="0 0 24 24"
            >
              <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2.033 16.01c.564-1.789 1.632-3.932 1.821-4.474.273-.787-.211-1.136-1.74.209l-.34-.64c1.744-1.897 5.335-2.326 4.113.613-.763 1.835-1.309 3.074-1.621 4.03-.455 1.393.694.828 1.819-.211.153.25.203.331.356.619-2.498 2.378-5.271 2.588-4.408-.146zm4.742-8.169c-.532.453-1.32.443-1.761-.022-.441-.465-.367-1.208.164-1.661.532-.453 1.32-.442 1.761.022.439.466.367 1.209-.164 1.661z" />
            </svg>
            <p className="ml-2 text-sm">{question?.interestingFact ? 1 : ""}</p>
          </div>
        </div>

        <JustModalComments
          navigate={navigate}
          country={country}
          comments={comments}
          values={values}
          fetchMoreParams={{
            forumId: null,
            questionId: question?.id,
            triviaNightId: null,
            quizId: null,
            productId: null,
            questionViewId: questionView ? questionView.id : null,
            seenIds: [],
            folders: [],
            forumPostRepliedToId: null,
            searchTerm: null,
            type: 0,
            pageSize: 6,
          }}
          fetchMoreComments={fetchMoreFPosts}
          setValues={setValues}
          newComments={newComments}
          headCommentToNewReplies={headCommentToNewReplies}
          targetCustomer={targetCustomer}
          targetGuestCustomer={targetGuestCustomer}
          questionId={questionId}
          loadingComments={loadingRecommendedForumPosts}
        />
        {(targetCustomer || targetGuestCustomer) && (
          <div
            className={` ${
              questionView.status === 1
                ? "h-9 short:h-8"
                : "h-0 sm:h-9 short:h-8"
            } relative shrink-0 overflow-hidden transition-all flex w-full mt-2 items-center space-x-2`}
          >
            <div className="w-full flex gap-2 snap always-stop snap-y snap-mandatory overflow-y-scroll scrollbar-hide flex-wrap grow items-center justify-start overflow-x-auto h-full short:text-sm ">
              {questionView &&
                questionView.status === 1 &&
                lastAnswerResult === questionView.answerResult &&
                suggestedMessages &&
                suggestedMessages.length > 0 &&
                suggestedMessages
                  .filter((msg) => !sentMessages.includes(msg))
                  .map((msg, index) => (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setValues({ ...values, details: values.details + msg });
                      }}
                      key={msg}
                      className="bg-gray-700 text-gray-200 leading-tight h-full hover:bg-opacity-50 transition-all rounded-lg px-2 py-1"
                    >
                      {msg}
                    </button>
                  ))}
            </div>
          </div>
        )}
        <div
          className={`${
            isMsgError
              ? "border-red-700 motion-safe:animate-shake"
              : "border-gray-700 "
          } mt-2 rounded-xl text-gray-200 duration-700 transition-height pl-4 short:pl-3 flex items-start justify-start border w-full`}
        >
          {isMsgError && errors.message && (
            <p className="absolute text-red-600 bottom-2 font-semibold">
              {errors.message}
            </p>
          )}
          <textarea
            name={"message"}
            id={"message"}
            rows="1"
            value={values.details}
            onChange={(e) => {
              e.preventDefault();

              var newMsg = e.target.value;
              setValues({
                ...values,
                details: newMsg,
              });
            }}
            className={`${
              isMsgError ? "" : ""
            } text-lg 2xl:text-2xl short:leading-tight py-4 short:py-2 short:text-base leading-tight w-full flex-1 bg-transparent placeholder:text-gray-200 focus:outline-none`}
            placeholder={
              values.repliedToDisplayName
                ? "Replying to " + values.repliedToDisplayName
                : "Add a comment..."
            }
            disabled={loadingCreateForumPost || !shouldShowCommentSection}
            onFocus={(e) => {
              e.stopPropagation();
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
              if (e.key === "Enter" && e.shiftKey === false) {
                e.preventDefault();
                createForumPost();
              }
            }}
            maxLength={200}
          ></textarea>
          <button
            onClick={createCommentCallback}
            className={`${
              isMsgError ? "text-red-700" : "text-white"
            } p-4 short:p-3 disabled:text-gray-300 sm:hover:opacity-50 transition-all`}
            disabled={loadingCreateForumPost}
          >
            <svg
              className="w-6 2xl:w-7 short:w-5 fill-current"
              viewBox="0 0 26 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M26 0L19.5 24L10.6936 16.1029L19.1458 7.12036L7.81625 15.0044L0 13.0909L26 0Z" />
            </svg>
          </button>
        </div>
        {/* <div className="px-0 sm:px-4 w-full">
          {targetTopic && triviaViewCount && (
            <div className="flex items-center justify-start border-b border-gray-200 pb-4 short:pb-2 sm:pt-4 space-x-4 w-full short:space-x-2">
              <button
                onClick={(e) => {
                  navigate("/topic/" + targetTopic.shortForm);
                }}
                className="rounded-xl hover:opacity-80 transition-all bg-blue-200 h-12 aspect-square"
              >
                <img
                  src={targetTopic.logoUrl}
                  className="h-12 aspect-square rounded-md mr-2 object-cover"
                  alt={targetTopic.name}
                />
              </button>
              <div className="">
                <p className="short:text-base text-lg text-left leading-tight">
                  {targetTopic.name} Battle
                </p>
                <div className="flex leading-tight items-center justify-start text-gray-700">
                  <svg
                    className="h-4 fill-current"
                    viewBox="0 0 13 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3.24404 3.7559C3.24404 1.96349 4.69581 0.511719 6.48822 0.511719C8.28064 0.511719 9.73241 1.96349 9.73241 3.7559C9.73241 5.54832 8.28064 7.00009 6.48822 7.00009C4.69581 7.00009 3.24404 5.54832 3.24404 3.7559ZM0 11.0554C0 8.898 4.32288 7.8112 6.48837 7.8112C8.65387 7.8112 12.9767 8.898 12.9767 11.0554V13.4885H0V11.0554Z"
                    />
                  </svg>
                  <p className="ml-1 font-semibold text-sm">
                    {triviaViewCount} player{triviaViewCount === 1 ? "" : "s"}
                  </p>

                  <svg
                    className="h-4 ml-2 fill-current"
                    viewBox="0 0 13 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.65257 13C10.043 13 12.7915 10.2515 12.7915 6.86106C12.7915 3.47064 10.043 0.722168 6.65257 0.722168C3.26215 0.722168 0.513672 3.47064 0.513672 6.86106C0.513672 10.2515 3.26215 13 6.65257 13Z"
                      fill="#F4900C"
                    />
                    <path
                      d="M6.65257 12.2778C10.043 12.2778 12.7915 9.52931 12.7915 6.1389C12.7915 2.74848 10.043 0 6.65257 0C3.26215 0 0.513672 2.74848 0.513672 6.1389C0.513672 9.52931 3.26215 12.2778 6.65257 12.2778Z"
                      fill="#FFCC4D"
                    />
                    <path
                      d="M6.65224 11.5555C9.44435 11.5555 11.7078 9.29201 11.7078 6.4999C11.7078 3.70779 9.44435 1.44434 6.65224 1.44434C3.86013 1.44434 1.59668 3.70779 1.59668 6.4999C1.59668 9.29201 3.86013 11.5555 6.65224 11.5555Z"
                      fill="#FFE8B6"
                    />
                    <path
                      d="M6.65224 11.1946C9.44435 11.1946 11.7078 8.93117 11.7078 6.13906C11.7078 3.34695 9.44435 1.0835 6.65224 1.0835C3.86013 1.0835 1.59668 3.34695 1.59668 6.13906C1.59668 8.93117 3.86013 11.1946 6.65224 11.1946Z"
                      fill="#FFAC33"
                    />
                    <path
                      d="M6.63662 2.40527C5.64537 2.40527 4.69642 2.79904 3.99849 3.49996C3.30057 4.20088 2.91084 5.15152 2.91507 6.14277C2.9193 7.134 3.31714 8.08468 4.021 8.78559C4.7249 9.4865 5.67725 9.88028 6.66846 9.88028C6.21152 9.88028 5.76422 9.74476 5.38318 9.49089C5.00218 9.23703 4.70449 8.87616 4.52784 8.45399C4.35114 8.03182 4.30342 7.56724 4.39066 7.11906C4.4779 6.67087 4.6962 6.2592 5.01793 5.93608C5.33969 5.61296 5.75044 5.39291 6.19822 5.30376C6.64604 5.21461 7.11079 5.26036 7.53371 5.43523C7.95662 5.61011 8.3187 5.90624 8.57421 6.2862C8.82972 6.66613 8.96712 7.11286 8.96904 7.56984C8.97165 8.18259 8.73075 8.77026 8.29928 9.20354C7.86786 9.63683 7.28125 9.88028 6.66846 9.88028C7.65972 9.88028 8.60867 9.4865 9.30659 8.78559C10.0045 8.08468 10.3942 7.134 10.39 6.14277C10.3858 5.15152 9.98794 4.20088 9.28409 3.49996C8.58018 2.79904 7.62788 2.40527 6.63662 2.40527Z"
                      fill="#FFD983"
                    />
                  </svg>
                  <p className="ml-1 font-semibold text-sm">
                    {quiz.prizeCoins} prize
                  </p>
                </div>
              </div>
            </div>
          )}
        </div> */}
        {/* <div className="mt-4 w-full flex items-start justify-start flex-col">
            <p className="font-medium">Full name</p>

          </div> */}

        {/* <button
          onClick={(e) => {
            e.preventDefault();
            setShouldShowCommentSection(false);
          }}
          className="text-lg font-semibold mt-4 uppercase sm:text-xl w-full bg-blue-600 hover:bg-blue-700 transition-all rounded-full shadow-sm text-white py-3 px-2"
        >
          Awesome!
        </button> */}

        {/* <p className="text-center w-full text-gray-400 mt-4 sm:mb-0 mb-2 text-base">
          Have a question?{" "}
          <a
            href={"mailto:info@tknight.live"}
            target="_blank"
            rel="noreferrer"
            className="text-blue-300 hover:text-blue-400 transition-all"
          >
            Contact us
          </a>
        </p> */}

        {/* <div className="m-2 w-full mt-4 sm:mb-0 bg-gray-100 py-5 rounded-2xl">
          <button
            onClick={(e) => {
              if (customer) {
                navigate("/settings");
              } else if (quiz) {
                navigate("/login/redirect/battle>" + quiz.id);
              }
            }}
            className="group mx-auto flex-shrink-0 flex transition-all items-center justify-center space-x-2 hover:bg-black bg-blue-600 text-white rounded-full backdrop-blur-sm pl-4 pr-3 py-1"
          >
            <p className="font-bold"> {!customer ? "Sign in" : "Account"}</p>
            <svg
              className="h-2.5 fill-current"
              viewBox="0 0 11 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className=" -translate-x-1 group-hover:translate-x-0 transition-all"
                d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
              />
              <rect
                className="opacity-0 group-hover:opacity-100 transition-all"
                y="4"
                width="9"
                height="2"
              />
            </svg>
          </button>
        </div> */}
      </form>
    </div>
  );
}

export default CommentsModal;
