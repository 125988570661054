import { useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";

import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import { adminClient } from "../../GraphqlApolloClients";

import {
  DELETE_TRIVIA_NIGHT,
  FETCH_TRIVIA_NIGHTS,
  FETCH_TRIVIA_VIEW_COUNT,
  GIVE_LIFE_TO_EVERY1_THAT_ANSWERED_X_MUTATION,
  SEND_TRIVIA_NIGHT_REMINDER_MUTATION,
  TOGGLE_PAUSE_QUIZ_MUTATION,
  UPDATE_QUIZ_STATUS_MUTATION,
} from "../../util/gqlFunctions";

import PieTimer from "./PieTimer";

function AdminTNBox({
  index,
  downloadScreenshots,
  triviaNight,
  triviaNightIndexToScrollIntoView,

  setTriviaNightIndexInView,
  setTargetTN,
  disabled,
}) {
  const tnRef = useRef();
  let navigate = useNavigate();

  const {
    ref: boxRef,
    inView: isBoxVisible,
    // entry,
  } = useInView({
    /* Optional options */
    threshold: 0.7,
  });

  const [isPaused, setIsPaused] = useState(true);
  const [tempElapsedTime, setTempElapsedTime] = useState(null);

  const [togglePauseQuiz] = useMutation(TOGGLE_PAUSE_QUIZ_MUTATION, {
    refetchQueries: [
      {
        query: FETCH_TRIVIA_NIGHTS,
      },
    ],
    update(_, { data }) {
      setErrors({});
      setTempElapsedTime(null);
      console.log(data);
      console.log(tempElapsedTime);
      setIsPaused(data);
    },
    onError(err) {
      setTempElapsedTime(null);

      setIsPaused(!isPaused);
      console.log(triviaNight);
      console.log(err.graphQLErrors[0].extensions.errors);
      console.log(err);
      if (err.graphQLErrors[0]) {
        // console.log(err.graphQLErrors[0].extensions.errors);

        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
        // setIsErrMsgShown(true);
        // if (err.graphQLErrors[0].extensions.errors) {
        //   setAnswerErr(err.graphQLErrors[0].extensions.errors.pause);
        //   setTypeOfErr(1);
        // }
      }
    },
    variables: { triviaNightId: triviaNight.id, secondsLeftAtPause: 10 },
    client: adminClient,
  });
  // console.log(triviaNight.id);

  useEffect(() => {
    if (index === triviaNightIndexToScrollIntoView) {
      tnRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  }, [triviaNightIndexToScrollIntoView, index]);

  useEffect(() => {
    if (isBoxVisible && !disabled) {
      setTriviaNightIndexInView(index);
    }
  }, [isBoxVisible, disabled, index, setTriviaNightIndexInView]);

  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const resetInterval = 1000;

  useEffect(() => {
    let timeout;
    if (isLinkCopied && resetInterval) {
      timeout = setTimeout(() => setIsLinkCopied(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isLinkCopied, resetInterval]);

  const [errors, setErrors] = useState({});

  const [sentLifeTo0Answerers, setSentLifeTo0Answerers] = useState(false);
  const [sentLifeTo1Answerers, setSentLifeTo1Answerers] = useState(false);
  const [sentLifeTo2Answerers, setSentLifeTo2Answerers] = useState(false);
  const [sentLifeTo3Answerers, setSentLifeTo3Answerers] = useState(false);

  const [life0Received, setLife0Received] = useState(false);
  const [life1Received, setLife1Received] = useState(false);
  const [life2Received, setLife2Received] = useState(false);
  const [life3Received, setLife3Received] = useState(false);

  const [deleteTriviaNight] = useMutation(DELETE_TRIVIA_NIGHT, {
    refetchQueries: [{ query: FETCH_TRIVIA_NIGHTS }],
    update(_, { data: { deleteTriviaNight } }) {
      console.log(errors);
    },
    onError(err) {
      console.log(err);
      // setValues({ ...values, shouldDelete: false });
      if (err.graphQLErrors[0]) {
        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
      }
    },
    variables: { triviaNightId: triviaNight.id },
    client: adminClient,
  });

  const [updateQuizStatus] = useMutation(UPDATE_QUIZ_STATUS_MUTATION, {
    refetchQueries: [{ query: FETCH_TRIVIA_NIGHTS }],
    update(_, { data: { updateQuizStatus: editedTN } }) {
      console.log("new status: " + editedTN.status);
    },
    onError(err) {
      console.log(err);
      // setValues({ ...values, shouldDelete: false });
      if (err.graphQLErrors[0]) {
        console.log(err.graphQLErrors[0].extensions.errors);
        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
      }
    },
    variables: {
      triviaNightId: triviaNight.id,
    },
    client: adminClient,
  });

  const [sendTriviaNightReminder, { loading: loadingSendTriviaNightReminder }] =
    useMutation(SEND_TRIVIA_NIGHT_REMINDER_MUTATION, {
      refetchQueries: [
        {
          query: FETCH_TRIVIA_NIGHTS,
        },
      ],
      update(_, { data: { sendTNReminderEmail: sentReminderMsg } }) {
        setErrors({});
      },
      onError(err) {
        console.log(err);
        if (err.graphQLErrors[0]) {
          console.log(err.graphQLErrors[0].extensions.errors);

          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      client: adminClient,
      variables: { triviaNightId: triviaNight.id },
    });

  function sendTNReminderCallback(e) {
    e.preventDefault();
    sendTriviaNightReminder();
  }

  const [
    giveExtraLifeToEveryoneThatAnsweredX,
    { loading: loadingGiveLifeToXAnswerers },
  ] = useMutation(GIVE_LIFE_TO_EVERY1_THAT_ANSWERED_X_MUTATION, {
    // refetchQueries: [{ query: FETCH_TRIVIA_NIGHTS }],
    update(_, { data: { giveExtraLifeToEveryoneThatAnsweredX } }) {
      if (giveExtraLifeToEveryoneThatAnsweredX === 0) {
        setLife0Received(true);
      } else if (giveExtraLifeToEveryoneThatAnsweredX === 1) {
        setLife1Received(true);
      } else if (giveExtraLifeToEveryoneThatAnsweredX === 2) {
        setLife2Received(true);
      } else if (giveExtraLifeToEveryoneThatAnsweredX === 3) {
        setLife3Received(true);
      }
    },
    onError(err) {
      console.log(err);

      // setValues({ ...values, shouldDelete: false });
      if (err.graphQLErrors[0]) {
        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
      }
    },
    // variables: { triviaNightId: triviaNight.id },
    client: adminClient,
  });

  const {
    data: { getTriviaViewCount: triviaViewCount } = {},
    // loading: loadingTriviaViewCount,
  } = useQuery(FETCH_TRIVIA_VIEW_COUNT, {
    client: adminClient,
    variables: { triviaNightId: triviaNight.id },
  });

  useEffect(() => {
    if (triviaNight.status === 1) {
      if (sentLifeTo0Answerers) {
        setSentLifeTo0Answerers(false);
      }
      if (life0Received) {
        setLife0Received(false);
      }
      if (sentLifeTo1Answerers) {
        setSentLifeTo1Answerers(false);
      }
      if (life1Received) {
        setLife1Received(false);
      }
      if (sentLifeTo2Answerers) {
        setSentLifeTo2Answerers(false);
      }
      if (life2Received) {
        setLife2Received(false);
      }
      if (sentLifeTo3Answerers) {
        setSentLifeTo3Answerers(false);
      }
      if (life3Received) {
        setLife3Received(false);
      }
    }
  }, [
    triviaNight,
    sentLifeTo0Answerers,
    sentLifeTo1Answerers,
    sentLifeTo2Answerers,
    sentLifeTo3Answerers,
    life0Received,
    life1Received,
    life2Received,
    life3Received,
  ]);

  return (
    <div ref={tnRef} className="w-full shrink-0">
      <div
        ref={boxRef}
        className="w-full sm:px-10 px-5 snap-center shrink-0 pb-5 pt-4 sm:pb-10 sm:pt-9 flex items-center justify-start flex-col bg-darker-gray rounded-xl sm:rounded-3xl short:px-2 short:py-2"
      >
        <div className="flex overflow-x-auto items-center space-x-2 justify-start w-full">
          <PieTimer
            endTime={
              !triviaNight
                ? new Date()
                : triviaNight.status === 2 || triviaNight.status === 3
                ? triviaNight.curQEndsAt
                : triviaNight.nextQStartsAt
            }
            imgUrl={
              triviaNight
                ? triviaNight.hostPFPUrl
                : "https://tknight-media.s3.amazonaws.com/profile.png"
            }
            alt={triviaNight ? triviaNight.hostName : "tknight"}
            width={50}
            lastXSeconds={
              triviaNight ? triviaNight.nextQLastsXSeconds * 1000 : 100
            }
          />
          <div className="flex-1 shrink-0 whitespace-nowrap w-full flex flex-col items-start justify-start truncate">
            <p className="font-semibold text-gray-200 text-xl short:text-lg leading-tight short:leading-tight w-full truncate ">
              TN {triviaNight.dayNum}
            </p>
            <p className=" text-gray-400 leading-tight">
              {moment(triviaNight.releaseDate).format("D MMM, YYYY h:mm A")}
            </p>
          </div>
          {triviaNight.status === 3 && (
            <>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  giveExtraLifeToEveryoneThatAnsweredX({
                    variables: { optionX: 0 },
                  });
                  setSentLifeTo0Answerers(true);
                }}
                disabled={loadingGiveLifeToXAnswerers || sentLifeTo0Answerers}
                className={`${
                  life0Received
                    ? "disabled:bg-blue-500"
                    : sentLifeTo0Answerers
                    ? "disabled:opacity-50"
                    : loadingGiveLifeToXAnswerers
                    ? "bg-gray-300"
                    : "hover:bg-opacity-50"
                } relative flex items-center justify-center bg-red-600 shrink-0 transition-all sm:w-12 w-10 aspect-square rounded-full `}
              >
                <p className="absolute font-semibold text-black">0</p>
                <svg
                  className="px-2 sm:px-2.5 pt-0.5 fill-current text-white"
                  viewBox="0 0 20 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M9.80926 18L8.38692 16.7052C3.33515 12.1243 0 9.103 0 5.3951C0 2.37384 2.37384 0 5.3951 0C7.10191 0 8.74005 0.79455 9.80926 2.05014C10.8785 0.79455 12.5166 0 14.2234 0C17.2447 0 19.6185 2.37384 19.6185 5.3951C19.6185 9.103 16.2834 12.1243 11.2316 16.715L9.80926 18Z" />
                </svg>
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  giveExtraLifeToEveryoneThatAnsweredX({
                    variables: { optionX: 1 },
                  });
                  setSentLifeTo1Answerers(true);
                }}
                disabled={loadingGiveLifeToXAnswerers || sentLifeTo1Answerers}
                className={`${
                  life1Received
                    ? "disabled:bg-blue-500"
                    : sentLifeTo1Answerers
                    ? "disabled:opacity-50"
                    : loadingGiveLifeToXAnswerers
                    ? "bg-gray-300"
                    : "hover:bg-opacity-50"
                } relative flex items-center justify-center bg-red-600 shrink-0 transition-all sm:w-12 w-10 aspect-square rounded-full `}
              >
                <p className="absolute font-semibold text-black">1</p>
                <svg
                  className="px-2 sm:px-2.5 pt-0.5 fill-current text-white"
                  viewBox="0 0 20 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M9.80926 18L8.38692 16.7052C3.33515 12.1243 0 9.103 0 5.3951C0 2.37384 2.37384 0 5.3951 0C7.10191 0 8.74005 0.79455 9.80926 2.05014C10.8785 0.79455 12.5166 0 14.2234 0C17.2447 0 19.6185 2.37384 19.6185 5.3951C19.6185 9.103 16.2834 12.1243 11.2316 16.715L9.80926 18Z" />
                </svg>
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  giveExtraLifeToEveryoneThatAnsweredX({
                    variables: { optionX: 2 },
                  });
                  setSentLifeTo2Answerers(true);
                }}
                disabled={loadingGiveLifeToXAnswerers || sentLifeTo2Answerers}
                className={`${
                  life2Received
                    ? "disabled:bg-blue-500"
                    : sentLifeTo2Answerers
                    ? "disabled:opacity-50"
                    : loadingGiveLifeToXAnswerers
                    ? "bg-gray-300"
                    : "hover:bg-opacity-50"
                } relative flex items-center justify-center bg-red-600 shrink-0 transition-all sm:w-12 w-10 aspect-square rounded-full `}
              >
                <p className="absolute font-semibold text-black">2</p>
                <svg
                  className="px-2 sm:px-2.5 pt-0.5 fill-current text-white"
                  viewBox="0 0 20 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M9.80926 18L8.38692 16.7052C3.33515 12.1243 0 9.103 0 5.3951C0 2.37384 2.37384 0 5.3951 0C7.10191 0 8.74005 0.79455 9.80926 2.05014C10.8785 0.79455 12.5166 0 14.2234 0C17.2447 0 19.6185 2.37384 19.6185 5.3951C19.6185 9.103 16.2834 12.1243 11.2316 16.715L9.80926 18Z" />
                </svg>
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  giveExtraLifeToEveryoneThatAnsweredX({
                    variables: { optionX: 3 },
                  });
                  setSentLifeTo3Answerers(true);
                }}
                disabled={loadingGiveLifeToXAnswerers || sentLifeTo3Answerers}
                className={`${
                  life3Received
                    ? "disabled:bg-blue-500"
                    : sentLifeTo3Answerers
                    ? "disabled:opacity-50"
                    : loadingGiveLifeToXAnswerers
                    ? "bg-gray-300"
                    : "hover:bg-opacity-50"
                } relative flex items-center justify-center bg-red-600 shrink-0 transition-all sm:w-12 w-10 aspect-square rounded-full `}
              >
                <p className="absolute font-semibold text-black">3</p>
                <svg
                  className="px-2 sm:px-2.5 pt-0.5 fill-current text-white"
                  viewBox="0 0 20 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M9.80926 18L8.38692 16.7052C3.33515 12.1243 0 9.103 0 5.3951C0 2.37384 2.37384 0 5.3951 0C7.10191 0 8.74005 0.79455 9.80926 2.05014C10.8785 0.79455 12.5166 0 14.2234 0C17.2447 0 19.6185 2.37384 19.6185 5.3951C19.6185 9.103 16.2834 12.1243 11.2316 16.715L9.80926 18Z" />
                </svg>
              </button>
            </>
          )}
          {triviaNight.status !== -1 && triviaNight.status !== 3 && (
            <button
              onClick={(e) => {
                if (triviaNight.status === -2 || triviaNight.status >= 5) {
                  navigate("/tn/" + triviaNight.dayNum);
                } else {
                  downloadScreenshots(e);
                }
              }}
              disabled={loadingSendTriviaNightReminder}
              className="bg-black shrink-0 transition-all w-12 hover:bg-opacity-50 disabled:hover:bg-opacity-100 aspect-square rounded-full "
            >
              <svg
                className="p-2.5 fill-current text-white"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M19 9.5H15V3.5H9V9.5H5L12 16.5L19 9.5ZM11 11.5V5.5H13V11.5H14.17L12 13.67L9.83 11.5H11ZM19 20.5V18.5H5V20.5H19Z"
                />
              </svg>
            </button>
          )}
          {triviaNight.status === 0 &&
            !(
              triviaNight.is1hReminderSent && triviaNight.is5minReminderSent
            ) && (
              <button
                onClick={sendTNReminderCallback}
                disabled={loadingSendTriviaNightReminder}
                className="bg-black shrink-0 transition-all w-12 hover:bg-opacity-50 disabled:hover:bg-opacity-100 aspect-square rounded-full "
              >
                {loadingSendTriviaNightReminder ? (
                  <svg
                    className={`p-3 text-white stroke-current m-auto motion-safe:animate-spin`}
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.636 3.636A9 9 0 0119 10"
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                  </svg>
                ) : triviaNight.is1hReminderSent ? (
                  <svg
                    className="p-2.5 fill-current text-white"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M18 15.75V10.75C18 7.68 16.37 5.11 13.5 4.43V3.75C13.5 2.92 12.83 2.25 12 2.25C11.17 2.25 10.5 2.92 10.5 3.75V4.43C7.64003 5.11 6.00003 7.67 6.00003 10.75V15.75L4.00003 17.75V18.75H20V17.75L18 15.75ZM12 21.75C13.1 21.75 14 20.85 14 19.75H10C10 20.85 10.9 21.75 12 21.75ZM8.00003 16.75H16V10.75C16 8.27 14.49 6.25 12 6.25C9.51003 6.25 8.00003 8.27 8.00003 10.75V16.75ZM7.58003 3.83L6.15003 2.4C3.75003 4.23 2.17003 7.05 2.03003 10.25H4.03003C4.18003 7.6 5.54003 5.28 7.58003 3.83ZM21.97 10.25H19.97C19.82 7.6 18.45 5.28 16.43 3.83L17.85 2.4C20.24 4.23 21.82 7.05 21.97 10.25Z"
                    />
                  </svg>
                ) : (
                  <svg
                    className="p-2.5 fill-current text-white"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M18 15.75V10.75C18 7.68 16.37 5.11 13.5 4.43V3.75C13.5 2.92 12.83 2.25 12 2.25C11.17 2.25 10.5 2.92 10.5 3.75V4.43C7.64 5.11 6 7.67 6 10.75V15.75L4 17.75V18.75H20V17.75L18 15.75ZM12 21.75C13.1 21.75 14 20.85 14 19.75H10C10 20.85 10.9 21.75 12 21.75ZM8 16.75H16V10.75C16 8.27 14.49 6.25 12 6.25C9.51 6.25 8 8.27 8 10.75V16.75Z"
                    />
                  </svg>
                )}
              </button>
            )}
          {/* <a
            href={"/@" + triviaNight.username}
            target="_blank"
            rel="noreferrer"
            className="bg-black shrink-0 transition-all w-12 hover:bg-opacity-50 aspect-square rounded-full "
          >
            <svg
              className="p-2 fill-current text-white"
              viewBox="0 0 27 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.375 16.875H7.875C6.01875 16.875 4.5 15.3562 4.5 13.5C4.5 11.6437 6.01875 10.125 7.875 10.125H12.375V7.875H7.875C4.77 7.875 2.25 10.395 2.25 13.5C2.25 16.605 4.77 19.125 7.875 19.125H12.375V16.875ZM19.125 7.875H14.625V10.125H19.125C20.9813 10.125 22.5 11.6437 22.5 13.5C22.5 15.3562 20.9813 16.875 19.125 16.875H14.625V19.125H19.125C22.23 19.125 24.75 16.605 24.75 13.5C24.75 10.395 22.23 7.875 19.125 7.875ZM18 12.375H9V14.625H18V12.375Z"
              />
            </svg>
          </a> */}
          {/* <a
            href={"mailto:" + triviaNight.email}
            target="_blank"
            rel="noreferrer"
            className="bg-black shrink-0 transition-all w-12 hover:bg-opacity-50 aspect-square rounded-full "
          >
            <svg
              className="p-2 fill-current text-white"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM4 8L12 13L20 8V18H4V8ZM4 6L12 11L20 6H4Z"
              />
            </svg>
          </a> */}
        </div>
        <div className="py-2 px-4 text-gray-200 bg-gray-800 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-4 w-full rounded-lg">
          <p>ID</p>
          <p>{triviaNight.id}</p>
        </div>

        <div className="py-2 px-4 text-gray-200 bg-gray-800 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg">
          <p> {triviaNight.status > 0 ? "Cur Q" : "Questions"}</p>
          <p>
            {triviaNight.status > 0
              ? `${triviaNight.curQIndex + 1}/${triviaNight.numOfQs}`
              : `${triviaNight.numOfQs}`}
          </p>
        </div>

        {triviaViewCount ? (
          <div className="py-2 px-4 text-gray-200 bg-gray-800 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg">
            <p>Views</p>
            <p>{triviaViewCount}</p>
          </div>
        ) : (
          <></>
        )}
        {triviaNight.status === -2 || triviaNight.status >= 5 ? (
          <div className="py-2 px-4 text-gray-200 bg-gray-800 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg">
            <p>Winners</p>
            <p>{triviaNight.numOfWinners}</p>
          </div>
        ) : (
          <></>
        )}
        {triviaNight.status === -2 || triviaNight.status >= 5 ? (
          <div className="py-2 px-4 text-gray-200 bg-gray-800 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg">
            <p>Prize Winners</p>
            <p>{triviaNight.numOfEligibleForPrizeWinners}</p>
          </div>
        ) : (
          <></>
        )}
        <div className="py-2 px-4 text-gray-200 bg-gray-800 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg">
          <p>Country</p>
          <p>{triviaNight.country}</p>
        </div>

        <div className="py-2 px-4 text-gray-200 bg-gray-800 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg">
          <p>Prize tokens</p>
          <p>{triviaNight.prizeCoins}</p>
        </div>

        {/* <div className="py-2 px-4 text-gray-200 bg-gray-800 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg">
          <p>Num of players</p>
          <p>{triviaNight.numOfShares}</p>
        </div> */}
        <div className="py-2 px-4 text-gray-200 bg-gray-800 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg">
          <p>Status</p>
          <p>
            {triviaNight.status === -1
              ? "Unlaunched"
              : triviaNight.status === 0
              ? "Wait screen"
              : triviaNight.status === 1
              ? "Ready screen"
              : triviaNight.status === 2
              ? "Question screen"
              : triviaNight.status === 3
              ? "Answer stats"
              : triviaNight.status === 4
              ? "Answer stats"
              : triviaNight.status === 5
              ? "Game complete"
              : triviaNight.status === 5
              ? "Post-game"
              : "Archived"}
          </p>
        </div>

        {triviaNight.status <= 4 && triviaNight.status !== -2 && (
          <div className="mt-4 text-center sm:text-lg flex items-center  text-white justify-start w-full overflow-hidden rounded-lg">
            {triviaNight.status === -1 && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  deleteTriviaNight();
                  // toggleCancelJobForm(index);
                }}
                className="px-4 short:px-2 py-3 hover:bg-red-100 transition-all"
              >
                <svg
                  className="h-6 fill-current  m-auto"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.5 3H9.5L8.5 4H5V6H19V4H15.5L14.5 3ZM16 9V19H8V9H16ZM6 7H18V19C18 20.1 17.1 21 16 21H8C6.9 21 6 20.1 6 19V7Z"
                  />
                </svg>
              </button>
            )}

            {/* {triviaNight.status === -1 && ( */}
            <button
              // onClick={copyTrackingLink}
              onClick={(e) => {
                e.preventDefault();
                setTargetTN(index);
              }}
              className={`${
                triviaNight.status === -1
                  ? "px-8 short:px-4 border-l"
                  : "px-4 border-r"
              } flex flex-1 items-center justify-center relative font-semibold  border-gray-700 bg-blue-700 hover:bg-blue-800 transition-all text-white`}
            >
              <p className={`py-3`}>Edit</p>
            </button>
            {/* )} */}

            {triviaNight.status >= 0 && triviaNight.status < 5 && (
              <button
                // onClick={copyTrackingLink}
                onClick={(e) => {
                  e.preventDefault();

                  // if (!isPaused) {
                  setIsPaused(!isPaused);
                  // }
                  var curTime = new Date();
                  var secondsLeftAtPause = 0;
                  var timeLeftInSec = 0;
                  var timeLeftInMilli = 0;
                  if (!triviaNight.isPaused) {
                    if (triviaNight.status === 0) {
                      const releaseDate = new Date(triviaNight.releaseDate);
                      timeLeftInMilli =
                        releaseDate.getTime() - curTime.getTime();
                      timeLeftInSec = Math.floor(timeLeftInMilli / 1000);

                      if (timeLeftInSec <= 0) {
                        // throw new UserInputError("Errors", {
                        //   errors: {
                        //     pause: "The show must go on!",
                        //   },
                        // });
                      } else {
                        secondsLeftAtPause =
                          timeLeftInSec > 0 ? timeLeftInSec : 20;
                      }
                    } else if (triviaNight.status === 1) {
                      const nextQStartsAt = new Date(triviaNight.nextQStartsAt);
                      timeLeftInMilli = nextQStartsAt - curTime.getTime();
                      timeLeftInSec = Math.floor(timeLeftInMilli / 1000);

                      if (timeLeftInSec <= 0) {
                        // throw new UserInputError("Errors", {
                        //   errors: {
                        //     pause: "The show must go on!",
                        //   },
                        // });
                      } else {
                        secondsLeftAtPause =
                          timeLeftInSec > 0 ? timeLeftInSec : 20;
                      }
                    } else if (triviaNight.status === 3) {
                      const curAnsEndsAt = new Date(triviaNight.curAnsEndsAt);
                      timeLeftInMilli =
                        curAnsEndsAt.getTime() - curTime.getTime();
                      timeLeftInSec = Math.floor(timeLeftInMilli / 1000);

                      if (timeLeftInSec <= 0) {
                        // throw new UserInputError("Errors", {
                        //   errors: {
                        //     pause: "The show must go on!",
                        //   },
                        // });
                      } else {
                        secondsLeftAtPause =
                          timeLeftInSec > 0 ? timeLeftInSec : 20;
                      }
                    }
                    setTempElapsedTime(secondsLeftAtPause);
                  }

                  togglePauseQuiz({
                    variables: {
                      triviaNight: triviaNight ? triviaNight.id : null,
                      secondsLeftAtPause,
                    },
                  });
                }}
                className={`px-8 short:px-3 flex items-center justify-center relative font-semibold border-r border-gray-700 bg-gray-700 hover:bg-gray-800 transition-all text-white`}
              >
                <p className={`py-3`}>
                  {triviaNight.isPaused ? "Play" : "Pause"}
                </p>
              </button>
            )}

            {triviaNight.numOfQs > 0 && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  updateQuizStatus();
                }}
                className={`${
                  triviaNight.status === -1 ? "border-l border-gray-700" : ""
                } whitespace-nowrap flex-1 shrink-0 py-3 font-semibold hover:bg-gray-800 transition-all short:px-4 px-8`}
              >
                <p className="">Update status</p>
              </button>
            )}
          </div>
        )}

        {/* 
        <div className="py-2 px-4 text-gray-200 bg-gray-800 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-4 w-full rounded-lg">
          <p>Plan</p>
          <p>{triviaNight.subscriptionType}</p>
        </div> */}

        {/* <div className="mt-4 text-center sm:text-lg flex items-center  text-gray-600 justify-start w-full overflow-hidden rounded-lg">
          <button
            onClick={(e) => {
              e.preventDefault();
              toggleCancelJobForm(index);
            }}
            className="px-4 short:px-2 py-3 hover:bg-red-100 transition-all"
          >
            <svg
              className="h-6 fill-current  m-auto"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.5 3H9.5L8.5 4H5V6H19V4H15.5L14.5 3ZM16 9V19H8V9H16ZM6 7H18V19C18 20.1 17.1 21 16 21H8C6.9 21 6 20.1 6 19V7Z"
              />
            </svg>
          </button>

          <button
            onClick={copyTrackingLink}
            className={`${
              pendingJob.status !== 5 ? "sm:hidden block" : "hidden"
            } px-4 py-3 border-l hover:bg-blue-100 transition-all`}
          >
            <svg
              className="h-6 fill-current  m-auto"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 15H7C5.35 15 4 13.65 4 12C4 10.35 5.35 9 7 9H11V7H7C4.24 7 2 9.24 2 12C2 14.76 4.24 17 7 17H11V15ZM17 7H13V9H17C18.65 9 20 10.35 20 12C20 13.65 18.65 15 17 15H13V17H17C19.76 17 22 14.76 22 12C22 9.24 19.76 7 17 7ZM16 11H8V13H16V11Z"
              />
            </svg>
          </button>

          <button
            onClick={copyTrackingLink}
            className={`${
              pendingJob.status !== 5 ? "px-4 hidden sm:block" : "w-full"
            } flex items-center justify-center relative font-semibold border-l border-gray-700 bg-blue-700 hover:bg-blue-800 transition-all text-white`}
          >
            <p
              className={`${
                isLinkCopied
                  ? "motion-safe:animate-fadeDownFast"
                  : "motion-safe:animate-fadeOutUpFast"
              } py-3  bg-green-200 inset-0 w-full absolute`}
            >
              Copied!
            </p>

            <p
              className={`${
                isLinkCopied
                  ? "motion-safe:animate-fadeOutDownFast"
                  : "motion-safe:animate-fadeUpFast"
              } py-3 `}
            >
              {pendingJob.status === 5
                ? "Copy revision payment link"
                : "Tracking link"}
            </p>
          </button>

          {pendingJob.status !== 5 && (
            <button
              onClick={(e) => {
                e.preventDefault();
                toggleMakeRevisionPaymentRequestForm(index);
              }}
              className=" flex-1 shrink-0 py-3 font-semibold border-l border-gray-700 bg-blue-700 hover:bg-blue-800 transition-all text-white"
            >
              <p className="hidden sm:block">Make revision payment request</p>
              <p className="block sm:hidden">Request revision payment</p>
            </button>
          )}
        </div> */}
      </div>
    </div>
  );
}

export default AdminTNBox;
