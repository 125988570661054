import { useMutation } from "@apollo/client";

import React, { useState } from "react";
import { adminClient } from "../../GraphqlApolloClients";

import { useForm } from "../../util/hooks";
import ProfilePicInputField from "./ProfilePicInputField";
import TagsInput from "./TagsInput";

import { EDIT_TOPIC_MUTATION, FETCH_TOPICS } from "../../util/gqlFunctions";
import TextInputField from "./TextInputField";

function EditTopicForm({ targetTopic, setTargetTopic }) {
  const [errors, setErrors] = useState({});

  const { onChange, values, setValues, onChangeMedia } = useForm(
    editTopicCallback,
    {
      topicId: targetTopic.id,
      fieldsToEdit: [],
      ...targetTopic,
    }
  );

  const [editTopic, { loading: loadingEditTopic }] = useMutation(
    EDIT_TOPIC_MUTATION,
    {
      refetchQueries: [{ query: FETCH_TOPICS }],
      update(_, { data: { editTopic: editedTopic } }) {
        setValues({
          ...values,
          ...editedTopic,
          fieldsToEdit: [],
        });

        // setTargetTopic(null);
        setErrors({});
      },
      onError(err) {
        console.log(err);
        if (err.graphQLErrors[0]) {
          console.log(err.graphQLErrors[0].extensions.errors);

          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: values,
      client: adminClient,
    }
  );

  function setFieldsToEdit(name) {
    if (
      values.fieldsToEdit &&
      values.fieldsToEdit.includes(name) &&
      targetTopic
    ) {
      if (
        name.includes("Amount") ||
        name.includes("Price") ||
        name === "discount"
      ) {
        setValues({
          ...values,
          fieldsToEdit: [],
          [name]: targetTopic[name] ? Math.floor(targetTopic[name] / 100) : 10,
        });
      } else {
        setValues({
          ...values,
          fieldsToEdit: [],
          [name]: targetTopic[name],
        });
      }
    } else {
      setValues({
        ...values,
        fieldsToEdit: [name],
      });
    }
  }

  function editTopicCallback(e) {
    e.preventDefault();
    editTopic();
  }

  // Initialize the Autocomplete objects
  var cityAutocomplete = new window.google.maps.places.Autocomplete(
    document.getElementById("city")
  );

  // Set the types to retrieve only cities and provinces/states
  cityAutocomplete.setTypes(["(cities)"]);

  // Listen for the "place_changed" event of the Autocomplete objects
  cityAutocomplete.addListener("place_changed", function () {
    var place = cityAutocomplete.getPlace();
    if (place && place.address_components) {
      // Update the city field value

      var province = getProvinceFromAddressComponents(place.address_components);
      var country = getCountryFromAddressComponents(place.address_components);

      setValues({
        ...values,
        fieldsToEdit: ["province", "city", "country"],
        province: province || values.province,
        city: place.address_components[0].long_name,
        country: country || values.country,
      });
    }
  });

  function getProvinceFromAddressComponents(components) {
    for (var i = 0; i < components.length; i++) {
      var component = components[i];
      if (component.types.indexOf("administrative_area_level_1") !== -1) {
        return component.long_name;
      }
    }
    return null;
  }
  function getCountryFromAddressComponents(components) {
    for (var i = 0; i < components.length; i++) {
      var component = components[i];
      if (component.types.indexOf("country") !== -1) {
        return component.long_name;
      }
    }
    return null;
  }

  return (
    <div className="fixed pt-16 sm:py-10 z-50 overscroll-contain w-screen h-screen max-h-screen max-w-screen overflow-hidden flex items-end justify-end sm:items-center sm:justify-center">
      <button
        onClick={(e) => {
          e.preventDefault();
          setErrors({});
          setTargetTopic(null);
        }}
        className="z-40 bg-gray-500 hide-button-flash overscroll-contain bg-opacity-10 motion-safe:animate-fadeInFast flex items-center justify-center inset-0 absolute backdrop-blur-sm"
        disabled={false}
      />
      <form
        // onSubmit={buyBandCallback}
        className={`overflow-x-hidden max-h-full flex-shrink motion-safe:animate-fadeUpFast overscroll-contain sm:m-auto w-full max-w-xl overflow-y-auto flex flex-col items-start justify-start z-40 rounded-xl px-6 pb-6 sm:p-8 bg-darker-gray shadow-xl rounded-b-none sm:rounded-3xl short:px-4 short:pb-4 `}
      >
        <button
          onClick={(e) => {
            e.preventDefault();
            setErrors({});
            setTargetTopic(null);
          }}
          className="w-full pt-4 short:pt-3 short:pb-1 pb-2 sm:hidden"
        >
          <div className="rounded-full bg-gray-700 flex-shrink-0 h-1 mx-auto w-10 mb-2"></div>
        </button>

        <p className="text-white text-2xl font-bold w-full short:text-xl short:leading-tight">
          Edit topic
        </p>
        <p className="text-gray-400 w-full">These edits reflect live</p>

        {/* <div className="mt-4 w-full flex items-start justify-start flex-col">
            <p className="font-medium">Full name</p>

          </div> */}
        <div className="w-full flex flex-col items-start justify-start space-y-6 mt-4">
          <TextInputField
            title={"Name"}
            name="name"
            type="text"
            error={errors.name}
            disabled={loadingEditTopic}
            onChange={onChange}
            maxLen={100}
            setFieldsToEdit={setFieldsToEdit}
            fieldsToEdit={values.fieldsToEdit}
            editFunc={editTopic}
            value={values.name}
          />
          <TextInputField
            title="Short form"
            name="shortForm"
            type="text"
            error={errors.shortForm}
            disabled={loadingEditTopic}
            onChange={onChange}
            maxLen={30}
            setFieldsToEdit={setFieldsToEdit}
            fieldsToEdit={values.fieldsToEdit}
            editFunc={editTopic}
            value={values.shortForm}
          />

          <div className="flex w-full items-start justify-start space-x-4 sm:space-x-8">
            <ProfilePicInputField
              title="Logo"
              name="logoFile"
              isSquare={true}
              onChangeMedia={onChangeMedia}
              value={values.logoUrl}
              editFunc={editTopic}
              values={values}
              error={errors.logoFile}
            />
            <div className="flex items-start flex-col justify-start space-y-2 sm:text-base text-sm">
              <label className="font-semibold text-gray-200">
                Accent colour
              </label>
              <div className="grid grid-cols-4 sm:grid-cols-6 w-full gap-2 h-full">
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((colour, index) => (
                  <button
                    className={`${
                      index === values.accentColor
                        ? "border-gray-700 border-4 box-border"
                        : "hover:border-gray-600 hover:border-4"
                    } sm:w-10 aspect-square w-8 rounded-full bg-accent-${index} bg-gradient-to-br from-[--gradient-color-1] via-purple-500 to-pink-500 transition-all`}
                    onClick={(e) => {
                      e.preventDefault();

                      editTopic({
                        variables: {
                          topicId: targetTopic.id,
                          fieldsToEdit: ["accentColor"],
                          accentColor: index,
                        },
                      });
                      // Gradient.apply
                    }}
                    key={index}
                  ></button>
                ))}
              </div>
            </div>
          </div>

          <TextInputField
            title="Description"
            name="description"
            type="multiline"
            value={values.description}
            onChange={onChange}
            setFieldsToEdit={setFieldsToEdit}
            fieldsToEdit={values.fieldsToEdit}
            editFunc={editTopic}
            error={errors.description}
            disabled={loadingEditTopic}
            maxLen={200}
            rows={"3"}
          />

          <TagsInput
            title="Tags"
            name="tags"
            type="multiline"
            value={values.tags}
            values={values}
            setValues={setValues}
            setFieldsToEdit={setFieldsToEdit}
            fieldsToEdit={values.fieldsToEdit}
            editFunc={editTopic}
            error={errors.tags}
            disabled={loadingEditTopic}
            maxTags={20}
          />

          <div className="w-full">
            <div className="flex items-center justify-between text-sm sm:text-base">
              <label htmlFor="type" className="font-semibold text-gray-400 ">
                Minimum bet{" "}
                {errors.type && (
                  <span className="text-red-500">{errors.type}</span>
                )}
              </label>
            </div>
            <div className="flex mt-1 overflow-x-auto items-center justify-start space-x-2">
              {[10, 50, 100, 150, 200, 300, 500, 750, 1000, 1250, 1500].map(
                (minBet) => (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      editTopic({
                        variables: {
                          fieldsToEdit: ["minimumBet"],
                          minimumBet: minBet,
                        },
                      });
                      // setValues({ ...values, minimumBet: minBet });
                    }}
                    className={`${
                      values.minimumBet === minBet
                        ? "bg-gray-700 border-gray-800 "
                        : "hover:bg-gray-700 border-gray-800 "
                    } shadow-sm text-white rounded-lg space-x-1 px-2 py-1 border transition-all flex items-center justify-start focus:border-indigo-500 focus:ring-indigo-500`}
                    disabled={loadingEditTopic}
                  >
                    <svg
                      className="w-5"
                      viewBox="0 0 800 800"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M400 800C608.641 800 777.778 630.863 777.778 422.222C777.778 213.581 608.641 44.4443 400 44.4443C191.36 44.4443 22.2227 213.581 22.2227 422.222C22.2227 630.863 191.36 800 400 800Z"
                        fill="#F4900C"
                      />
                      <path
                        d="M400 755.556C608.641 755.556 777.778 586.419 777.778 377.778C777.778 169.137 608.641 0 400 0C191.36 0 22.2227 169.137 22.2227 377.778C22.2227 586.419 191.36 755.556 400 755.556Z"
                        fill="#FFCC4D"
                      />
                      <path
                        d="M400 711.111C571.822 711.111 711.111 571.822 711.111 400C711.111 228.178 571.822 88.8887 400 88.8887C228.178 88.8887 88.8887 228.178 88.8887 400C88.8887 571.822 228.178 711.111 400 711.111Z"
                        fill="#FFE8B6"
                      />
                      <path
                        d="M400 688.889C571.822 688.889 711.111 549.6 711.111 377.778C711.111 205.956 571.822 66.667 400 66.667C228.178 66.667 88.8887 205.956 88.8887 377.778C88.8887 549.6 228.178 688.889 400 688.889Z"
                        fill="#FFAC33"
                      />
                      <path
                        d="M399.02 148C338.02 148 279.623 172.232 236.674 215.365C193.725 258.499 169.742 317 170.002 378C170.262 438.998 194.745 497.502 238.059 540.635C281.376 583.767 339.982 608 400.98 608C372.86 608 345.334 599.66 321.885 584.038C298.439 568.415 280.12 546.208 269.249 520.228C258.375 494.249 255.439 465.659 260.807 438.079C266.176 410.498 279.61 385.165 299.408 365.28C319.209 345.396 344.486 331.854 372.042 326.368C399.6 320.882 428.2 323.698 454.225 334.459C480.251 345.221 502.532 363.444 518.256 386.826C533.98 410.206 542.435 437.698 542.553 465.819C542.714 503.527 527.89 539.691 501.338 566.355C474.789 593.019 438.69 608 400.98 608C461.98 608 520.377 583.767 563.326 540.635C606.275 497.502 630.258 438.998 629.998 378C629.738 317 605.255 258.499 561.941 215.365C518.624 172.232 460.021 148 399.02 148Z"
                        fill="#FFD983"
                      />
                    </svg>
                    <p>{minBet}</p>
                  </button>
                )
              )}
            </div>
          </div>

          <div className="w-full">
            <div className="flex items-center justify-between text-sm sm:text-base">
              <label
                htmlFor={"shouldIncludeInTN"}
                className="font-semibold text-gray-400 "
              >
                Should include in TKL{" "}
                {errors.shouldIncludeInTN && (
                  <span className="text-red-500">{errors.type}</span>
                )}
              </label>
              <div className="flex-1"></div>
              {values.fieldsToEdit && values.fieldsToEdit.length === 0 && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setValues({
                      ...values,
                      fieldsToEdit: ["shouldIncludeInTN"],
                    });
                  }}
                  className="text-blue-300 ml-auto text-right transition-all font-semibold hover:text-blue-400"
                >
                  Edit
                </button>
              )}

              {values.fieldsToEdit &&
                values.fieldsToEdit.includes("shouldIncludeInTN") && (
                  <div className="flex items-center justify-end space-x-2">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setFieldsToEdit("shouldIncludeInTN");
                      }}
                      className="text-red-500 transition-all font-semibold hover:text-red-700"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        editTopic();
                      }}
                      className="text-green-500 transition-all font-semibold hover:text-green-700"
                    >
                      Save
                    </button>
                  </div>
                )}
            </div>

            <div
              className={`gap-2 flex-wrap flex items-center justify-start mt-2`}
            >
              {[true, false].map((option, index) => (
                <button
                  key={index}
                  onClick={(e) => {
                    e.preventDefault();
                    setValues({
                      ...values,
                      shouldIncludeInTN: option,
                    });
                  }}
                  className={`px-4 ${
                    values.shouldIncludeInTN !== option
                      ? "flex text-gray-400 disabled:opacity-30 disabled:hover:bg-transparent hover:bg-gray-700"
                      : "bg-gray-700"
                  }  rounded-full text-white items-center space-x-2 transition-all justify-start border border-gray-800 overflow-hidden py-1`}
                  disabled={
                    loadingEditTopic ||
                    (values.fieldsToEdit &&
                      !values.fieldsToEdit.includes("shouldIncludeInTN"))
                  }
                >
                  <p>{index === 0 ? "Yes" : "No"}</p>
                </button>
              ))}
            </div>
          </div>

          <div className="w-full">
            <div className="flex items-center justify-between text-sm sm:text-base">
              <label
                htmlFor={"shouldIncludeInIntro"}
                className="font-semibold text-gray-400 "
              >
                Should include in Intro{" "}
                {errors.shouldIncludeInIntro && (
                  <span className="text-red-500">{errors.type}</span>
                )}
              </label>
              <div className="flex-1"></div>
              {values.fieldsToEdit && values.fieldsToEdit.length === 0 && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setValues({
                      ...values,
                      fieldsToEdit: ["shouldIncludeInIntro"],
                    });
                  }}
                  className="text-blue-300 ml-auto text-right transition-all font-semibold hover:text-blue-400"
                >
                  Edit
                </button>
              )}

              {values.fieldsToEdit &&
                values.fieldsToEdit.includes("shouldIncludeInIntro") && (
                  <div className="flex items-center justify-end space-x-2">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setFieldsToEdit("shouldIncludeInIntro");
                      }}
                      className="text-red-500 transition-all font-semibold hover:text-red-700"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        editTopic();
                      }}
                      className="text-green-500 transition-all font-semibold hover:text-green-700"
                    >
                      Save
                    </button>
                  </div>
                )}
            </div>

            <div
              className={`gap-2 flex-wrap flex items-center justify-start mt-2`}
            >
              {[true, false].map((option, index) => (
                <button
                  key={index}
                  onClick={(e) => {
                    e.preventDefault();
                    setValues({
                      ...values,
                      shouldIncludeInIntro: option,
                    });
                  }}
                  className={`px-4 ${
                    values.shouldIncludeInIntro !== option
                      ? "flex text-gray-400 disabled:opacity-30 disabled:hover:bg-transparent hover:bg-gray-700"
                      : "bg-gray-700"
                  }  rounded-full text-white items-center space-x-2 transition-all justify-start border border-gray-800 overflow-hidden py-1`}
                  disabled={
                    loadingEditTopic ||
                    (values.fieldsToEdit &&
                      !values.fieldsToEdit.includes("shouldIncludeInIntro"))
                  }
                >
                  <p>{index === 0 ? "Yes" : "No"}</p>
                </button>
              ))}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditTopicForm;
