import React from "react";

export default function BlueFlatGradBack({ gradIndex }) {
  // useEffect(() => {
  //   const gradient = new Gradient();

  //   gradient.initGradient("#gradient-canvas-" + gradIndex || 0);
  // }, [gradIndex]);

  return (
    <div
      className={`z-10 w-screen h-64 ${
        gradIndex === 2
          ? "from-blue-700"
          : gradIndex === 1
          ? "from-emerald-800"
          : "from-blue-950"
      } to-black bg-gradient-to-b origin-top-left object-cover absolute inset-0 `}
    >
      {/* <canvas
        id={`gradient-canvas-${gradIndex || 0}`}
        className="motion-safe:animate-fadeInFast"
        data-js-darken-top
        data-transition-in
      /> */}
    </div>
  );
}
