import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";

import { customerClient, guestClient } from "../../GraphqlApolloClients";
import { useForm } from "../../util/hooks";

import {
  CREATE_FORUM_POST_MUTATION,
  FETCH_CUSTOMER,
  FETCH_GUEST_CUSTOMER,
  FETCH_QUESTION_VIEW_BY_CUSTOMER,
  FETCH_RECOMMENDED_FORUM_POSTS,
  FETCH_SUGGESTED_MSGS_BY_EVENT_QUERY,
} from "../../util/gqlFunctions";
import HorAdSmallBox from "./HorAdSmallBox";
import JustWideComments from "./JustWideComments";
import ProductAdBox from "./ProductAdBox";
import QuickPowerupSelection from "./QuickPowerupSelection";

function QuestionWideCommentSection({
  questionId,
  customer,
  navigate,
  targetGuestCustomer,
  targetCustomer,
  question,
  country,
  shouldShowCommentSection,
  setShouldShowCommentSection,
  narrowHorAd,
  regularHorAd,
  targetAdBookmark,
  livesCount,
  size,
  togglePurchasePowerUpForm,
  productAds,
}) {
  const [errors, setErrors] = useState({});
  const {
    // subscribeToMore: subscribeToMoreQView,
    data: { getQuestionViewByCustomer: questionView } = {},
    // loading: loadingQuestionView,
    // refetch: refetchQuestionView,
  } = useQuery(FETCH_QUESTION_VIEW_BY_CUSTOMER, {
    client: targetCustomer ? customerClient : guestClient,

    variables: { questionId },
  });
  const {
    data: { getRecommendedForumPostsBySearch: forumPosts } = {},
    // refetch: refetchRecommendedForumPosts,
    fetchMore: fetchMoreFPosts,

    loading: loadingRecommendedForumPosts,
  } = useQuery(FETCH_RECOMMENDED_FORUM_POSTS, {
    variables: {
      forumId: null,
      questionId: question?.id,
      triviaNightId: null,
      quizId: null,
      productId: null,
      questionViewId: questionView ? questionView.id : null,
      seenIds: [],
      folders: [],
      forumPostRepliedToId: null,
      searchTerm: null,
      type: 0,
      pageSize: 6,
    },
    fetchPolicy: "cache-and-network",

    client: customer ? customerClient : guestClient,
  });

  const { values, setValues } = useForm(createCommentCallback, {
    triviaNightId: null,
    quizId: null,
    productId: null,
    questionId: question?.id,
    questionViewId: questionView ? questionView.id : null,
    details: "",
    forumPostRepliedToId: null,
    repliedToUsername: "",
    pageSize: 10,
    seenIds: [],
    type: 0,

    title: "",
    mediaFiles: [],
    folders: [],
    visibility: 2,
  });

  const inputRef = useRef();

  // useEffect(() => {
  //   if (inputRef.current && size && size.width >= 640) {
  //     inputRef.current.focus();
  //   }
  // });

  const [sentMessages, setSentMessages] = useState([]);
  const resetInterval = 1000;
  const [isMsgError, setIsMsgError] = useState(false);
  useEffect(() => {
    let timeout;
    if (isMsgError && resetInterval) {
      timeout = setTimeout(() => setIsMsgError(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isMsgError, resetInterval]);
  const [headCommentToNewReplies, setHeadCommentToNewReplies] = useState({});
  const [newComments, setNewComments] = useState([]);

  const [createForumPost, { loading: loadingCreateForumPost }] = useMutation(
    CREATE_FORUM_POST_MUTATION,
    {
      refetchQueries: [
        { query: FETCH_CUSTOMER },
        { query: FETCH_GUEST_CUSTOMER },
      ],
      update(_, { data: { createForumPost: createdForumPost } }) {
        setValues({
          ...values,
          details: "",
          msg: "",
          forumPostRepliedToId: null,
          repliedToDisplayName: null,
          type: 0,
          searchTerm: null,
        });

        if (createdForumPost.type === 0) {
          setNewComments([createdForumPost, ...newComments]);
        } else {
          setHeadCommentToNewReplies({
            ...headCommentToNewReplies,
            [createdForumPost.headForumPostId]:
              headCommentToNewReplies[createdForumPost.headForumPostId] &&
              headCommentToNewReplies[createdForumPost.headForumPostId].length >
                0
                ? [
                    ...headCommentToNewReplies[
                      createdForumPost.headForumPostId
                    ],
                    createdForumPost,
                  ]
                : [createdForumPost],
          });
        }

        setErrors({});
      },
      onError(err) {
        setIsMsgError(true);

        console.log(err);

        if (err.graphQLErrors[0]) {
          console.log(err.graphQLErrors[0].extensions.errors);

          if (!targetCustomer) {
            navigate("/register");
          }

          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: values,
      client: targetCustomer ? customerClient : guestClient,
    }
  );
  function createCommentCallback(e) {
    e.preventDefault();

    createForumPost();
  }

  const [lastAnswerResult, setLastAnswerResult] = useState(-1);

  const {
    data: { getSuggestedMessagesByEvent: suggestedMessages } = {},
    refetch: refetchSuggestedMessages,
    loading: loadingSuggestedMessages,
  } = useQuery(FETCH_SUGGESTED_MSGS_BY_EVENT_QUERY, {
    variables: {
      triviaNightId: null,
      quizId: null,
      productId: null,
      questionViewId: questionView ? questionView.id : null,
      seenIds: [],
      pageSize: 10,
    },
    skip:
      !questionView ||
      questionView.answerResult === -1 ||
      questionView.status !== 1
        ? true
        : false,
    client: targetCustomer ? customerClient : guestClient,
  });

  useEffect(() => {
    if (
      questionView &&
      (values.questionViewId !== questionView.id ||
        values.questionId !== questionView.questionId)
    ) {
      setValues({
        ...values,
        questionViewId: questionView.id,
        questionId: questionView.questionId,
      });
    }
  }, [questionView, setValues, values]);

  useEffect(() => {
    if (
      questionView &&
      questionView.status === 1 &&
      questionView.answerResult !== lastAnswerResult
    ) {
      refetchSuggestedMessages({
        triviaNightId: null,
        quizId: null,
        productId: null,
        questionViewId: questionView.id,
        seenIds: [],
        pageSize: 10,
      });

      setSentMessages([]);
      setLastAnswerResult(questionView.answerResult);
    }
  }, [lastAnswerResult, questionView, refetchSuggestedMessages]);

  return (
    <div className="flex z-10 pl-4 xshort:pb-14 short:pb-24 pb-28 sm:pb-24 2xl:pb-28 shrink flex-col relative h-full items-center justify-end w-full">
      {shouldShowCommentSection ? (
        <>
          {/* {livesCount !== null && livesCount < 5 ? ( */}
          <QuickPowerupSelection
            targetCustomer={targetCustomer}
            togglePurchasePowerUpForm={togglePurchasePowerUpForm}
          />

          {productAds && productAds.length > 0 ? (
            <div className="flex z-10 mb-4 w-full shrink-0 items-start justify-start space-x-4 overflow-x-auto">
              {productAds.map((productAd, index) => (
                <ProductAdBox
                  key={productAd.id}
                  product={productAd}
                  size={size}
                  customer={targetCustomer}
                />
              ))}
            </div>
          ) : (
            <></>
          )}

          {/* <button
            onClick={(e) => {
              e.preventDefault();
              togglePurchasePowerUpForm(2);
            }}
            className="px-4 mb-4 xshort:px-1 w-full xshort:py-1 short:py-1.5 short:px-1.5 py-2 sm:pb-1 hover:bg-opacity-80 transition-opacity flex-col space-y-1 rounded-2xl short:rounded-xl bg-white flex items-center justify-center"
          >
            <div className="flex items-center justify-between w-full">
              <p>More lore</p>
              <svg
                className="h-5"
                viewBox="0 0 12 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.59 21.41L6 16.83L1.41 21.41L1.23266e-07 20L6 14L12 20L10.59 21.41Z"
                  fill="black"
                />
                <path
                  d="M1.41 0L6 4.58L10.59 0L12 1.41L6 7.41L0 1.41L1.41 0Z"
                  fill="black"
                />
              </svg>
            </div>
          </button> */}

          {/* {targetCustomer && ( */}
          <JustWideComments
            newComments={newComments}
            comments={forumPosts}
            country={country}
            fetchMoreParams={{
              forumId: null,
              questionId: question?.id,
              triviaNightId: null,
              quizId: null,
              productId: null,
              questionViewId: questionView ? questionView.id : null,
              seenIds: [],
              folders: [],
              forumPostRepliedToId: null,
              searchTerm: null,
              type: 0,
              pageSize: 6,
            }}
            fetchMoreComments={fetchMoreFPosts}
            navigate={navigate}
            loadingComments={loadingRecommendedForumPosts}
            setValues={setValues}
            values={values}
            targetCustomer={targetCustomer}
            targetGuestCustomer={targetGuestCustomer}
            questionId={questionId}
            headCommentToNewReplies={headCommentToNewReplies}
          />
        </>
      ) : (
        <>
          <div
            onClick={() => {
              navigate("/search");
            }}
            className={`bg-darker-gray hide-button-flash text-left cursor-text space-x-2 rounded-lg text-gray-300 duration-700 transition-height sm:pl-4 px-3 sm:px-4 flex items-center justify-start w-full`}
          >
            <svg
              className="fill-current text-gray-400 w-5 short:w-4"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 7C12 9.76142 9.76142 12 7 12C4.23858 12 2 9.76142 2 7C2 4.23858 4.23858 2 7 2C9.76142 2 12 4.23858 12 7ZM10.8482 12.8482C9.74407 13.5763 8.42152 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7C14 8.72211 13.3781 10.299 12.3467 11.5183L16.4142 15.5858L15 17L10.8482 12.8482Z"
              />
            </svg>

            <p
              className={`text-lg py-3 sm:py-4 font-bold leading-none w-full flex-1 bg-transparent text-gray-300 focus:outline-none`}
            >
              What are you looking for TKnight?
            </p>
            {/* <input
              // ref={inputRef}
              name={"searchTerm"}
              id={"searchTerm"}
              value={tempSearchTerm}
              onChange={(e) => {
                // const { value } = e.target;
                navigate("/search");
                // setTempSeachTerm(value);

                // Call the debouncedOnChange function with the updated value
                // debouncedOnChange(value);
              }}
              onClick={(e) => {
                // const { value } = e.target;
                navigate("/search");
                // setTempSeachTerm(value);

                // Call the debouncedOnChange function with the updated value
                // debouncedOnChange(value);
              }}
              className={`text-lg short:text-base py-3 sm:py-4 font-bold leading-none w-full flex-1 bg-transparent placeholder:text-gray-400 focus:outline-none`}
              placeholder={"What are you looking for TKnight?"}
              maxLength={100}
            /> */}

            {/* <button
            onClick={createCommentCallback}
            className={`${
              isMsgError ? "text-red-600" : "text-white"
            } p-4  hover:opacity-50 transition-all`}
          >
            <svg
              className="w-6 fill-current"
          
               viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M26 0L19.5 24L10.6936 16.1029L19.1458 7.12036L7.81625 15.0044L0 13.0909L26 0Z" fill="white"/>
</svg>

          </button> */}
          </div>
          <div className="my-4 rounded-lg overflow-y-auto flex-1 grid grid-cols-3 gap-3 grid-flow-row">
            <button
              onClick={() => {
                navigate("/search/tv-shows");
              }}
              className="w-full group"
            >
              <div className="w-full rounded-lg group-hover:opacity-80 transition-all overflow-hidden aspect-square grid grid-cols-2 grid-rows-2">
                <img
                  src={
                    "https://tknight-media.s3.amazonaws.com/376973_topic_logo_stranger-things.png"
                  }
                  alt="Stranger Things"
                />
                <img
                  src={
                    "https://tknight-media.s3.amazonaws.com/627921_topic_logo_the-flash.png"
                  }
                  alt="The Flash"
                />
                <img
                  src={
                    "https://tknight-media.s3.amazonaws.com/639415_topic_logo_the-office.png"
                  }
                  alt="The Office"
                />
                <img
                  src={
                    "https://tknight-media.s3.amazonaws.com/725404_topic_logo_friends.png"
                  }
                  alt="Friends"
                />
              </div>
              <p className="truncate text-center font-semibold text-white mt-1 2xl:text-lg">
                Shows
              </p>
            </button>
            <button
              onClick={() => {
                navigate("/search/sports");
              }}
              className="w-full group"
            >
              <div className="w-full rounded-lg group-hover:opacity-80 transition-all overflow-hidden aspect-square grid grid-cols-2 grid-rows-2">
                <img
                  src={
                    "https://tknight-media.s3.amazonaws.com/818855_topic_logo_nba.png"
                  }
                  alt="NBA"
                />

                <img
                  src={
                    "https://tknight-media.s3.amazonaws.com/556671_topic_logo_fifa.png"
                  }
                  alt="FIFA"
                />

                <img
                  src={
                    "https://tknight-media.s3.amazonaws.com/041947_topic_logo_tennis-icon.png"
                  }
                  alt="Grand Slam"
                />
                <img
                  src={
                    "https://tknight-media.s3.amazonaws.com/057893_topic_logo_nfl-icon.png"
                  }
                  alt="NFL"
                />
              </div>
              <p className="truncate text-center font-semibold text-white mt-1 2xl:text-lg">
                Sports
              </p>
            </button>
            <button
              onClick={() => {
                navigate("/search/music");
              }}
              className="w-full group"
            >
              <div className="w-full rounded-lg group-hover:opacity-80 transition-all overflow-hidden aspect-square grid grid-cols-2 grid-rows-2">
                <img
                  src={
                    "https://tknight-media.s3.amazonaws.com/487408_topic_logo_rap.png"
                  }
                  alt="Rap"
                />
                <img
                  src={
                    "https://tknight-media.s3.amazonaws.com/758941_topic_logo_bollywood.png"
                  }
                  alt="Hindi Film Music"
                />
                <img
                  src={
                    "https://tknight-media.s3.amazonaws.com/603545_topic_logo_pop.png"
                  }
                  alt="Taylor Swift"
                />
                <img
                  src={
                    "https://tknight-media.s3.amazonaws.com/780569_topic_logo_kpop.png"
                  }
                  alt="K-Pop"
                />
              </div>
              <p className="truncate text-center font-semibold text-white mt-1 2xl:text-lg">
                Music
              </p>
            </button>
            <button
              onClick={() => {
                navigate("/search/history");
              }}
              className="w-full group"
            >
              <div className="w-full rounded-lg group-hover:opacity-80 transition-all overflow-hidden aspect-square grid grid-cols-2 grid-rows-2">
                <img
                  src={
                    "https://tknight-media.s3.amazonaws.com/009156_topic_logo_world-war.png"
                  }
                  alt="World War II"
                />
                <img
                  src={
                    "https://tknight-media.s3.amazonaws.com/952778_topic_logo_civil-right.png"
                  }
                  alt="Civil Rights Movement"
                />
                <img
                  src={
                    "https://tknight-media.s3.amazonaws.com/847490_topic_logo_mongolian-empite.png"
                  }
                  alt="Mongol Empire"
                />
                <img
                  src={
                    "https://tknight-media.s3.amazonaws.com/373184_topic_logo_ancient-egypt-2.png"
                  }
                  alt="Ancient Egypt"
                />
              </div>
              <p className="truncate text-center font-semibold text-white mt-1 2xl:text-lg">
                History
              </p>
            </button>
            <button
              onClick={() => {
                navigate("/search/science");
              }}
              className="w-full group"
            >
              <div className="w-full rounded-lg group-hover:opacity-80 transition-all overflow-hidden aspect-square grid grid-cols-2 grid-rows-2">
                <img
                  src={
                    "https://tknight-media.s3.amazonaws.com/725397_topic_logo_biology.png"
                  }
                  alt="Biology"
                />
                <img
                  src={
                    "https://tknight-media.s3.amazonaws.com/034018_topic_logo_physics.png"
                  }
                  alt="Physics"
                />
                <img
                  src={
                    "https://tknight-media.s3.amazonaws.com/864564_topic_logo_space.png"
                  }
                  alt="Space"
                />
                <img
                  src={
                    "https://tknight-media.s3.amazonaws.com/552107_topic_logo_chemistry.png"
                  }
                  alt="Chemistry"
                />
              </div>
              <p className="truncate text-center font-semibold text-white mt-1 2xl:text-lg">
                Science
              </p>
            </button>
            <button
              onClick={() => {
                navigate("/search/books");
              }}
              className="w-full group"
            >
              <div className="w-full rounded-lg group-hover:opacity-80 transition-all overflow-hidden aspect-square grid grid-cols-2 grid-rows-2">
                <img
                  src={
                    "https://tknight-media.s3.amazonaws.com/032028_topic_logo_twilight.png"
                  }
                  alt="Twilight"
                />
                <img
                  src={
                    "https://tknight-media.s3.amazonaws.com/078640_topic_logo_percy.png"
                  }
                  alt="Percy Jackson"
                />
                <img
                  src={
                    "https://tknight-media.s3.amazonaws.com/829170_topic_logo_harry-potter.png"
                  }
                  alt="Harry Potter"
                />
                <img
                  src={
                    "https://tknight-media.s3.amazonaws.com/076900_topic_logo_hungergames.png"
                  }
                  alt="Hunger Games"
                />
              </div>
              <p className="truncate text-center font-semibold text-white mt-1 2xl:text-lg">
                Books
              </p>
            </button>
            <button
              onClick={() => {
                navigate("/search/video-games");
              }}
              className="w-full group"
            >
              <div className="w-full rounded-lg group-hover:opacity-80 transition-all overflow-hidden aspect-square grid grid-cols-2 grid-rows-2">
                <img
                  src={
                    "https://tknight-media.s3.amazonaws.com/469092_topic_logo_fortnite.png"
                  }
                  alt="Fortnite"
                />
                <img
                  src={
                    "https://tknight-media.s3.amazonaws.com/738033_topic_logo_lastofus.png"
                  }
                  alt="The Last of Us"
                />
                <img
                  src={
                    "https://tknight-media.s3.amazonaws.com/461932_topic_logo_gta.png"
                  }
                  alt="Grand Theft Auto"
                />
                <img
                  src={
                    "https://tknight-media.s3.amazonaws.com/943752_topic_logo_mario.png"
                  }
                  alt="Mario"
                />
              </div>
              <p className="truncate text-center font-semibold text-white mt-1 2xl:text-lg">
                Video Games
              </p>
            </button>
            <button
              onClick={() => {
                navigate("/search/films");
              }}
              className="w-full group"
            >
              <div className="w-full rounded-lg group-hover:opacity-80 transition-all overflow-hidden aspect-square grid grid-cols-2 grid-rows-2">
                <img
                  src={
                    "https://tknight-media.s3.amazonaws.com/180874_topic_logo_hindi%20films.png"
                  }
                  alt="Hindi Films"
                />
                <img
                  src={
                    "https://tknight-media.s3.amazonaws.com/853580_topic_logo_hollywoo.png"
                  }
                  alt="Sci-Fi Films"
                />
                <img
                  src={
                    "https://tknight-media.s3.amazonaws.com/362628_topic_logo_disney.png"
                  }
                  alt="Disney Animated Films"
                />
                <img
                  src={
                    "https://tknight-media.s3.amazonaws.com/853053_topic_logo_anakinn.png"
                  }
                  alt="Star Wars"
                />
              </div>
              <p className="truncate text-center font-semibold text-white mt-1 2xl:text-lg">
                Films
              </p>
            </button>

            <button
              onClick={() => {
                navigate("/search/business");
              }}
              className="w-full group"
            >
              <div className="w-full rounded-lg group-hover:opacity-80 transition-all overflow-hidden aspect-square grid grid-cols-2 grid-rows-2">
                <img
                  src={
                    "https://tknight-media.s3.amazonaws.com/896320_topic_logo_steve.png"
                  }
                  alt="Tech Giants"
                />
                <img
                  src={
                    "https://tknight-media.s3.amazonaws.com/253828_topic_logo_kylie.png"
                  }
                  alt="Famous Entrepreneurs"
                />
                <img
                  src={
                    "https://tknight-media.s3.amazonaws.com/691543_topic_logo_brnad%20logos.png"
                  }
                  alt="Brand Logos"
                />
                <img
                  src={
                    "https://tknight-media.s3.amazonaws.com/280133_topic_logo_marketting.png"
                  }
                  alt="Ad Campaigns"
                />
              </div>
              <p className="truncate text-center font-semibold text-white mt-1 2xl:text-lg">
                Business
              </p>
            </button>
          </div>
          {/* <div className="flex-1"></div> */}
        </>
      )}
      {/* // )} */}

      {/* <div
        className={`${
          shouldOpenQRCodeBox
            ? " motion-safe:animate-fadeLeftFast"
            : " motion-safe:animate-fadeOutRightFast "
        } absolute  flex-col p-2 right-0 bottom-0 flex items-center justify-center aspect-square w-1/3 bg-white rounded-xl`}
      > */}
      {/* <p className="text-lg font-semibold tracking-wider text-blue-600 leading-none mb-1">
          Code
        </p> */}

      {/* <div className="relative w-full">
          <QRCodeSVG
            value={"https://tknight.live/" + quizId}
            level="M"
            bgColor="transparent"
            className="w-full h-full rounded"
            imageSettings={{
              src: "https://convrt-media.s3.amazonaws.com/tknight_qr_code_logo.png",
              width: 40,
              height: 40,
            }}
          />
          <div className="absolute hover:opacity-100 opacity-0 transition-all flex items-center justify-center inset-0 w-full h-full">
            {/* <img
              className=" w-1/3 h-1/3 m-auto bottom-auto object-cover z-10"
              src={
                "https://convrt-media.s3.amazonaws.com/tknight_qr_code_logo.png"
              }
              alt={"Battle Code: " + quizId}
            /> 
            <svg
              className="w-1/4 bg-white hover:text-red-800 hover:scale-90 transition-all rounded-full h-1/4 fill-current text-red-600"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={(e) => {
                e.preventDefault();
                toggleShowQRCode();
              }}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 8.59L13.59 5L15 6.41L11.41 10L15 13.59L13.59 15L10 11.41L6.41 15L5 13.59L8.59 10L5 6.41L6.41 5L10 8.59Z"
              />
            </svg>
          </div>
        </div> */}

      {/* <p className="text-lg font-semibold tracking-wider text-blue-600 leading-none mt-1">
          {quizId}
        </p> */}
      {/* </div> */}
      {shouldShowCommentSection && (targetCustomer || targetGuestCustomer) && (
        <div
          className={`h-9 short:h-8 relative shrink-0 overflow-hidden transition-all flex w-full mb-2 items-center space-x-2`}
        >
          <div className="w-full flex gap-2 snap always-stop snap-y snap-mandatory overflow-y-scroll scrollbar-hide flex-wrap grow items-center justify-start overflow-x-auto h-full short:text-sm ">
            {questionView &&
              questionView.status === 1 &&
              lastAnswerResult === questionView.answerResult &&
              suggestedMessages &&
              !loadingSuggestedMessages &&
              suggestedMessages.length > 0 &&
              suggestedMessages
                .filter((msg) => !sentMessages.includes(msg))
                .map((msg, index) => (
                  <button
                    onClick={(e) => {
                      e.preventDefault();

                      setValues({ ...values, details: values.details + msg });
                    }}
                    key={msg}
                    className="hover:bg-gray-700 transition-all bg-gray-800 leading-tight h-full text-white rounded-lg px-2 py-1"
                  >
                    {msg}
                  </button>
                ))}
          </div>
          <svg
            className="h-full shrink-0 fill-current text-gray-800"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M18 0H2C0.9 0 0 0.9 0 2V20L4 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0Z" />
          </svg>
        </div>
      )}
      {!targetCustomer || !targetCustomer.isPaidSubscriber ? (
        !targetCustomer && size.width >= 768 ? (
          <div className="flex w-full shrink-0 mb-1 sm:mb-2 aspect-[58/7] h-auto items-center justify-center space-x-3">
            <a
              href="https://apps.apple.com/us/app/tknight/id6476206206"
              target="_blank"
              rel="noreferrer"
              className="flex w-full h-full pl-5 pr-6 border-gray-700 border bg-black hover:bg-gray-800 transition-all text-white rounded-lg items-center justify-center"
            >
              <div className="mr-3">
                <svg
                  viewBox="0 0 384 512"
                  className="aspect-square w-10 2xl:w-12"
                >
                  <path
                    fill="currentColor"
                    d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
                  />
                </svg>
              </div>
              <div>
                <div className="text-xs 2xl:text-base truncate">
                  Download on the
                </div>
                <div className="text-xl 2xl:text-2xl truncate font-semibold font-sans -mt-1">
                  App Store
                </div>
              </div>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=live.tknight"
              target="_blank"
              rel="noreferrer"
              className="flex w-full h-full border-gray-700 border bg-black hover:bg-gray-800 transition-all text-white rounded-lg items-center justify-center"
            >
              <div className="mr-3">
                <svg
                  viewBox="30 336.7 120.9 129.2"
                  className="aspect-square w-8 2xl:w-12"
                >
                  <path
                    fill="#FFD400"
                    d="M119.2,421.2c15.3-8.4,27-14.8,28-15.3c3.2-1.7,6.5-6.2,0-9.7  c-2.1-1.1-13.4-7.3-28-15.3l-20.1,20.2L119.2,421.2z"
                  />
                  <path
                    fill="#FF3333"
                    d="M99.1,401.1l-64.2,64.7c1.5,0.2,3.2-0.2,5.2-1.3  c4.2-2.3,48.8-26.7,79.1-43.3L99.1,401.1L99.1,401.1z"
                  />
                  <path
                    fill="#48FF48"
                    d="M99.1,401.1l20.1-20.2c0,0-74.6-40.7-79.1-43.1  c-1.7-1-3.6-1.3-5.3-1L99.1,401.1z"
                  />
                  <path
                    fill="#3BCCFF"
                    d="M99.1,401.1l-64.3-64.3c-2.6,0.6-4.8,2.9-4.8,7.6  c0,7.5,0,107.5,0,113.8c0,4.3,1.7,7.4,4.9,7.7L99.1,401.1z"
                  />
                </svg>
              </div>
              <div>
                <div className="text-xs 2xl:text-base truncate">GET IT ON</div>
                <div className="text-xl 2xl:text-2xl truncate font-semibold font-sans -mt-1">
                  Google Play
                </div>
              </div>
            </a>
          </div>
        ) : (
          <HorAdSmallBox
            horAdLink={
              // (triviaNight || quiz) && (triviaNight || quiz).status === 5

              // shouldShowCommentSection
              //   ?
              narrowHorAd?.link || null
              // : narrowHorAd?.link || null
            }
            areCommentsInFocus={false}
            isOutOfLives={livesCount === 0}
            targetCustomer={targetCustomer}
            targetGuestCustomer={targetGuestCustomer}
            targetAdBookmark={targetAdBookmark}
            horAdUrl={
              // shouldShowCommentSection
              //   ?
              narrowHorAd?.narrowHorAdUrl || null
              // : narrowHorAd?.narrowHorAdUrl || null
            }
            adId={
              // shouldShowCommentSection
              //   ?
              narrowHorAd?.id || null
              // : narrowHorAd?.id || null
            }
          />
        )
      ) : (
        <></>
      )}

      <div
        className={`${
          isMsgError
            ? "border-red-600 motion-safe:animate-shake"
            : "border-gray-700 "
        } ${
          shouldShowCommentSection ? "" : "h-0"
        } overflow-hidden sm:border-opacity-100 rounded-xl text-white duration-700 transition-height pl-4 short:pl-3 flex items-start justify-start border w-full`}
      >
        {isMsgError && errors.message && (
          <p className="absolute text-red-600 bottom-2 font-semibold">
            {errors.message}
          </p>
        )}
        <textarea
          ref={inputRef}
          name={"message"}
          id={"message"}
          rows="3"
          value={values.details}
          onChange={(e) => {
            e.preventDefault();

            var newMsg = e.target.value;
            setValues({
              ...values,
              details: newMsg,
            });
          }}
          className={`${
            isMsgError ? "" : ""
          } text-lg 2xl:text-2xl py-4 leading-tight w-full flex-1 bg-transparent placeholder:text-gray-200 focus:outline-none`}
          placeholder={
            values.repliedToDisplayName
              ? "Replying to " + values.repliedToDisplayName
              : "Add a comment..."
          }
          disabled={loadingCreateForumPost || !shouldShowCommentSection}
          onKeyDown={(e) => {
            if (e.key === "Enter" && e.shiftKey === false) {
              e.preventDefault();
              createForumPost();
            }
          }}
          maxLength={200}
        ></textarea>
        <button
          onClick={createCommentCallback}
          className={`${
            isMsgError ? "text-red-600" : "text-white"
          } p-4  hover:opacity-50 transition-all`}
          disabled={loadingCreateForumPost}
        >
          <svg
            className="w-6 2xl:w-7 short:w-5 fill-current"
            viewBox="0 0 26 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M26 0L19.5 24L10.6936 16.1029L19.1458 7.12036L7.81625 15.0044L0 13.0909L26 0Z" />
          </svg>
        </button>
      </div>
    </div>
  );
}

export default QuestionWideCommentSection;
