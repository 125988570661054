import { useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { adminClient, customerClient } from "../../GraphqlApolloClients";
import { useForm } from "../../util/hooks";

import {
  CREATE_QUESTION,
  FETCH_QUESTIONS_BY_TN_FOR_ADMIN,
  FETCH_RECOMMENDED_TOPICS,
} from "../../util/gqlFunctions";
import SelectTopicWSearchInputField from "./SelectTopicWSearchInputField";
import SubmitButton from "./SubmitButton";
import TextInputField from "./TextInputField";
import TopicIcon from "./TopicIcon";

// const questionTypes = {
//   2: "Majority-friends",
//   1: "Majority-everyone",
//   0: "Regular",
// };

function MakeQuestion({
  setShouldOpenCreateQ,
  triviaNightId,
  targetTopic,
  toggleQuestionAddedSuccessfullyForm,
  isAdmin,
}) {
  const [errors, setErrors] = useState({});

  const { onChange, values, setValues } = useForm(createQuestionCallback, {
    triviaNightId,
    topicNames: targetTopic ? [targetTopic.name] : [],
    questionDesc: "",
    interestingFact: "",
    option0: "",
    option1: "",
    option2: "",
    option3: "",
    questionType: 0,
    correctAnswer: 0,

    searchTerm: "",
    pageSize: 3,
    seenIds: [],
  });
  const [topicInputValue, setTopicInputValue] = useState("");

  const [createQuestion, { loading: loadingCreateQ }] = useMutation(
    CREATE_QUESTION,
    {
      refetchQueries: isAdmin
        ? [
            {
              query: FETCH_QUESTIONS_BY_TN_FOR_ADMIN,
              variables: { triviaNightId },
            },
          ]
        : [],
      update(_, { data: { createQuestion: createQ } }) {
        setValues({
          ...values,
          triviaNightId,
          topicNames: targetTopic ? [targetTopic.name] : [],
          questionDesc: "",
          interestingFact: "",
          option0: "",
          option1: "",
          option2: "",
          option3: "",
          questionType: 0,
          correctAnswer: 0,

          searchTerm: "",
        });
        setShouldOpenCreateQ(false);
        if (!isAdmin) {
          toggleQuestionAddedSuccessfullyForm();
        }
        setErrors({});
      },
      onError(err) {
        console.log(err);
        console.log(values);
        if (err.graphQLErrors[0]) {
          console.log(err.graphQLErrors[0].extensions.errors);

          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: values,
      client: isAdmin ? adminClient : customerClient,
    }
  );

  function createQuestionCallback(e) {
    e.preventDefault();
    e.stopPropagation();
    createQuestion();
  }

  // const { data: { getRecommendedTopicTitles: topicNames } = {} } = useQuery(
  //   FETCH_RECOMMENDED_TOPIC_TITLES,
  //   {
  //     client: admin ? adminClient : customerClient,
  //     variables: values,
  //     fetchPolicy: "network-only",
  //   }
  // );

  const {
    data: { getRecommendedTopicsBySearch: topics } = {},
    loading: loadingTopics,

    // fetchMore: fetchMoreTopics,
  } = useQuery(FETCH_RECOMMENDED_TOPICS, {
    client: isAdmin ? adminClient : customerClient,
    variables: values,
  });

  return (
    <div className="fixed pt-16 sm:py-10 z-50 overscroll-contain w-screen h-screen max-h-screen max-w-screen overflow-hidden flex items-end justify-end sm:items-center sm:justify-center">
      <button
        onClick={(e) => {
          e.preventDefault();
          setErrors({});
          setShouldOpenCreateQ(false);
        }}
        className="z-40 hide-button-flash bg-opacity-10 motion-safe:animate-fadeInFast flex items-center justify-center inset-0 absolute backdrop-blur-sm"
        disabled={false}
      />
      <form
        className={`overflow-x-hidden max-h-full flex-shrink motion-safe:animate-fadeUpFast overscroll-contain sm:m-auto w-full max-w-xl overflow-y-auto flex flex-col items-start justify-start z-40 rounded-xl px-6 pb-6 sm:p-8 bg-darker-gray shadow-xl rounded-b-none sm:rounded-3xl short:px-4 short:pb-4 `}
      >
        <button
          onClick={(e) => {
            e.preventDefault();
            setErrors({});
            setShouldOpenCreateQ(false);
          }}
          className="w-full pt-4 short:pt-3 short:pb-1 pb-2 sm:hidden"
        >
          <div className="rounded-full bg-gray-700 flex-shrink-0 h-1 mx-auto w-10 mb-2"></div>
        </button>

        <p className="text-white text-2xl font-bold w-full short:text-xl short:leading-tight">
          Add a question
        </p>
        <p className="text-gray-400 w-full leading-tight ">
          {isAdmin
            ? "Simple question with short options"
            : "Earn a token for every upvote!"}
        </p>

        {/* <div className="mt-4 w-full flex items-start justify-start flex-col">
            <p className="font-medium">Full name</p>

          </div> */}
        <div className="w-full flex flex-col items-start justify-start space-y-6 mt-6 mb-4">
          <div className="w-full">
            <div className="flex items-center justify-between text-sm sm:text-base">
              <label htmlFor="topic" className="font-semibold text-gray-400 ">
                Topic{" "}
                {errors.topic && (
                  <span className="text-red-500">{errors.topic}</span>
                )}
              </label>
            </div>
            {isAdmin || !targetTopic ? (
              <SelectTopicWSearchInputField
                error={errors.topicId}
                name={"topicId"}
                values={values}
                setValues={setValues}
                options={topics}
                loading={loadingTopics || loadingCreateQ}
                title={"Subject"}
                value={values.topicId}
                disabled={loadingTopics || loadingCreateQ}
                setTopicInputValue={setTopicInputValue}
                topicInputValue={topicInputValue}
              />
            ) : (
              <div className="mt-2 flex items-center justify-start space-x-3">
                <TopicIcon
                  topicId={targetTopic.id}
                  height={"h-10"}
                  disabled={true}
                  hideSeek={true}
                />
                <p className="text-lg">{targetTopic.name}</p>
              </div>
            )}
          </div>

          {/* <MultiSelectTopicWSearchInputField
            error={errors.topic}
            name={"topicNames"}
            values={values}
            setValues={setValues}
            options={topicNames}
            loading={loadingCreateQ}
            title={"Topic"}
            value={values.topicNames}
          /> */}

          <TextInputField
            title="Question"
            name="questionDesc"
            error={errors.questionDesc}
            disabled={loadingCreateQ}
            onChange={onChange}
            value={values.questionDesc}
            type="multiline"
            maxLen={200}
          />

          {values.questionType !== 2 ? (
            <>
              <TextInputField
                title="Correct option"
                name="option0"
                error={errors.option0}
                disabled={loadingCreateQ}
                onChange={onChange}
                value={values.option0}
              />
              <TextInputField
                title="Wrong option 1"
                name="option1"
                error={errors.option1}
                disabled={loadingCreateQ}
                onChange={onChange}
                value={values.option1}
              />
              <TextInputField
                title="Wrong option 2"
                name="option2"
                error={errors.option2}
                disabled={loadingCreateQ}
                onChange={onChange}
                value={values.option2}
              />
              <TextInputField
                title="Wrong option 3"
                name="option3"
                error={errors.option3}
                disabled={loadingCreateQ}
                onChange={onChange}
                value={values.option3}
              />
            </>
          ) : (
            <></>
          )}

          <TextInputField
            title="Interesting fact (optional)"
            name="interestingFact"
            error={errors.interestingFact}
            disabled={loadingCreateQ}
            onChange={onChange}
            value={values.interestingFact}
            type="multiline"
            maxLen={200}
          />

          {/* <div className="w-full grid grid-cols-2 gap-2 sm:gap-4">
            <div className="flex w-full items-start justify-start flex-col">
              <div className="flex items-center justify-between text-sm sm:text-base mb-2">
                <label
                  htmlFor={"questionMediaFile"}
                  className="font-semibold text-gray-400 "
                >
                  Question image (
                  {values.answerMediaFile ? "required" : "optional"}, 9:16){" "}
                  {errors.questionMediaFile && (
                    <span className="text-red-500">
                      {errors.questionMediaFile}
                    </span>
                  )}
                </label>
              </div>
              <div className="w-full aspect-[9/16]">
                <QuestionMediaInputField
                  name="questionMediaFile"
                  onChangeMedia={onChangeMedia}
                  error={errors.questionMediaFile}
                  disabled={loadingCreateQ}
                  previewPhotoURL={previewPhotoURL}
                  setPreviewPhotoURL={setPreviewPhotoURL}
                />
              </div>
            </div>

            <div className="flex w-full items-start justify-start flex-col">
              <div className="flex items-center justify-between text-sm sm:text-base mb-2">
                <label
                  htmlFor={"answerMediaFile"}
                  className="font-semibold text-gray-400 "
                >
                  Answer image (optional, 9:16){" "}
                  {errors.answerMediaFile && (
                    <span className="text-red-500">
                      {errors.answerMediaFile}
                    </span>
                  )}
                </label>
              </div>
              <div className="w-full aspect-[9/16]">
                <QuestionMediaInputField
                  name="answerMediaFile"
                  onChangeMedia={onChangeMedia}
                  error={errors.answerMediaFile}
                  disabled={loadingCreateQ}
                  previewPhotoURL={previewPhotoURL2}
                  setPreviewPhotoURL={setPreviewPhotoURL2}
                />
              </div>
            </div>
          </div> */}
        </div>

        <SubmitButton
          onClickFunc={createQuestionCallback}
          disabled={loadingCreateQ}
          title={"Submit"}
          errors={errors}
        />
      </form>
    </div>
  );
}

export default MakeQuestion;
